import React from 'react';
import styles from './styles.module.css';

export const Left = (props: any): JSX.Element => {
  return (
    <div className={styles.left}>
      {props.children}
    </div>
  );
};

export const Right = (props: any): JSX.Element => {
  return (
    <div className={styles.right}>
      {props.children}
    </div>
  );
};
