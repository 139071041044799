import { IConfig } from './types';

const config: IConfig = {
  env: 'staging',
  productName: 'Actively Learn',
  productUrl: 'https://altestal.activelylearn.com',
  backendUrl: 'https://api-test.activelylearn.com',
  readerAppUrl: 'https://altestal-reader.activelylearn.com',
  cookieDomain: '.activelylearn.com',
  csrfTokenKey: 'CSRF-TOKEN-STAGING',
  stripeKey: 'pk_test_hGcCTxnNucfD8epy7K4uXcNJ',
};

export default config;
