import Assign from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any, ownProps: any) => ({
  ltiAccountSource: state.userData.ltiAccountSource,
  classAssignments: ownProps.classAssignments,
  classId: ownProps.classId,
});

export default connect(
  mapStateToProps,
)(Assign);
