import Hints from './component';
import { connect } from 'react-redux';
import { getData, postData } from '../../../../actions/api';
import { markAllHintsViewed } from '../../../../actions/readerNavigation';
import { editMode, goToHint } from '../../../../actions/hints';

const mapStateToProps = (state: any) => {
  return {
    stepId: state.readerNavigation.currentStepId,
    currentHintIndex: state.hintsData.currentHintIndex,
    hints: state.hintsData.hints,
    isEditing: state.hintsData.isEditing,
    problemSetId: state.problemSetData.problemSetId,
    classId: state.problemSetData.classId,
    role: state.userData.role,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (stepId: number) => {
      dispatch(getData({
        type: 'HINT_DATA',
        params: { stepId },
        preCheck: (state: any) => {
          // don't fetch if data is already in store
          return state.hintsData.hints.length === 0;
        },
      }));
    },
    goToHint: (indexToGoTo: number) => {
      dispatch(goToHint(indexToGoTo));
    },
    track: (params: any) => {
      dispatch(postData({
        type: 'TRACK_HINTS',
        params
      }));
    },
    markAllHintsViewed: () => {
      dispatch(markAllHintsViewed());
    },
    saveHintEdits: (hintId: number, hintJson: any) => {
      const newHintJson = JSON.stringify({
        ...hintJson,
        response_id: undefined,
        is_math: undefined,
        instant_feedback: undefined,
      });

      dispatch(postData({
        type: 'SAVE_HINT_EDITS',
        params: { hintId, hintJson: newHintJson },
        mutators: {
          onResolve: (resolveAction: any) => ({
            ...resolveAction,
            body: { hintJson }
          })
        }
      }));
    },
    startEditing: () => {
      dispatch(editMode(true));
    },
    stopEditing: () => {
      dispatch(editMode(false));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hints);
