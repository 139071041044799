export const checkHasMasteredTheSkill = (params: any) => {
  // Show popup on problem complete (not in the middle of steps)
  const isProblemComplete = !!params.completedProblemId;

  const numProblemsCompleted = params.problemIds.indexOf(params.completedProblemId) + 1;
  const isLastProblemCompleted = numProblemsCompleted === params.problemIds.length;

  // Has mastery 0.9 on 5th problem or has mastered the skill after 5th problem (mastery < 0.9 to >= 0.9)
  const hasMasteredTheSkill = params.newMastery
    && params.newMastery >= 0.9
    && (
      numProblemsCompleted === 5
      || (params.currentMastery < 0.9 && numProblemsCompleted > 5)
    );

  return params.isSameProblemSetId && isProblemComplete && !isLastProblemCompleted && hasMasteredTheSkill;
};