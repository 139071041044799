import React from 'react';
import Button from '../../../common/button/component';
import ShowOriginalProblemModal from '../../../reader/modals/showOriginalProblem/container';
import { openModal } from '../../../../actions/eventEmitter';

const ShowOriginalProblemButton = (props: any): JSX.Element => {
  return props.isProblemNotComplete && props.validRoles.includes(props.role)
    ? (
      <Button
        orangeType="empty"
        onClick={() => openModal(<ShowOriginalProblemModal />)}
      >
        show problem
      </Button>
    )
    : <React.Fragment></React.Fragment>
};

export default ShowOriginalProblemButton;
