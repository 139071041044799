import React from 'react';
import { SchoolSelector, GradeLevelSelector } from '../../selectors';
import { TextValidator } from '../../formValidator';
import Button from '../../button/Button';
import MuiButton from '@mui/material/Button';
import { useNativeClassesListStyles } from './styles';
import CancelIcon from '../../../icons/cancel.svg';
import classnames from 'classnames';
import { Feature } from '../../../helpers/constants';

interface IAddNativeClassForm {
  isHigherEd?: boolean;
  schoolInfo: any[];
  nativeClasses: INativeClass[];
  isCreatingFirstClass: boolean;
  setNativeClasses: Function;
  gradeLevelSuggestions?: any;
};
interface INativeClass {
  id: number;
  name: string;
  gradeLevelId: string;
  schoolId: number | null;
  gradeLevelCourse: string;
};

export default function AddNativeClassForm({
  isHigherEd = false,
  schoolInfo,
  nativeClasses,
  isCreatingFirstClass,
  setNativeClasses,
  gradeLevelSuggestions = null,
}: IAddNativeClassForm) {
  const styles: any = useNativeClassesListStyles();
  const classes: any = {
    ...styles,
    classRow: classnames({ [styles.classRow]: isCreatingFirstClass }),
  };

  function handleNativeClassParamsChange(classToAdd): void {
    const index = nativeClasses.findIndex((x) => (x.id === classToAdd.id));
    const clonedNativeClasses = [...nativeClasses];

    if (index >= 0) {
      clonedNativeClasses[index] = classToAdd;
    }
    else {
      clonedNativeClasses.push(classToAdd);
    }

    setNativeClasses(clonedNativeClasses);
  }

  function removeNativeClass(classId): void {
    setNativeClasses(
      nativeClasses.filter((x) => (x.id !== classId))
    );
  }

  return (
    <div>
      <p>To start a new class, enter the info below:</p>
      {nativeClasses.map((nativeClass, index) => (
        <div
          className={classes.classRow}
          key={nativeClass.id}>
          <TextValidator
            id='txtName'
            label='Class name (up to 40 characters)'
            className="textValidator"
            inputProps={{
              maxLength: 40
            }}
            required='Please enter a class name'
            value={nativeClass.name}
            onChange={ev => handleNativeClassParamsChange({
              ...nativeClass,
              name: ev.target.value,
            })}
            fullWidth
          />
          <GradeLevelSelector
            isHigherEd={isHigherEd}
            showGradeLevelAsRange={false}
            gradeLevelId={nativeClass.gradeLevelCourse}
            onChange={ev => handleNativeClassParamsChange({
              ...nativeClass,
              gradeLevelCourse: ev.target.value.toString(),
              gradeLevelId: Number((ev.target.value).toString().replace(/\D/g, '')),
            })}
            gradeLevelSuggestions={gradeLevelSuggestions}
          />
          <SchoolSelector
            triggeredFrom={Feature.ADD_CLASS}
            schoolInfo={schoolInfo}
            schoolId={nativeClass.schoolId}
            onChange={ev => handleNativeClassParamsChange({
              ...nativeClass,
              schoolId: ev.target.value
            })}
          />
          {isCreatingFirstClass && (
            <Button
              title="Remove"
              tabIndex={Math.min(index - 1, 0)}
              className={classnames(classes.cancelIconBtn, {
                [classes.hidden]: index === 0
              })}
              variant='icon'
              onClick={() => removeNativeClass(nativeClass.id)}>
              {/* @ts-ignore */}
              <CancelIcon className={classes.cancelIcon} width={14} height={14} />
            </Button>
          )}
          {index !== 0 && (
            <MuiButton className={classes.cancelBtn} variant='outlined' size="small" onClick={() => removeNativeClass(nativeClass.id)}>
              Remove
            </MuiButton>
          )}
        </div>
      ))}
      {isCreatingFirstClass && (
        <Button
          variant="secondary"
          className={classes.addAnotherClass}
          onClick={() => handleNativeClassParamsChange({
            id: nativeClasses.length + 1,
            name: '',
            gradeLevelId: '',
          })}
        >Add Another Class</Button>
      )}
    </div>
  );
}
