import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  gutterBottom: { }, // needs to be present to include nested in label style
  fullWidth: { },
  label: {
    display: 'inline-block',
    color: '#a1a1a1',
    fontSize: '12px',
    letterSpacing: '1.7px',
    lineHeight: 1.4,
    textTransform: 'uppercase',
    margin: 0,

    '&$gutterBottom': {
      marginBottom: '10px'
    },

    '&$fullWidth': {
      width: '100%'
    }
  }
})

interface LabelProps {
  className?: string,
  gutterBottom?: boolean,
  fullWidth?: boolean,
  value?: string,
  children: JSX.Element | string,
}

const Label: React.FunctionComponent<LabelProps> = (props) => {
  const { className, gutterBottom, fullWidth, value, children } = props;
  const styles = useStyles();

  return <p className={classnames(gutterBottom ? styles.gutterBottom : '', fullWidth ? styles.fullWidth : '', styles.label, className)}> {value || children}</p>
}

export default Label;
