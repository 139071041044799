import React from 'react';
import { Component } from 'react';
import { groupBy } from './utils';
import { getDataPlain } from '../../actions/api';
import InterventionList from './interventionList/component';
import styles from './styles.module.css';
import Button from '../common/button/component';
import { getStudentDisplayName } from '../../utils/common';
import DateRangePicker from '../common/dateRangePicker/dateRangePicker.container';
import { Tooltip } from 'sharedfrontend';
import { ReactTable, IHeaderData, IRowData, ICellData } from 'sharedfrontend/src/components/tables/ReactTable';
import tableStyles from '../../styles/table.module.css';

const AssignmentCell: React.FunctionComponent<any> = (props: any): JSX.Element => {
  return (
    <Tooltip title={props.standardCode} tooltipColor="blue">
      <div className={styles.problemSetNameCellContainer}>
        {props.skill}
      </div>
    </Tooltip>
  );
};

const StudentCell: React.FunctionComponent<any> = (props: any): JSX.Element => {
  return (
    <span className={styles.studentNameCellContainer}>
      {props.name}
    </span>
  );
};

const BodyCell = (
  interventions: any[],
  isViewByAssignment: boolean,
  isExpanded: boolean,
) => {
  return (
    <React.Fragment>
      <div className={styles.number}>{interventions.length}</div>
      {isExpanded &&
        <InterventionList
          interventions={interventions}
          showStudentName={isViewByAssignment}
        />
      }
    </React.Fragment>
  );
};

const Table = ({ viewBy, interventionData }: {
  viewBy: string;
  interventionData: any[];
}) => {
  const [expandedRows, setExpandedRows] = React.useState([] as number[]);

  React.useEffect(() => {
    setExpandedRows([]);
  }, [viewBy]); // eslint-disable-line

  const onRowClick = (rowIndex: number) => {
    const alreadyExpanded = expandedRows.includes(rowIndex);
    setExpandedRows(alreadyExpanded ? expandedRows.filter(x => x !== rowIndex) : expandedRows.concat([rowIndex]));
  };

  const isViewByStudent = viewBy === 'student';

  const columns: IHeaderData[] = [
    {
      Header: isViewByStudent ? 'Student Name' : 'Skill',
      accessor: isViewByStudent ? 'name' : 'skill',
      minWidth: 200,
    },
    {
      Header: 'Suggested',
      accessor: 'suggested',
      minWidth: 420,
      className: styles.suggestedCol
    }
  ];

  const data: IRowData[] = interventionData.map((x, index) => {
    const isExpanded = expandedRows.includes(index);

    const rowContent: { [key: string]: ICellData } = {
      suggested: {
        cellContent: BodyCell(x.suggested, viewBy === 'assignment', isExpanded),
      }
    };

    if (isViewByStudent) {
      rowContent.name = {
        cellContent: <StudentCell name={x.name} />,
        sortValue: x.name,
      }
    }
    else {
      rowContent.skill = {
        cellContent: <AssignmentCell {...x} />,
        sortValue: x.skill
      }
    }

    return {
      rowContent,
      className: styles.expandingRow,
      onClick: () => onRowClick(index),
    };
  });

  return (
    <ReactTable
      columns={columns}
      data={data}
      noDataText="No data!"
      gridLines
      defaultSortBy={[{ id: 'name', desc: false }]}
      type="secondary"
      ignoreHeightAdjust
      fixedHeader={false}
      className={tableStyles.reactTable}
    />
  );
};

interface IState {
  viewBy: string;
  interventionData: any[];
  loading: boolean;
}

class Interventions extends Component<any, IState> {
  state = {
    viewBy: this.props.viewBy,
    interventionData: [] as any[],
    loading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: { studentUserId: number; startDate: string; endDate: string }) {
    const filterUpdated = prevProps.studentUserId !== this.props.studentUserId
      || prevProps.startDate !== this.props.startDate
      || prevProps.endDate !== this.props.endDate;

    if (filterUpdated) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { classId, studentUserId, startDate, endDate } = this.props;

    if (studentUserId) {
      const data = await getDataPlain({
        type: 'STUDENT_DATA_INTERVENTION',
        params: { classId, studentUserId, startDate, endDate }
      });

      this.setState({ interventionData: data, loading: false });
    }
    else if (classId) {
      const data = await getDataPlain({
        type: 'CLASS_INTERVENTION_DATA',
        params: { classId, startDate, endDate }
      });

      this.setState({ interventionData: data, loading: false });
    }
  }

  onViewSwitch = () => {
    const { viewBy } = this.state;
    const newViewBy = viewBy === 'student' ? 'assignment' : 'student';
    
    this.setState({ viewBy: newViewBy });
  }

  getTransformedData = () => {
    const data: any = this.state.viewBy === 'student'
      ? groupBy(this.state.interventionData, 'userId')
      : groupBy(this.state.interventionData, 'createdFromProblemSetId');

    return Object.keys(data).reduce((acc: any, id) => {
      const currentData = data[parseInt(id)];
  
      if (this.state.viewBy === 'student') {
        const { userId, firstName, lastName } = currentData[0];
        acc.push({
          userId,
          name: getStudentDisplayName(firstName, lastName),
          suggested: currentData,
        });
      }
      else {
        acc.push({
          skill: currentData[0].createdFromAssignmentName,
          standardCode: currentData[0].createFromStandardCode,
          suggested: currentData,
        });
      }
      
      return acc;
    }, []);
  }

  render() {
    const { canFlip, studentUserId } = this.props;
    const { loading, interventionData, viewBy } = this.state;

    if (loading) {
      return <div className="ctaInfo">Loading...</div>;
    }

    return (
      <React.Fragment>
        <div className={styles.controlsContainer}>
          <div>
            {canFlip && interventionData.length > 0 &&
              <Button
                onClick={this.onViewSwitch}
                orangeType="empty"
                additionalStyles={styles.btnViewBy}
              >
                {viewBy === 'student' ? 'View by assignment' : 'View by student'}
              </Button>
            }
          </div>
          {/* Student data date filter is along with student name header (studentData.component.tsx) */ }
          {!studentUserId && <DateRangePicker />}
        </div>
        {interventionData.length > 0
          ? (
            <Table
              interventionData={this.getTransformedData()}
              viewBy={viewBy}
            />
          )
          : <div className="ctaInfo">No prerequisites!</div>
        }       
      </React.Fragment>
    );
  }
}

export default Interventions;
