import React from 'react';
import LearnosityQuestion from '../../common/learnosityQuestion/container';
import styles from './styles.module.css';
import { plural } from '../../../../utils/common';
import { Modal } from 'sharedfrontend';
import { closeModal } from '../../../../actions/eventEmitter';

const ShowOriginalProblemModal = (props: any): JSX.Element => {
  const { numAttempts, restartProblem, problemId } = props;
  const numAttemptsRemaining = 3 - numAttempts;
  const canRestart: boolean = numAttempts < 3;

  return (
    <Modal
      title=""
      maxWidth="lg"
      okText={canRestart ? 'Restart' : undefined}
      okCallback={canRestart
        ? () => {
          restartProblem(problemId);
          closeModal();
        }
        : undefined}
      cancelText="Close"
      cancelCallback={closeModal}
    >
      <React.Fragment>
        <LearnosityQuestion type="problem" />
        {!!canRestart &&
          <p className={styles.note}>
            Note: you can restart this problem {plural(numAttemptsRemaining, '%count more time')}.
          </p>
        }
      </React.Fragment>
    </Modal>
  );
};

export default ShowOriginalProblemModal;
