import { IConfig } from './types';

const config: IConfig = {
  env: 'production',
  productName: 'Achieve3000 Math',
  backendUrl: 'https://al-api.achieve3000.com',
  productUrl: 'https://math.achieve3000.com',
  mathIframeApi: 'https://math-api-prod.achieve3000.com',
  mathIframeOrigin: 'https://math-app.achieve3000.com',
  cookieDomain: '.achieve3000.com',
  csrfTokenKey: 'CSRF-TOKEN',
  isMath: true,
};

export default config;
