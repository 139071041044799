import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  title: {
    color: '#253945',
    fontSize: '24px',
    letterSpacing: '0.4px',
    lineHeight: '1.5'
  }
})

interface TitleProps {
  className?: string;
  value?: string;
  children: string;
}

const Title: React.FunctionComponent<TitleProps> = (props) => {
  const { className, value, children } = props;
  const styles = useStyles();

  return <h2 className={classnames(styles.title, className)}>{value || children}</h2>
}

export default Title;
