import React from 'react';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import { CheckboxProps, default as MuiCheckbox } from '@mui/material/Checkbox';
import { Tooltip } from '../tooltip';

interface ICheckbox extends CheckboxProps {
  checked: boolean;
  setChecked: Function;
  color?: 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  required?: boolean;
  className?: string;
  stopPropagation?: boolean;
  fullWidth?: boolean;
}

export function Checkbox({
  checked,
  setChecked,
  color = 'primary',
  disabled = false,
  required = false,
  sx = {},
  stopPropagation = false,
  ...rest
}: ICheckbox) {
  return (
    <MuiCheckbox
      color={color}
      sx={sx}
      checked={checked}
      onChange={(ev) => setChecked(ev.target.checked)}
      onClick={(ev) => stopPropagation && ev.stopPropagation()}
      disabled={disabled}
      required={required}
      {...rest} />
  );
}

interface ILabeledCheckbox extends ICheckbox {
  label: React.ReactNode;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  labelProps?: Partial<FormControlLabelProps>;
  checkBoxSx?: object;
  classes?: object;
}

export default function LabeledCheckbox({
  checked,
  setChecked,
  label,
  color = 'primary',
  labelPlacement = 'end',
  disabled = false,
  required = false,
  className = '',
  checkBoxSx = {},
  stopPropagation = false,
  labelProps = {},
  classes = {},
  title,
  ...rest
}: ILabeledCheckbox) {
  return (
    <Tooltip title={title || ''}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            setChecked={setChecked}
            color={color}
            sx={checkBoxSx}
            disabled={disabled}
            required={required}
            stopPropagation={stopPropagation}
            {...rest} />
        }
        className={className}
        classes={classes}
        label={label}
        labelPlacement={labelPlacement}
        onClick={(ev) => stopPropagation && ev.stopPropagation()}
        {...labelProps}
      />
    </Tooltip>
  );
}
