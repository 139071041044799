import produce from 'immer';

const initialState = {
  isCorrect: null,
  feedbackMessage: null,
};

const reducer: any = produce((draft: any, action: any) => {
  switch (action.type) {
  case 'RESOLVE_GRADE_RESPONSE': {
    draft.isCorrect = action.body.isCorrect;
    draft.feedbackMessage = action.body.feedbackMessage;
    break;
  }
  case 'RESOLVE_GRADE_BENCHMARK_RESPONSE': {
    draft.feedbackMessage = action.body.feedbackMessage;
    break;
  }
  case 'RESOLVE_PROBLEM_DATA':
  case 'RESOLVE_GET_STEP_DATA': {
    if (action.body.responseData) {
      draft.isCorrect = action.body.responseData.isCorrect;
      draft.feedbackMessage = action.body.responseData.feedbackMessage;
    }
    break;
  }
  case 'GO_TO_PROBLEM':
  case 'GO_TO_STEP':
  case 'CLOSE_FEEDBACK_BAR':
  case 'RESOLVE_RESTART_PROBLEM':
  case 'RESOLVE_SHOW_STEPANSWER':
  case 'RESOLVE_SKIPPED_TO_STEPS': {
    Object.assign(draft, initialState);
    break;
  }
  }
}, initialState);

export default reducer;
