import { IConfig } from './types';

const config: IConfig = {
  env: 'live',
  productName: 'Achieve3000 Math',
  backendUrl: 'https://al-api-live.achieve3000.com',
  productUrl: 'https://math-live.achieve3000.com',
  mathIframeApi: 'https://math-api-live.achieve3000.com',
  mathIframeOrigin: 'https://math-app-live.achieve3000.com',
  cookieDomain: '.achieve3000.com',
  csrfTokenKey: 'CSRF-TOKEN-LIVE',
  isMath: true,
};

export default config;
