import React from 'react';
import Button from '../../../common/button/component';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { IoIosArrowRoundBack } from 'react-icons/io';
import exitButtonStyles from '../../../common/exitButton/styles.module.css';
import classnames from 'classnames';
import { Role } from '../../../../utils/constants';
import { handleExitButtonClick } from '../../../../utils/common';

const GoToProblemButton = (props: any): JSX.Element => {
  // exit step button for Ts
  if (props.type === 'back' && props.validRoles.includes(props.role)) {
    const buttonClassNames = classnames({
      [exitButtonStyles.button]: true,
      [exitButtonStyles.step]: props.isOnStep,
    });

    return (
      <Button
        additionalStyles={buttonClassNames}
        onClick={() => props.goToProblem(props.currentProblemId)}
      >
        <IoIosArrowRoundBack />
      </Button>
    )
  }

  const problemIdToGoTo = props.type === 'previous' ? props.previousProblemId : props.nextProblemId;

  if (props.validRoles.includes(props.role)) {
    if (props.role === Role.STUDENT) {
      if (problemIdToGoTo) {
        return (
          <Button orangeType="filled" onClick={() => props.goToProblem(problemIdToGoTo)}>
            go to next problem
          </Button>
        )
      }
      else {
        return (
          <Button orangeType="filled" onClick={() => handleExitButtonClick({ classId: props.classId })}>
            exit assignment
          </Button>
        )
      }
    }
    else if ([Role.SUPERADMIN, Role.TEACHER].includes(props.role)) {
      return (
        <Button iconSize="large" disabled={!problemIdToGoTo} onClick={() => props.goToProblem(problemIdToGoTo)}>
          {props.type === 'previous' ? <FaArrowLeft /> : <FaArrowRight />}
        </Button>
      )
    }
  }

  return <React.Fragment></React.Fragment>
};

export default GoToProblemButton;
