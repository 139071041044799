import ToggleButton from './component';
import { connect } from 'react-redux';
import { postData } from '../../../../actions/api';
import { ImplementationType } from '../../../../utils/constants';

const mapStateToProps = (state: any, ownProps: any) => {
  let isToggleOn = ownProps.isToggleOn;

  if (ownProps.type === ImplementationType.PROBLEM) {
    isToggleOn = !state.problemData.hideForNonSuperAdmin;
  }
  else if (ownProps.type === ImplementationType.STEP) {
    isToggleOn = !state.stepData.isDeleted;
  }
  else if (ownProps.type === ImplementationType.HINT) {
    isToggleOn = !state.hintsData.hints[state.hintsData.currentHintIndex].isDeleted;
  }

  return {
    isToggleOn,
    role: state.userData.role,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    handleToggleSwitch: ownProps.handleToggleSwitch
      ? ownProps.handleToggleSwitch
      : (id: number, implementationType: ImplementationType) => {

        const actionType = {
          hint: 'DELETE_HINT',
          problem: 'HIDE_PROBLEM',
          step: 'DELETE_STEP',
        };

        dispatch(postData({
          type: `TOGGLE_${actionType[implementationType]}`,
          params: { [`${implementationType}Id`]: id },
        }));
      },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToggleButton);
