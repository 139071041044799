import React from 'react';
import cn from 'classnames';
import Label, { getLabelFromAssignment } from './label/label.component';
import DueDate from './dueDate/dueDate.component';
import Progress from './stats/stats.component';
import MasteryGauge from './masteryGauge.component';
import { TableRow, TableRowContents } from './table/table.component';
import { getMasterySegmentFromAssignment } from './mastery.component';
import { redirectToProblemSet } from './common';
import styles from './styles.module.css';

interface IProps {
  assignment: any;
}

const WelcomeCard = (props: IProps) => {
  const {
    assignment,
  } = props;

  return (
    <div className={styles.welcomeCardContainer}>
      <div className={styles.doNext}>Do Next</div>
      {!assignment.isBenchmark &&
        <Card assignment={assignment} />
      }
      {!!assignment.isBenchmark &&
        <TableRow className={styles.welcomeLevelSet} assignment={assignment} isWelcomeCard />
      }
    </div>
  );
};

const Card = (props: IProps) => {
  const {
    assignment,
  } = props;

  const hasInterventions = assignment.interventions && assignment.interventions.length > 0;
  const containerClass = cn({
    [styles.welcomeCardWrapper]: true,
    [styles.welcomeCardWrapperWithPrereq]: hasInterventions,
  });

  return (
    <div className={styles.welcomeCard}>
      <div
        className={containerClass}
        role="button"
        tabIndex={0}
        onClick={() => redirectToProblemSet(assignment)}
        onKeyDown={() => redirectToProblemSet(assignment)}
      >
        <div className={styles.welcomeCardInfo}>
          <Label label={getLabelFromAssignment(assignment)} />
          <div className={styles.assignmentName}>{assignment && assignment.name}</div>
          <DueDate
            className={styles.cardDueDate}
            startDate={new Date(assignment.startDate.replace(' ', 'T') + 'Z')}
            endDate={assignment.endDate && new Date(assignment.endDate.replace(' ', 'T') + 'Z')}
            showDistance
          />
          <Progress
            masterySegment={getMasterySegmentFromAssignment(assignment)}
            completedCount={assignment.numProblemsCompleted}
          />
        </div>
        <div className={styles.welcomeCardGauge}>
          <MasteryGauge mastery={assignment.mastery} numProblemsCompleted={assignment.numProblemsCompleted} />
        </div>
      </div>
      {assignment.interventions.map((a: any) =>
        <TableRowContents key={`cardPreReq_${a.problemSetId}`} className={styles.welcomeCardPreReq} assignment={a} />
      )}
    </div>
  );
};

export default WelcomeCard;
