import DateRangePicker from '../../common/dateRangePicker/dateRangePicker.component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    startDate: state.reports.dateFilter.startDate,
    endDate: state.reports.dateFilter.endDate,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setDateRange: (datesObj: any) => {
      dispatch({
        type: 'ADMIN_DATE_RANGE_SELECTED',
        body: datesObj,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DateRangePicker);
