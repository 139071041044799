import React from 'react';
import { PercentileBarChart } from 'sharedfrontend';
import classnames from 'classnames';
import styles from './styles.module.css';
import { getDataPlain } from '../../actions/api';

const Reveal = (props: any): JSX.Element => {
  const { label, benchmarkSource } = props;
  const [show, setShow] = React.useState(false);
  const handleHover = () => setShow(true);
  const handleHoverLeave = () => setShow(false);

  return (
    <span
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
    >
      <div className={classnames(styles.reveal, show ? styles.showReveal : '')}>
        {props.children}
        {benchmarkSource === 'levelset' && 
          <p className={styles.trademarkText}>
            QUANTILE®is a trademarks of MetaMetrics, Inc., and are registered in the United States and abroad.<br />
            Copyright © 2020 MetaMetrics, Inc. All rights reserved.
          </p>
        }
      </div>
      <span className={styles.revealBtn}>{label}</span>
    </span>
  )
};

const QuantileMeasure = (props: any): JSX.Element => {
  const { classId, studentUserId } = props;
  const [data, setData] = React.useState<any | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getDataPlain({
        type: 'STUDENT_DATA_QUANTILE',
        params: { classId, studentUserId }
      });

      setData(result);
    };

    fetchData();
  }, [studentUserId]); // eslint-disable-line

  return data
    ? (
      <Reveal label={data.label}>
        <div className={styles.tooltip}>
          <PercentileBarChart title={data.benchmarkSource === 'nwea' ? 'NWEA MAP' : 'LevelSet Math'} data={data} />
        </div>
      </Reveal>
    )
    : <React.Fragment></React.Fragment>
}

export default QuantileMeasure;
