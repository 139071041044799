import React from 'react';
import Button from '../../../common/button/component';
import { openModal } from '../../../../actions/eventEmitter';
import SolutionVideoModal from '../../modals/solutionVideo/container';

const ShowSolutionVideoButton = (props: any): JSX.Element => {
  const { showButton } = props;

  return showButton
    ? (
      <Button
        orangeType="empty"
        onClick={() => openModal(<SolutionVideoModal />)}
      >
        show solution
      </Button>
    )
    : <React.Fragment></React.Fragment>
};

export default ShowSolutionVideoButton;
