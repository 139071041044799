import config from '../config';
import DOMPurify from 'dompurify';
import { LevelSetTimeDifference, PageView, TeacherGradeLevel } from './constants';
import { inIframe, sendMessage } from './iframe';

export const parseQueryString = (queryString: string): any => {
  const parsedQueryObj: any = {};
  const pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&');

  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    const decodedKey = decodeURIComponent(key);
    const decodedValue: any = decodeURIComponent(value || '');

    parsedQueryObj[decodedKey] = isNaN(decodedValue) ? decodedValue : parseInt(decodedValue);
  }

  return parsedQueryObj;
};

export const plural = (count: any, singular: any, pluralValue?: any) => {
  return count === 1 ? singular.replace('%count', count) : (pluralValue || (singular + 's')).replace('%count', count);
};

export const setCookie = (name: any, value: any, exdays?: any) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  const exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);

  const cookieValue = `${name}=${encodeURIComponent(value)}; `;
  const expires = !exdays ? '' : `expires=${exdate.toUTCString()}; `;
  const domain = process.env.REACT_APP_ENV === 'local' ? '' : 'domain=' + config.cookieDomain + '; ';

  document.cookie = `${cookieValue}${expires}${domain}path=/`;
};

export const getCookie = (name: string) => {
  const value = document.cookie;
  let start = value.indexOf(' ' + name + '=');

  if (start === -1) {
    start = value.indexOf(name + '=');
  }

  if (start === -1) {
    return null;
  }
  else {
    start = value.indexOf('=', start) + 1;
    let end = value.indexOf(';', start);

    if (end === -1) {
      end = value.length;
    }

    return decodeURIComponent(value.substring(start, end));
  }
};

export const handleEnterKey = (e: any, callback: any) => {
  if (e.keyCode === 13) {
    callback();
  }
};

export const isCurrentDateBetweenGivenRange = (startDateStr: string, endDateStr: string) => {
  const currentTimestamp = new Date().getTime();
  const startDate = new Date(startDateStr).getTime();
  const endDate = new Date(endDateStr).getTime();

  return startDate <= currentTimestamp && endDate >= currentTimestamp;
};

export const toMonthAndDay = (dateStr: string) => {
  const date = new Date(dateStr);

  return `${date.toLocaleString('default', { month: 'long' })} ${ordinalFormat(date.getDate())}`;
};

export const toMonthDayAndYear = (dateStr: any) => {
  const date = new Date(dateStr);

  return `${date.toLocaleString('default', { month: 'short'})} ${date.getDate()}, ${date.getFullYear()}`;
};

export const utcToLocal = (dateStr: string, showTime = true) => {
  const localDate = new Date(dateStr.replace(/\s+/g, 'T') + 'Z');

  return formatDate(localDate, showTime);
};

const formatDate = (dateVal: Date, showTime = true) => {
  const mm = padValue(dateVal.getMonth() + 1);
  const dd = padValue(dateVal.getDate());
  const yy = dateVal.getFullYear();
  let hh: any = dateVal.getHours();
  const min = padValue(dateVal.getMinutes());
  const sAMPM = hh >= 12 ? 'PM' : 'AM';
  hh = padValue(hh > 12 ? hh - 12 : hh);

  return showTime
    ? `${mm}-${dd}-${yy} ${hh}:${min} ${sAMPM}`
    : `${mm}-${dd}-${yy}`;
};

export const padValue = (value: number) => {
  return (value < 10) ? '0' + value : value;
};

const ordinalFormat = (x: number) => {
  if (x > 3 && x < 21) {
    return x + 'th';
  }

  switch (x % 10) {
  case 1:
    return x + 'st';
  case 2:
    return x + 'nd';
  case 3:
    return x + 'rd';
  default:
    return x + 'th';
  }
};

export const numberToOrdinal = (x: number) => {
  return ['Zero', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth','Tenth'][x];
};

export const getFormattedTime = (seconds: any, useShortUnits: any) => {
  const minuteUnit = useShortUnits ? 'm' : ' min';
  const minutesUnit = useShortUnits ? 'm' : ' mins';
  const hourUnit = useShortUnits ? 'h ' : ' hr ';
  const hoursUnit = useShortUnits ? 'h ' : ' hrs ';

  let timeString = '0' + minutesUnit;

  if (seconds) {
    if (seconds < 60) {
      timeString = '1' + minuteUnit;
    }
    else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor(seconds % 3600 / 60);
      const hrUnitStr = hours === 1 ? hourUnit : hoursUnit;
      const minUnitStr = minutes === 1 ? minuteUnit : minutesUnit;

      timeString = (hours
        ? hours + hrUnitStr
        : '')
        + minutes + minUnitStr;
    }
  }

  return timeString;
};

export const secondsToFriendlyFormat = (numSeconds: number, showSeconds = true) => {
  const hours = Math.floor(numSeconds / 3600);
  numSeconds %= 3600;
  const minutes = Math.floor(numSeconds / 60);
  const seconds = numSeconds % 60;

  const timeParts = [];

  if (hours > 0) {
    timeParts.push(plural(hours, '%count hour', '%count hours'));
  }

  if (!showSeconds || minutes > 0) {
    timeParts.push(plural(minutes, '%count minute', '%count minutes'));
  }

  if (showSeconds && seconds >= 0) {
    timeParts.push(plural(seconds, '%count second', '%count seconds'));
  }

  return timeParts.join(', ');
};

export const isArrayEqual = (arr1: number[] | string[], arr2: number[] | string[]) => {
  return JSON.stringify([...(arr1 || [])].sort()) === JSON.stringify([...(arr2 || [])].sort());
};

export const sanitize = (dirty: any, allowedTags?: any, allowedAttributes?: any) => {
  if (dirty === null) {
    return null;
  }
  if (typeof (dirty) === 'undefined') {
    return dirty;
  }

  allowedTags = allowedTags || ['b', 'i', 'u', 'em', 'strong', 'a', 'p', 'br', 'sub', 'sup'];
  allowedAttributes = allowedAttributes || ['href', 'target'];

  return DOMPurify.sanitize(dirty, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: allowedAttributes,
  });
};

export const getStudentDisplayName = (firstName: string, lastName: string) => {
  return (lastName ? (sanitize(lastName) + ', ') : '') + sanitize(firstName);
};

export const checkRightProblemOrderLoadingAndRedirect = ({
  problemSetId,
  classId,
  currentProblemId,
  currentStepId,
  problemIds,
  completedProblemIds,
  isStudent,
}: {
  problemSetId: number;
  classId: number;
  currentProblemId: number;
  currentStepId: number;
  completedProblemIds: number[];
  problemIds: number[];
  isStudent: boolean;
}) => {
  if (isStudent && currentProblemId) {
    const problemIdToSolve = completedProblemIds.length === problemIds.length
      ? problemIds[completedProblemIds.length - 1]
      : problemIds[completedProblemIds.length];

    if (currentStepId ||
      (completedProblemIds.length > 0 &&
      currentProblemId === completedProblemIds[completedProblemIds.length - 1])) {
      return true; // Don't redirect if student is on last solved problem or in on steps
    }

    if (problemIdToSolve && currentProblemId !== problemIdToSolve) {
      window.location.assign(
        `/mathreader?problemSetId=${problemSetId}&classId=${classId}&problemId=${problemIdToSolve}`
      );

      return false;
    }
  }

  return true;
};

export const sortBy = (key: any) => {
  return (a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
};

export const sortArrayByGivenSortOrder = (itemsArray: number[], sortingArray: number[]) => {
  return Array.from(new Set([...sortingArray, ...itemsArray]));
};

export const getCurrentSchoolYearDatesAsObj = () => {
  const currentSchoolYear = getYearFromCurrentSchoolYear();

  return {
    startDate: new Date(`August 1, ${currentSchoolYear}`),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
  };
};

export const getCurrentSchoolYearDatesAsString = () => {
  const { startDate, endDate } = getCurrentSchoolYearDatesAsObj();

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const truncate = (text: any, length: any) => {
  if (text) {
    if (text.length <= length) {
      return text;
    }
    else {
      text = text.substr(0, length);
      const lastSpaceIndex = text.lastIndexOf(' ');
      if (lastSpaceIndex === -1) {
        return text + '...';
      }
      else {
        return text.slice(0, lastSpaceIndex) + '...';
      }
    }
  }
};

export const sumBy = (items: any[], prop: string) => {
  return items.reduce(function(total, obj) {
    return total + obj[prop];
  }, 0);
};

export const formatTimeSpent = (timeInSeconds: number) => {
  if (timeInSeconds === 0) {
    return '';
  }

  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;

  const result = [];
  if (minutes) {
    result.push(`${minutes} min`);
  }
  if (seconds) {
    result.push(`${seconds} sec`);
  }
  return result.join(', ');
};

export const mapTeacherGradeLevelIdToCatalogGradeLevels = (
  gradeLevelId: number, stateAbbreviation: string): string[] => {
  switch (gradeLevelId) {
  case TeacherGradeLevel.GradeKTo1:
    return ['Kindergarten', 'Grade 1'];
  case TeacherGradeLevel.Grade2To3:
    return ['Grade 2', 'Grade 3'];
  case TeacherGradeLevel.Grade4To5:
    return ['Grade 4', 'Grade 5'];
  case TeacherGradeLevel.Grade6To8:
    return ['Grade 6', 'Grade 7', 'Grade 8'];
  case TeacherGradeLevel.Grade9To12:
  case TeacherGradeLevel.HigherEd:
    return higherEdGradesByState[stateAbbreviation] || ['Kindergarten', 'Grade 1'];
  default:
    return ['Kindergarten', 'Grade 1'];
  }
};

const higherEdGradesByState: { [x: string]: string[] } = {
  AK:	['9-12'],
  AL:	['Algebra 1','Algebra 2','Geometry'],
  AR:	['Algebra 1','Algebra 2','Geometry'],
  AZ:	['Algebra 1','Algebra 2','Geometry'],
  CA:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  CC:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  CO:	['Algebra I','Algebra II','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  CT:	['Algebra I','Algebra II','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  DC:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  DD:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  DE:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  FL:	['Algebra 1','Algebra 2','Geometry'],
  GA:	['Algebra 1','Algebra 2','Geometry'],
  HI:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  IA:	['Algebra I','Algebra II','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  ID:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  IL:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  IN:	['Algebra 1','Algebra 2','Geometry'],
  KS:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  KY:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  LA:	['Algebra 1','Algebra 2','Geometry'],
  MA:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  MD:	['Algebra 1','Algebra 2','Geometry'],
  ME:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  MI:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  MN:	['Grades 9-12'],
  MO:	['Algebra 1','Algebra 2','Geometry'],
  MS:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  MT:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  NC:	['Mathematics I','Mathematics II','Mathematics III'],
  ND:	['High School Math G 9-12'],
  NE:	['Grades 9-12'],
  NH:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  NJ:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  NM:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  NV:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  NY:	['Algebra 1','Algebra 2','Geometry'],
  OH:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  OK:	['Algebra 1','Algebra 2','Geometry','Pre-Algebra'],
  OR:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  PA:	['9-12'],
  RI:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  SC:	['Algebra 1','Algebra 2','Geometry'],
  SD:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  TN:	['Algebra 1','Algebra 2','Core Math I','Core Math II','Core Math III','Geometry'],
  TX:	['Algebra 1','Algebra 2','Geometry'],
  UT:	['Secondary Mathematics I','Secondary Mathematics II','Secondary Mathematics III'],
  VA:	['Algebra 1','Algebra 2','Geometry'],
  VI:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  VT:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  WA:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
  WI:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  WV:	['Algebra 1','Algebra 2','Geometry','Integrated Mathematics I','Integrated Mathematics II',
    'Integrated Mathematics III'],
  WY:	['Algebra 1','Algebra 2','Geometry','Mathematics I','Mathematics II','Mathematics III'],
};

export const uniqArray = (array: any[]) => {
  return Array.from(new Set(array));
};

export const addDays = (date: Date, days: number) => {
  const resultedDate = new Date(date);
  resultedDate.setDate(resultedDate.getDate() + days);

  return resultedDate;
};

export const capitalize = (text: string) => {
  return text.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
};

export const getLevelSetStartDueDates = (startDate: any) => {
  const moyStart = addDays(startDate, LevelSetTimeDifference.MOY_EOY_START);
  const eoyStart = addDays(moyStart, LevelSetTimeDifference.MOY_EOY_START);
  const moyEnd = addDays(moyStart, LevelSetTimeDifference.MOY_END);
  const eoyEnd = addDays(eoyStart, LevelSetTimeDifference.EOY_END);

  return { 
    moyStart: moyStart.toISOString(),
    moyEnd: moyEnd.toISOString(),
    eoyStart: eoyStart.toISOString(),
    eoyEnd: eoyEnd.toISOString(),
  };
};

export const formatStartAndDueDate = (dateStr?: string) => {
  const localDate = dateStr ? new Date(dateStr) : new Date();
  const mm = padValue(localDate.getMonth() + 1);
  const dd = padValue(localDate.getDate());
  const yy = localDate.getFullYear();
  const hh = padValue(localDate.getHours());
  const min = padValue(localDate.getMinutes());

  return `${yy}-${mm}-${dd}T${hh}:${min}`;
};

export const getTimeFromString = (timeString: string) => {
  return new Date(timeString.replace(' ', 'T') + 'Z');
};

export const getLevelSetSeries = (levelSetString: string) => {
  return levelSetString.split(' ')[1];
};

export const getQuantileScoreFromString = (quantileString: string) => {
  return parseInt(quantileString.replace('Q', '').replace('EM', '-'));
};

export const groupObjectArrayByKey = (array: any[], key: string) => {
  return array.reduce((a, b) => {
    (a[b[key]] = a[b[key]] || []).push(b);
    return a;
  }, {});
};

export const getNWEAAssesmentAdaptiveTestDates = () => {
  const currentSchoolYear = getYearFromCurrentSchoolYear();

  return {
    BOY: {
      startDate: new Date(`August 1, ${currentSchoolYear}`),
      endDate: new Date(new Date(`October 31, ${currentSchoolYear}`).setHours(23, 59, 59, 999))
    },
    MOY: {
      startDate: new Date(`November 1, ${currentSchoolYear}`),
      endDate: new Date(new Date(`March 31, ${currentSchoolYear + 1}`).setHours(23, 59, 59, 999))
    },
    EOY: {
      startDate: new Date(`April 1, ${currentSchoolYear + 1}`),
      endDate: new Date(new Date(`July 31, ${currentSchoolYear + 1}`).setHours(23, 59, 59, 999))
    },
  };
};

const getYearFromCurrentSchoolYear = () => {
  // if today is in August or later -> current school year is current calendar year
  // if today is before August -> current school year is last calendar year
  return new Date().getMonth() + 1 >= 8
    ? new Date().getFullYear()
    : new Date().getFullYear() - 1;
};

export const handleExitButtonClick = ({
  pageView,
  classId,
  revealBookTitle = null,
  catalogSearchTerm = '',
  catalogSelectedGradeLevel = '',
}: {
  pageView?: string;
  classId?: number;
  revealBookTitle?: any;
  catalogSearchTerm?: string;
  catalogSelectedGradeLevel?: string;
}) => {
  if (inIframe()) {
    sendMessage('EXIT_BUTTON_CLICK', {
      pageView: pageView || (classId ? PageView.ASSIGNMENT : PageView.PREVIEW),
      classId,
      revealBookTitle,
      catalogSearchTerm,
      catalogSelectedGradeLevel,
    });
  }
  else {
    window.location.assign('/catalog');
  }
};