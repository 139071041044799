import React, { useEffect } from "react";
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Drawer from '@mui/material/Drawer';
import { Button } from '../../button';
import CancelIcon from '../../../icons/cancel.svg';
import styles from './styles.module.css';
import OnDemandLiveRegion from 'on-demand-live-region';

type AppDrawerProps = {
  anchor?: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
  id?: string;
  closeMenu: any;
  logo?: string;
  logoAltText?: string;
  logoUrl?: string;
  onSkipNavigationBtnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fixedSection?: JSX.Element;
  children?: React.ReactNode;
  open: boolean;
  color?: 'dark' | 'light';
};

const AppDrawer: React.FunctionComponent<AppDrawerProps> = (props) => {
  const {
      anchor = 'left',
      className,
      closeMenu,
      children,
      color = 'dark',
      fixedSection,
      logo,
      logoAltText = '',
      logoUrl,
      open = false,
      onSkipNavigationBtnClick,
      ...rest
    } = props;

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
        window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (open) {
      const onDemandLiveRegion = new OnDemandLiveRegion({
        level: 'assertive',
      });
      onDemandLiveRegion.say('menu expanded');
    }
  }, [open]);

  const handleOrientationChange = (): void => {
    if (Math.abs(window.orientation as number) === 90) {
      // portrait to landscape
      // do nothing
    }
    else {
      // landscape to portrait
      closeMenu();
    }
  };

  const drawerClasses = classnames({
    [styles.appDrawer]: true,
    [`${className}`]: !!className,
  });

  const drawerHeaderClasses = classnames({
    [styles.appDrawerHeader]: true,
    [styles[color]]: color !== 'dark',
  });

  const drawerMenuWrapperClasses = classnames({
    [styles.menuListWrapper]: true,
    [styles[color]]: color !== 'dark',
  });

  const handleDrawerClose = () => {
    if (document.body.getAttribute('oneOrMoreTooltip') !== 'true') {
      closeMenu();
    }
  };

  return (
    <Drawer role="navigation" className={drawerClasses} anchor={anchor} open={open} onClose={handleDrawerClose} {...rest}>
      <div className={drawerHeaderClasses}>
        {onSkipNavigationBtnClick && (
          <Button className={styles.skipNavigationBtn} onClick={onSkipNavigationBtnClick}>
            Skip Navigation
          </Button>
        )}
        {logo ? (
          logoUrl ? (
            <button
              onClick={() => {
                location.href = logoUrl;
              }}
              className={styles.logo}
              aria-label="Actively Learn Logo"
              role="link"
            >
              <img src={logo} alt={logoAltText} />
            </button>
          ) : (
            <div className={styles.logo}>
              <img src={logo} alt={logoAltText} />
            </div>
          )
        ) : (
          <div></div>
        )}
        <Button
          title={'Close menu'}
          variant={color === 'light' ? 'iconText' : 'icon'}
          onClick={closeMenu}
          tooltipColor={'black'}
          tooltipPlacement={'right'}
          aria-label={'Close menu'}
          className='icon-close-menu'
        >
          {/* @ts-ignore */}
          <CancelIcon width={20} height={20} role="presentation" />
        </Button>
      </div>
      <Scrollbars
        className={drawerMenuWrapperClasses}
        renderThumbVertical={(): JSX.Element => <div className={styles.scrollThumb}></div>}
      >
        {children}
      </Scrollbars>
      {!!fixedSection && <div className={styles.fixedSection}>{fixedSection}</div>}
    </Drawer>
  );
};

export default AppDrawer;