import React from 'react';
import { styled } from '@mui/system';
import ChartHeader from './ChartHeader';
import HorizontalBarChart from './HorizontalBarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import { LegendProps } from './Legend';

export enum ChartTypes {
  Line = 'line',
  Pie = 'pie',
  HorizontalBar = 'horizontalBar'
}

interface IProps {
  className?: string;
  column?: boolean;
  config: any;
  title: string;
  variant?: ChartTypes;
  subtitle?: JSX.Element;
  header?: boolean;
  legend?: LegendProps;
  legendTooltip?: JSX.Element;
  tooltip?: JSX.Element;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '35px',
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const ChartSection = styled('div')({
  flex: '3 0',
  width: '100%',
});

const ALChart = ({
  header,
  title,
  subtitle,
  legend,
  legendTooltip,
  tooltip,
  config,
  variant,
  column = true
}: IProps) => {

  const renderChart = () => {
    switch (variant) {
      case ChartTypes.Line:
        return <LineChart config={config} />;
      case ChartTypes.HorizontalBar:
        return <HorizontalBarChart config={config} />;
      case ChartTypes.Pie:
      default:
        return <PieChart config={config} />;
    }
  };

  return (
    <Root sx={{ flexDirection: column ? 'column' : 'row' }}>
      {header && (
        <ChartHeader
          column={!column}
          title={title}
          subtitle={subtitle}
          legend={legend}
          legendTooltip={legendTooltip}
          tooltip={tooltip}
        />
      )}
      <ChartSection>{renderChart()}</ChartSection>
    </Root>
  );
};

export default ALChart;
