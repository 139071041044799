import React from 'react';
import GoToFirstStepButton from '../../buttons/goToFirstStep/container';
import ShowOverviewVideoButton from '../../buttons/showOverviewVideo/container';
import SubmitResponseButton from '../../buttons/submitResponse/container';
import ExitInterventionButton from '../../buttons/exitIntervention/container';
import readerStyles from '../../styles.module.css';
import { Role } from '../../../../utils/constants';

const Footer = (props: any): JSX.Element => {
  return (
    <footer className={readerStyles.footer}>
      {!props.isBenchmark &&
      <React.Fragment>
        <ExitInterventionButton />
        <ShowOverviewVideoButton />
        <GoToFirstStepButton type="beforeAnswering" />
      </React.Fragment>
      }
      <SubmitResponseButton validRoles={[Role.SUPERADMIN, Role.STUDENT]} />
    </footer>
  );
};

export default Footer;
