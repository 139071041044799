import { toCamelCaseFromPascalCase, toPascalCaseFromCamelCase } from './stringHelpers';

export const isEmptyObj = function(obj): boolean {
  return Object.keys(obj).length === 0;
};

export const uniqueValues = <T>(array: T[]): T[] => {
  const setList = new Set(array);
  return Array.from(setList);
};

export const getParamsInCamelCase = (json: any): any => {
  if (json && typeof json === 'object') {
    if (Array.isArray(json)) {
      return json.map((item) => getParamsInCamelCase(item));
    }
    else {
      const keys = Object.keys(json);
      const convertedObj = {};

      keys.forEach((key) => {
        convertedObj[toCamelCaseFromPascalCase(key)] = getParamsInCamelCase(json[key]);
      });

      return convertedObj;
    }
  }
  else {
    return json;
  }
};

export const getParamsInPascalCase = (json: any): any => {
  if (json && typeof json === 'object') {
    if (Array.isArray(json)) {
      return json.map((item) => getParamsInPascalCase(item));
    }
    else {
      const keys = Object.keys(json);
      const convertedObj = {};

      keys.forEach((key) => {
        convertedObj[toPascalCaseFromCamelCase(key)] = getParamsInPascalCase(json[key]);
      });

      return convertedObj;
    }
  }
  else {
    return json;
  }
};

export const groupByValue = <T>(array: T[], key: string): { [key: string]: T[] } => {
  return (array || []).reduce((result: { [key: string]: T[] }, obj) => {
    result[obj[key]] = result[obj[key]] || [];
    result[obj[key]].push(obj);

    return result;
  }, {});
};

export const isEqual = (obj1, obj2): boolean => {
  if (obj1 === obj2) {
    return true;
  }
  else if (typeof obj1 !== typeof obj2) {
    return false;
  }
  else if (obj1 && typeof obj1 === 'object') {
    if (Array.isArray(obj1)) {
      if (obj1.length === obj2.length) {
        let isItemsEqual = true;

        for (let i = 0; i < obj1.length; i++) {
          isItemsEqual = isEqual(obj1[i], obj2[i]);
          if (!isItemsEqual) {
            break;
          }
        }

        return isItemsEqual;
      }
      else {
        return false;
      }
    }
    else {
      if (Object.keys(obj1).length === Object.keys(obj2).length) {
        let isItemsEqual = true;

        for (const key of Object.keys(obj1)) {
          isItemsEqual = isEqual(obj1[key], obj2[key]);
          if (!isItemsEqual) {
            break;
          }
        }

        return isItemsEqual;
      }
      else {
        return false;
      }
    }
  }
  else {
    return false;
  }
};

export function isObject(item): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep<T>(target, ...sources): any {
  if (sources.length > 0) {
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }

          mergeDeep(target[key], source[key]);
        }
        else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return mergeDeep(target, ...sources);
  }
  else {
    return target;
  }
}

export const debounce = (func, timeout = 250) => {
  let timer;

  return {
    debouncedFunction: (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), timeout);
    },
    timer,
  };
};