import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { sendMessage } from '../../utils/iframe';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../actions/api';

function ValidateRoute(props: { component: any }) {
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const { userId, csrfToken } = userData;
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (!csrfToken) {
      sendMessage('GET_USER_TOKEN');
    }

    if (!userId) {
      dispatch(getData({
        type: 'USER_DATA',
      }));
    }
  }, []);

  if (userId) {
    return React.createElement(props.component, { location, params });
  }
  else {
    return <></>;
  }
}

export default ValidateRoute;
