import React, { useEffect } from 'react';
import { Divider } from 'sharedfrontend/src/components/divider';
import styles from './styles.module.css';
import StudentKeyUsageChart from './studentKeyUsage.container';
import QuantileBenchmarksChart from './quantileBenchmarksChart.container';
import StateForecastChart from './stateTestForecast.container';
import SkillsProgressTable from './skillProgressOverview.container';
import AdminReportsHeader from './header/adminHeader.container';
import { sendMessage } from '../../utils/iframe';

const AdminReports = (props: any): JSX.Element => {
  const { schools } = props;

  useEffect(() => {
    sendMessage('GET_SCHOOL_INFO');
  }, []);

  return schools.length > 0
    ? (
      <React.Fragment>
        <AdminReportsHeader />
        <main className={styles.main}>
          <StudentKeyUsageChart title="Key Usage Information" />
          <Divider />
          <QuantileBenchmarksChart />
          <Divider />
          <StateForecastChart />
          <Divider />
          <SkillsProgressTable />
        </main>
      </React.Fragment>
    )
    : <React.Fragment></React.Fragment>;
};

export default AdminReports;