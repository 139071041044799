import produce from 'immer';

const initialState = {
  currentHintIndex: 0,
  hints: [],
  allHintsViewed: false,
  isEditing: false,
};

const reducer: any = produce((draft: any, action: any) => {
  switch (action.type) {
  case 'RESOLVE_HINT_DATA': {
    draft.hints = draft.hints.concat(action.body);
    break;
  }
  case 'RESOLVE_GET_STEP_DATA': {
    Object.assign(draft, initialState);
    break;
  }
  case 'ALL_HINTS_VIEWED': {
    draft.allHintsViewed = true;
    break;
  }
  case 'RESOLVE_SAVE_HINT_EDITS': {
    const hint = draft.hints[draft.currentHintIndex];
    hint.learnosityJson.questions = [action.body.hintJson];
    draft.isEditing = false;
    break;
  }
  case 'GO_TO_HINT': {
    draft.currentHintIndex = action.body;
    break;
  }
  case 'EDIT_MODE': {
    draft.isEditing = action.body;
    break;
  }
  case 'RESOLVE_TOGGLE_DELETE_HINT': {
    draft.hints[draft.currentHintIndex].isDeleted = !draft.hints[draft.currentHintIndex].isDeleted;

    break;
  }
  }
}, initialState);

export default reducer;
