import { makeStyles } from '@mui/styles';
import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

interface Props extends React.HTMLAttributes<any> {
  children?: React.ReactNode;
  Tag?:
    | keyof JSX.IntrinsicElements
    | React.FunctionComponent<any>
    | React.ComponentClass<any>;
  shouldShowShadow?: boolean;
  [key: string]: any;
}

const useStyles = makeStyles({
  scrollable: {
    background: "inherit",
    boxShadow:
      "0px -8px 8px -8px rgb(0,0,0,0.3) inset, 0px 8px 8px -8px rgb(0,0,0,0.3) inset",
  },
  container: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 7,
      height: 7,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0, 0.3)",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(220,220,220, 0.7)",
    },
  },
});

function ScrollableAreaWithShadow({
  children = <></>,
  Tag = "div",
  className = "",
  shouldShowShadow = true,
  ...rest
}: Props) {
  const containerRef = useRef<Element>();
  const shadowTimer = useRef<any>(undefined);
  const [isScrollable, setIsScrollable] = useState(false);
  const styles = useStyles();
  const observer = useRef(new ResizeObserver(addShadowForScrollableModal));

  function addShadowForScrollableModal() {
    clearTimeout(shadowTimer.current);

    shadowTimer.current = setTimeout(() => {
      if (containerRef.current) {
        const scrollHeight = containerRef.current.scrollHeight;
        const clientHeight = containerRef.current.clientHeight;

        containerRef.current.setAttribute('tabindex', `${scrollHeight > clientHeight ? '0' : '-1'}`);
        setIsScrollable(scrollHeight > clientHeight);
      }
    }, 150); // debounce calculation for 150ms
  }

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current && shouldShowShadow) {
        observer.current.observe(containerRef.current);
        addShadowForScrollableModal();
      }
    }, 0); // wait for modal body to be visible

    return () => {
      if (containerRef.current) {
        observer.current.unobserve(containerRef.current);
      }
    };
  }, [shouldShowShadow]);

  return (
    <Tag
      className={classNames(className, styles.container, {
        [styles.scrollable]: isScrollable,
      })}
      ref={shouldShowShadow ? containerRef : undefined}
      {...rest}
    >
      {children}
    </Tag>
  );
}

export default ScrollableAreaWithShadow as React.FC<Props>;
