import MasteryProgress from './masteryProgress.component';
import { connect } from 'react-redux';
import { exitReader } from '../../../actions/readerNavigation';
import { Role } from '../../../utils/constants';

const mapStateToProps = (state: any) => {
  return {
    isDemoUser: state.userData.isDemoUser,
    isStudent: state.userData.role === Role.STUDENT,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    exitReader: () => {
      dispatch(exitReader());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MasteryProgress);
