import { combineReducers } from 'redux';
import userDataReducer from './userDataReducer';
import catalogDataReducer from './catalogDataReducer';
import gradeFeedbackReducer from './gradeFeedbackReducer';
import problemSetDataReducer from './problemSetDataReducer';
import problemDataReducer from './problemDataReducer';
import stepDataReducer from './stepDataReducer';
import hintsDataReducer from './hintsDataReducer';
import readerNavigationReducer from './readerNavigationReducer';
import reportsReducer from './reports.reducer';
import classesReducer from './classesReducer';
import classReportsDataFilterReducer from './classReportsDataFilterReducer';
import classDataReducer from './classDataReducer';

const appReducers = combineReducers({
  userData: userDataReducer,
  catalogData: catalogDataReducer,
  problemSetData: problemSetDataReducer,
  problemData: problemDataReducer,
  gradeFeedbackData: gradeFeedbackReducer,
  stepData: stepDataReducer,
  hintsData: hintsDataReducer,
  readerNavigation: readerNavigationReducer,
  reports: reportsReducer,
  userClasses: classesReducer,
  classReportsDataFilter: classReportsDataFilterReducer,
  classData: classDataReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'EXIT_READER') {
    state.problemSetData = undefined;
    state.problemData = undefined;
    state.gradeFeedbackData = undefined;
    state.stepData = undefined;
    state.hintsData = undefined;
    state.readerNavigation = undefined;
    state.isAssigned = undefined;
  }

  return appReducers(state, action);
};

export default rootReducer;
