import React from 'react';
import classnames from 'classnames';
import { Button } from '../../button';
import { Tooltip } from '../../tooltip';
import styles from './styles.module.scss';
const { Fragment } = React;

interface IPropsButtonGroupBtn {
  children?: any;
  className?: string;
  id?: string;
  onClick?: any;
  onFocus?: any;
  onBlur?: any;
  title?: string | React.ReactNode;
  component?: JSX.Element | React.ReactNode;
  tooltipPlacement?: 'left' | 'right' | 'bottom' | 'top';
  tooltipColor?: 'black' | 'white';
  shouldCloseTooltipOnClick?: boolean;
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'text'
    | 'textSecondary'
    | 'pill'
    | 'icon'
    | 'iconText'
    | 'iconPrimary'
    | 'iconSecondary'
    | 'tab';
  disabled?: boolean;
  notStyled?: boolean;
  selected?: boolean;
  type?: 'button' | 'reset' | 'submit';
  [rest: string]: any;
}

interface IPropsButtonGroup {
  className?: string;
  buttons: IPropsButtonGroupBtn[];
  buttonType?: string;
  orangeTheme?: boolean;
}
const ButtonGroup: React.FunctionComponent<IPropsButtonGroup> = (props) => {
  const { className, buttons, buttonType, orangeTheme } = props;

  if (!buttons || !Array.isArray(buttons)) {
    return null;
  }

  const buttonGroupClasses = classnames({
    [styles.buttonGroup]: true,
    [styles[`${buttonType}`]]: !!buttonType,
    [styles.orangeTheme]: orangeTheme,
    [`${className}`]: !!className,
  });

  return (
    <div className={buttonGroupClasses}>
      {buttons.map((button, index) => {
        if (React.isValidElement(button.component)) {
          return <Fragment key={index}>
            {button.component}
          </Fragment>
        }
        if (button.href) {
          return (
            <Tooltip key={button.key || index} title={button.title}>
              <a
                id={button.id}
                className={classnames(
                  button.className,
                  button.selected ? styles.selected : '',
                  button.isLogoIcon ? styles.logo : ''
                )}
                target={button.target}
                href={button.href}
                onClick={button.onClick}
                aria-label={button.ariaLabel}
              >
                {button.icon}
                {button.children}
                {button.locked && <span className="icon-premium-lock"></span>}
              </a>
            </Tooltip>
          );
        }
        return (
          <Button
            id={button.id}
            key={button.key || index}
            className={classnames(
              button.className,
              button.selected ? styles.selected : '',
              button.isLogoIcon ? styles.logo : ''
            )}
            title={button.title}
            aria-label={button.ariaLabel}
            variant={button.variant || 'iconPrimary'}
            onClick={button.onClick}
            onFocus={button.onFocus}
            onBlur={button.onBlur}
            selected={button.selected}
            aria-expanded={button.expandableBtn ? button.selected : undefined}
          >
            {button.icon}
            {button.locked && (
              // TODO - clean up lock icon, embed lock icon here
              <span className="icon-premium-lock"></span>
            )}
          </Button>
        );
      })}
    </div>
  );
}

export default ButtonGroup;
