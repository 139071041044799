import React from 'react';
import classnames from 'classnames';
import { sendMessage } from '../../../../utils/iframe';
import styles from './styles.module.css';

const AssignmentTitle = (props: any): JSX.Element => {
  sendMessage('UPDATE_PAGE_TITLE', { title: `${props.problemSetName} - Achieve3000 Math` });

  const standardClassNames = classnames({
    [styles.standardName]: true,
    [styles.light]: props.isOnStep,
    [styles.intervention]: props.isIntervention,
  });

  const problemClassNames = classnames({
    [styles.problemName]: true,
    [styles.light]: props.isOnStep,
    [styles.intervention]: props.isIntervention,
  });

  return (
    <div className={styles.nameContainer}>
      <div className={standardClassNames}>
        {props.problemSetName}
      </div>
      <div className={problemClassNames}>
        Problem #{props.problemNumber}
        {props.isOnStep ? ': Step by Step': ''}
      </div>
    </div>
  );
};

export default AssignmentTitle;
