import React from 'react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the required chart elements for the line chart
Chart.register(LineElement, PointElement, LinearScale, CategoryScale, ChartDataLabels, Tooltip, Legend);

const gray = '#a1a1a1';

const defaultLineChartDataSetOptions = {
  borderColor: '#1878D2',
  pointsColor: '#FFF',
  borderWidth: 4,
  pointRadius: 8,
  borderDash: [],
  borderDashOffset: 0,
  pointHoverRadius: 10,
  pointBorderColor: '#1878D2',
  pointBackgroundColor: '#FFF',
  pointHoverBorderWidth: 4,
  tension: 0,
  fill: false,
};

const LineChart = ({ config = {} }: any) => {
  const {
    options: additionalOptions = {},
    labels,
    data,
    tooltipOptionOverrides,
    plugins,
  } = config;

  const chartData = {
    labels,
    datasets: data.map((dataset) =>
      Object.assign({}, defaultLineChartDataSetOptions, dataset)
    ),
  };

  const options = {
    plugins: {
      legend: { display: false },
      tooltip: {
        backgroundColor: '#fff',
        borderColor: '#0077d1',
        borderWidth: 2,
        titleFont: { family: 'Arial', size: 15 },
        bodyFont: { family: 'Arial', size: 15 },
        displayColors: false,
        bodyFontColor: '#253945',
        bodyFontStyle: 'bold',
        padding: 15,
        titleMarginBottom: 10,
        ...tooltipOptionOverrides,
      },
    },
    layout: { padding: { top: 15, bottom: 0, right: 40, left: 0 } },
    scales: {
      y: {
        grid: {
          borderDash: [10, 4],
          drawBorder: false,
        },
        title: {
          display: true,
          color: gray,
          font: { size: 13 },
          text: additionalOptions.yAxisLabel,
          padding: 10,
        },
        ticks: {
          color: gray,
          beginAtZero: true,
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          color: gray,
          font: { size: 13 },
          text: additionalOptions.xAxisLabel,
          padding: 10,
        },
        ticks: {
          color: gray,
        },
      },
    },
    ...additionalOptions,
  };

  return <Line data={chartData} options={options} plugins={[ChartDataLabels]} />;
};

export default LineChart;
