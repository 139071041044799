import { IConfig } from './types';

const config: IConfig = {
  env: 'nonprod',
  productName: 'Actively Learn',
  productUrl: 'https://read-nonprod.activelylearn.com',
  backendUrl: 'https://api-nonprod.activelylearn.com',
  readerAppUrl: 'https://reader-nonprod.activelylearn.com',
  cookieDomain: '.activelylearn.com',
  csrfTokenKey: 'CSRF-TOKEN-NONPROD',
  stripeKey: 'pk_test_hGcCTxnNucfD8epy7K4uXcNJ',
};

export default config;
