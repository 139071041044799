import React from 'react';
import styles from './styles.module.css';
import ProblemSet from './problemSet';

const Cluster = ({
  name, code, problemSets, toggleFavorite,
}: {
  name: string;
  code: string;
  problemSets: any[];
  toggleFavorite: (data: { problemSetIds: number[]; isFavorited: boolean }) => void;
}): JSX.Element => {
  return (
    <li className={styles.standard}>
      {name &&
        <div className={styles.standardName}>
          Cluster{code ? ' ' + code : ''}: {name}
        </div>
      }
      <ul>
        {problemSets.map(problemSet =>
          <ProblemSet
            key={problemSet.name}
            {...problemSet}
            toggleFavorite={toggleFavorite}
          />
        )}
      </ul>
    </li>
  );
};

export default Cluster;