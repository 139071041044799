/**
 * Simple wrapper for react-material-ui-form-validator that does 2 things:
 *
 *  1. Combines validators and errorMessages properties into single, more intuitive
 *     validators object property that uses validators as key and errorMessage as value
 *
 *  2. Passes on ONLY valid, active validators which allows for validators to be
 *     dynamically added without boilerplate of checking whether they are present
 *     or not when calling a component
 */
import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  ValidatorForm as ValidatorFormOriginal,
  TextValidator as TextValidatorOriginal,
  SelectValidator as SelectValidatorOriginal
} from 'react-material-ui-form-validator';
import styles from './styles.module.css';

export const ValidatorForm = ValidatorFormOriginal;

interface IPropsValidator {
  validators?: any;
  required?: boolean | string;
  label: string;
  id: string;
  name?: string;
  margin?: 'dense' | 'none' | 'normal';
  shouldHideLabelWhenSelected?: boolean;
  value?: any;
  variant?: string;
  height?: number;
  children?: React.ReactNode;
  hideAriaLabel?: boolean;
  [key: string]: any;
}

export const TextValidator = ({
  validators = {},
  required = false,
  label,
  id,
  name,
  margin = 'normal',
  variant = 'outlined',
  height = 48,
  children,
  InputProps = {},
  hideAriaLabel,
  ...rest
}: IPropsValidator) => {
  const { rules, errorMessages } = extractValidators(validators, required, label);
  const styles = getTextFieldStyles(height, margin, rest.error);

  return <TextValidatorOriginal
    {...rest}
    id={id}
    className={styles.root}
    name={name || id}
    label={label}
    validators={rules}
    errorMessages={errorMessages}
    margin={margin}
    variant={variant}
    InputProps={{
      ...InputProps,
      endAdornment: children,
      classes: {
        input: styles.input,
      }
    }}
    InputLabelProps={{
      classes: {
        root: styles.label,
      }
    }}
  />;
};

export class SelectValidator extends React.Component<IPropsValidator, any> {
  private selectRef: any;

  constructor(props: Readonly<IPropsValidator>) {
    super(props);
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    const selectBtn = this.selectRef.current.querySelector('[role="button"], [role="combobox"]');
    const elm = document.getElementById('gradeSelector');

    if (selectBtn && (document.getElementById(`${this.props.id}-label`)?.textContent !== this.props.label)) {
      if (elm) {
        selectBtn.setAttribute('aria-label', getSelectLabelText('Select responses'));
      }
      else {
        selectBtn.setAttribute('aria-label', getSelectLabelText(this.props.label));
      }
    }
  }

  componentDidUpdate() {
    const selectBtn = this.selectRef.current.querySelector('[role="button"], [role="combobox"]');
    const selectInput = this.selectRef.current.querySelector('input[type="hidden"]');
    const elm = document.getElementById('gradeSelector');

    if (!this.props.hideAriaLabel && selectBtn && selectInput && selectInput.value) {
      if (elm) {
        selectBtn.setAttribute('aria-label', getSelectLabelText('Select responses'));
      }
      else {
        selectBtn.setAttribute('aria-label', getSelectLabelText(this.props.label));
      }
    }

    if (document.getElementById(`${this.props.id}-label`)?.textContent === this.props.label) {
      selectBtn.removeAttribute('aria-label');
    }
    else {
      if (elm) {
        selectBtn.setAttribute('aria-label', getSelectLabelText('Select responses'));
      }
      else {
        selectBtn.setAttribute('aria-label', getSelectLabelText(this.props.label));
      }
    }
  }

  render() {
    const { rootClasses = '', ...rest } = this.props;

    return <div ref={this.selectRef} className={`${styles.fullWidth} ${rootClasses}`}>
      <RenderSelectValidatorComponent {...rest} />
    </div>;
  }
};

function RenderSelectValidatorComponent(props) {
  const { validators, required, label, id, margin = 'normal', onChange, name,
    shouldHideLabelWhenSelected, value, variant = 'outlined', height = 48, hideAriaLabel, ...rest } = props;
  const { rules, errorMessages } = extractValidators(validators, required, label, true);
  const styles = getTextFieldStyles(height, margin);

  const handleSelectAriaAttr = (event) => {
    event.target?.setAttribute('aria-expanded', 'false');

    if (event.target.id !== 'editCustomLabel') {
      if (id === 'grade-levels' || id === 'ddlSchools' || 'selectPrimaryUser' || 'selectSecondaryUser') {
        const spanElement = event.target?.querySelector('span');

        if (spanElement) {
          spanElement.innerHTML = '';
        }
        event.target?.setAttribute('role', 'combobox');
      }
    }
  };

  return (
    <SelectValidatorOriginal
      {...rest}
      value={value}
      id={id}
      name={name || id}
      className={styles.root}
      label={shouldHideLabelWhenSelected && value ? '' : label}
      validators={rules}
      errorMessages={errorMessages}
      margin={margin}
      onChange={(ev) => setTimeout(() => onChange(ev), 0)} // Ensure the Select UI renders before performing onchange action
      variant={variant}
      InputProps={{
        classes: {
          input: styles.input,
        }
      }}
      InputLabelProps={{
        classes: {
          root: styles.label,
        }
      }}
      SelectProps={{
        MenuProps: {
          role: 'region',
        }
      }}
      onFocus={(e) => handleSelectAriaAttr(e)}
    />
  );
}

const extractValidators = (validators: any, required: boolean | string, label: string, isSelector?: boolean) => {
  if (typeof required === 'string') {
    validators = { required, ...validators };
  }
  else if (required) {
    const requiredText = isSelector
      ? `Please ${getSelectLabelText(label)}`
      : `Please enter the ${label.toLowerCase()}`;

    validators = { required: requiredText, ...validators };
  }

  const noRules = !validators || Object.keys(validators).length === 0;
  const rules = noRules
    ? []
    : Object.keys(validators);
  const errorMessages = noRules
    ? []
    : Object.keys(validators).map((rule: string) => validators[rule]);
  return { rules, errorMessages };
};

function getSelectLabelText(label) {
  label = label.toLowerCase();

  return label.indexOf('select') === -1
    ? 'select a ' + label
    : label;
}

export function getTextFieldStyles(height: number, margin: string, error = undefined) {
  const offset = (56 - height) / 2;
  const paddingY = 18.5 - offset;
  const labelTop = margin === 'dense' ? offset : -offset

  const useStyles = makeStyles({
    input: {
      paddingTop: `${paddingY}px !important`,
      paddingBottom: `${paddingY}px !important`,
    },

    label: {
      top: `${labelTop}px !important`,
      marginLeft: '0px !important',
    },

    root: {
      padding: '0px !important',
      '& .Mui-error': {
        color: error === false ? 'black' : '#d31111',
      },
      '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: error === false ? 'black' : '#d31111',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--label-text-color)'
      }
    }
  });

  return useStyles();
}