export const ReactSelectStyles = {
  container: (base: any) => ({
    ...base,
    letterSpacing: -0.5,
    fontSize: 32,
    fontFamily: 'AvenirNext-Medium',
  }),
  control: (base: any) => ({
    ...base,
    width: 350,
    cursor: 'pointer',
    border: 0,
    boxShadow: 'initial',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base: any) => ({
    ...base,
    margin: 0,
  }),
  input: (base: any) => ({
    ...base,
    height: 41,
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 3,
    width: 335,
    marginTop: 5,
    fontSize: 24,
  }),
  option: (base: any) => ({
    ...base,
    lineHeight: 1.3,
    paddingTop: 10,
    paddingBottom: 10,
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: 50,
    '& svg': {
      height: 40,
      width: 40,
    }
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const colors = {
  blue: '#0077d1',
  white: '#fff',
  brown: '#8f5100',
  orange: '#e68200',
  lightBlue: '#3098e8',
  darkBrown: '#482900',
  darkBlue: '#134d5f',
  borderBlue: '#1878d2',
  grayBlue: '#455a64',
  placeHolderGray: '#cecfcf',
  boxShadowGray: '#ededed',
  borderGray: '#f1f1f1',
  black: '#000',
  gray1: '#cdcdcd',
  gray2: '#ccc',
  backgroundGray: '#f2f2f2',
  beginningRed: '#c10606',
  developingRed: '#e96a63',
  approachingGreen: '#17cf85',
  masteryGreen: '#00a884',
  levelI: '#d46d4e',
  levelII: '#f9ad6a',
  levelIII: '#f9e07f',
  levelIV: '#43978d',
  levelV: '#264d59',
};

export const levelSetColors = [
  colors.levelI,
  colors.levelII,
  colors.levelIII,
  colors.levelIV,
  colors.levelV,
];
