import { IConfig } from './types';

const config: IConfig = {
  env: 'ci',
  productName: 'Actively Learn',
  productUrl: 'https://www.activelylearn.com',
  readerAppUrl: 'https://reader.activelylearn.com',
  backendUrl: 'https://pr.activelylearn.com',
  cookieDomain: '.activelylearn.com',
  csrfTokenKey: 'CSRF-TOKEN-CI',
  stripeKey: 'pk_test_hGcCTxnNucfD8epy7K4uXcNJ',
};

export default config;
