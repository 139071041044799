import React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import Button from '../button/Button';
import withStyles from '@mui/styles/withStyles';

interface IProps {
  submitData: any;
  enableMultiple?: boolean;
  enableMultipleErrorMessage?: string;
  disablePreview?: boolean;
  fileType?: string;
  fileTypeErrorMessage?: string;
  fileSizeErrorMessage?: string;
  fileDropPlaceholder: string;
  maxFileSize?: number;
  containerModal?: any;
  onDropCallback?: Function;
  onDropErrorCallback?: Function;
  onSubmit: Function;
  onSubmitError: Function;
  pdfjsLibRef?: any;
  classes?: any;
}

const styles = () => ({
  dropzone: {
    width: '100%',
    borderColor: '#666666',
    borderStyle: 'dashed',
    boxSizing: 'border-box',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    minHeight: 50,
  },
  addedFiles: {
    fontSize: 14,
  },
  btnRemoveAddedFile: {
    display: 'inline',
    marginLeft: 10,
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent',
    verticalAlign: 'middle',
    paddingTop: 4,
    color: '#CCC',
    fontSize: 20,

    '&:focus': {
      outline: 'none',
    },
  },
  imagePreview: {
    width: 60,
    height: 60,
    marginRight: 10,
  }
});

class Uploader extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.submit = this.submit.bind(this);
    this.state = { files: [] };

    return this;
  }

  onDrop(files: File[], rejectedFiles: FileRejection[]) {
    const { onDropErrorCallback, containerModal, onDropCallback, disablePreview } = this.props;
    const errorMessage = this.getErrorMessage(files, rejectedFiles);

    if (errorMessage && onDropErrorCallback) {
      onDropErrorCallback(errorMessage);
    }
    else {
      if (containerModal) {
        containerModal.enableOk();
      }

      if (onDropCallback) {
        onDropCallback();
      }

      if (!disablePreview) {
        files = files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
      }

      this.setState({ files: this.state.files.concat(files) });
    }
  }

  getErrorMessage(files: File[], rejectedFiles: FileRejection[]) {
    const maxAllowedFileSize = this.props.maxFileSize || 10000000; // Default 10MB max allowed file size
    const isAnyFileAboveMaxSize = files.find((f: any) => f.size > maxAllowedFileSize);
    const isMultipleError = !this.props.enableMultiple && this.state.files.length > 0;
    const isFileRejected = rejectedFiles && rejectedFiles.length;

    if (isAnyFileAboveMaxSize) {
      const maxAllowedFileSizeInMB: number = maxAllowedFileSize / 1000000;
      return this.props.fileSizeErrorMessage ||
        `Wow, that's a pretty big file! Please try uploading a file that's less than ${maxAllowedFileSizeInMB}MB instead.`;
    }
    else if (isMultipleError) {
      return this.props.enableMultipleErrorMessage || 'Only one file allowed.';
    }
    else if (isFileRejected) {
      return this.props.fileTypeErrorMessage || 'This file type is not allowed.';
    }
    else {
      // no error
      return null;
    }
  }

  getNumPagesInPdf(callback: any) {
    if (this.state.files[0].type === 'application/pdf') {
      const fileReader = new FileReader();
      const pdfjsLib = this.props.pdfjsLibRef;
      fileReader.onload = function () {
        // @ts-ignore
        const typedarray = new Uint8Array(this.result);
        pdfjsLib.getDocument(typedarray).then((doc: any) => {
          const numPages = doc.numPages;
          doc.destroy();
          callback(numPages);
        });
      };

      fileReader.readAsArrayBuffer(this.state.files[0]);
    }
    else {
      callback(0);
    }
  }

  isFileUploaded(callback: any) {
    callback(this.state.files.length);
  }

  submit(moreSubmitDataToAdd: any) {
    this.revokeUri();

    if (this.state.files.length > 0) {
      const data = new FormData();
      const submitData = { ...this.props.submitData, ...(moreSubmitDataToAdd || {}) };
      const keys = Object.keys(submitData);

      for (const key of keys) {
        if (submitData[key]) {
          data.append(key, submitData[key]);
        }
      }

      this.state.files.forEach((file: File, i: number) => {
        data.append('file_' + i.toString(), file, file.name);
      });

      this.props.onSubmit(data);
    }
    else {
      const error = new Error('noFiles');
      this.props.onSubmitError(error);
    }
  }

  removeFile(fileName: string) {
    const files = this.state.files.filter((f: File) => f.name !== fileName);
    this.setState({ files });

    if (files.length === 0 && this.props.containerModal) {
      this.props.containerModal.disableOk();
    }
  }

  revokeUri() {
    if (!this.props.disablePreview) {
      this.state.files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }
  }

  componentWillUnmount() {
    this.revokeUri();
  }

  render() {
    const styles = this.props.classes;

    return (
      <section>
        <Dropzone
          onDrop={this.onDrop}
          multiple={this.props.enableMultiple}
          accept={this.props.fileType}>
          {({ getRootProps, getInputProps }) => (
            // @ts-ignore
            <button className={styles.dropzone} {...getRootProps()} type="button">
              {/*
               // @ts-ignore */}
              <input {...getInputProps()} />
              <p>{this.props.fileDropPlaceholder}</p>
            </button>
          )}
        </Dropzone>
        { this.state.files.length > 0 &&
          <ul className={styles.addedFiles}>
            {this.state.files.map((f: any, i: number) => (
              <li key={i}>
                {!this.props.disablePreview &&
                  <img className={styles.imagePreview} src={f.preview} key={f.preview} alt="" />
                }
                {f.name}
                <Button
                  aria-label="remove file"
                  title="Remove file"
                  variant="default"
                  className={styles.btnRemoveAddedFile}
                  onClick={() => this.removeFile(f.name)}>
                  &times;
                </Button>
              </li>))
            }
          </ul>
        }
      </section>
    );
  }
};

// @ts-ignore
export default withStyles(styles)(Uploader) as React.ComponentClass<IProps, any>;
