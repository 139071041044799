import React, { useEffect } from 'react';
import { Modal } from '../../modal';
import ExternalClassImportButtons from './ExternalClassImportButtons';
import { Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '../../../icons/chevron-down.svg';
import { useRowStyles } from './styles';
import AddNativeClassForm from './AddNativeClassForm';
import AdminConsentPendingModal from './adminConsentPendingModal';
import AdminConsentComponent from './AdminConsentComponent';

interface IAddNewClass {
  title?: string;
  cssClass?: string;
  onGradeLevelUpdated?: Function;
  googleImportHelper?: {
    getGoogleCourses: Function;
    apiCallback: Function;
  };
  edmodoImportHelper?: {
    getCourses: Function;
    apiCallback: Function;
  };
  cleverImportHelper?: {
    getCourses: Function;
    apiCallback: Function;
    getErrorMessage: Function;
  };
  canvasImportHelper?: {
    getCourses: Function;
    apiCallback: Function;
  };
  brightspaceImportHelper?: {
    getCourses: Function;
    apiCallback: Function;
  };
  schoologyImportHelper?: {
    getCourses: Function;
    apiCallback: Function;
  };
  oneRosterImportHelper?: {
    getCourses: Function;
    apiCallback: Function;
  };
  achieve3KImportHelper?: {
    getCourses: Function;
    apiCallback: Function;
  };
  onSubmit: Function;
  cancelCallback?: Function;
  isHigherEd?: boolean;
  schoolInfo: any[];
  hideModal: Function;
  showModal: Function;
  isCreatingFirstClass?: boolean;
  classes?: {
    divider?: string;
    classImportButton?: string;
  };
  addClassIsDisabled?: boolean;
  gradeLevelSuggestions?: any;
  requestAdminConsent: Function;
  adminConsentData: any;
};
interface INativeClass {
  name: string;
  gradeLevelId: string;
  schoolId: number | null;
  id: number;
  gradeLevelCourse: string;
};

export default function AddNewClassPopup({
  title = 'Add new class',
  cssClass = '',
  onGradeLevelUpdated,
  googleImportHelper,
  edmodoImportHelper,
  cleverImportHelper,
  canvasImportHelper,
  brightspaceImportHelper,
  schoologyImportHelper,
  oneRosterImportHelper,
  achieve3KImportHelper,
  isHigherEd = false,
  onSubmit,
  schoolInfo,
  hideModal,
  showModal,
  cancelCallback,
  isCreatingFirstClass = false,
  addClassIsDisabled = false,
  gradeLevelSuggestions = null,
  requestAdminConsent,
  adminConsentData,
}: IAddNewClass) {
  const [nativeClasses, setNativeClasses] = React.useState<INativeClass[]>([{
    name: '',
    gradeLevelId: '',
    id: 1,
    schoolId: null,
    gradeLevelCourse: ''
  }]);
  const styles = useRowStyles({});
  const shouldHideAddNewClassForm =
    googleImportHelper ||
    edmodoImportHelper ||
    cleverImportHelper ||
    canvasImportHelper ||
    brightspaceImportHelper ||
    oneRosterImportHelper ||
    schoologyImportHelper ||
    achieve3KImportHelper;
  const [showAddClassForm, setShowAddClassForm] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [schoolIdForConsent, setSchoolIdForConsent] =React.useState(null);
  const disableAddClass = addClassIsDisabled;

  useEffect(() => {
    setShowAddClassForm(!shouldHideAddNewClassForm);
  }, [shouldHideAddNewClassForm]);

  async function sendAdminConsent() {
    requestAdminConsent(email, schoolIdForConsent);
    hideModal();

    if (cancelCallback) {
      cancelCallback();
    }
    showModal(<AdminConsentPendingModal />);
  }

  function addNewClass() {
    const classesData: {
      name: string;
      gradeLevel: string;
      schoolId?: number;
      gradeLevelCourse: string;
    }[] = [];

    nativeClasses.forEach((classData) => {
      if (classData.name) {
        const data: {
          name: string;
          gradeLevel: string;
          schoolId?: any;
          gradeLevelCourse: string;
        } = {
          name: classData.name,
          gradeLevel: isHigherEd ? '' : classData.gradeLevelId,
          gradeLevelCourse: isHigherEd ? '' : classData.gradeLevelCourse,
        };

        if (classData.schoolId) {
          data.schoolId = classData.schoolId;
        }

        classesData.push(data);
      }
    });

    onSubmit({ classes: classesData });
  }

  function toggleAddClassForm(): void {
    setShowAddClassForm(!showAddClassForm);
  }

  return disableAddClass || adminConsentData.status === true ? (
    <Modal
      title={!disableAddClass ? title : "Add Class is disabled"}
      okCallback={addNewClass}
      cancelCallback={cancelCallback}
      okText={showAddClassForm ? "Submit" : ""}
      cssClass={cssClass}
      disabledOkText="Adding..."
      id="add-new-class-modal"
      maxWidth={isCreatingFirstClass && schoolInfo.length > 1 ? "lg" : "sm"}
      fullWidth>
      {!disableAddClass ? (
        <>
          <ExternalClassImportButtons
            onGradeLevelUpdated={onGradeLevelUpdated}
            googleImportHelper={googleImportHelper}
            edmodoImportHelper={edmodoImportHelper}
            cleverImportHelper={cleverImportHelper}
            canvasImportHelper={canvasImportHelper}
            brightspaceImportHelper={brightspaceImportHelper}
            oneRosterImportHelper={oneRosterImportHelper}
            schoologyImportHelper={schoologyImportHelper}
            achieve3KImportHelper={achieve3KImportHelper}
            schoolInfo={schoolInfo}
            hideModal={hideModal}
            showModal={showModal}
            closeParentModal={cancelCallback}
          />
          <Accordion
            expanded={showAddClassForm}
            onChange={toggleAddClassForm}
            classes={{ root: styles.accordion }}
          >
            {shouldHideAddNewClassForm && (
              <AccordionSummary
                expandIcon={
                  <span className={styles.accordionArrow}>
                    <ExpandMoreIcon />
                  </span>
                }
                aria-controls="addNewClassForm"
                id="addNewClassFormToggler"
                classes={{
                  root: styles.accordionSummary,
                  expanded: styles.expandIcon,
                }}
              >
                <span>Manually create a class in Actively Learn</span>
              </AccordionSummary>
            )}
            <AddNativeClassForm
              isHigherEd={isHigherEd}
              schoolInfo={schoolInfo}
              nativeClasses={nativeClasses}
              setNativeClasses={setNativeClasses}
              isCreatingFirstClass={isCreatingFirstClass}
              gradeLevelSuggestions={gradeLevelSuggestions}
            />
          </Accordion>
        </>
      ) : (
        <div>
          This feature has been disabled by your admin. You cannot add any new
          classes.
        </div>
      )}
      {cleverImportHelper && (
        <p className="info">
          Your students will need a code to join this class, because it is not
          being sync'ed from your SIS.
        </p>
      )}
      {edmodoImportHelper && (
        <p className="info">
          Your students will need a code to join this class, because it is not
          being sync'ed from Edmodo.
        </p>
      )}
    </Modal>
  ) : (
    <AdminConsentComponent
      okCallback={sendAdminConsent}
      cancelCallback={cancelCallback}
      adminConsentData={adminConsentData}
      setEmail={setEmail}
      email={email}
      schoolInfo={schoolInfo}
      setSchoolIdForConsent={setSchoolIdForConsent}
      schoolIdForConsent = {schoolIdForConsent}
    />
  );
}
