import produce from 'immer';

const initialState = {
  data: null,
  benchmarks: [],
  gradeLevelsForDropdown: [],
  assignedProblemSetIds: [],
  favoriteProblemSetIds: [],
  revealBookTitleSlug: '',
  filters: {
    search: '',
    grade: '',
    hasSearched: false,
    showingFavorites: false,
    defaultGrade: '',
    shouldSelectAllGrades: false,
  },
};

const reducer: any = produce((draft: any, action: any) => {
  switch (action.type) {
  case 'REQUEST_CATALOG_DATA': {
    draft.data = null;
    break;
  }
  case 'RESOLVE_CATALOG_DATA': {
    Object.assign(draft, action.body);
    break;
  }
  case 'ASSIGNED_PROBLEMSET': {
    draft.assignedProblemSetIds.push(action.body);
    break;
  }
  case 'UNASSIGNED_PROBLEMSET': {
    draft.assignedProblemSetIds = draft.assignedProblemSetIds.filter((x: number) => x !== action.body);
    break;
  }
  case 'RESOLVE_FAVORITE_PROBLEM_SET': {
    const { problemSetIds } = action.body;
    const isFavorite = draft.favoriteProblemSetIds.some((favoriteProblemSetId: number) =>
      problemSetIds.includes(favoriteProblemSetId)
    );

    draft.favoriteProblemSetIds = isFavorite
      ? draft.favoriteProblemSetIds.filter((favoriteProblemSetId: number) =>
        !problemSetIds.includes(favoriteProblemSetId)
      )
      : [...draft.favoriteProblemSetIds, ...problemSetIds];
    break;
  }
  case 'UPDATE_CATALOG_FILTERS': {
    Object.assign(draft.filters, action.body);
    break;
  }
  case 'SET_ASSIGNED_PROBLEMSETS': {
    draft.assignedProblemSetIds = action.body;
    break;
  }
  case 'SET_REVEAL_BOOKTITLESLUG': {
    draft.revealBookTitleSlug = action.body;
    break;
  }
  }
}, initialState);

export default reducer;
