import React, { useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { makeStyles } from '@mui/styles';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { groupBy, uniqBy } from "lodash";
import {
  Fonts,
  helpArticleLink,
  weThePeoplePublisher,
  LearnEntityPurchaseSource,
} from "../../../helpers/constants";
import { getParamsInCamelCase } from "../../../helpers/objectHelpers";
import { plural } from "../../../helpers/textHelpers";
import { sanitize } from "../../../helpers/commonHelpers";
import { getLicenseAccess } from "./api";
import { getNumOfLicensesParams } from "../Payment/PaymentModal/helper";
import { Button } from "../../button";
import LoadingWrapper from '../../LoadingWrapper';
import { StudentAccessInfo } from '../types';
dayjs.extend(utc);
dayjs.extend(relativeTime);

const useStyles = makeStyles({
  text: {
    fontFamily: Fonts.MEDIUM,
  },
  needLicenses: {
    color: "#f50057", // use css var
  },
  actionButtons: {
    display: "flex",
    alignItems: "space-around",
    marginBottom: "1rem",

    "& button": {
      margin: "0 1rem",
    },
    "& button:first-of-type": {
      marginLeft: "0",
    },
    "& button:last-of-type": {
      marginRight: "0",
    },
  },
});

interface ManageRentalLicensesModalProps {
  show: boolean;
  classId: number;
  currentUserId: number;
  isUserOnPrime: boolean;
  learnEntityId: number;
  setCoteacherSelectModalState: Function;
  setPurchaseModalState: Function;
  setOrderHistoryModalState: Function;
  setManageRentalLicensesModalState: Function;
  title: string;
  cancelSelectMode: Function;
  isOnPrimeUnlimited: Boolean;
}

const ManageRentalLicenses = ({
  show,
  classId,
  currentUserId,
  isUserOnPrime,
  isOnPrimeUnlimited,
  learnEntityId,
  setPurchaseModalState,
  setCoteacherSelectModalState,
  setOrderHistoryModalState,
  setManageRentalLicensesModalState,
  title,
  cancelSelectMode,
}: ManageRentalLicensesModalProps): JSX.Element => {
  const [numberOfLicenseNeeded, setNumberOfLicenseNeeded] = useState(0);
  const [numberOfLicenseAvailable, setNumberOfLicenseAvailable] = useState(0);
  const [numberOftotalLicense, setNumberOftotalLicense] = useState(0);
  const [numberofAssignedStudents, setNumberOfAssignedStudents] = useState(0);
  const [numberofAssignedTeachers, setNumberOfAssignedTeachers] = useState(0);
  const [isSchoolBookCreditsActive, setIsSchoolBookCreditsActive] = useState(false);
  const [numOfStudentsWithoutLicense, setNumOfStudentsWithoutLicense] = useState(0);
  const [numOfStudentsLicenseUsed, setNumOfStudentsLicenseUsed] = useState(0);
  const [numOfTeacherLicenseUsed, setNumOfTeacherLicenseUsed] = useState(0);
  const [textM, setTextM] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [classes, setClasses] = useState<any[]>([]);
  const [coteacherLicense, setCoteacherLicense] = useState<any[]>([]);
  const [isAllowedCoTeacherToUseLicense, setIsAllowedCoTeacherToUseLicense] = useState(false);
  const [students, setStudents] = useState<any>([]);
  const styles = useStyles();

  useEffect(() => {
    setIsLoading(true);
    // for each class, get list of students and if they have access or not
    const getLicenseAccessData = async () => {
      const data = await getLicenseAccess({ learnEntityId: learnEntityId });

      if (data) {
        const accessList = data.accessList || [];
        const tempStudentsList = uniqBy(accessList.filter(s=>s.licenseId), "licenseId");
        const groupedTempStudentsList = groupBy(tempStudentsList, "studentUserId");
        let studentsList: {
          userId: string;
          total: number;
          fullName: string;
          expireDate: Date | null
        }[] = [];

        for(let [userId, data] of Object.entries(groupedTempStudentsList)) {
          const expireDate = data.reduce((acc,curr)=>{
            if (!acc || !curr.expireDate) {
              return null;
            }

            return acc.getTime() > new Date(curr.expireDate).getTime() ? acc : new Date(curr.expireDate);
          }, new Date(0));

          studentsList.push({
            userId: userId,
            total: data.length,
            fullName: data[0].fullName || '',
            expireDate,
          });
        }
        setStudents(studentsList);
        const numOfLicensesParams = getNumOfLicensesParams( accessList, currentUserId );
        const coteacherLicense = uniqBy(data.coTeacherLicenseInfo.filter(s=>s.licenseId), "licenseId");
        const groupedCoteacherLicense = groupBy(coteacherLicense, "teacherUserId");

        let teacherList: any[] = [];

        for(let [userId, data] of Object.entries(groupedCoteacherLicense)) {
          const expireDate = (data || []).reduce((acc,curr)=>{
            if (!acc || !curr.expireDate) {
              return null;
            }

            return acc.getTime() > new Date(curr.expireDate).getTime() ? acc : new Date(curr.expireDate);
          }, new Date(0));

          teacherList.push({
            userId: userId,
            total: (data || []).length,
            fullName: (data || [])[0].fullName,
            expireDate,
          });
        }

        setCoteacherLicense(teacherList);
        const numberOfTeacherLicenseUsed = uniqBy(data.coTeacherLicenseInfo, (s: any) => s.licenseId).length;
        setNumOfTeacherLicenseUsed(numberOfTeacherLicenseUsed);
        setIsAllowedCoTeacherToUseLicense(data.isAllowedCoTeacherToUseLicense);
        const studentsLicenseUsed = uniqBy(accessList, s => s.licenseId);
        const accessListByStudent = groupBy(
          accessList.filter((x) => x.UserDeleted === false),
          'StudentUserId'
        );
        const studentUserIds = Object.keys(accessListByStudent);

        studentUserIds.forEach((studentUserId) => {
          const a: any = accessListByStudent[studentUserId][0]; // latest license for student
          a.hasAccess =
            a.LicenseId !== null &&
            (a.ExpireDate === null ||
              dayjs.utc(a.ExpireDate).isAfter(dayjs().utc().format()));
          accessList.push(a);
        });

        setNumberOftotalLicense(data.numberOftotalLicense + numOfLicensesParams.numOfStudentLicensesUsedSchoolContentCredits);
        setNumberOfAssignedStudents(data.numberofAssignedStudents);
        setNumberOfAssignedTeachers(data.numberofAssignedTeachers);
        setIsSchoolBookCreditsActive(data.isSchoolBookCreditsActive);
        setNumOfStudentsWithoutLicense(numOfLicensesParams.numOfStudentsWithoutLicense);
        setNumOfStudentsLicenseUsed(studentsLicenseUsed.length);
        setTextM(data.learnEntityInfo);
        setNumberOfLicenseNeeded(data.numberOfLicenseNeeded || 0);
        setNumberOfLicenseAvailable(data.numberOfLicenseAvailable || 0);
        const classesFromAccessList = uniqBy(accessList, 'classId').map(
          (c: any) => {
            return { classId: c.classId, className: c.className };
          }
        );
        setClasses(classesFromAccessList);
      }
    };

    if (learnEntityId) {
      getLicenseAccessData().then(()=> setIsLoading(false));
    }
  }, [learnEntityId]);

  const viewOrderHistory = () => {
    setOrderHistoryModalState({
      show: true,
      data: {
        title: `Order History for ${textM.title}`,
        learnEntityId,
        cancelText: false,
      },
    });
  };

  const buyMore = () => {
    setPurchaseModalState({
      show: true,
      data: {
        textM: getParamsInCamelCase(textM),
        source: LearnEntityPurchaseSource.MANAGE_LICENSES,
        title: textM.Title,
        afterPurchaseCallback: (quantity) => licensesUpdated(quantity),
        learnEntityId,
      },
    });
  };

  const licensesUpdated = (quantity) => setNumberOftotalLicense(numberOftotalLicense + quantity);
  const isWTP = textM ? textM.Publisher === weThePeoplePublisher : false;
  const showLicenseStateCount = !(isSchoolBookCreditsActive && isUserOnPrime);
  const numberOfAvailableLicenses = Math.max(numberOftotalLicense - (numOfStudentsLicenseUsed + numOfTeacherLicenseUsed), 0 );

  if (!show) {
    return <></>;
  }

  const getLicenseStatus = (expireDate: any) => {
    
    if (!expireDate) {
      return (<div>Allocated</div>);
    }

    if (dayjs.utc(expireDate).isBefore(dayjs.utc())) {
      return (<div>Expired - ({dayjs.utc(expireDate).local().format("MMM D, YYYY")})</div>);
    }

    return (<div>Accessed - (Expires {dayjs.utc(expireDate).local().format("MMM D, YYYY")})</div>);
  }

  return (
    <Modal
      title={title}
      okText="Close"
      okCallback={(modal) => {
        setManageRentalLicensesModalState({ show: false });
        cancelSelectMode();
      }}
      maxWidth="lg"
      cancelText={null}
      id="manageRentalLicensesModal"
    >
      <LoadingWrapper isLoading={isLoading} type="spinner" >
        {!(showLicenseStateCount && !isOnPrimeUnlimited) && (
          <div>
            <p>
              <span>
                This text is assigned to {numberofAssignedStudents}{" "}
                {plural(numberofAssignedStudents, "student.", "students.")}
              </span>
            </p>
          </div>
        )}
        {(showLicenseStateCount && !isOnPrimeUnlimited) && (
          <div>
            <p>
              <span>
                This text is assigned to {numberofAssignedStudents}{" "}
                {plural(numberofAssignedStudents, "student", "students")}{" "}
              </span>
              <span>
                and  {numberofAssignedTeachers}{" "}
                {plural(numberofAssignedTeachers, "teacher", "teachers")}.{" "}
              </span>
              <span>
                {numberOfLicenseNeeded} {plural(numberOfLicenseNeeded, "student", "students")} still require a license and you have {numberOfLicenseAvailable} available licenses.
              </span>
              {isWTP && (
                <div className="info">
                  Note: Assignments from <i>We the People</i> are divided into one
                  month long licenses so they can be used more efficiently. When
                  you assign the book to another class, students will
                  automatically start using their one month licenses when they log
                  in.
                  <a
                    className="btnLearnMore"
                    href={helpArticleLink.wtpLicenseInfo}
                  >
                    Learn more
                  </a>
                </div>
              )}
            </p>
            <div className={styles.actionButtons}>
              <Button variant="primary" onClick={buyMore}>
                Buy more licenses
              </Button>
              <Button variant="primary" onClick={viewOrderHistory}>
                View orders
              </Button>
            </div>
          </div>
        )}
        <div className="info licenseAllocation">
          Note: We automatically allocate licenses to students{" "}
          {isAllowedCoTeacherToUseLicense ? "and coteachers" : ""} as they enter
          the assignment. {}
        </div>
        <div className="studentsLicense">
          {classes.length === 1 && <h4>{classes[0].className}</h4>}

          <table className="tablesorter fullwidth">
            <tbody>
              <tr>
                <th>Student name</th>
                <th>License status</th>
                <th>Total License used</th>
              </tr>
              {students && students.length ? (
                students.map((s) => (
                  <tr key={s.userId}>
                    <td>{sanitize(s.fullName)}</td>
                    <td>{getLicenseStatus(s.expireDate)}</td>
                    <td>{s.total}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No students!</td>
                </tr>
              )}
            </tbody>
          </table>
          <table className="tablesorter fullwidth">
            <tbody>
              <tr>
                <th>Teacher name</th>
                <th>License status</th>
                <th>Total License used</th>
              </tr>
              {coteacherLicense && coteacherLicense.length ? (
                coteacherLicense.map((s) => (
                  <tr key={s.userId}>
                    <td>{sanitize(s.fullName)}</td>
                    <td>{getLicenseStatus(s.expireDate)}</td>
                    <td>{s.total}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Co-Teachers!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        </LoadingWrapper>
    </Modal>
  );
};

export default ManageRentalLicenses;
