import React from 'react';
import showAlert from '../../../common/toaster';
import { closeModal } from '../../../../actions/eventEmitter';
import { colors } from '../../../../utils/style';
import { getDataPlain } from '../../../../actions/api';
import { ReactSelect, Modal } from 'sharedfrontend';

const UpdateStepProblemSetModal = (props: any): JSX.Element => {
  const { stepId, problemSetName, saveStepProblemSet } = props;
  const [ problemSetList, setProblemSetList ] = React.useState([]);
  const [ newProblemSetId, setNewProblemSetId ] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getDataPlain({
        type: 'GET_ALL_PROBLEMSETS',
      });
      setProblemSetList(result);
    }

    fetchData();
  }, []);

  const onSave = () => {
    if (newProblemSetId) {
      saveStepProblemSet({ 
        stepId,
        problemSetId: newProblemSetId,
        problemSetName: (problemSetList as any).find((x: any) => x.value === newProblemSetId).label
      });
      closeModal();
    }
    else {
      showAlert({ message: 'Select a Problem Set!', isError: true });
    }
  };

  return (
    <Modal
      title="Change problem set"
      okText="Save"
      okCallback={onSave}
      cancelText="Cancel"
      maxWidth="md"
      shouldOverflow={true}
    >
      <React.Fragment>
        <p>Currently: {problemSetName}</p>
        <ProblemSetSelector 
          problemSets={problemSetList}
          selectedProblemSetId={newProblemSetId}
          onProblemSetSelected={setNewProblemSetId}
        />
      </React.Fragment>
    </Modal>
  );
};

export default UpdateStepProblemSetModal;

const ProblemSetSelector = (props: any) => {
  const { selectedProblemSetId, onProblemSetSelected, problemSets } = props;
  const selectedProblemSetOption = problemSets.find((x: any) => x.value === selectedProblemSetId);

  return (
    <ReactSelect
      placeholder="Search by problem set name"
      value={selectedProblemSetOption}
      suggestions={problemSets}
      onChange={(selectedProblemSetOption: any) => onProblemSetSelected(selectedProblemSetOption.value)}
      isSearchable={true}
      styles={{
        container: (base: any) => ({
          ...base,
          boxShadow: `1px 1px 10px ${colors.boxShadowGray}`,
          minWidth: 300,
          marginBottom: 10
        }),
        control: (base: any) => ({
          ...base,
          padding: '1px 6px',
          cursor: 'pointer',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        placeholder: (base: any) => ({
          ...base,
          fontFamily: 'AvenirNext-Bold',
          textTransform: 'uppercase',
          fontSize: 12,
          color: colors.placeHolderGray,
          letterSpacing: 0.5,
        }),
      }}
    />
  );
};