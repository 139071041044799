import React from 'react';
import LearnosityQuestion from '../common/learnosityQuestion/container';
import GradeFeedbackBar from '../gradeFeedbackBar/container';
import styles from './styles.module.css';
import Toolbar from '../common/toolbar/container';

class LearnosityQuestionWrapper extends React.Component<any, any> {
  render() {
    const { id, type, showStepAnswer, toggleFeatures } = this.props;

    return (
      <React.Fragment>
        <div className={styles.tools}>
          <Toolbar targetId={`container-${id}`} toggleFeatures={toggleFeatures} />
        </div>
        <LearnosityQuestion type={type} showStepAnswer={showStepAnswer} />
        <GradeFeedbackBar />
      </React.Fragment>
    );
  }
}

export default LearnosityQuestionWrapper;
