import AssignButton from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    problemSetName: ownProps.problemSetName || state.problemSetData.problemSetName,
    description: ownProps.description || state.problemSetData.description,
    problemSetId: ownProps.problemSetId || state.problemSetData.problemSetId,
    classId: ownProps.classId || state.problemSetData.classId,
    assignedProblemSetIds: state.catalogData.assignedProblemSetIds,
    isBenchmark: ownProps.isBenchmark || state.problemSetData.isBenchmark,
    role: state.userData.role,
    isProblemSetAssigned: state.problemSetData.isAssigned,
  };
};

export default connect(
  mapStateToProps,
)(AssignButton);
