import produce from 'immer';
import { ImplementationType } from '../utils/constants';

const initialState = {
  stepProblemSetId: null,
  problemSetName: null,
  ageRange: null,
  numAttempts: 0,
  learnosityJson: {},
  graphJson: null,
  isDeleted: false,
  numUndeletedSteps: 0,
  showRuler: false,
  showProtractor: false,
};

const reducer: any = produce((draft: any, action: any) => {
  switch (action.type) {
  case 'RESOLVE_GET_STEP_DATA': {
    Object.assign(draft, { ...action.body, responseData: undefined });
    break;
  }
  case 'RESOLVE_SAVE_STEP_EDITS': {
    draft.learnosityJson.questions = [action.body];
    break;
  }
  case 'RESOLVE_GRADE_RESPONSE': {
    if (action.body.type === ImplementationType.STEP) {
      if (action.body.isValidAttempt) {
        draft.numAttempts++;
      }

      if (action.body.isCorrect) {
        draft.learnosityJson.responses = action.body.response;
      }
    }
    break;
  }
  case 'GO_TO_PROBLEM':
  case 'RESOLVE_RESTART_PROBLEM':
  case 'RESOLVE_SHOW_STEPANSWER': {
    Object.assign(draft, initialState);
    break;
  }
  case 'RESOLVE_UPDATE_STEP_PROBLEMSET': {
    draft.stepProblemSetId = action.body.problemSetId;
    draft.problemSetName = action.body.problemSetName;
    break;
  }
  case 'RESOLVE_TOGGLE_DELETE_STEP': {
    draft.isDeleted = !draft.isDeleted;
    draft.numUndeletedSteps = draft.isDeleted
      ? draft.numUndeletedSteps - 1
      : draft.numUndeletedSteps + 1;

    break;
  }
  case 'RESOLVE_PROBLEM_DATA': {
    draft.numUndeletedSteps = action.body.numUndeletedSteps;

    break;
  }
  case 'RESOLVE_TOGGLE_STEP_FEATURES': {
    draft[action.body] = !draft[action.body];
    break;
  }
  }
}, initialState);

export default reducer;
