import StudentData from './interventions.component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    startDate: state.classReportsDataFilter.startDate,
    endDate: state.classReportsDataFilter.endDate,
  };
};


export default connect(
  mapStateToProps,
)(StudentData);