import React, { useState } from 'react';
import styles from './styles.module.css';
import { Modal } from 'sharedfrontend';
import commonStyles from '../../commonStyles.module.css';
import { closeModal } from '../../../../actions/eventEmitter';
import VideoPlayer from '../../videoPlayer/component';

let playedVideoInIntervalTime = 0;

function LearnZillionVideosModal(props: any) {
  const [intervalId, setIntervalId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const { classId, problemSetId, videos } = props;
  const videoToShow = videos[currentIndex];
  const showVideoNavButtons = videos.length > 1;
  const actionButtons = showVideoNavButtons
    ? [{
      text: 'Previous Video',
      callback: () => goToVideo(-1),
      classes: currentIndex === 0 ? commonStyles.disabled : undefined
    },
    {
      text: 'Next Video',
      callback: () => goToVideo(1),
      classes: currentIndex === videos.length - 1 ? commonStyles.disabled : undefined
    }]
    : undefined;

  const goToVideo = (indexChange: number) => {
    const totalPlayedTime = playedSeconds + playedVideoInIntervalTime;
    const indexToGoTo = currentIndex + indexChange;
    playedVideoInIntervalTime = 0;
    track(currentIndex, totalPlayedTime); // send tracking info of previously played video, if there is one
    setPlayedSeconds(0);
    clearInterval(intervalId);
    setCurrentIndex(indexToGoTo);
  }

  const track = (currentIndex: number, timeSpentInSeconds: number) => {
    const percentageViewed = timeSpentInSeconds / duration;

    if (percentageViewed > 0) {
      props.track({
        event: 'percentageViewed',
        value: percentageViewed,
        timeSpentInSeconds,
        learnzillionVideoId: videos[currentIndex].learnzillionVideoId,
        classId,
        problemSetId,
      });
    }
  }

  const handleCloseModal = () => {
    const totalPlayedTime = playedSeconds + playedVideoInIntervalTime;
    track(currentIndex, totalPlayedTime); // send any tracking data for last video
    clearInterval(intervalId);
    closeModal();
  }

  const handleOnPlay = () => {
    const startTime = Date.now();
    const intervalId: any = setInterval(() => {
      playedVideoInIntervalTime = Math.round((Date.now() - startTime) / 1000);
    }, 1000);

    setIntervalId(intervalId);
  }

  const setVideoPlayedTime = () => {
    const totalPlayedTime = playedSeconds + playedVideoInIntervalTime;
    playedVideoInIntervalTime = 0;
    setPlayedSeconds(totalPlayedTime);
    clearInterval(intervalId);
  }

  return (
    <Modal
      title=""
      maxWidth={false}
      actionButtons={actionButtons}
      okText=""
      cancelText="Close"
      cancelCallback={handleCloseModal}
    >
      <div className={styles.videos}>
        <React.Fragment>
          <p>{videoToShow.title}</p>
          <VideoPlayer
            url={videoToShow.url}
            controls
            setDuration={setDuration}
            handleOnPlay={handleOnPlay}
            setVideoPlayedTime={setVideoPlayedTime}
          />
        </React.Fragment>
      </div>
    </Modal>
  );
}

export default LearnZillionVideosModal;
