import React, { Fragment, useState, useEffect } from 'react';
import { ReadingSettings } from '../types';
import { Checkbox } from '../../inputs';
import classNames from 'classnames';
import styles from './styles.module.css';
import { RadioGroup, FormControlLabel, Radio, Switch } from '@mui/material';
import { WritingCoachFeedbackStrategy } from '../../../helpers/constants';
import { Tooltip } from '../../tooltip';
import InfoCircleIcon from '../../../icons/info_circle.svg';
import PremiumLockIcon from '../../../icons/lock.svg';
import ALPremiumBannerModal from '../ALPremiumBannerModal';

interface Props {
  readingSettings: ReadingSettings;
  setReadingSettings?: Function;
  showLess?: boolean;
  isQuiz: boolean;
  isPerformanceTask: boolean;
  isSummativeAssessment: boolean;
  isTextToSpeechDisabled: boolean;
  isTranslationDisabled: boolean;
  isWritingCoachAccessible: boolean;
  isWritingCoachLocked: boolean;
  isNonTextAssignment: boolean;
}

const settingsInOrder = [
  'viewPeerResponses',
  'namedPeerResponses',
  'allowLikingPeerResponses',
  'allowStudentSharedNotes',
  'questionGates',
  'minimizeQuestions',
  'allowStudentsMCAnswer',
  'disAllowAnswerEditing',
  'notifications',
  'allowTranslation',
  'allowDictionary',
  'allowTextToSpeech',
];

const settingsToShowWhenShowingLess = ['viewPeerResponses', 'namedPeerResponses', 'allowLikingPeerResponses'];

const settingsToShowWhenShowingLessForQuiz = ['allowStudentsMCAnswer', 'allowTranslation', 'allowDictionary', 'allowTextToSpeech'];
const settingsToShowWhenShowingLessForAssessment = ['allowTranslation', 'allowDictionary', 'allowTextToSpeech'];

const settingsDisabledForQuiz: (keyof (ReadingSettings))[] = [
  'viewPeerResponses',
  'namedPeerResponses',
  'allowLikingPeerResponses',
  'allowStudentSharedNotes',
  'questionGates',
  'minimizeQuestions'
];

const settingsLabels = {
  'viewPeerResponses': 'See classmates’ responses after answering questions',
  'namedPeerResponses': 'See classmates’ names with their responses',
  'allowLikingPeerResponses': 'Allow students to like classmates’ responses',
  'allowStudentSharedNotes': 'See classmates’ shared notes',
  'questionGates': 'Questions require a response to continue reading',
  'minimizeQuestions': 'Minimize questions (ex. for a first read activity)',
  'allowStudentsMCAnswer': 'See correct multiple choice answer after responding',
  'disAllowAnswerEditing': 'Responses are locked until you allow revisions',
  'notifications': 'Enable notifications',
  'allowTranslation': 'Enable language translation',
  'allowDictionary': 'Enable word lookups',
  'allowTextToSpeech': 'Enable text to speech',
};

const indentedSettings = ['namedPeerResponses', 'allowLikingPeerResponses', 'minimizeQuestions'];

const viewPeerResponsesNestedSettings = ['namedPeerResponses', 'allowLikingPeerResponses'];

const questionGatesNestedSettings = ['minimizeQuestions'];

const viewPeerResponsesByLabels = {
  'all': 'All',
  'onlyproficientandadvanced': 'Only proficient and advanced',
  'onlyadvanced': 'Only advanced',
}

const writingCoachToolTipText = "The Writing Assistant provides immediate, personalized guidance and feedback on student writing.";

export default function AssignmentSettingsSelector({
  readingSettings,
  setReadingSettings,
  showLess = false,
  isQuiz,
  isPerformanceTask,
  isSummativeAssessment,
  isTextToSpeechDisabled,
  isTranslationDisabled,
  isWritingCoachAccessible,
  isWritingCoachLocked,
  isNonTextAssignment,
}: Props) {
  const settingsToShow = showLess
    ? isQuiz
      ? settingsToShowWhenShowingLessForQuiz
      : isSummativeAssessment
        ? settingsToShowWhenShowingLessForAssessment
        : settingsToShowWhenShowingLess
    : settingsInOrder;
  const isPreviewOnly = !setReadingSettings;
  const disabledSettings = getDisabledSettings();
  const [viewPeerResponses, setViewPeerResponses] = useState('all');
  const [premiumBannerModal, setPremiumBannerModal] = useState(false);
  const disableWritingCoachSetting = isQuiz || isPerformanceTask || isWritingCoachLocked;
  const [enableWritingCoach, setEnableWritingCoach] = useState(
    disableWritingCoachSetting ? false : readingSettings.enableWritingCoach
  );

  useEffect(() => {
    setViewPeerResponses(readingSettings.viewPeerResponses === 'none' ? 'all' : readingSettings.viewPeerResponses);
  }, [readingSettings.viewPeerResponses]);

  useEffect(() => {
    if (!disableWritingCoachSetting) {
      setEnableWritingCoach(readingSettings.enableWritingCoach);
    }
  }, [readingSettings.enableWritingCoach]);

  function getReadingSettingsToSet(key: keyof (ReadingSettings)) {
    switch (key) {
      case 'viewPeerResponses':
        return {
          ...readingSettings,
          viewPeerResponses: readingSettings.viewPeerResponses === 'none' ? viewPeerResponses : 'none',
        };
      case 'questionGates':
        return {
          ...readingSettings,
          questionGates: !readingSettings.questionGates,
        }
      default:
        return {
          ...readingSettings,
          [key]: !readingSettings[key],
        };
    }
  }

  useEffect(() => {
    if (!showLess) {
      const elm3 = (document.querySelector('#viewPeerResponses')
        ?.nextSibling as Element)?.querySelector('input[type="checkbox"]');
      (elm3 as HTMLElement)?.focus();
    }
  }, [showLess]);

  function getDisabledSettings() {
    let disabledSettings: (keyof (ReadingSettings))[] = [];

    if (isQuiz || isSummativeAssessment || isPerformanceTask) {
      disabledSettings = disabledSettings.concat(settingsDisabledForQuiz);
    }
    if (isSummativeAssessment) {
      disabledSettings.push('allowStudentsMCAnswer', 'disAllowAnswerEditing');
    }
    if (readingSettings.viewPeerResponses === 'none') {
      disabledSettings.push('namedPeerResponses', 'allowLikingPeerResponses');
    }
    if (readingSettings.questionGates) {
      disabledSettings.push('minimizeQuestions');
    }
    if (isTextToSpeechDisabled) {
      disabledSettings.push('allowTextToSpeech');
    }
    if (isTranslationDisabled) {
      disabledSettings.push('allowTranslation');
    }

    return disabledSettings;
  }

  function handleViewPeerResponsesChoiceChange(ev) {
    if (setReadingSettings) {
      setReadingSettings({
        ...readingSettings,
        viewPeerResponses: ev.target.value,
      });
    }
  }

  function setAllowWritingCoach(enableWritingCoach) {
    if (setReadingSettings) {
      setReadingSettings({
        ...readingSettings,
        enableWritingCoach,
        writingCoachFeedbackStrategy: WritingCoachFeedbackStrategy.AUTOMATICALLY_SEND_FEEDBACK
      });
    }
  }

  function handleFeedbackTypeChange(val) {
    if (setReadingSettings) {
      setReadingSettings({ ...readingSettings, writingCoachFeedbackStrategy: val });
    }
  }

  return (
    <>
      {isWritingCoachAccessible && !isNonTextAssignment && (
        <div className='mb-xl'>
          <h3 className={`${styles.writingCoachHeading} my-md semiBold flex`}>
            Writing Assistant
            {isWritingCoachLocked
              ? <button
                className={styles.transparentButton}
                onClick={() => setPremiumBannerModal(true)}><PremiumLockIcon /></button>
              : (
                <Tooltip title={writingCoachToolTipText}>
                   <span 
                    tabIndex={0} 
                    role="tooltip"
                    className={`ml-lg ${styles.infoIcon}`}
                    aria-label={writingCoachToolTipText}>
                    <InfoCircleIcon />
                  </span>
                </Tooltip>
              )}
          </h3>
          <div>
            <span>Off</span>
            <FormControlLabel
              className='mx-md'
              control={
                <>
                <Switch
                  color="primary"
                  disabled={disableWritingCoachSetting}
                  onChange={(ev) => setAllowWritingCoach(ev.target.checked)}
                  checked={enableWritingCoach} />
                <div className="visuallyHidden screen-reader-only">toggle writing assistant</div>
                </>
              } label="" />
            <span>On</span>
          </div>
          <RadioGroup
            row
            onChange={(ev) => handleFeedbackTypeChange(ev.target.value)}>
            <FormControlLabel
              disabled={!readingSettings.enableWritingCoach || disableWritingCoachSetting }
              value={WritingCoachFeedbackStrategy.AUTOMATICALLY_SEND_FEEDBACK}
              checked={readingSettings.writingCoachFeedbackStrategy === 
                WritingCoachFeedbackStrategy.AUTOMATICALLY_SEND_FEEDBACK}
              control={<Radio color="primary"  />}
              label="Automatically send feedback" />
            <FormControlLabel
              disabled={!readingSettings.enableWritingCoach || disableWritingCoachSetting}
              checked={readingSettings.writingCoachFeedbackStrategy === 
                WritingCoachFeedbackStrategy.REVIEW_FEEDBACK_FIRST}
              value={WritingCoachFeedbackStrategy.REVIEW_FEEDBACK_FIRST}
              control={<Radio color="primary"  />}
              label="Review feedback first"
            />
          </RadioGroup>
        </div>
      )}
      <ul>
        {settingsToShow.map((key: keyof (ReadingSettings)) => {
          return (
            !indentedSettings.includes(key) && <li key={key} id={key}>
              <Checkbox
                label={settingsLabels[key]}
                checked={
                  !disabledSettings.includes(key) &&
                  (key === 'viewPeerResponses' ? readingSettings.viewPeerResponses !== 'none' : !!readingSettings[key])
                }
                setChecked={() => {
                  if (setReadingSettings) {
                    setReadingSettings(getReadingSettingsToSet(key))
                  }
                }}
                inputProps={{
                  tabIndex: isPreviewOnly ? -1 : 0,
                  'disabled': isPreviewOnly
                }}
                className={classNames(styles.checkbox, {
                  // 'ml-xl': indentedSettings.includes(key),
                  [styles.previewOnly]: isPreviewOnly,
                })}
                disabled={disabledSettings.includes(key)}
                name={key}
              />
              {key === 'viewPeerResponses' && (
                <ul>
                  <li>
                    <RadioGroup
                      row
                      className={classNames(styles.peerResponsesRadioGroup, {
                        [styles.previewOnly]: isPreviewOnly,
                      })}
                      aria-label="View peer responses"
                      value={viewPeerResponses}
                      onChange={handleViewPeerResponsesChoiceChange}>
                      {Object.keys(viewPeerResponsesByLabels).map(value => (
                        <ViewPeerResponsesChoice
                          value={value}
                          label={viewPeerResponsesByLabels[value]}
                          key={value}
                          disabled={disabledSettings.includes(key) || readingSettings.viewPeerResponses === 'none'}
                          previewOnly={isPreviewOnly}
                        />
                      ))}
                    </RadioGroup>
                  </li>
                  {viewPeerResponsesNestedSettings.map((item) => {
                    return (<li id={item}>
                      <Checkbox
                        label={settingsLabels[item]}
                        checked={readingSettings[item]}
                        setChecked={() => {
                          if (setReadingSettings) {
                            setReadingSettings(getReadingSettingsToSet(item as keyof ReadingSettings));
                          }
                        }}
                        inputProps={{
                          tabIndex: isPreviewOnly ? -1 : 0,
                          name: item,
                        }}
                        className={classNames(styles.checkbox, {
                          'ml-xl': viewPeerResponsesNestedSettings.includes(item),
                          [styles.previewOnly]: isPreviewOnly,
                        })}
                        disabled={disabledSettings.includes(key) || readingSettings.viewPeerResponses === 'none'}
                      />
                    </li>);
                  }
                  )}

                </ul>
              )}
              {key === 'questionGates' && <ul>
                {
                  questionGatesNestedSettings.map((item: keyof (ReadingSettings)) => {
                    return (<li id={key}>
                      <Checkbox
                        label={settingsLabels[item]}
                        checked={!disabledSettings.includes(item) && readingSettings.minimizeQuestions}
                        setChecked={() => {
                          if (setReadingSettings) {
                            setReadingSettings({
                              ...readingSettings,
                              minimizeQuestions: !readingSettings.minimizeQuestions
                            });
                          }
                        }}
                        inputProps={{
                          tabIndex: isPreviewOnly ? -1 : 0,
                        }}
                        className={classNames(styles.checkbox, {
                          'ml-xl': indentedSettings.includes(item),
                          [styles.previewOnly]: isPreviewOnly,
                        })}
                        disabled={disabledSettings.includes(item)}
                      />
                    </li>);
                  })
                }
              </ul>}
              {key === 'disAllowAnswerEditing' && !isSummativeAssessment && (
                <div className={classNames(styles.subHeading, 'info')}>
                  <div>When off, students can revise their response until graded.</div>
                  {!isQuiz &&
                    <div>
                      To prevent cheating, you may also want to turn off 'See classmates’ responses after answering questions' above.
                    </div>
                  }
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {premiumBannerModal && (
        <ALPremiumBannerModal 
          title="Writing Assistant"
          headerText="The Writing Assistant provides immediate, personalized feedback on student writing and is only available\
    on our prime plan. You can upgrade now to use this feature."
          hideModal={()=>setPremiumBannerModal(false)} />
      )}
    </>
  )
}

const ViewPeerResponsesChoice = ({ value, label = '', disabled, previewOnly }) => (
  <FormControlLabel
    className={classNames('mr-xl', {
      [styles.previewOnly]: previewOnly,
    })}
    value={value}
    control={
      <Radio
        inputProps={{ tabIndex: previewOnly ? -1 : 0 }}
        className={`radio${value}PeerResponses`}
        color="primary" 
      />
    }
    label={label}
    labelPlacement="end"
    disabled={disabled}
  />
);
