import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, CategoryScale, LinearScale } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Fonts } from '../../helpers/constants';

Chart.register(ArcElement, CategoryScale, LinearScale, ChartDataLabels, Tooltip);

const demoData = {
  labels: [
    'Red',
    'Blue',
    'Yellow'
  ],
  data: [300, 50, 100],
  colors: [
    '#FF6384',
    '#36A2EB',
    '#FFCE56'
  ],
};

const PieChart = ({ config }: any) => {
  const {
    labels,
    data,
    colors,
    tooltipOptionOverrides,
    additionalOptions,
    pluginLabel,
  } = config;

  const chartData = {
    labels: labels || demoData.labels,
    datasets: [{
      data: data || demoData.data,
      borderColor: 'transparent',
      hoverBorderColor: 'transparent',
      backgroundColor: colors || demoData.colors,
      hoverBackgroundColor: colors || demoData.colors,
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        showZero: false,
        color: '#fff',
        font: { family: Fonts.MEDIUM, size: 15 },
        ...pluginLabel,
      },
      tooltip: {
        backgroundColor: '#fff',
        borderColor: '#0077d1',
        borderWidth: 2,
        titleColor: '#0077d1',
        bodyColor: '#0077d1',
        titleFont: { family: Fonts.MEDIUM },
        bodyFont: { family: Fonts.MEDIUM, size: 15 },
        displayColors: false,
        titleMarginBottom: 10,
        ...tooltipOptionOverrides,
      },
    },
    ...additionalOptions
  };

  return <Pie data={chartData} options={options} height={350} />;
};

export default PieChart;
