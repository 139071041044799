import React from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '../tooltip';

export interface IPropsBtn {
  children?: any;
  className?: string;
  id?: string;
  onClick?: any;
  title?: string | React.ReactNode;
  tooltipPlacement?: 'left' | 'right' | 'bottom' | 'top';
  tooltipColor?: 'black' | 'white';
  shouldCloseTooltipOnClick?: boolean;
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'text'
    | 'textSecondary'
    | 'pill'
    | 'icon'
    | 'iconText'
    | 'iconPrimary'
    | 'iconSecondary'
    | 'tab';
  disabled?: boolean;
  notStyled?: boolean;
  selected?: boolean;
  type?: 'button' | 'reset' | 'submit';
  [rest: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  /* Styles applied to the root element. */
  root: {
    boxSizing: 'border-box',
    outline: 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: theme?.transitions?.create(
      [
        'background-color',
        'box-shadow',
        'border',
        'color',
        'fill',
        'font-size',
      ],
      {
        duration: theme?.transitions?.duration?.short,
      }
    ),

    '& svg': {
      transition: theme?.transitions?.create(
        [
          'background-color',
          'box-shadow',
          'border',
          'color',
          'fill',
          'font-size',
        ],
        {
          duration: theme?.transitions?.duration?.short,
        }
      ),
    },

    '&:hover, &:focus': {
      textDecoration: 'none',
      // backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&$disabled': {
      opacity: 0.5,
    },
  },
  /* Styles applied to the root element if `variant='primary'`. */
  primary: {
    ...theme?.typography?.button,
    padding: '10px 22px',
    border: '1px solid #0077d1',
    backgroundColor: '#0077d1',
    color: '#fff',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 600,
    lineHeight: 'initial',

    '&:hover, &:focus': {
      backgroundColor: '#fff',
      color: '#0077d1',
      border: '1px solid #0077d1',
    },
  },
  /* Styles applied to the root element if `variant='secondary'`. */
  secondary: {
    ...theme?.typography?.button,
    padding: '10px 22px',
    border: '1px solid #0077d1',
    backgroundColor: '#fff',
    color: '#0077d1',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 600,
    lineHeight: 'initial',

    '&:hover, &:focus': {
      backgroundColor: '#0077d1',
      color: '#fff',
      border: '1px solid #0077d1',
    },
  },
  /* Styles applied to the root element if `variant='text'`. */
  text: {
    background: 'none',
    border: 0,
    color: '#0077d1',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: '"WorkSans-Regular", helvetica, arial, sans-serif',
    padding: '3px',
    textTransform: 'none',

    '&:hover, &:focus': {
      color: '#0077d1',
      textDecoration: 'underline',
    },
  },
  /* Styles applied to the root element if `variant='textSecondary'`. */
  textSecondary: {
    background: 'none',
    border: 0,
    color: '#253238',
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 'normal',
    fontFamily:
      '"WorkSans-Medium", "WorkSans-Regular", helvetica, arial, sans-serif',
    padding: '9px 12px 9px 11px',
    textTransform: 'none',
    textAlign: 'left',

    '&:hover, &:focus': {
      color: '#0171A6',
      textDecoration: 'none',
    },
  },
  /* Styles applied to the root element if `variant='pill'`. */
  pill: {
    ...theme?.typography?.button,
    border: 0,
    borderRadius: '100px',
    backgroundColor: '#fff',
    color: '#0077d1',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    padding: '2px 20px',
    textTransform: 'none',

    '& svg': {
      fill: '#0077d1',
      stroke: '#0077d1',
      marginLeft: 8,
    },

    '&:hover, &:focus': {
      backgroundColor: '#0077d1',
      color: '#fff',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },
    },
  },
  /* Styles applied to the root element if `variant='icon'`. */
  icon: {
    background: 'none',
    border: 0,
    padding: 0,
    lineHeight: 'initial',

    '& svg': {
      fill: '#fff',
      stroke: '#fff',
    },

    '&:hover, &:focus': {
      color: '#0077d1',

      '& svg': {
        fill: '#0077d1',
        stroke: '#0077d1',
      },
    },
  },
  /* Styles applied to the root element if `variant='iconText'`. */
  iconText: {
    background: 'none',
    border: 0,
    padding: 0,
    lineHeight: 'initial',
    color: '#253238',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      marginRight: '10px',
      fill: '#253238',
      stroke: '#253238',
    },

    '&:hover, &:focus': {
      color: '#0077d1',

      '& svg': {
        fill: '#0077d1',
        stroke: '#0077d1',
      },
    },
  },
  /* Styles applied to the root element if `variant='iconPrimary'`. */
  iconPrimary: {
    background: '#fff',
    border: 0,
    lineHeight: 'initial',
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      fill: '#0077d1',
      stroke: '#0077d1',
    },

    '& span:before': {
      color: '#0077d1',
    },

    '&:hover, &:focus': {
      backgroundColor: '#0077d1',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },

      '& span:before': {
        color: '#fff',
      },

      '& [class*="icon-"]::before': {
        color: '#fff',
      },
    },

    '&.disabled': {
      backgroundColor: '#CDCDCD',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },

      '& span:before': {
        color: '#fff',
      },

      '&:hover, &:focus': {
        backgroundColor: '#CDCDCD !important',

        '& svg': {
          fill: '#fff !important',
          stroke: '#fff !important',
        },

        '& span:before': {
          color: '#fff !important',
        },
      },
    },
    '&.disabledActionButton': {
      backgroundColor: '#929394 !important',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },

      '& span:before': {
        color: '#fff',
      },

      '&:hover, &:focus': {
        backgroundColor: '#929394 !important',

        '& svg': {
          fill: '#fff !important',
          stroke: '#fff !important',
        },

        '& span:before': {
          color: '#fff !important',
        },
      },
    },
  },
  /* Styles applied to the root element if `variant='iconSecondary'`. */
  iconSecondary: {
    background: '#f7f7f7',
    border: 0,
    lineHeight: 'initial',
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.disabled': {
      backgroundColor: '#CDCDCD',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },

      '&:hover, &:focus': {
        backgroundColor: '#CDCDCD',

        '& svg': {
          fill: '#fff',
          stroke: '#fff',
        },
      },
    },

    '& svg': {
      fill: '#0077d1',
      stroke: '#0077d1',
    },

    '&:focus-visible': {
      backgroundColor: '#0077d1',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },

      '& [class*="icon-"]::before': {
        color: '#fff',
      },
    },

    '&:focus:not(:focus-visible)': {
      backgroundColor: '#f7f7f7',

      '& svg': {
        fill: '#0077d1',
        stroke: '#0077d1',
      },

      '& [class*="icon-"]::before': {
        color: '#0077d1',
      },
    },

    '&:hover, &$selected': {
      backgroundColor: '#0077d1',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },

      '& [class*="icon-"]::before': {
        color: '#fff',
      },
    },
  },
  tab: {
    border: 0,
    lineHeight: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    padding: '12px 15px',
    borderBottom: '2px solid transparent',

    '&.disabled': {
      backgroundColor: '#CDCDCD',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },

      '&:hover, &:focus': {
        backgroundColor: '#CDCDCD',

        '& svg': {
          fill: '#fff',
          stroke: '#fff',
        },
      },
    },

    '& svg': {
      // fill: '#0077d1',
      stroke: '#0077d1',
    },

    '&:hover, &:focus, &$selected': {
      borderColor: '#0077d1',
      color: '#0077d1',

      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  /* placeholder objects for styles above */
  default: {},
  selected: {
    '&:focus': {
      outline: '2px solid var(--al-blue)',
      outlineOffset: '2px',
    },
  },
  disabled: {},
}));

function Button({
  className = '',
  title = '',
  children,
  onClick = (e) => {},
  tooltipPlacement = 'bottom',
  tooltipColor = 'black',
  shouldCloseTooltipOnClick = true,
  variant = 'default',
  disabled,
  fullWidth,
  notStyled,
  selected,
  type = 'button',
  customProps,
  ...rest
}: IPropsBtn, ref) {
  const [isTooltipOpen, setOpen] = React.useState(true);
  const styles = useStyles({});

  const handleClick = (e) => {
    onClick(e);

    if (shouldCloseTooltipOnClick) {
      setOpen(false);
    }
  };

  const classes = classnames(
    styles[variant],
    {
      [styles.root]: variant !== 'default' || notStyled,
      disabled: disabled,
      [styles.fullWidth]: fullWidth,
      [styles.selected]: selected,
    },
    className
  );

  if (title && title !== '') {
    return (
      <Tooltip
        title={title}
        tooltipPlacement={tooltipPlacement}
        tooltipColor={tooltipColor}
        isTooltipOpen={isTooltipOpen}>
        <button
          className={classes}
          onClick={handleClick}
          onFocus={() => setOpen(true)}
          onMouseOver={() => setOpen(true)}
          type={type}
          disabled={disabled}
          {...rest}
          ref={ref}>
          {children}
        </button>
      </Tooltip>
    )
  }
  else {
    return (
      <button
        className={classes}
        onClick={handleClick}
        onMouseOver={() => setOpen(true)}
        type={type}
        disabled={disabled}
        {...rest}
        ref={ref}>
        {children}
      </button>
    )
  }
}

export default React.forwardRef(Button) as React.FunctionComponent<IPropsBtn>;
