import React from 'react';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import { Fonts } from '../../helpers/constants';
import Button, { IPropsBtn } from '../button/Button';

export interface IPropsPopover extends IPropsBtn {
  content?: string | JSX.Element;
  className?: string;
  id?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  popoverClassName?: string;
  isClosed?: boolean;
  onClose?: Function;
  [key: string]: any;
};

const usePopoverStyles: any = makeStyles({
  paper: {
    maxWidth: 400,
    minWidth: 100,
    maxHeight: 400,
    fontSize: 14,
    fontFamily: Fonts.MEDIUM,
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '9px 14px',

    '&::before': {
      content: '""',
      position: 'fixed',
      border: '10px solid transparent',
      width: 0,
      height: 0,
      filter: 'drop-shadow(0 -2px 1px rgba(50,50,50,0.1))',
    },
  },
  root: {
    '&[data-placement="bottom"] .MuiPaper-root': {
      marginTop: 10,
      flexDirection: 'column',
      '&::before': {
        content: '',
        borderBottomColor: 'white',
        marginTop: -28,
        filter: 'drop-shadow(0 -2px 1px rgba(50,50,50,0.1))',
      }
    },
    '&[data-placement="top"] .MuiPaper-root': {
      marginTop: -10,
      flexDirection: 'column-reverse',
      '&::before': {
        content: '',
        borderTopColor: 'white',
        marginBottom: -28,
        filter: 'drop-shadow(0 2px 1px rgba(50,50,50,0.1))',
      }
    },
    '&[data-placement="left"] .MuiPaper-root': {
      marginRight: 10,
      flexDirection: 'row-reverse',
      '&::before': {
        borderLeftColor: 'white',
        marginLeft: 33,
        filter: 'drop-shadow(2px 0 1px rgba(50,50,50,0.1))',
      }
    },
    '&[data-placement*="right"] .MuiPaper-root': {
      marginLeft: 10,
      flexDirection: 'row',
      '&::before': {
        borderRightColor: 'white',
        marginLeft: -33,
        filter: 'drop-shadow(-2px 0 1px rgba(50,50,50,0.1))',
      }
    },
  }
});

const Positioning = {
  bottom: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  top: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  },
  left: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'right',
    },
  },
  right: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'left',
    },
  },
}

export function PopoverButton({
  content = '',
  className = '',
  children,
  popoverId = '',
  placement = 'bottom',
  onClick,
  popoverClassName = '',
  isClosed = false,
  onClose,
  ...rest
}: IPropsPopover) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const styles: any = usePopoverStyles();

  function handleClose(ev) {
    ev.stopPropagation();
    setAnchorEl(null);

    if (onClose) {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Button
        className={className}
        onClick={(ev) => {
          if (onClick) {
            onClick(ev);
          }
          setAnchorEl(ev.currentTarget)
        }}
        data-toggle="popover"
        {...rest}>
        {content}
      </Button>
      <Popover
        id={popoverId}
        classes={styles}
        open={!!anchorEl && !isClosed}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={Positioning[placement].anchorOrigin as PopoverOrigin}
        transformOrigin={Positioning[placement].transformOrigin as PopoverOrigin}
        data-placement={placement}
        className={popoverClassName}
        transitionDuration={0}
        role="region"
      >
        <div className="content">
          {children}
        </div>
      </Popover>
    </React.Fragment>
  );
};
