import React from 'react';
import { Tooltip } from 'sharedfrontend';
import styles from './styles.module.css';
import classnames from 'classnames';

interface ProgressBarProps {
  progressTitle: string;
  progressDisplay: string;
  progressPercentage: number;
  isIntervention: boolean;
}

interface TooltipProgressBarProps extends ProgressBarProps {
  showTooltip: boolean;
}

const ProgressBar = (props: ProgressBarProps): JSX.Element => {
  const { progressTitle, progressDisplay, progressPercentage, isIntervention } = props;

  const topClassNames = classnames({
    [styles.top]: true,
    [styles.intervention]: isIntervention,
  });

  return (
    <div className={styles.container}>
      <div className={topClassNames}>
        <div>{progressTitle}</div>
        <div className={styles.number}>{progressDisplay}</div>
      </div>
      <div className={styles.bar}>
        <div className={styles.progress} style={{ width: progressPercentage + '%' }}></div>
      </div>
    </div>
  );
}

// https://material-ui.com/guides/composition/#caveat-with-refs
// https://stackoverflow.com/q/57527896
const TooltipProgressBar = (props: TooltipProgressBarProps): JSX.Element => {
  const { showTooltip, ...rest } = props;

  if (showTooltip) {
    return (
      <Tooltip
        title="You may have mastery because of encountering this skill in previous assignments."
        tooltipColor="blue"
      >
        <div>
          <ProgressBar {...rest} />
        </div>
      </Tooltip>
    );
  }
  else {
    return (
      <ProgressBar {...rest} />
    );
  }
};

export default TooltipProgressBar;
