import { connect } from 'react-redux';
import { getData } from '../../../actions/api';
import BenchmarkTable from './benchmarkTable.component';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    data: state.classData.benchmarkData,
    students: state.userClasses.students.filter((s: any) => s.classId === parseInt(ownProps.classId)),
    benchmarkSource: state.reports.benchmarkData && state.reports.benchmarkData.benchmarkSource,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: ({ classId, startDate, endDate }: {
      classId: number;
      startDate: string;
      endDate: string;
    }) => {
      dispatch(getData({
        type: 'ADMIN_REPORT_QUANTILE_BENCHMARKS_DATA',
        params: {
          classId,
          startDate,
          endDate,
        },
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenchmarkTable);
