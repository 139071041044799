import MasteryProgressBar from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    mastery: state.problemSetData.mastery,
    role: state.userData.role,
    gradeFeedback: state.gradeFeedbackData,
    isIntervention: state.problemSetData.isIntervention,
  }
}

export default connect(
  mapStateToProps,
)(MasteryProgressBar);
