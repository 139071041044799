import { IConfig } from './types';

const config: IConfig = {
  env: 'live',
  productName: 'Actively Learn',
  productUrl: 'https://read-live.activelylearn.com',
  backendUrl: 'https://api-live.activelylearn.com',
  readerAppUrl: 'https://reader-live.activelylearn.com',
  cookieDomain: '.activelylearn.com',
  csrfTokenKey: 'CSRF-TOKEN-LIVE',
  stripeKey: 'pk_test_hGcCTxnNucfD8epy7K4uXcNJ',
};

export default config;
