import React from 'react';
import { makeStyles } from '@mui/styles';
import { TabContext as MuiTabContext } from '@mui/lab';

interface TabContextProps {
  classes?: any;
  value: string;
}

const useStyles = makeStyles({
  root: {
    color: '#a1a1a1',
  }
})

const TabContext: typeof MuiTabContext = ((props: TabContextProps) => {
  const styles = useStyles();

  return <MuiTabContext classes={styles} {...props} />
});

export default TabContext
