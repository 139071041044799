import React from 'react';
import ProgressBar from '../../../common/progressBar/component';

const CompletionProgressBar = (props: any): JSX.Element => {
  return props.validRoles.includes(props.role)
    ? (
      <ProgressBar
        progressTitle="Problems Completed"
        progressDisplay={props.numProblemsCompleted}
        progressPercentage={Math.min(props.numProblemsCompleted / props.denominator, 1) * 100}
        showTooltip={props.showTooltip}
        isIntervention={props.isIntervention}
      />
    )
    : <React.Fragment></React.Fragment>
};

export default CompletionProgressBar;
