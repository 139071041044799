import ExitInterventionButton from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    isIntervention: state.problemSetData.isIntervention,
    createdFromProblemSetId: state.problemSetData.createdFromProblemSetId,
    classId: state.problemSetData.classId,
  }
}

export default connect(
  mapStateToProps,
)(ExitInterventionButton);
