import React from 'react';
import { makeStyles } from '@mui/styles';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    display: 'none',
  },
})

const Tabs = ((props: TabsProps) => {
  const styles = useStyles();

  return <MuiTabs classes={styles} {...props} />
});

export default Tabs;
