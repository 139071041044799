import React from 'react';
import { DateTimePicker } from '../../../../dateTimePicker';
import { LearnEntityInfo } from '../../..//types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  assignLayerStartDueDate: {
    fontSize: "16px",
  },
  dateTimePicker: {
    width: '37%',
    maxWidth: 'none',
    "& .Mui-error": {
      color: "var(--grade-colors-incomplete)",
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '@media (max-width: 1024px)': {
      width: '48%',
    },
    '@media (max-width: 767px)': {
      width: '98%',
  }
  },
});

interface Props {
  assignmentDate: {
    startDate: string;
    dueDate: string | null;
  };
  setAssignmentDate: Function;
  learnEntityInfo: LearnEntityInfo;
  datePickerErrorText: string;
  setDatePickerErrorText: Function;
  utcNow: string;
}

export default function StartAndDueDateSelector({
  assignmentDate,
  setAssignmentDate,
  learnEntityInfo,
  datePickerErrorText,
  setDatePickerErrorText,
  utcNow,
}: Props) {
  let startDateErrorProps = !assignmentDate.startDate ? getErrorProps() : {};
  const dueDateErrorProps = assignmentDate.startDate ? getErrorProps() : {};
  const styles = useStyles();

  function getErrorProps () {
    return datePickerErrorText
      ? {
        error: !!datePickerErrorText,
        helperText: datePickerErrorText,
      }
      : {};
  }

  function handleStartAndDueDateChange(key, value): void {
    const unlockDateTime: any = { ...assignmentDate, [key]: value ? value.toISOString() : null };
    const { startDate, dueDate } = unlockDateTime;
    let errorText = '';

    setAssignmentDate(unlockDateTime);

    if (!startDate) {
      errorText = 'Please select a start date!';
    }

    if (dueDate) {
      if (new Date(startDate) >= new Date(dueDate)) {
        errorText = 'The due date must be after the start date!';
      }
      else if (new Date(dueDate) < new Date(utcNow)) {
        errorText = 'The due date should not be in the past!';
      }
    }

    setDatePickerErrorText(errorText);
  }
    
  return (
    <div>
      <h3
        className={`mt-xl mb-md semiBold upperCase assignLayerStartDueDate ${styles.assignLayerStartDueDate}`}
      >
        Start and due dates
      </h3>
      <div className="mt-md mb-xl info">
        Students will not be able to access assignments before the start date and after the due date.
        </div>
      <div className="mt-lg">
        <DateTimePicker
          label="Start date"
          withKeyBoard
          id="assessmentStartDatePicker"
          value={assignmentDate.startDate}
          onChange={(value) => handleStartAndDueDateChange('startDate', value)}
          className={`alignStart mr-xl mb-lg ${styles.dateTimePicker}`}
          {...startDateErrorProps}
          KeyboardButtonProps={{
            'aria-label': 'Select Start Date',
          }} 
        />
        <DateTimePicker
          label={`Due date ${learnEntityInfo.isSummativeAssessment ? '' : '(optional)'}`}
          id="assessmentDueDatePicker"
          withKeyBoard
          value={assignmentDate.dueDate}
          onChange={(value) => handleStartAndDueDateChange('dueDate', value)}
          className={`alignStart mb-lg ${styles.dateTimePicker}`}
          {...dueDateErrorProps}
          KeyboardButtonProps={{
            'aria-label': 'Select Due Date',
          }} 
        />
      </div>
    </div>
  )
}