import { connect } from 'react-redux';
import { postData } from '../../../../actions/api';
import { closeModal } from '../../../../actions/eventEmitter';
import { updateHash } from '../../../../utils/iframe';
import UpdateProblemProblemSetModal from './updateProblemProblemSet.component';

const mapStateToProps = (state: any) => {
  return {
    problemId: state.readerNavigation.currentProblemId,
    problemSetId: state.problemSetData.problemSetId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveProblemProblemSet: async ({ problemId, oldProblemSetId, newProblemSetId }: {
      problemId: number;
      oldProblemSetId: number;
      newProblemSetId: number;
    }) => {
      dispatch(postData({
        type: 'UPDATE_PROBLEM_PROBLEMSET',
        params: { problemId, oldProblemSetId, newProblemSetId },
        mutators: {
          onResolve: (resolveAction: any) => {
            closeModal();
            updateHash({ path: `/mathreader?problemSetId=${newProblemSetId}&problemId=${problemId}`, redirect: true });
            return {
              ...resolveAction,
              body: {
                newProblemSetId
              }
            }
          }
        }
      }));
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateProblemProblemSetModal);