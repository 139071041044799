import Reveal from './component';
import { connect } from 'react-redux';
import { setAssignedProblemSets, setRevealBookTitleSlug } from '../../actions/modal';

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAssignedProblemSets: (assignedProblemSetIds: number[]) => {
      dispatch(setAssignedProblemSets(assignedProblemSetIds));
    },
    setRevealBookTitleSlug: (bookTitleSlug: string) => {
      dispatch(setRevealBookTitleSlug(bookTitleSlug));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Reveal);
