import React from 'react';
import Button from '../../../common/button/component';
import { Left, Right } from '../../common/barParts/component';
import ExitButton from '../../../common/exitButton/container';
import EditQuestionButton from '../../buttons/editQuestion/container';
import GoToProblemButton from '../../buttons/goToProblem/container';
import AssignmentTitle from '../../common/assignmentTitle/container';
import { FaCheck } from 'react-icons/fa';
import classnames from 'classnames';
import styles from './styles.module.css';
import readerStyles from '../../styles.module.css';
import UpdateStepProblemSetButton from '../../common/updateStepProblemSetButton/updateProblemSetButton.container';
import { ImplementationType, Role } from '../../../../utils/constants';
import ToggleButton from '../../buttons/toggleButton/container';

const Header = (props: any): JSX.Element => {
  const {
    currentStepId,
    stepIds,
    role,
    stepIdsWithCorrectResponse,
    isIntervention,
    stepData,
  } = props;
  const isDisabled = stepData?.numUndeletedSteps <= 3 && !stepData?.isDeleted;

  const headerClassNames = classnames({
    [readerStyles.header]: true,
    [styles.header]: true,
    [styles.intervention]: isIntervention,
  });

  return (
    <header className={headerClassNames} data-ignore="1">
      <Left>
        <GoToProblemButton type="back" validRoles={[Role.SUPERADMIN, Role.TEACHER]} />
        <ExitButton validRoles={[Role.STUDENT]} />
        <AssignmentTitle />
        <EditQuestionButton type={ImplementationType.STEP} validRoles={[Role.SUPERADMIN]} />
        <UpdateStepProblemSetButton validRoles={[Role.SUPERADMIN]} />
        <ToggleButton
          id={currentStepId}
          validRoles={[Role.SUPERADMIN]}
          type={ImplementationType.STEP}
          isDisabled={isDisabled}
          tooltipText={isDisabled ? 'You must leave at least 3 steps unhidden' : ''}
        />
      </Left>
      <Right>
        <div className={styles.stepPillsContainer}>
          {stepIds.map((stepId: number, i: number) => {
            const isActive = currentStepId === stepId;
            const isAnswered = role === Role.STUDENT && stepIdsWithCorrectResponse.includes(stepId);
            const isAccessible = role !== Role.STUDENT || isAnswered || stepIdsWithCorrectResponse.length === i;

            const buttonClassNames = classnames({
              [styles.selected]: isActive,
              [styles.answered]: isAnswered,
            });
            const lineClassNames = classnames(styles.line, {
              [styles.orange]: isAnswered,
            });

            return (
              <React.Fragment key={stepId}>
                <Button
                  theme="light"
                  additionalStyles={buttonClassNames}
                  selected={isActive}
                  disabled={!isAccessible}
                  onClick={props.goToStep.bind(null, stepId)}
                >
                  {isAnswered ? <FaCheck /> : i + 1}
                </Button>
                {i !== stepIds.length - 1 &&
                  <div className={lineClassNames}></div>
                }
              </React.Fragment>
            )
          })
          }
        </div>
      </Right>
    </header>
  );
};

export default Header;
