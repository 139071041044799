export enum Fonts {
  REGULAR = 'WorkSans-Regular, AvenirNextLTW02-Regular, AvenirNext-Regular',
  MEDIUM = 'WorkSans-Medium, AvenirNextLTW02-Medium, AvenirNext-Medium',
  SEMI_BOLD = 'WorkSans-SemiBold, Avenir Next LT W02 Demi, AvenirNext-Demi',
  BOLD = 'WorkSans-Bold, "Avenir Next LT W02 Bold", AvenirNext-Bold',
};

export const GradeLevelsAsRange = [
  { value: 1, label: 'Grade 2 - 3' },
  { value: 2, label: 'Grade 4 - 5' },
  { value: 3, label: 'Grade 6 - 8' },
  { value: 4, label: 'Grade 9 - 12' },
  { value: 5, label: 'Higher Education' },
];

export const GradeLevels = [
  { value: 14, label: 'Kindergarten' },
  { value: 1, label: '1st Grade' },
  { value: 2, label: '2nd Grade' },
  { value: 3, label: '3rd Grade' },
  { value: 4, label: '4th Grade' },
  { value: 5, label: '5th Grade' },
  { value: 6, label: '6th Grade' },
  { value: 7, label: '7th Grade' },
  { value: 8, label: '8th Grade' },
  { value: 9, label: '9th Grade' },
  { value: 10, label: '10th Grade' },
  { value: 11, label: '11th Grade' },
  { value: 12, label: '12th Grade' },
  { value: 13, label: 'Adult Learners' },
];

export const StateMathSubjectGradeLevel: any[] = [
  { value: '14', label: 'Kindergarten' },
  { value: '1', label: 'Grade 1' },
  { value: '2', label: 'Grade 2' },
  { value: '3', label: 'Grade 3' },
  { value: '4', label: 'Grade 4' },
  { value: '5', label: 'Grade 5' },
  { value: '6', label: 'Grade 6' },
  { value: '7', label: 'Grade 7' },
  { value: '8', label: 'Grade 8' },
  { value: '9', label: 'Grade 9' },
  { value: '10', label: 'Grade 10' },
  { value: '11', label: 'Grade 11' },
  { value: '12', label: 'Grade 12' },
  { value: '8A', label: 'Pre-Algebra' },
  { value: '9A', label: 'Algebra 1' },
  { value: '9B', label: 'Algebra I' },
  { value: '9C', label: 'Mathematics I' },
  { value: '9D', label: 'Integrated Mathematics I' },
  { value: '9E', label: 'Core Math I' },
  { value: '9F', label: 'Secondary Mathematics I' },
  { value: '10A', label: 'Geometry' },
  { value: '10B', label: 'Mathematics II' },
  { value: '10C', label: 'Integrated Mathematics II' },
  { value: '10D', label: 'Core Math II' },
  { value: '10E', label: 'Secondary Mathematics II' },
  { value: '11A', label: 'Algebra 2' },
  { value: '11B', label: 'Algebra II' },
  { value: '11C', label: 'Mathematics III' },
  { value: '11D', label: 'Integrated Mathematics III' },
  { value: '11E', label: 'Core Math III' },
  { value: '11F', label: 'Secondary Mathematics III' },
];

export enum UserAccountSource {
  NATIVE = 'native',
  GOOGLE = 'google',
  CANVAS = 'canvas',
  BRIGHTSPACE = 'brightspace',
  SCHOOLOGY = 'schoology',
  LTI = 'lti',
  EDMODO = 'edmodo',
  CLEVER = 'clever',
  OFFICE365 = 'office365',
  ONEROSTER = 'oneroster',
  ACHIEVE3K = 'achieve3k',
}

export const UserOrigin = [
  { value: 'search engine', label: 'Search engine (Google, Yahoo, etc.)' },
  { value: 'colleague', label: 'Colleague' },
  { value: 'conference', label: 'Conference or PD' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'twitter', label: 'X (formerly known as Twitter)' },
  { value: 'edWeb', label: 'EdWeb' },
  { value: 'new ed-tech classroom', label: 'New EdTech Classroom (Sam Kary)' },
  { value: 'newsletter', label: 'Newsletter' },
  { value: 'other', label: 'Other' },
];

export const UserSignupTitles = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Mx.', label: 'Mx.' },
  { value: 'Dr.', label: 'Dr.' },
];

export enum EducatorType {
  TEACHER = 'teacher',
  INSTRUCTIONAL_COACH = 'instructional coach',
  DEPARTMENT_HEAD = 'department head',
  SCHOOL_ADMINISTRATOR = 'school administrator',
  DISTRICT_ADMINISTRATOR = 'district administrator',
}

export const TeacherEducatorTypes = [
  { value: EducatorType.TEACHER, label: 'Teacher' },
  { value: EducatorType.INSTRUCTIONAL_COACH, label: 'Instructional coach' },
  { value: EducatorType.DEPARTMENT_HEAD, label: 'Department head' },
  { value: EducatorType.SCHOOL_ADMINISTRATOR, label: 'School administrator' },
  { value: EducatorType.DISTRICT_ADMINISTRATOR, label: 'District administrator' },
];

export const UserSubjects = [
  { value: 1, label: 'ELA' },
  { value: 2, label: 'Social Studies' },
  { value: 3, label: 'Science' },
  { value: 4, label: 'Math' },
  { value: 5, label: 'World Languages' },
  { value: 7, label: 'Special Education' },
  { value: 6, label: 'Other' },
];

export const contentTypes = [
  { value: 1, label: 'Literature' },
  { value: 2, label: 'Informational' },
  { value: 3, label: 'Science' },
  { value: 5, label: 'Other' },
];

export const helpArticleLink = {
  studentOverview: 'https://help.activelylearn.com/hc/en-us/articles/115000511674-Student-Overview-Video',
  teacherOverview: 'https://go.activelylearn.com/TeacherOverview',
  whatsNew: 'http://help.activelylearn.com/hc/en-us/articles/115000855953-What-s-New-',
  extraHelp: 'https://go.activelylearn.com/ExtraHelp',
  renewRental: 'https://go.activelylearn.com/RenewRental',
  contentCredits: 'https://go.activelylearn.com/content-credits',
  managerentals: 'https://go.activelylearn.com/ManageRentals',
  wtpLicenseInfo: 'https://go.activelylearn.com/wtp-license'
};

export const gradeNameSets = {
  '-1': {
    zero: 'Zero',
    incomplete: 'Incomplete',
    basic: 'Developing',
    proficient: 'Proficient',
    advanced: 'Advanced',
  },
  '3': {
    zero: 'F',
    incomplete: 'D',
    basic: 'C',
    proficient: 'B',
    advanced: 'A',
  },
  '4': {
    zero: '0',
    incomplete: '1',
    basic: '2',
    proficient: '3',
    advanced: '4',
  },
};

export const mcqGradeNameMaps = {
  correct: 'Correct',
  incorrect: 'Incorrect',
};

export const gradeNameToGradeValueMap = {
  advanced: 4,
  proficient: 3,
  basic: 2,
  incomplete: 1,
  zero: 0,
};

export const GRADE_NAME_KEYS_IN_ORDER = Object.keys(gradeNameToGradeValueMap);

export const CUSTOM_GRADE_LEVELS_OPTION = 5;
export const weThePeopleLevel2PaidLessonLeIds = [
  244470,
  244484,
  244556,
  244561,
  244744,
  248257,
  248384,
  248426,
  248435,
  248440,
  248441,
  249975,
  249976,
  249982,
  249983,
  253864,
  253929,
  253938,
  254487,
  254488,
  254489,
  257160,
  257167,
  257176,
  257275,
  257278,
  257281,
  258168,
  258178,
];

export const weThePeopleLevel3PaidLessonLeIds = [
  219804,
  220449,
  220496,
  220503,
  220793,
  220824,
  220828,
  221790,
  221805,
  222999,
  223002,
  223313,
  223317,
  223320,
  224509,
  224522,
  224538,
  224970,
  224979,
  224994,
  227417,
  227452,
  211875,
  227544,
  227589,
  227630,
  230208,
  230224,
  230247,
  230258,
  230265,
  232722,
  232729,
  232755,
  232780,
  232783,
  232785,
  232786,
];

export enum LearnEntityPurchaseSource {
  CATALOG_TILE, // buying for yourself and n students
  PREVIEW_READER, // buying for yourself and n students
  TEACHER_READER, // buying for yourself
  NEED_MORE_WHILE_ASSIGNING, // buying for n students
  MANAGE_LICENSES, // buying for n students
  WORKSPACE_TILE,
};

export const weThePeoplePublisher = 'Center for Civic Education';
export const wtpTextWithFreeAccess = [501513,505198,505215];

export enum TextLicenseState {
  NoAccess = 0,
  AccessExpired = 1,
  AccessGranted = 2,
  HasAccess = 3,
};

export const connectionErrors = {
  blocking:
    'We\'re sorry, we couldn\'t complete your last action. We\'ve been notified \
and are looking into what went wrong! Please refresh the page and try again. If you \
were working on something, please exit this prompt and copy any unsaved information \
before refreshing.',
  nonblocking: 'Sorry! We could not complete your last action. Please refresh your browser and try again.',
  notAuthorized: 'You are not authorized to do this.',
  teacherOfflineMessage: 'You are offline. The last action did not save! Please try again when you are online.',
  offline: 'Unable to reach Actively Learn, please check your internet connection.',
  online:
    'Aaaand we\'re back! Please refresh the page. If you were working on something, \
please exit this prompt and copy any unsaved information before refreshing.',
  onlineAfterRequestFailedInOfflineMode: 'Looks like you just came back online! Please refresh the page.',
};

export enum ClassSource {
  NATIVE = 'native',
  GOOGLE = 'google',
  LTI = 'lti',
  EDMODO = 'edmodo',
  CLEVER = 'clever',
  CANVAS = 'canvas',
  BRIGHTSPACE = 'brightspace',
  SCHOOLOGY = 'schoology',
  ONEROSTER = 'oneroster',
  ACHIEVE3K = 'achieve3k',
}

export enum LayerTypes {
  USER_LAYER = 'UserLayer',
  CATALOG_LAYER = 'CatalogLayer',
}

export const paidPublishers: {
  decrypted: string[];
  disallowExcerpting: string[];
} = {
  decrypted: ['Dover Publications', 'Jump!'],
  disallowExcerpting: [],
};

export const infoTexts = {
  summativeAssessment: {
    welcomeTitle: 'This is a performance task, and it\'s a little different than what you may be used to at Actively Learn.',
    teacherWelcomeMessage: `Students can take notes in the text, but will not be able to share notes with other students
      or see feedback on questions as they work. Their answers will be submitted all at once when they are done.
      Once they click "Submit" they will not be able to change their answers.`,
  },
};

export const alertsText = {
  copyClipboardPasteInstruction: ' Use "Control+V" or "Paste" to use the link.',
};

export const harperCollinsPublisherNames = ['HarperCollins', 'Harper Collins', 'HarperCollinsPublishers', 'HarperCollins US', 'HarperCollins Studio', 'HarperCollins e-books', 'files.harpercollins.com'];
export const hmhPublisherNames = ['Houghton Mifflin Harcourt (www.hmhco.com)', 'hmh.org', 'hmhfyi.com', 'www.hmhco.com', 'hmhco.com'];
export const hachettePublisherNames = ['Hachette Book Group', 'Hachette Books', 'Hachette'];
export const publishersWithMinimumLicensePurchaseRequirement = [...harperCollinsPublisherNames, ...hmhPublisherNames, ...hachettePublisherNames];
export const getPublisherName = (publisher) => {
  if (harperCollinsPublisherNames.includes(publisher)) {
    return 'Harper Collins';
  }
  else if (hmhPublisherNames.includes(publisher)) {
    return 'Houghton Mifflin Harcourt';
  }
  else if (hachettePublisherNames.includes(publisher)) {
    return 'Hachette';
  }
  else {
    return '';
  }
}

export enum WritingCoachFeedbackStrategy {
  REVIEW_FEEDBACK_FIRST = 'reviewFeedbackFirst',
  AUTOMATICALLY_SEND_FEEDBACK = 'automaticallySendFeedback'
}

export const defaultReadingSettings = {
  notifications: true,
  questionGates: true,
  minimizeQuestions: false,
  viewPeerResponses: 'all',
  namedPeerResponses: true,
  allowLikingPeerResponses: true,
  allowStudentsMCAnswer: true,
  disAllowAnswerEditing: true,
  allowStudentSharedNotes: true,
  allowTranslation: true,
  allowDictionary: true,
  allowTextToSpeech: true,
  enableWritingCoach: true,
  writingCoachFeedbackStrategy: WritingCoachFeedbackStrategy.AUTOMATICALLY_SEND_FEEDBACK,
};

export const defaultQuizAssessmentSettings = {
  allowStudentsMCAnswer: false,
  allowStudentSharedNotes: false,
  ViewPeerResponses: 'none',
  namedPeerResponses: false,
  allowLikingPeerResponse: false,
  questionGates: false,
};

export enum Feature {
  IR = 'ir',
  RESEARCH = 'research',
  MEDIA_RESPONSE = 'mediaresponse',
  UNENROLL = 'unenroll',
  ANIMATIONS = 'animations',
  SCHOOLOGY_ASSIGNMENT_SYNCING = 'schoologyassignmentsyncing',
  CATALOG = 'catalog',
  ACHIEVE3K_MAIN_MENU_LINK = 'achieve3kmainmenulink',
  ACHIEVE3K_ROSTERING = 'achieve3krostering',
  JOIN_CLASS = 'joinclass',
  ADD_CLASS = 'addclass',
  DELETE_CLASS = 'deleteclass',
  EDIT_CLASS = 'editclass',
  ARCHIVE_CLASS = 'archiveclass',
  MANGE_CLASS_ROSTER = 'manageclassroster',
  MANAGE_USERS_ACCOUNT_WRITE = 'manageusers.accounts.write'
};

export const nonTextLearnEntityType = ['Video', 'Simulation', 'Interactive Data Set'];