import CatalogHeader from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  const hasFavorites = state.catalogData.favoriteProblemSetIds.length > 0;
  const {
    search,
    grade,
    hasSearched,
    showingFavorites,
    shouldSelectAllGrades,
  } = state.catalogData.filters;

  return {
    gradeLevelsForDropdown: ['All Grades', ...state.catalogData.gradeLevelsForDropdown],
    hasFavorites,
    search,
    grade,
    hasSearched,
    showingFavorites,
    shouldSelectAllGrades,
  };
};

export default connect(
  mapStateToProps,
)(CatalogHeader);
