import React from 'react';
import styles from './styles.module.css';
import { getFormattedTime, sumBy } from '../../../utils/common';

const Card = ({ title, data }: { title: string; data: any }): JSX.Element => {
  return (
    <div className={styles.scorecard}>
      <div className={styles.scorecardCaption}>{title}</div>
      <div className={styles.scorecardNum}>{data}</div>
    </div>
  );
};

const ScoreCards = ({ problemSetData, hintsAndVideoData }: { 
  problemSetData: any[];
  hintsAndVideoData: any[];
}): JSX.Element => {
  const numProblemAttempted = problemSetData.reduce((acc, x) => acc +
    x.problemData.filter((x: any) => x.type !== 'steps').length
  , 0);
  const numCorrectResponse = problemSetData.reduce((acc, x) => acc +
    x.problemData.filter((x: any) => x.type === 'answeredProblemCorrectly').length
  , 0);
  const numStepAttempted = problemSetData.reduce((acc, x) => acc +
    x.problemData.filter((x: any) => x.type === 'answeredLastStepCorrectly').length
  , 0);

  const instructionalVideos = sumBy(hintsAndVideoData, 'numInstructionVideoWatched');
  const numSolutionVideoWatched = sumBy(hintsAndVideoData, 'numSolutionVideoWatched');
  const numHintsViewed = sumBy(hintsAndVideoData, 'numHintsViewed');

  const timeSpent = getFormattedTime(
    problemSetData.reduce((acc, x) => acc + x.timeSpentInSeconds, 0),
    false);

  const mastered = problemSetData.filter((x: any) => x.isMastered).length;

  return (
    <div className={styles.scorecardsContainer}>
      <Card title="PROBLEMS ATTEMPTED" data={numProblemAttempted} />
      <Card title="CORRECTLY SOLVED" data={numCorrectResponse} />
      <Card title="STEP-BY-STEP" data={numStepAttempted} />
      <Card title="TOTAL TIME SPENT" data={timeSpent} />
      <Card title="INSTRUCTIONAL VIDEOS" data={instructionalVideos} />
      <Card title="HINTS VIEWED" data={numHintsViewed} />
      <Card title="SOLUTIONS WATCHED" data={numSolutionVideoWatched} />
      <Card title="MASTERED SKILLS" data={`${mastered} of ${problemSetData.length}`} />
    </div>
  );
};

export default ScoreCards;
