import ClassData from './component';
import { connect } from 'react-redux';
import { getData } from '../../actions/api';

const mapStateToProps = (state: any, ownProps: any) => ({
  ltiAccountSource: state.userData.ltiAccountSource,
  classes: state.userClasses.classes,
  students: state.userClasses.students,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClasses: async () => {
      dispatch(getData({
        type: 'CLASSES_DATA',
      }));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassData);
