import ShowOriginalProblemButton from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    isProblemNotComplete: !state.problemSetData.completedProblemIds.includes(state.readerNavigation.currentProblemId),
    role: state.userData.role,
  }
}

export default connect(
  mapStateToProps
)(ShowOriginalProblemButton);
