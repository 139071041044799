import React from 'react';
import Mastery from '../mastery.component';
import styles from './styles.module.css';

interface IProps {
  masterySegment: any;
  completedCount: number;
}

const Progress = (props: IProps) => {
  const {
    masterySegment,
    completedCount,
  } = props;

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressMastery}>
        <div className={styles.progressTitle}>Mastery</div>
        <Mastery masterySegment={masterySegment} />
      </div>
      <div className={styles.progressCompleted}>
        <div className={styles.progressTitle}>Completed</div>
        <div className={styles.progressCompletedCount}>{completedCount}</div>
      </div>
    </div>
  );
};

export default Progress;
