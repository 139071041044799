import React, { useEffect } from 'react';
import Header from './header/container';
import Scroll from './scroll';
import styles from './styles.module.css';
import { getCookie, mapTeacherGradeLevelIdToCatalogGradeLevels } from '../../utils/common';
import { openModal } from '../../actions/eventEmitter';
import Button from '../common/button/component'
import AssignModal from '../common/modals/assign/container';
import { sendMessage } from '../../utils/iframe';

const Catalog = (props: any) => {
  const { userId, gradeLevelId, stateAbbreviation } = props;
  const defaultGradeLevel = getCookie(`${userId}-mathCatalogSelectedGradeLevel`);
  const gradeLevel = defaultGradeLevel ||
    mapTeacherGradeLevelIdToCatalogGradeLevels(gradeLevelId, stateAbbreviation)[0];
  const mainEl = document.querySelector('main');

  useEffect(() => {
    sendMessage('CATALOG_LOADED');
  }, []);

  useEffect(() => {
    // scroll window back to top when new data loads
    // TODO: do only if data is changed to avoid extra scroll top
    if (mainEl) {
      mainEl.scrollTop = 0;
    }
  }, [mainEl]);

  const renderCatalogResults = () => {
    const {
      data,
      benchmarks,
      filters: { search, grade },
      favoriteProblemSetIds,
      setFilters,
      toggleFavorite,
    } = props;

    if (!data) {
      return <div className={styles.main}>Loading...</div>;
    }
    else if (data.length === 0 && benchmarks.length === 0) {
      return (
        <div className={styles.main}>
          No results!
          {!!search && grade !== 'All Grades' &&
            <div className={styles.info}>
              Try another search term or change the grade filter to
              <Button
                size="small"
                additionalStyles={styles.link}
                onClick={() => setFilters({ shouldSelectAllGrades: true })}
              >
                  All Grades
              </Button>
            </div>
          }
        </div>
      );
    }
    else if (data.length > 0 || benchmarks.length > 0) {
      return (
        <React.Fragment>
          {data.length > 0 &&
            <Scroll
              data={data}
              toggleFavorite={toggleFavorite}
            />
          }
          {benchmarks.length > 0 &&
            <Scroll
              data={benchmarks}
              favoriteProblemSetIds={favoriteProblemSetIds}
              toggleFavorite={toggleFavorite}
            />
          }
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <Header
        fetchData={props.fetchData}
        gradeLevel={gradeLevel}
        setFilters={props.setFilters}
        userId={userId}
      />
      <main className={styles.main}>
        {renderCatalogResults()}
      </main>
    </React.Fragment>
  );
};

export default Catalog;

export const openAssignModal = (props: any) => openModal(<AssignModal {...props} />);