import LearnosityQuestion from './component';
import { connect } from 'react-redux';
import { ImplementationType } from '../../../utils/constants';
import { postData } from '../../../actions/api';

const mapStateToProps = (state: any, ownProps: any) => {
  const showingProblem = ownProps.type === ImplementationType.PROBLEM;
  const { currentProblemId, currentStepId } = state.readerNavigation;

  return {
    id: showingProblem ? currentProblemId : currentStepId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleFeatures: (params: any) => {
      dispatch(postData({
        type: `TOGGLE_${params.problemId ? 'PROBLEM' : 'STEP'}_FEATURES`,
        params,
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LearnosityQuestion);
