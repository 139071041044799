import LocationModal from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  const { problemSetId, levelSetLocation } = state.problemSetData;

  return {
    problemSetId,
    levelSetLocation,
  };
}

export default connect(
  mapStateToProps
)(LocationModal);