import { connect } from 'react-redux';
import { Role } from '../../utils/constants';
import Component from './studentData.component';

const mapStateToProps = (state: any) => {
  return {
    isStudent: state.userData.role === Role.STUDENT,
    ltiAccountSource: state.userData.ltiAccountSource,
  };
};


export default connect(
  mapStateToProps,
)(Component);
