import React, { useEffect } from 'react';
import ProblemFooter from '../problem/footer/container';
import LearnosityQuestionWrapper from '../learnosityQuestionWrapper/container';
import styles from './styles.module.css';

const Problem = (props: any) => {
  const {
    problemId,
    problemSetId,
    classId,
    studentUserId,
    hasEntityLoaded,
    hasEntityRendered,
    responseExists,
    showRuler,
    showProtractor,
    fetchData,
  } = props;

  useEffect(() => {
    fetchData({
      problemId: problemId,
      problemSetId: problemSetId,
      classId: classId,
      studentUserId: studentUserId || undefined,
    });
    // eslint-disable-next-line
  }, [fetchData, problemId, hasEntityLoaded, showRuler, showProtractor]);

  return (
    <main>
      <div className={styles.problem}>
        {hasEntityLoaded && <LearnosityQuestionWrapper type="problem" />}
      </div>
      {hasEntityRendered && !responseExists && !studentUserId && <ProblemFooter />}
    </main>
  );
};

export default Problem;
