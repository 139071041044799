import React from 'react';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';
import { Theme } from "@mui/material/styles";

import { makeStyles } from '@mui/styles';

interface TabProps extends MuiTabProps {
  label: string;
  labelData: string;
}

const statUseStyles = makeStyles((theme: Theme) => ({
  labelData: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '0',

    [theme?.breakpoints?.up('sm')]: {
      fontSize: '25px'
    }
  }
})
);

const tabUseStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#a1a1a1',
    fontSize: '12px',
    letterSpacing: '1.7px',
    lineHeight: '1.2',
    opacity: 1,
    padding: '20px 25px',
    boxShadow: '0px 2px 5px 0px rgba(184, 184, 184, 0.25)',
    borderStyle: 'solid',
    borderColor: '#cccccc',
    borderTopWidth: '0px',
    borderBottomWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '1px',
    overflow: 'visible',
    fontWeight: 'lighter',

    [theme?.breakpoints?.up('sm')]: {
      fontSize: '13px',
    },

    '&:last-child': {
      border: 0
    },

    '& .labelData': {
      color: '#0077d1',
      wordBreak: 'break-word'
    },
    '&:hover': {
      color: '#40a9ff',
    },
    '&$selected': {
      color: '#fff',
      backgroundColor: '#0077d1',
      border: 0,
      boxShadow: 'none',

      '& .labelData': {
        color: '#fff',
      },
    },
  },
  wrapper: {
    alignItems: 'initial',
    textAlign: 'left',
  },
  selected: {},
}));

interface StatisticProps {
  labelData: string;
}

const Statistic = (props: StatisticProps) => {
  const { labelData } = props;
  const styles = statUseStyles();

  return <p className={`labelData ${styles.labelData}`}>{labelData}</p>
}

const Tab = (props: TabProps) => {
  const { labelData, ...rest } = props;
  const styles = tabUseStyles();

  return <MuiTab disableRipple classes={styles} icon={<Statistic {...props} />} {...rest} />;
}

export default Tab;
