import config from '../config';
import * as iframeUtils from '../utils/iframe';
import qs from 'qs';
import showAlert from '../components/common/toaster';
const fetchActionCreator = require('fetch-action-creator');

const apiUrls: { [key: string]: string } = {
  ADMIN_REPORT_KEY_USAGE_DATA: 'reports/admin/keyusage',
  ADMIN_REPORT_QUANTILE_BENCHMARKS_DATA: 'reports/admin/quantilebenchmarks',
  ADMIN_REPORT_STATE_FORECAST_DATA: 'reports/admin/stateforecast',
  ADMIN_REPORT_PROGRESS_OVERVIEW_DATA: 'reports/admin/progressoverview',
  ADMIN_REPORT_EXPORT: 'reports/admin/export',
  ASSIGN: 'class/assigntoclasses',
  UNASSIGN: 'classassignment/unassignmathassignments',
  GET_ASSIGNMENTS_DATES: 'classassignment/getassignmentsdates',
  CLASSES_DATA: 'class/getclasses',
  CLASS_SETTING: 'class/classsetting',
  CLASS_STUDENTS: 'class/getstudentsassignedtostandard',
  GET_ASSIGNMENTS_STUDENT: 'classassignment/getlistforstudent',
  CATALOG_DATA: 'catalog/getdata',
  FAVORITE_PROBLEM_SET: 'catalog/favoriteproblemset',
  HINT_DATA: 'reader/gethintdata',
  GRADE_RESPONSE: 'reader/graderesponse',
  LEARNOSITY_EDITOR_SIGNED_REQUEST: 'reader/getlearnosityquestioneditorsignedrequest',
  PROBLEMSET_DATA: 'reader/getproblemsetdata',
  PROBLEM_DATA_FOR_STEP: 'reader/getproblemdata',
  PROBLEM_DATA: 'reader/getproblemdata',
  RESTART_PROBLEM: 'reader/restartproblem',
  GET_STEP_DATA: 'reader/getstepdata',
  GRADE_BENCHMARK_RESPONSE: 'reader/gradebenchmarkresponse',
  SAVE_PROBLEM_EDITS: 'reader/saveproblemedits',
  SAVE_STEP_EDITS: 'reader/savestepedits',
  SAVE_GRAPH_EDITS: 'reader/savegraphedits',
  SHOW_STEPANSWER: 'reader/showstepanswer',
  SKIPPED_TO_STEPS: 'reader/skippedtosteps',
  TRACK_HINTS: 'track/hints',
  TRACK_VIDEOS: 'track/videos',
  TRACK_SOLUTION_VIDEO: 'track/solutionvideo',
  TOGGLE_HIDE_PROBLEM: 'reader/togglehideproblem',
  USER_DATA: 'account/getuserinfo',
  CLASS_MASTERY_DATA: 'classdata/getclassmasterydata',
  CLASS_USAGE_DATA: 'classdata/getclassusagedata',
  CLASS_INTERVENTION_DATA: 'classdata/getclassinterventiondata',
  CLASS_DATA_MASTERY_EXPORT: 'classdata/mastery/export',
  STUDENT_DATA_QUANTILE: 'studentdata/getstudentquantiledata',
  STUDENT_DATA_MASTERY: 'studentdata/getstudentmasterydata',
  STUDENT_DATA_INTERVENTION: 'studentdata/getstudentinterventiondata',
  STUDENT_DATA_PROBLEM_ACTIVITY: 'studentdata/getproblemactivity',
  RESET_PROBLEMSET: 'classassignment/reset',
  CLASS_STUDENT_LIST: 'classstudentdata/getstudents',
  CLASS_EXTERNAL_SYNCED_ASSIGNMENTS: 'classassignment/getexternalsyncedassignments',
  SYNC_MASTERY: 'classassignment/syncmastery',
  TRIGGER_ASSIGNMENT_AUTO_ASSIGN: 'classassignment/triggerautoassign',
  TRIGGER_ASSIGNMENT_AUTO_RESET: 'classassignment/triggerautoreset',
  SAVE_HINT_EDITS: 'reader/savehintedits',
  TOGGLE_DELETE_HINT: 'reader/toggledeletehint',
  TRACK_LEVELSET_LOCATION: 'reader/savelevelsetlocation',
  RESET_LEVELSET: 'reader/resetlevelset',
  MOVE_PROBLEM: 'reader/moveproblem',
  GET_ALL_PROBLEMSETS: 'superadmin/getallproblemsets',
  UPDATE_STEP_PROBLEMSET: 'superadmin/updatestepproblemset',
  GET_READER_NEW_URL_PARAMS: 'reader/getproblemsetandclass',
  TOGGLE_DELETE_STEP: 'reader/toggledeletestep',
  UPDATE_PROBLEM_PROBLEMSET: 'internal/updateproblemproblemset',
  REVEAL_BOOK_UNITS: 'reveal/getbookdata',
  REVEAL_BOOKS: 'reveal/getbooks',
  TOGGLE_PROBLEM_FEATURES: 'reader/togglefeatures',
  TOGGLE_STEP_FEATURES: 'reader/togglefeatures',
};

// RESOLVE = body, statusCode
// REJECT = error, statusCode

export const getData = ({ type, params, mutators, preCheck, isRetry = false }: {
  type: string; params?: any; mutators?: any; preCheck?: any; isRetry?: boolean;
}): any =>
  fetchActionCreator(
    type,
    `${config.backendApiUrl}/${apiUrls[type]}?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      credentials: 'include',
    },
    {
      onReject: (res: any) => {
        if (!isRetry && !res.statusCode && res.error === 'Failed to fetch') {
          return getData({ type, params, mutators, preCheck, isRetry: true });
        }
        else {
          handleError(res.statusCode, res.error, type);
          return res;
        }
      },
      ...mutators,
    },
    preCheck,
  );

export const postData = ({ type, params, mutators }: { type: string; params: any; mutators?: any }): void => {

  return fetchActionCreator(
    type,
    `${config.backendApiUrl}/${apiUrls[type]}`,
    {
      method: 'POST',
      body: qs.stringify({ ...params}),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    },
    {
      onReject: (res: any) => {
        handleError(res.statusCode, res.error, type);
        return res;
      },
      ...mutators,
    },
    {},
  );
};

export const getDataPlain = async ({ type, params, isALAPI, isRetry = false, returnErrorResponse = false }: {
  type: string; params?: any; isALAPI?: boolean; isRetry?: boolean; returnErrorResponse?: boolean;
}): Promise<any> => {
  try {
    const response =
      await fetch(`${getBackendUrl(isALAPI)}/${apiUrls[type]}?${qs.stringify(params, { arrayFormat: 'brackets' })}`, {
        method: 'GET',
        credentials: 'include',
      });

    return getResponseData(response, type);
  }
  catch (err) {
    if (!isRetry && err && err.message === 'Failed to fetch') {
      return getDataPlain({ type, params, isALAPI, isRetry: true });
    }
    else {
      return getResponseData(null, type, returnErrorResponse);
    }
  }
};

export const postDataPlain = async ({ type, params, isALAPI, isFileUpload = false, returnErrorResponse = false }: {
  type: string; params?: any; isALAPI?: boolean; isFileUpload?: boolean; returnErrorResponse?: boolean;
}) => {
  let response;

  if (isFileUpload) {
    response = await fetch(`${getBackendUrl(isALAPI)}/${apiUrls[type]}`, {
      method: 'POST',
      body: params,
      credentials: 'include',
    });
  }
  else {
    response = await fetch(`${getBackendUrl(isALAPI)}/${apiUrls[type]}`, {
      method: 'POST',
      body: qs.stringify({ ...params }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
    });
  }

  return getResponseData(response, type, returnErrorResponse);
};

const getResponseData = async (response: any, type: string, returnErrorResponse = false) => {
  const responseStatusCode = response ? response.status : null;
  const responseData = response ? await response.json() : null;

  if (responseStatusCode !== 200) {
    handleError(responseStatusCode, responseData, type);
    return returnErrorResponse ? responseData : null;
  }
  else {
    return responseData;
  }
};

export const handleError = (
  statusCode: number, 
  response: { isMathSpecificError: boolean; message: string; },
  type: string
): void => {
  if (response && response.isMathSpecificError) {
    showAlert({ message: response.message, isError: true });
  }
  else {
    if (statusCode === 403 && customErrorFunctions[type] && customErrorFunctions[type].unAuthorisedAccess) {
      return customErrorFunctions[type].unAuthorisedAccess(response);
    }

    iframeUtils.sendMessage('API_ERROR', { statusCode, message: customErrorMessage[type] });
  }
};

const customErrorFunctions: { [key: string]: any } = {
  PROBLEMSET_DATA: {
    unAuthorisedAccess: (response: any) => iframeUtils.sendMessage('READER_ACCESS_ERROR', response.data),
  },
};

const customErrorMessage: { [key: string]: any } = {
};

const getBackendUrl = (isALAPI = false) => {
  return isALAPI ? config.alBackendUrl : config.backendApiUrl;
}