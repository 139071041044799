import React from 'react';
import { makeStyles } from '@mui/styles';
import HorizontalBar from './HorizontalBar';

interface IProps {
  config: [{
    displayName?: string;
    label?: string;
    data: any[];
    barColors: string[];
    labelColors?: string[];
  }];
  className?: string
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  barChartWrapper: {
    margin: '18px 0',
    fontSize: '14px',
  },
  barChartTextWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20px',
    width: '200px',
  },
  barChartLabelText: {
    fontSize: '16px',
  },
  barWrapper: {
    display: 'flex',
    width: '100%'
  }
})

const HorizontalBarChart: React.FunctionComponent<IProps> = (props) => {
  const { config } = props;
  const styles = useStyles();

  const renderChart = () => {
    return config.map((bar) => (
      <div className={styles.barChartWrapper} key={bar.displayName}>
        {
          bar.data.length > 0 &&
          <div className={styles.barWrapper}>
            {(bar.label || bar.displayName) && <div className={styles.barChartTextWrapper}>
              {bar.displayName && <p className={styles.barChartLabelText}>{bar.displayName}</p>}
              {bar.label && <p className={styles.barChartLabelText}>{bar.label}</p>}
            </div>}
            <HorizontalBar
              data={bar.data}
              barColors={bar.barColors}
              labelColors={bar.labelColors}
            />
          </div>
        }
      </div>
    ));
  }

  return (
    <div className={styles.root}>
      {renderChart()}
    </div>
  );
}

export default HorizontalBarChart;
