import { connect } from 'react-redux';
import { getData } from '../../actions/api';
import QuantileBenchmarksChart from './quantileBenchmarksChart.component';

const mapStateToProps = (state: any) => {
  return {
    selectedGradeLevel: state.reports.selectedGradeLevel,
    selectedSchoolIds: state.reports.selectedSchoolIds,
    startDate: state.reports.dateFilter.startDate,
    endDate: state.reports.dateFilter.endDate,
    data: state.reports.benchmarkData,
    benchmarkSourceFilter: state.reports.benchmarkSourceFilter,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: ({
      classId,
      endDate,
      gradeLevel,
      schoolIds,
      startDate,
      benchmarkSourceFilter,
    }: {
      classId: number;
      gradeLevel: string;
      schoolIds: number[];
      startDate: string;
      endDate: string;
      benchmarkSourceFilter?: string;
    }) => {
      dispatch(getData({
        type: 'ADMIN_REPORT_QUANTILE_BENCHMARKS_DATA',
        params: {
          classId,
          gradeLevel,
          schoolIds,
          startDate,
          endDate,
          benchmarkSource: benchmarkSourceFilter,
        },
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuantileBenchmarksChart);
