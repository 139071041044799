import React from 'react';
import { getDataPlain } from '../../actions/api';
import WelcomeSection from './welcomeSection.component';
import Table from './table/table.component';
import ZeroState from './zeroState.component';
import { getMasterySegmentFromAssignment } from './mastery.component';
import Loading from '../common/loading/loading.component';
import styles from './styles.module.css';

interface IProps {
  firstName: string;
  params: any;
}

const StudentView = ({ firstName, params }: IProps) => {
  const { classId } = params;
  const [loading, setLoading] = React.useState(true);
  const [assignments, setAssignments] = React.useState<any[]>([]);
  const [assignmentsMastered, setAssignmentsMastered] = React.useState<any[]>([]);
  const [assignmentsEnded, setAssignmentsEnded] = React.useState<any[]>([]);
  const [assignmentsInProgress, setAssignmentsInProgress] = React.useState<any[]>([]);
  const [assignmentsToDo, setAssignmentsToDo] = React.useState<any[]>([]);
  const [completeLevelsets, setCompleteLevelsets] = React.useState<any[]>([]);
  const [assignmentToDoNext, setAssignmentToDoNext] = React.useState(null);
  const [timeSpentLastWeekInSeconds, setTimeSpentLastWeekInSeconds] = React.useState(0);

  React.useEffect(() => {
    async function fetchData() {
      const data = await getDataPlain({
        type: 'GET_ASSIGNMENTS_STUDENT',
        params: { classId },
      });

      const mastered: any[] = [];
      const ended: any[] = [];
      const inProgress: any[] = [];
      const toDo: any[] = [];
      const levelsets: any[] = [];
      let doNext: any = null;

      const setDoNext = (assignment: any) => {
        // make sure the assignment is not locked
        if (new Date() < new Date(assignment.startDate.replace(' ', 'T') + 'Z')) {
          return;
        }
        // set "Do Next" assignment as the first unlocked one we find
        if (doNext === null) {
          doNext = assignment;
        }
        // override a "To Do" assignment with an "In Progress" one
        else if (doNext.numProblemsCompleted === 0 && assignment.numProblemsCompleted > 0) {
          doNext = assignment;
        }
      };

      data.assignments.forEach((a: any) => {
        if (a.masteredDate && getMasterySegmentFromAssignment(a).label === 'Mastery') {
          mastered.push(a);
        }
        else if (a.isBenchmark && a.numProblemsCompleted === a.numProblemsInProblemSet) {
          levelsets.push(a);
        }
        else if (
          (a.endDate && (new Date(a.endDate.replace(' ', 'T') + 'Z') < new Date()))
          || (a.numProblemsInProblemSet === a.numProblemsCompleted)
        ) {
          ended.push(a);
        }
        else if (a.numProblemsCompleted > 0) {
          inProgress.push(a);
          setDoNext(a);
        }
        else {
          toDo.push(a);
          setDoNext(a);
        }
      });

      setAssignments(data.assignments);
      setAssignmentsMastered(mastered);
      setAssignmentsEnded(ended);
      setAssignmentsInProgress(inProgress);
      setAssignmentsToDo(toDo);
      setAssignmentToDoNext(doNext);
      setCompleteLevelsets(levelsets);
      setTimeSpentLastWeekInSeconds(data.timeSpentInSeconds);
      setLoading(false);
    }

    fetchData();
  }, [classId]);

  if (loading) {
    return (
      <div className={styles.studentViewContainer}>
        <Loading text="Loading..." />
      </div>
    )
  }

  return (
    <div className={styles.studentViewContainer}>
      <WelcomeSection
        masteredCounts={getMasteredCounts(assignments)}
        studentFirstName={firstName}
        assignment={assignmentToDoNext}
        assignmentsCount={assignments.length}
        timeSpentLastWeekInSeconds={timeSpentLastWeekInSeconds}
      />
      <ZeroState assignmentsCount={assignments.length} />
      <Table
        assignments={assignmentsInProgress}
        onSort={() => setAssignmentsInProgress(assignmentsInProgress.slice().reverse())}
        title="IN PROGRESS"
      />
      <Table
        assignments={assignmentsToDo}
        onSort={() => setAssignmentsToDo(assignmentsToDo.slice().reverse())}
        title="TO DO"
      />
      <Table
        assignments={assignmentsMastered}
        onSort={() => setAssignmentsMastered(assignmentsMastered.slice().reverse())}
        title="MASTERED"
      />
      <Table
        assignments={completeLevelsets}
        onSort={() => setCompleteLevelsets(completeLevelsets.slice().reverse())}
        title="LEVELSETS"
        disabled
      />
      <Table
        assignments={assignmentsEnded}
        onSort={() => setAssignmentsEnded(assignmentsEnded.slice().reverse())}
        title="CLOSED"
        disabled
      />
    </div>
  );
};

const getMasteredCounts = (assignments: any) => {
  const masteredAssignments = assignments.filter((a: any) =>
    a.masteredDate && getMasterySegmentFromAssignment(a).label === 'Mastery');
  const numMasteredPrereqs = assignments.reduce((acc: number, a: any) => {
    if (a.interventions) {
      const masteredInterventions = a.interventions.filter((i: any) => !!i.masteredDate);
      return acc + masteredInterventions.length;
    }
    else {
      return acc;
    }
  }, 0);

  return {
    assigned: masteredAssignments.length,
    prereq: numMasteredPrereqs,
  };
};

export default StudentView;