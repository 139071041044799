import React from 'react';
import { Checkbox, ReactSelect, ReactSelectComponents } from 'sharedfrontend';
import styles from './styles.module.css';
import { getStudentDisplayName, plural } from '../../../../utils/common';
import { colors } from '../../../../utils/style';
import { FaCheck } from 'react-icons/fa';

const AssignClass = (props: any): JSX.Element => {
  const studentOptions = props.students.map((student: any) => ({
    value: student.userId,
    label: getStudentDisplayName(student.firstName, student.lastName),
  }));

  return (
    <React.Fragment>
      <div className={styles.listItem} id={props.classId}>
        {!props.isUpdate && 
          <Checkbox
            className={styles.listItemCheckbox}
            label={props.name}
            checked={!!props.isAssigned}
            setChecked={() => props.onClassSelection(props.classId)}
          />
        }
        {props.isUpdate && <span>Assigned Students</span>}
        <ReactSelect
          key={`${props.isUpdate ? 'update' : ''}-` + props.classId}
          placeholder="All students"
          components={{ MultiValueContainer, Option }}
          suggestions={studentOptions}
          value={props.alreadyAssignedStudents}
          onChange={(selectedStudentOptions: any) =>
            props.onStudentSelection({
              classId: props.classId,
              userIds: selectedStudentOptions.map((x: any) => x.value),
            })
          }
          hideSelectedOptions={false}
          isMulti={true}
          isSearchable={false}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={{
            container: (base: any) => ({
              ...base,
              width: 250,
            }),
            control: (base: any) => ({
              ...base,
              padding: '1px 6px',
              cursor: 'pointer',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            placeholder: (base: any) => ({
              ...base,
              fontFamily: 'AvenirNext-Medium',
              textTransform: 'uppercase',
              fontSize: 12,
              color: !!props.isAssigned ? colors.darkBlue : colors.placeHolderGray,
              letterSpacing: 0.5,
            }),
            menuPortal: (base: any) => ({
              ...base,
              zIndex: 9999
            }),
          }}
        />
      </div>
    </React.Fragment>
  );
};

const MultiValueContainer = (props: any) => {
  const indexInValue = props.selectProps.value.findIndex((x: any) => x === props.data);
  const label = plural(props.selectProps.value.length, '%count student');

  return indexInValue === 0
    ? <span>{label}</span>
    : null;
};

const Option = (props: any) => {
  return (
    <ReactSelectComponents.Option {...props} className={styles.studentOption}>
      <label>{props.label}</label>
      {props.isSelected && <FaCheck className={styles.selectedStudentCheckmark}/>}
    </ReactSelectComponents.Option>
  );
};

export default AssignClass;