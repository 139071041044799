import { useEffect, useState } from 'react';
import { Checkbox, Modal } from 'sharedfrontend';
import Checklist from '../../checklist/component';
import styles from './styles.module.css';
import * as iframeUtils from '../../../../utils/iframe';
import showAlert from '../../toaster';
import {
  getStudentDisplayName,
  getLevelSetStartDueDates,
  formatStartAndDueDate as formatDate,
} from '../../../../utils/common';
import { getAssignmentsStartDueDate } from '../../../../utils/data';
import {
  StateMathSubjectGradeLevelMap,
  GradeLevelSetMap,
  LevelSet,
  AlertDuration,
} from '../../../../utils/constants';
import { FaAngleDown } from 'react-icons/fa';
import { openModal, closeModal } from '../../../../actions/eventEmitter';
import StartAndDueDatePicker from '../../startAndDueDatePicker/startAndDueDate.component';
import LevelSetStartAndDueDatePicker from '../../levelSetStartAndDueDatePicker/levelSetStartAndDueDatePicker';
import { Accordion, AccordionSummary } from '@mui/material';
import Button from '../../button/component';
import { groupBy } from '../../../interventions/utils';
import AssignClass from './assignClass.component';

interface Student {
  userId: number;
  firstName: string;
  lastName: string;
  classId: number;
  isAssigned: boolean;
}

interface Class {
  classId: number;
  name: string;
  isAssigned: boolean;
  startDate: null;
  endDate: null;
}

const AssignModal = (props: any): JSX.Element => {
  const [students, setStudents] = useState<Student[]>([]);
  const [assignClasses, setAssignClasses] = useState<any[]>([]);
  const [assignClassesDraft, setAssignClassesDraft] = useState<any[]>([]);
  const [assignDates, setAssignDates] = useState<any[]>([]);
  const [alreadyAssignedClasses, setAlreadyAssignedClasses] = useState<any[]>([]);
  const [unassignedClassIds, setUnassignedClassIds] = useState<any[]>([]);
  const [assignModalRef, setAssignModalRef] = useState<any>(null);
  const [updateModalRef, setUpdateModalRef] = useState<any>(null);
  const [classIdToUpdate, setClassIdToUpdate] = useState<any>(null);
  const existingClassIds: any[] = alreadyAssignedClasses.map(classData => classData.classId);
  const classToUpdate = assignClasses.find((assignClass: any) => assignClass.classId === classIdToUpdate);
  const unassignedClasses = assignClasses.filter((assignClass: any) =>
    unassignedClassIds.includes(assignClass.classId)
  );
  // Essentially the levelset series should have same GL - pick first
  // TODO - For '9-12', 'Grades 9-12', 'High School Math G 9-12'
  // it will pick the first levelset of the first levelset series but will be fixed in a separate PR.
  const gradeLevel = props.problemSets[0].gradeLevel;

  useEffect(() => {
    (async () => {
      const problemSetIds = props.problemSets.map((problemSet: any) => problemSet.problemSetId);
      const { classes, students } = await props.getClassesData(problemSetIds);
      const { startDate, dueDate } = getAssignmentsStartDueDate();
      const { moyStart, moyEnd, eoyStart, eoyEnd } = getLevelSetStartDueDates(startDate);
      const assignDates: any = [];

      props.problemSets.forEach((problemSet: any) => {
        switch(problemSet.key) {
        case LevelSet.BOY:
          assignDates.push({
            problemSetId: problemSet.problemSetId,
            startDate: formatDate(startDate),
            dueDate: formatDate(dueDate),
            isAssigned: true,
          });
          break;
        case LevelSet.MOY:
          assignDates.push({
            problemSetId: problemSet.problemSetId,
            startDate: formatDate(moyStart),
            dueDate: formatDate(moyEnd),
            isAssigned: true,
          });
          break;
        case LevelSet.EOY:
          assignDates.push({
            problemSetId: problemSet.problemSetId,
            startDate: formatDate(eoyStart),
            dueDate: formatDate(eoyEnd),
            isAssigned: true,
          });
          break;
        default:
          assignDates.push({
            problemSetId: problemSet.problemSetId,
            startDate: formatDate(startDate),
            dueDate: formatDate(dueDate),
            isAssigned: true,
          });
          break;
        }
      });

      const classIds = classes.map((classData: any) => classData.classId);
      const assignmentsDates = await props.getAssignmentsDates(problemSetIds, classIds);
      const studentGroupedByClass: any = groupBy(students, 'classId');
      const assignmentsDatesGroupedByClass = groupBy(assignmentsDates, 'classId');
      const alreadyAssignedClasses: any[] = (classes as Class[]).filter(classData => classData.isAssigned);
      const unassignedClassIds: any [] = (classes as Class[]).reduce((classIds: any[], classData: any) => {
        if (!classData.isAssigned) {
          classIds.push(classData.classId);
        }

        return classIds;
      }, []);

      const assignClasses = classes.map((classData: any) => {
        classData.students = studentGroupedByClass[classData.classId] || [];
        const assignmentsDatesGroupedByProblemSet = groupBy(
          assignmentsDatesGroupedByClass[classData.classId] || [], 'problemSetId');
        classData.problemSets = props.problemSets.map((problemSet: any) => {
          const isAssigned = !!assignmentsDatesGroupedByProblemSet[problemSet.problemSetId]?.[0];
          const { startDate, endDate } = assignmentsDatesGroupedByProblemSet[problemSet.problemSetId]?.[0] || {};
          const { startDate: defaultStartDate, dueDate: defaultDueDate } = assignDates.find((assignDate: any) =>
            assignDate.problemSetId === problemSet.problemSetId
          );

          return {
            ...problemSet,
            startDate: formatDate(startDate ? new Date(startDate) : defaultStartDate),
            dueDate: (props.isBenchmark || endDate) // If endDate is null, set due date as null for problemsets
              ? formatDate(endDate ? new Date(endDate) : defaultDueDate)
              : endDate,
            isAssigned,
          };
        });

        return classData;
      });

      setStudents(students);
      setAssignClasses(assignClasses);
      setAssignClassesDraft(JSON.parse(JSON.stringify(assignClasses)));
      setAssignDates(assignDates);
      setAlreadyAssignedClasses(alreadyAssignedClasses);
      setUnassignedClassIds(unassignedClassIds);
    })();
  }, [props]);

  useEffect(() => {
    if (unassignedClassIds.length > 0) {
      (assignModalRef as any)?.enableOkButton();
    }
    else {
      (assignModalRef as any)?.disableOkButton();
    }
  }, [assignModalRef, unassignedClassIds]);

  const enableModalOkButton = () => {
    (assignModalRef as any)?.enableOkButton();
    (updateModalRef as any)?.enableOkButton();
  }

  const onClassSelection = (classId: number, viaStudentSelection: boolean) => {
    const classes = [...assignClasses];
    const selectedClass = classes.find((classData: Class) => classData.classId === classId);
    const shouldShowGradeMismatchModal = props.isBenchmark && !selectedClass.isAssigned &&
      selectedClass.gradeLevel !== StateMathSubjectGradeLevelMap[gradeLevel];
    selectedClass.isAssigned = viaStudentSelection || !selectedClass.isAssigned || classToUpdate;

    const handleSelectClass = () => {
      if (!(viaStudentSelection || selectedClass.isAssigned)) {
        selectedClass.students = students.reduce((classStudents: any, student: any) => {
          if (student.classId === classId) {
            student.isAssigned = false;
            classStudents = [...classStudents, student];
          }
    
          return classStudents;
        }, []);
      }

      setAssignClasses(classes);
    }

    if (shouldShowGradeMismatchModal) {
      openModal(
        <Modal
          title="Class LevelSet Grade level mismatch"
          okText="Yes, Continue..."
          cssClass={styles.gradeMismatchModal}
          okCallback={() => {
            handleSelectClass();
            closeModal();
          }}
          cancelText="Cancel"
        >
          <p>
            {selectedClass.name} is assigned grade level {selectedClass.gradeLevel}.{' '}
            Students in {gradeLevel} should be assigned <b>LevelSet {GradeLevelSetMap[gradeLevel]}.</b>
            <br /><br />
             Do you wish to continue and assign LevelSet {GradeLevelSetMap[gradeLevel]}?
          </p>
        </Modal>
      );
    }
    else {
      handleSelectClass();
    }
  }

  const onStudentSelection = (selectedOptions: any) => {
    const { classId, userIds } = selectedOptions;
    const classes = [...assignClasses];
    const selectedClass = classes.find((classData: Class) => classData.classId === classId);
    onClassSelection(classId, userIds.length);

    selectedClass.students = selectedClass.students.map((student: any) => {
      student.isAssigned = userIds.includes(student.userId);
      
      return student;
    });
    setAssignClasses(classes);
  }

  const checkPreAssignValidations = () => {
    let problemSets = assignDates;
    let message = '';
    let isValidationFailed = false;

    if (classIdToUpdate) {
      problemSets = classToUpdate.problemSets;
    }

    problemSets.forEach((problemSet: any) => {
      const { startDate, dueDate } = problemSet;

      if (startDate >= dueDate && (props.isBenchmark || dueDate)) {
        message = `Due date for the ${props.isBenchmark ? 'levelSet' : 'problemSet'} cannot be before start date`;
        isValidationFailed = true;
      }
    });

    if (isValidationFailed) {
      showAlert({
        message,
        isError: true,
        autoCloseTimeInMs: AlertDuration.MEDIUM
      });
      enableModalOkButton();
    }

    return isValidationFailed;
  }

  const handleAssignToClasses = async (checkLevelSetAssignedStudents = true) => {
    const isValidationFailed = checkPreAssignValidations();

    if (isValidationFailed) {
      return;
    }

    const selectedClassIds: any[] = [];
    const selectedStudents: any[] = [];
    let selectedProblemSets: any [] = assignDates;
    let classes = assignClasses.filter((assignClass: any) => !existingClassIds.includes(assignClass.classId));

    if (classIdToUpdate) {
      classes = assignClasses.filter((assignClass: any) => assignClass.classId === classIdToUpdate);
      selectedProblemSets = classes[0].problemSets;
    }

    classes.forEach((assignClass: any) => {
      const { classId, isAssigned } = assignClass;

      if (isAssigned) {
        const students = assignClass.students.reduce((students: any[], student: any) => {
          if (student.isAssigned) {
            students.push({ userId: student.userId, classId });
          }

          return students;
        }, []);

        if (assignClass.students.length !== students.length) {
          selectedStudents.push(...students);
        }
        selectedClassIds.push(classId);
      }
    }, []);

    if (selectedClassIds.length === 0) {
      showAlert({ message: 'Please select a class!', isError: true, autoCloseTimeInMs: AlertDuration.SHORT });
      (assignModalRef as any).enableOkButton();

      return;
    }

    const problemSetIdsToUnassign: any[] = [];
    const problemSets = selectedProblemSets.reduce((problemSets: any[], problemSet: any) => {
      const { problemSetId, startDate, dueDate, isAssigned } = problemSet;
      problemSets.push({
        problemSetId,
        startDate: new Date(startDate).toISOString(),
        dueDate: dueDate ? new Date(dueDate).toISOString() : undefined,
        isAssigned
      });

      if (!isAssigned) {
        problemSetIdsToUnassign.push(problemSetId);
      }

      return problemSets;
    }, []);

    if (problemSets.length > 0 && problemSets.length === problemSetIdsToUnassign.length) {
      showAlert({ message: 'Please select a LevelSet!', isError: true, autoCloseTimeInMs: AlertDuration.SHORT });
      enableModalOkButton();

      return;
    }

    const assignProblemSetsData: any = {
      problemSets,
      selectedClassIds,
      selectedStudents,
      checkLevelSetAssignedStudents: !!props.isBenchmark && checkLevelSetAssignedStudents,
      isAssigningLevelSet: !!props.isBenchmark,
    }

    // Tracking when a problem set is assigned originating from the Reveal Catalog -
    // both catalog and reader
    if (props.revealBookTitle) {
      assignProblemSetsData['assignedFrom'] = `reveal-${props.revealBookTitle}`;
    }

    const data = await props.assignProblemSets(assignProblemSetsData);

    if (classIdToUpdate && problemSetIdsToUnassign.length > 0) {
      await props.unassignAssignments(problemSetIdsToUnassign, classIdToUpdate);
    }

    if (checkLevelSetAssignedStudents && !!data?.levelSetAssignedStudents) {
      openModal(
        <ValidateStudentModal
          {...data}
          handleAssignToClasses={handleAssignToClasses}
          closeModal={closeModal}
        />
      );
      enableModalOkButton();
    }
    else {
      if (data && data.isMathSpecificError) {
        enableModalOkButton();
  
        return;
      }

      if (!!classIdToUpdate) {
        setClassIdToUpdate(null);
        showAlert({ message: 'Updated!', autoCloseTimeInMs: AlertDuration.SHORT });
      }
      else {
        postAssignToClasses(data);
      }
    }
  }

  const postAssignToClasses = (data: any) => {
    closeModal(2);

    if (data.length > 0) {
      if (props.isBenchmark) {
        showAutoAssignProblemSetPopup(data);
      }
      else {
        showAssignSuccessPopup(data);
      }
    }
    else {
      showAlert({
        message: 'LevelSet series was assigned to no one!',
        autoCloseTimeInMs: AlertDuration.MEDIUM,
      });
    }

    // handle assign button color
    props.assignedProblemSet(data[0]?.problemSetId);
  }

  const showAssignSuccessPopup = (data: any) => {
    iframeUtils.sendMessage('ASSIGN_SUCCESS', {
      assignmentsAdded: data,
      shouldNavigate: false,
    });
  }

  const showAutoAssignProblemSetPopup = async (data: any[]) => {
    const selectedClassIds = assignClasses.filter((assignClass: Class) => !!assignClass.isAssigned)
      .map(assignClass => assignClass.classId);

    const classSettings = await Promise.all(
      selectedClassIds.map((classId: number) => props.getClassSettings(classId)),
    );
    const allClassesHaveSetting = classSettings.every((setting: any) => setting !== null);

    if (allClassesHaveSetting) {
      showAssignSuccessPopup(data);

      return;
    }

    const classIds = data.map(x => x.ClassId);
    const onButtonClick = (isAutoAssignEnabled: boolean) => {
      props.toggleAutoAssignForClasses(classIds, isAutoAssignEnabled);
      closeModal();
      showAssignSuccessPopup(data);
    };

    openModal(
      <Modal
        title="Auto-assign problem sets"
        okText="Yes, auto-assign content"
        okCallback={() => onButtonClick(true)}
        cancelText="Don't auto-assign"
        cancelCallback={() => onButtonClick(false)}
      >
        <p>
          Problem sets will be auto-assigned to students based on their results.
        </p>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        title="Assign to classes"
        maxWidth="md"
        okText="Assign"
        okCallback={() => handleAssignToClasses()}
        cancelText="Cancel"
        cancelCallback={closeModal}
        onOpenCallback={(modal: any) => setAssignModalRef(modal)}
      >
        {alreadyAssignedClasses.length > 0 &&
        <div className={styles.alreadyAssignedClassesWrapper}>
          <Accordion>
            <AccordionSummary
              aria-controls="alreadyAssignedClasses"
              id="accordionSummary_assignedClass"
              expandIcon={<span><FaAngleDown /></span>}
            >
              Classes where this is already assigned
            </AccordionSummary>
            <Checklist>
              {alreadyAssignedClasses.map((classData: Class) => (
                <div key={classData.classId} className="spaceBetween">
                  <div>
                    <Checkbox
                      className="mr-null"
                      checked={!!classData.isAssigned}
                      label
                      disabled
                      setChecked={() => null}
                    />
                    <span className="verticalAlignMiddle">
                      {classData.name}
                    </span>
                  </div>
                  <Button
                    additionalStyles="px-md"
                    onClick={(ev: any) => {
                      ev.preventDefault()
                      setClassIdToUpdate(classData.classId);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              ))}
            </Checklist>
          </Accordion>
        </div>
        }
        <Checklist>
          {unassignedClasses.map((classData: any) => (
            <AssignClass
              key={classData.classId}
              {...classData}
              students={classData.students}
              defaultGradeLevel={gradeLevel}
              alreadyAssignedStudents={classData.students.filter((student: any) => !!student.isAssigned)
                .map((student: any) => ({
                  value: student.userId, label: getStudentDisplayName(student.firstName, student.lastName)
                }))
              }
              onClassSelection={onClassSelection}
              onStudentSelection={onStudentSelection}
              ltiAccountSource={props.ltiAccountSource}
              existingClassIds={existingClassIds}
            />
          ))}
        </Checklist>
        {props.isBenchmark
          ? (
            <LevelSetStartAndDueDatePicker
              assignDates={assignDates}
              setAssignDates={setAssignDates}
              problemSets={props.problemSets}
            />
          )
          : (
            <StartAndDueDatePicker
              assignDates={assignDates}
              setAssignDates={setAssignDates}
              clearable
              showTodayButton={false}
            />
          )
        }
      </Modal>
      {!!classIdToUpdate && (
        <Modal
          title={classToUpdate.name}
          okText="Update"
          okCallback={() => handleAssignToClasses()}
          cancelText="Cancel"
          cancelCallback={() => {
            const assignClasses = JSON.parse(JSON.stringify(assignClassesDraft));  
            setClassIdToUpdate(null);
            setAssignClasses(assignClasses);
          }}
          maxWidth="md"
          onOpenCallback={(modal: any) => setUpdateModalRef(modal)}
        >
          <AssignClass
            {...classToUpdate}
            isUpdate
            students={classToUpdate.students}
            alreadyAssignedStudents={classToUpdate.students.filter((student: any) => !!student.isAssigned)
              .map((student: any) => ({
                value: student.userId, label: getStudentDisplayName(student.firstName, student.lastName),
              }))
            }
            onStudentSelection={onStudentSelection}
          />
          {props.isBenchmark
            ? (
              <LevelSetStartAndDueDatePicker
                isUpdate
                assignDates={classToUpdate.problemSets}
                setAssignDates={setAssignClasses}
                problemSets={props.problemSets}
                assignClasses={assignClasses}
                classIdToUpdate={classIdToUpdate}
              />
            )
            : (
              <StartAndDueDatePicker
                isUpdate
                assignDates={classToUpdate.problemSets}
                setAssignDates={setAssignClasses}
                assignClasses={assignClasses}
                classIdToUpdate={classIdToUpdate}
                clearable
                showTodayButton={false}
              />
            )
          }
        </Modal>
      )}
    </>
  );
}

const ValidateStudentModal = (props: any): JSX.Element => {
  return (
    <Modal
      title="Warning"
      okText="Continue"
      okCallback={() => {
        props.handleAssignToClasses(false, props.levelSetAssignedStudents);
        props.closeModal();
      }}
      cancelText="Cancel"
    >
      <p>
        The following students have already been assigned a LevelSet series either by you,
        an administrator or by a teacher in another course.
        Each student can only take one LevelSet series during the school year.
        You will be able to see the Quantile measure for this student once a LevelSet assessment has been completed.
      </p>
      <ul>
        {props.levelSetAssignedStudents.map((student: any) =>
          <li key={student.userId}>{student.firstName} {student.lastName}</li>
        )}
      </ul>
    </Modal>
  );
};

export default AssignModal;