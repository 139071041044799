import React from 'react';
import Button from '../../../common/button/component';
import { FaPencilAlt } from 'react-icons/fa';
import LearnosityQuestionEditorModal from '../../modals/learnosityQuestionEditor/container';
import { openModal } from '../../../../actions/eventEmitter';
import { ImplementationType } from '../../../../utils/constants';

const EditQuestionButton = (props: any): JSX.Element => {
  const theme = props.type === ImplementationType.STEP ? 'light' : undefined;

  return props.validRoles.includes(props.role)
    ? (
      <Button
        iconSize="large"
        theme={theme}
        onClick={() => openModal(<LearnosityQuestionEditorModal type={props.type} />)}
      >
        <span><FaPencilAlt /></span>
      </Button>
    )
    : <React.Fragment></React.Fragment>;
};

export default EditQuestionButton;
