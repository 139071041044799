import React from 'react';
import Loading from '../common/loading/loading.component';
import { FaInfoCircle } from 'react-icons/fa';
import tooltipStyles from '../common/tooltipStyles.module.css';
import { colors, levelSetColors } from '../../utils/style';
import { Tooltip } from 'sharedfrontend/src/components/tooltip';
import ChartHeader from 'sharedfrontend/src/components/dataViz/ChartHeader';
import ALChart, { ChartTypes } from 'sharedfrontend/src/components/dataViz/ALChart';
import { InfoText } from '../../utils/constants';

const QuantileBenchmarksChart = (props: any): JSX.Element => {
  const {
    classId,
    data,
    fetchData,
    selectedGradeLevel,
    startDate,
    selectedSchoolIds,
    endDate,
    benchmarkSourceFilter,
  } = props;

  React.useEffect(() => {
    fetchData({
      classId,
      gradeLevel: selectedGradeLevel,
      schoolIds: selectedSchoolIds,
      startDate,
      endDate,
      benchmarkSourceFilter,
    });
  }, [
    classId,
    fetchData,
    selectedGradeLevel,
    selectedSchoolIds,
    startDate,
    endDate,
    benchmarkSourceFilter,
  ]);

  if (!data) {
    return <Loading text="Quantile Benchmarks" />;
  }

  const {
    benchmarks,
    numGradedStudents,
    numRosteredStudents,
    benchmarkSource,
  } = data;

  const chartTitle = benchmarkSource === 'nwea' ? 'NWEA Class Data' : 'LevelSet Math Class Data';

  if (numGradedStudents === 0) {
    return (
      <div>
        <ChartHeader title={chartTitle} column={false} />
        <p>{InfoText.noChartDataText}</p>
      </div>
    );
  }

  const config = benchmarks.map((benchmark: any) => {
    const data = benchmark.levels.map((level: any) => {
      return {
        percentValue: Math.round((level.numStudentsInGroup / numRosteredStudents) * 100) + '%',
        tooltipLabel: `${level.numStudentsInGroup} of ${numRosteredStudents} students`,
      }
    });

    // add a gray section in bar chart for rostered students who haven't taken the levelset
    const numStudentsWithoutLevelSet = numRosteredStudents - benchmark.numStudentsThatTookThisBenchmark;
    const barColors = levelSetColors.slice(0, data.length);
    if (numStudentsWithoutLevelSet > 0) {
      data.push({
        percentValue: Math.round((numStudentsWithoutLevelSet / numRosteredStudents) * 100) + '%',
        tooltipLabel: `${numStudentsWithoutLevelSet} of ${numRosteredStudents} students`,
      });
      barColors.push(colors.gray1);
    }

    return {
      data,
      displayName: benchmark.name,
      barColors,
      labelColors
    }
  });

  let items = [];
  if (benchmarks[0]) {
    items = benchmarks[0].levels
      .map((level: any, index: number) => ({ color: levelSetColors[index], label: level.name }));
    items.push({ color: colors.gray1, label: 'No Data' });
  }
  const legend = { items };
  const tooltipText = benchmarkSource === 'nwea'
    ? 'Quantile® measures have been imported from NWEA MAP.'
    : `Achieve3000’s LevelSet, an online assessment that utilizes the Quantile® Framework for Math, developed by
    Achieve3000 in conjunction with MetaMetrics Inc.`

  return (
    <ALChart
      title={chartTitle}
      subtitle={<>{numGradedStudents} of {numRosteredStudents} students</>}
      legend={legend}
      legendTooltip={
        <Tooltip title={legendTooltip(legend.items.length)} tooltipColor="blue">
          <div className={tooltipStyles.legendTooltip}>
            <FaInfoCircle style={{ color: colors.blue }} />
          </div>
        </Tooltip>
      }
      config={config}
      tooltip={
        <p>{tooltipText}</p>
      }
      variant={ChartTypes.HorizontalBar}
      header
    />
  );
}

export default QuantileBenchmarksChart;

const labelColors = [
  colors.white,
  colors.white,
  colors.white,
  colors.white,
  colors.white,
];

/* eslint-disable max-len */
const legendTooltip = (numLevels: number) => (
  <div className={tooltipStyles.legendTooltipInner}>
    <p>
      The levels of your student can help you identify what steps you can take to help them:
    </p>
    <ul>
      <li>
        <strong>Level I (Falls Far Below):</strong>
        <ul>
          <li>Poor academic performance indicating minimal or no understanding of state mathematics content standards’ knowledge and skills</li>
          <li>Not on track for likely success in the next grade or course</li>
        </ul>
      </li>
      <li>
        <strong>Level II (Approaches)</strong>
        <ul>
          <li>Marginal or inconsistent academic performance indicating a partial understanding of state mathematics content standards’ knowledge and skills</li>
          <li>May be on track for likely success in the next grade or course with additional supports</li>
        </ul>
      </li>
      <li>
        <strong>Level III (Meets)</strong>
        <ul>
          <li>Satisfactory academic performance indicating a solid understanding of state mathematics content standards’ knowledge and skills</li>
          <li>On track for likely success in the next grade or course</li>
        </ul>
      </li>
      <li>
        <strong>Level IV (Exceeds)</strong>
        <ul>
          <li>Competent/superior academic performance indicating advanced/in-depth understanding of state mathematics content standards’ knowledge and skills</li>
          <li>On track for success in the next grade or course</li>
        </ul>
      </li>
      <li style={{ display: numLevels === 5 ? 'block' : 'none' }}>
        <strong>Level V (Mastery)</strong>
        <ul>
          <li>Superior academic performance indicating complete understanding of state mathematics content standards’ knowledge and skills</li>
          <li>Highly likely to excel in the next grade/course</li>
        </ul>
      </li>
    </ul>
  </div>
);
/*eslint-enable max-len */