import { Modal } from 'sharedfrontend';
import React from 'react';
import { closeModal, openModal } from '../../../actions/eventEmitter';
import { getFormattedTime } from '../../../utils/common';
import Button from '../../common/button/component';
import { TimeLabel } from './benchmarkTable.component';
import { ReadministerLevelSetModal } from './resetLevelSetModal.component';
import styles from './styles.module.css';
import { BenchmarkSource } from '../../../utils/constants';

export const ViewPastAssessmentsModal = ({ benchmarkSource, pastAssessments }: {
  benchmarkSource: string;
  pastAssessments: any[];
}): JSX.Element | null => {
  const [assessments, setAssessments] = React.useState(
    pastAssessments.sort((a, b) =>
      new Date(b.lastUpdatedDate).getTime() - new Date(a.lastUpdatedDate).getTime())
  );

  const onAssessmentReset = (benchmarkId: number) => {
    const updatedAssessments = [...assessments];
    const resettedAssessment = updatedAssessments.find(x => x.benchmarkId === benchmarkId);
    resettedAssessment.resetDate = new Date().toISOString().replace('T', ' ').replace('Z', '');

    setAssessments(updatedAssessments);
  };

  return (
    <Modal
      title="Past assessments"
      maxWidth="sm"
      okText="Close"
      okCallback={closeModal}
      cancelText=""
    >
      {pastAssessments.length === 0 && <p>No past assessments!</p>}

      {pastAssessments.length > 0 &&
        <ul className={styles.pastAssessmentsList}>
          {pastAssessments.map((pastAssessment: any, i: number) => {
            const {
              benchmarkId,
              benchmarkName,
              quantileMeasure,
              percentComplete,
              problemSetTimeInSeconds,
              levelSetLocation,
              resetDate,
              lastUpdatedDate,
              shouldShowInCatalog,
            } = pastAssessment;
            return (
              <li key={i}>
                <div className={styles.timeago}>
                  <TimeLabel
                    isCompleted={true}
                    lastUpdatedDate={lastUpdatedDate}
                  />
                </div>
                <div>{benchmarkName}</div>
                <div>{quantileMeasure || percentComplete + '% complete'}</div>
                {problemSetTimeInSeconds &&
                  <div className={styles.helperText}>
                    {getFormattedTime(problemSetTimeInSeconds, false)}
                    {levelSetLocation ? ', taken at ' + levelSetLocation : ''}
                  </div>
                }
                {!!resetDate &&
                  <div className={styles.helperText}>
                    Readministered <TimeLabel isCompleted={false} lastUpdatedDate={resetDate} />
                  </div>
                }
                {!!shouldShowInCatalog && i < 3 && !resetDate && benchmarkSource === BenchmarkSource.LEVELSET &&
                  <Button
                    theme="dark"
                    size="small"
                    type="button"
                    onClick={() => openModal(
                      <ReadministerLevelSetModal
                        levelSetData={pastAssessment}
                        onReset={() => onAssessmentReset(benchmarkId)}
                      />
                    )}
                  >
                    Readminister
                  </Button>
                }
              </li>
            );
          })}
        </ul>
      }
    </Modal>
  );
};
