import produce from 'immer';

const initialState = {
  userId: null,
  userName: null,
  firstName: null,
  lastName: null,
  role: null,
  ltiAccountSource: [],
  isDemoUser: false,
  stateAbbreviation: null,
};

const reducer: any = produce((draft, action: any) => {
  switch (action.type) {
  case 'RESOLVE_USER_DATA': {
    Object.assign(draft, action.body);
    break;
  }
  }
}, initialState);

export default reducer;
