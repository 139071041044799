import React from 'react';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../../dateRangePickerOverride.css';
import styles from './styles.module.css';
import { FaAngleDown } from 'react-icons/fa';
import { getCurrentSchoolYearDatesAsString, toMonthDayAndYear } from '../../../utils/common';
import Button from '../button/component';
import { isSameDay } from 'date-fns';
import classnames from 'classnames';

const { startDate, endDate } = getCurrentSchoolYearDatesAsString();
const staticRanges = createStaticRanges([
  ...defaultStaticRanges,
  {
    label: 'This School Year',
    range: () => ({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    }),
    isSelected (range: any) {
      const definedRange = (this as any).range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
      );
    },
  }
]);

const DatePickerDropdown = (props: any): JSX.Element => {
  const wrapperRef: any = React.createRef();
  const [dateRange, setDate] = React.useState({
    startDate: new Date(props.startDate),
    endDate: new Date(props.endDate),
  });
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setDate({
      startDate: new Date(props.startDate),
      endDate: new Date(props.endDate),
    });
  }, [props.startDate, props.endDate]);

  React.useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (isOpen && wrapperRef && wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef, isOpen]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const onSave = (startDate: Date, endDate: Date) => {
    props.setDateRange({
      startDate: new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString(),
      endDate: new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString(),
    });

    setIsOpen(false);
  };

  const dropdownClasses = classnames({
    [styles.dropdown]: true,
    [styles.open]: isOpen,
    [styles.rightAligned]: props.position === 'right',
  });

  return (
    <div className={dropdownClasses + ' dateRangePicker'}>
      <button
        onClick={toggleDropdown}
        className={styles.dropdownToggle}
      >
        {toMonthDayAndYear(dateRange.startDate)} - {toMonthDayAndYear(dateRange.endDate)}
        <FaAngleDown className={styles.caret} />
      </button>
      <div className={styles.dropdownMenu} ref={wrapperRef}>
        <DateContainer
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onSave={onSave}
          onCancel={() => setIsOpen(false)}
        />
      </div>
    </div>
  );
};

export default DatePickerDropdown;

const DateContainer = (props: any): JSX.Element => {
  const [selectionRange, setDate] = React.useState({
    startDate: props.startDate,
    endDate: props.endDate,
    key: 'selection',
  });

  const handleSelect = (ranges: any) => {
    setDate({
      startDate: ranges.selection.startDate ? new Date(ranges.selection.startDate) : props.startDate,
      endDate: ranges.selection.endDate ? new Date(ranges.selection.endDate) : props.endDate,
      key: 'selection',
    });
  };

  const saveDate = () => {
    props.onSave(selectionRange.startDate, selectionRange.endDate);
  };

  const onCancel = () => {
    setDate({
      startDate: props.startDate,
      endDate: props.endDate,
      key: 'selection',
    });

    props.onCancel();
  };

  return (
    <React.Fragment>
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
        showMonthAndYearPickers={false}
        direction={'horizontal'}
        editableDateInputs={true}
        months={2}
        staticRanges={staticRanges}
      />
      <div className={styles.btnContainer}>
        <Button
          orangeType="empty"
          size="small"
          additionalStyles={styles.datePickerBtn}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          orangeType="filled"
          size="small"
          onClick={saveDate}
        >
          Apply
        </Button>
      </div>
    </React.Fragment>
  );
};
