import Problem from './component';
import { connect } from 'react-redux';
import { getData } from '../../../actions/api';
import { Role } from '../../../utils/constants';

const mapStateToProps = (state: any) => {
  const isStudent = state.userData.role === Role.STUDENT;

  return {
    hasEntityLoaded: state.readerNavigation.hasEntityLoaded,
    hasEntityRendered: state.readerNavigation.hasEntityRendered,
    problemId: state.readerNavigation.currentProblemId,
    studentUserId: state.readerNavigation.studentUserId,
    problemSetId: state.problemSetData.problemSetId,
    classId: state.problemSetData.classId,
    responseExists: state.gradeFeedbackData.feedbackMessage && isStudent,
    showRuler: state.problemData.showRuler,
    showProtractor: state.problemData.showProtractor,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: ({ problemId, problemSetId, classId, studentUserId }: { 
      problemId: number;
      problemSetId: number;
      classId: number;
      studentUserId: number;
    }) => {
      dispatch(getData({
        type: 'PROBLEM_DATA',
        params: { problemId, problemSetId, classId, studentUserId },
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Problem);
