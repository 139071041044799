import React from 'react';
import { format, formatDistance } from 'date-fns';
import { FaRegClock } from 'react-icons/fa';
import cn from 'classnames';
import styles from './styles.module.css';

interface IProps {
  className?: string;
  showDistance?: boolean;
  startDate: Date;
  endDate?: Date;
}

const DueDate = (props: IProps) => {
  const {
    className = '',
    showDistance = false,
    startDate,
    endDate,
  } = props;

  let text = '';

  if (showDistance) {
    if (!endDate) {
      return null;
    }
    text = `Due ${formatDistance(endDate, new Date(), { addSuffix: true })}`;
  }
  else {
    const ending = endDate
      ? `${format(endDate, 'MM/dd')}`
      : String.fromCharCode(8734);

    text = `${format(startDate, 'MM/dd')}`
        + ` ${String.fromCharCode(8594)} `
        + ending;
  }

  const classes = cn({
    [styles.dueDate]: true,
    [className]: true,
    [styles.pastDue]: endDate && endDate < new Date(),
  });

  return (
    <div className={classes}>
      <FaRegClock />
      <span className={styles.dueDateText}>{text}</span>
    </div>
  );
};

export default DueDate;
