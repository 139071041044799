import { makeStyles } from '@mui/styles';

export const useChecklistStyles = makeStyles({
  checklist: {
    '& > li:nth-child(2n)': {
      backgroundColor: 'var(--lighter-accent, #fafafa)',
    }
  },
});

export const useAddExternalCourseModalStyle = makeStyles({
  schoolSelector: {
    position: 'sticky',
    top: 0,
    background: 'inherit',
    zIndex: 100,
  },
  modal: {
    '& div.modal-body': {
      paddingTop: '4px !important'
    },
  }
});

export const useRowStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0 3px 15px',
  },
  name: {
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    '& > label': {
      marginLeft: 0,
      width: '100%',
    },

    '& > label span.Mui-disabled.MuiFormControlLabel-label': {
      color: 'var(--label-text-color, rgba(0, 0, 0, 0.87))',
    },

    '& .MuiFormControlLabel-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  gradeLevel: {
    '& > div .MuiFormControl-root': {
      margin: '1em',
      width: 220,
    }
  },
  gradeName: {
    textAlign: 'right',
    margin: '0 1em 0 0',
    width: 220,
  },
  accordionArrow: {
    '& > svg': {
      width: 18,
      height: 18
    }
  },
  accordion: {
    boxShadow: 'none',
    background: 'transparent',
    color: 'inherit',
    '&::before': {
      display: 'none',
    },
  },
  accordionSummary: {
    padding: 0,
  },
  expandIcon: {
    color: 'inherit',
  },
  "@media (max-width: 480px)": {
    row: {
      flexWrap: 'wrap',
    },
    gradeName: {
      textAlign: 'left',
      margin: '5px 1em',
    },
    gradeLevel: {
      '& > div .MuiFormControl-root': {
        marginTop: 5,
      }
    },
    name: {
      whiteSpace: 'normal',
    },
  },
});

export const useNativeClassesListStyles = makeStyles(theme => ({
  "@media (min-width: 840px)": {
    classRow: {
      display: 'flex',
      '& div.textValidator, div.selectValidator': {
        paddingRight: '10px',
      },
      '& div.textValidator': {
        minWidth: '365px',
      },
      '& div.selectValidator': {
        maxWidth: '230px',
        minWidth: '170px',
      }
    },
    cancelBtn: {
      display: 'none',
    },
    cancelIcon: {
      fill: 'currentColor !important',
    },
    addAnotherClass: {
      marginTop: '15px',
    },
    hidden: {
      visibility: 'hidden',
    }
  },
  "@media (max-width: 839px)": {
    addAnotherClass: {
      marginTop: '30px',
    },
    cancelBtn: {
      display: 'block',
      margin: '0 0 0 auto',
    },
    cancelIconBtn: {
      display: 'none',
    },
    classRow: {
      paddingBottom: '10px',
      marginBottom: '10px',
      '&:not(:last-of-type)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }
    }
  },
}));
