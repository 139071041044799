import Button from '../../../common/button/component';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import showAlert from '../../../common/toaster';
import commonStyles from '../../../common/commonStyles.module.css';
import { Tooltip } from 'sharedfrontend';
import { ImplementationType } from '../../../../utils/constants';

interface IProps {
  id?: number;
  role: string;
  type?: ImplementationType;
  isDisabled?: boolean;
  validRoles?: string[];
  tooltipText?: string;
  tooltipColor?: 'blue' | 'black' | 'white';
  handleToggleSwitch: (id ?: number, type ?: string) => void;
  isToggleOn?: boolean;
}

const ToggleButton = ({
  id,
  role,
  type,
  isDisabled = false,
  validRoles,
  tooltipText,
  tooltipColor = 'black',
  handleToggleSwitch,
  isToggleOn,
}: IProps): JSX.Element => {
  const isValidRole = !validRoles || validRoles.includes(role);
  const iconClass = isDisabled ? commonStyles.disabled : '';
  tooltipText = tooltipText || `${isToggleOn ? 'Shown' : 'Hidden'} for Ts and Ss`;
  const theme = type === ImplementationType.STEP ? 'light' : undefined;

  const handleOnClick = () => {
    if (!type) {
      handleToggleSwitch();
    }
    else if (!isDisabled) {
      handleToggleSwitch(id, type);
      if(type) {
        showAlert({
          message: `This ${type} is now ${isToggleOn ? 'hidden' : 'shown'} for teachers and students.`
        });
      }
    }
  }

  return isValidRole
    ? (
      <Button
        iconSize="large"
        theme={theme}
        onClick={handleOnClick}
      >
        <Tooltip title={tooltipText} tooltipColor={tooltipColor}>
          <span>
            {isToggleOn
              ? <FaToggleOn className={iconClass} />
              : <FaToggleOff className={iconClass} />
            }
          </span>
        </Tooltip>
      </Button>
    )
    : <></>;
};

export default ToggleButton;
