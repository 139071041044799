import Paging from './component';
import { connect } from 'react-redux';
import { postData } from '../../../actions/api';

const mapStateToProps = (state: any) => {
  return {
    currentProblemId: state.readerNavigation.currentProblemId,
    problemNumber: state.problemSetData.problemIds.indexOf(state.readerNavigation.currentProblemId) + 1,
    numProblems: state.problemSetData.problemIds.length,
    role: state.userData.role,
  };
}

const moveProblem = (newProblemNumber: number) => {
  return(dispatch: any, getState: any) => {
    const { currentProblemId } = getState().readerNavigation;
    const { problemIds } = getState().problemSetData;

    dispatch(postData({
      type: 'MOVE_PROBLEM',
      params: {
        problemId: currentProblemId,
        oldPosition: problemIds.indexOf(currentProblemId),
        newPosition: newProblemNumber - 1,
      },
    }));
  }
}

export default connect(
  mapStateToProps,
  { moveProblem },
)(Paging);
