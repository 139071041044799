import { connect } from 'react-redux';
import AdminReports from './adminReports.page';

const mapStateToProps = (state: any) => {
  return {
    schools: state.reports.schools,
  }
}

export default connect(
  mapStateToProps,
)(AdminReports);
