import React from 'react';
import ShowStepAnswerButton from '../../buttons/showStepAnswer/container';
import ShowHintsButton from '../../buttons/showHints/component';
import ShowOriginalProblemButton from '../../buttons/showOriginalProblem/container';
import SubmitResponseButton from '../../buttons/submitResponse/container';
import ShowSolutionVideoButton from '../../buttons/showSolutionVideo/container';
import readerStyles from '../../styles.module.css';
import { Role } from '../../../../utils/constants';

interface IProps {
  showStepAnswer: () => void;
}

const Footer = (props: IProps): JSX.Element => {
  return (
    <footer className={readerStyles.footer}>
      <ShowOriginalProblemButton validRoles={[Role.STUDENT]} />
      <ShowHintsButton />
      <ShowStepAnswerButton validRoles={[Role.STUDENT]} showStepAnswer={props.showStepAnswer} />
      <ShowSolutionVideoButton />
      <SubmitResponseButton validRoles={[Role.SUPERADMIN, Role.STUDENT]} />
    </footer>
  );
};

export default Footer;
