import React from 'react';
import Spinner from 'react-spinkit';
import styles from './styles.module.css';
import { colors } from '../../../utils/style';

const Loading = (props: any): JSX.Element => {
  const { text } = props;
  
  return (
    <div className={styles.loadingContainer}>
      <Spinner name="double-bounce" color={colors.darkBlue} />
      {text && <p>{text}</p>}
    </div>
  );
}

export default Loading;
