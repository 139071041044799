import { IConfig } from './types';

const config: IConfig = {
  env: 'production',
  productName: 'Actively Learn',
  productUrl: 'https://read.activelylearn.com',
  backendUrl: 'https://api.activelylearn.com',
  readerAppUrl: 'https://reader.activelylearn.com',
  cookieDomain: '.activelylearn.com',
  csrfTokenKey: 'CSRF-TOKEN',
  stripeKey: 'pk_live_jyiywcXcAJJhKEvWvpA3HnxY',
};

export default config;
