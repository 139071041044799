import ProblemFooter from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    isBenchmark: state.problemSetData.isBenchmark,
  }
}

export default connect(
  mapStateToProps,
)(ProblemFooter);
