import React from 'react';
import { ReactSelect } from 'sharedfrontend';
import { colors } from '../../../utils/style';

const AssignmentDropdown = (props: any) => {
  const { selectedProblemSetId, assignments, onAssignmentSelection, className } = props;
  const suggestions = assignments.map((assignment: any) => ({
    value: assignment.problemSetId,
    label: `${assignment.name}${assignment.standardCode ? ` (${assignment.standardCode})` : ''}`,
  }));
  const selectedAssignment = selectedProblemSetId
    ? suggestions.find((x: any) => x.value === selectedProblemSetId)
    : null;

  return (
    <ReactSelect
      value={selectedAssignment || suggestions[0]}
      suggestions={suggestions}
      onChange={(selectedAssignment: any) => onAssignmentSelection(selectedAssignment.value)}
      inputId="select-assignment"
      menuPosition="fixed"
      className={className}
      customSelectStyles={{
        container: (base: any) => ({
          ...base,
          boxShadow: `1px 1px 10px ${colors.boxShadowGray}`,
          width: 350,
        }),
        control: (base: any) => ({
          ...base,
          padding: '1px 6px',
          cursor: 'pointer',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        option: (base: any) => ({
          ...base,
          lineHeight: 1.4,
        }),
      }}
    />
  );
};

export default AssignmentDropdown;