import React from 'react';
import Checklist from '../../checklist/component';
import styles from './styles.module.css';
import { postDataPlain } from '../../../../actions/api';
import showAlert from '../../toaster';
import { closeModal } from '../../../../actions/eventEmitter';
import { Checkbox, Modal } from 'sharedfrontend';

interface IAssignment {
  problemSetId: number;
  name: string;
  source: string;
}

class SyncMasteryModal extends React.Component<any, any> {
  state = {
    assignmentIdsToSync: [] as number[],
  };

  syncAssignmentSelected = (problemSetId: number) => (isSelected: boolean) => {
    const assignmentIdsToSync: number[] = this.state.assignmentIdsToSync.slice();
    const assignmentIdIndex =  assignmentIdsToSync.findIndex(x => x === problemSetId);

    if (isSelected && assignmentIdIndex === -1) {
      assignmentIdsToSync.push(problemSetId);
    }
    else if (!isSelected && assignmentIdIndex >= 0) {
      assignmentIdsToSync.splice(assignmentIdIndex, 1);
    }

    this.setState({ assignmentIdsToSync });
  }

  syncMastery = async () => {
    const selectedAssignmentIds = this.state.assignmentIdsToSync;

    if (selectedAssignmentIds.length === 0 ) {
      showAlert({ message: 'Please select an assignment!', isError: true });
      return;
    }

    await postDataPlain({
      type: 'SYNC_MASTERY',
      params: {
        problemSetIds: selectedAssignmentIds,
        classId: this.props.classId,
      }
    });

    showAlert({ message: 'Success!' });
    closeModal();
  }

  render() {
    const { assignmentIdsToSync } = this.state;
    const { classAssignments } = this.props;

    return (
      <Modal
        title="Sync Mastery"
        okText="Sync Mastery"
        okCallback={this.syncMastery}
        cancelText="Cancel"
        cancelCallback={closeModal}
      >
        <Checklist>
          {classAssignments.map((a: IAssignment) => (
            <Assignment
              key={a.problemSetId}
              {...a}
              isSelected={assignmentIdsToSync.includes(a.problemSetId)}
              onSyncAssignmentSelected={this.syncAssignmentSelected}
            />
          ))}
        </Checklist>
      </Modal>
    );
  }
}

const Assignment = (props: any): JSX.Element => {
  return (
    <div className={styles.listItem}>
      <Checkbox
        className={styles.listItemCheckbox}
        label={props.name}
        checked={props.isSelected}
        setChecked={props.onSyncAssignmentSelected(props.problemSetId)}
      />
    </div>
  )
};

export default SyncMasteryModal;