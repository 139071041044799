import React from 'react';
import { Checkbox, DateTimePicker } from 'sharedfrontend';
import { LevelSet } from '../../../utils/constants';
import styles from './styles.module.css';
import { formatStartAndDueDate as formatDate } from '../../../utils/common';

const LevelSetStartAndDueDatePicker = (props: any): JSX.Element => {
  props.problemSets.map((ps: any) => {
    if (ps.name.includes('Beginning of Year')) {
      ps.key = LevelSet.BOY;
    } 
    else if (ps.name.includes('Middle of Year')) {
      ps.key = LevelSet.MOY;
    } 
    else if (ps.name.includes('End of Year')) {
      ps.key = LevelSet.EOY;
    }

    return ps;
  });

  const handleDateChange = (value: any, problemSetId: number, fieldName: string) => {
    const problemSets = [...props.assignDates];
    const selectedProblemSet = problemSets.find((problemSetDate: any) =>
      problemSetDate.problemSetId === problemSetId
    );

    if (fieldName === 'startDate') {
      selectedProblemSet.startDate = value ? formatDate(new Date(value).toString()) : selectedProblemSet.startDate;
    }
    else {
      selectedProblemSet.dueDate = value ? formatDate(new Date(value).toString()) : selectedProblemSet.dueDate;
    }

    if (props.isUpdate) {
      const classes = [...props.assignClasses];
      const selectedClass = classes.find((classData: any) => classData.classId === props.classIdToUpdate);
      selectedClass.problemSets = problemSets;
      props.setAssignDates(classes);
    }
    else {
      props.setAssignDates(problemSets);
    }
  };

  const onLevelSetSelection = (problemSetId: number) => {
    const problemSets = [...props.assignDates];
    const selectedProblemSet = problemSets.find((problemSetDate: any) =>
      problemSetDate.problemSetId === problemSetId
    );

    selectedProblemSet.isAssigned = !selectedProblemSet.isAssigned;

    if (props.isUpdate) {
      const classes = [...props.assignClasses];
      const selectedClass = classes.find((classData: any) => classData.classId === props.classIdToUpdate);
      selectedClass.problemSets = problemSets;
      props.setAssignDates(classes);
    }
    else {
      props.setAssignDates(problemSets);
    }
  }

  const renderLevelSetDates = () => (
    props.problemSets.map((problemSet: any, index: number) => {
      const { name, problemSetId } = problemSet;
      const { startDate, dueDate, isAssigned } = props.assignDates.find((assignDate: any) =>
        assignDate.problemSetId === problemSetId
      ) || {};

      return (
        <div key={index} className={styles.levelSetContainer}>
          <Checkbox
            className={styles.listItemCheckbox}
            label
            checked={!!isAssigned}
            setChecked={() => onLevelSetSelection(problemSetId)}
          />
          <div className={styles.levelSetCheckbox}>
            <p>{name}</p>
            <div className={styles.dateContainer}>
              <DateTimePicker
                label="Start Date:"
                id={`startDate_${problemSetId}`}
                placeholder="Select a date"
                value={startDate}
                onChange={(data) => handleDateChange(data, problemSetId, 'startDate')}
                withKeyBoard
                minDateMessage=""
                disablePast={true}
              />
            </div>
            <div className={styles.dateContainer}>
              <DateTimePicker
                label="Due Date:"
                id={`dueDate_${problemSetId}`}
                placeholder="Select a date"
                value={dueDate}
                onChange={(data) => handleDateChange(data, problemSetId, 'dueDate')}
                withKeyBoard
                minDateMessage=""
                disablePast={true}
              />
            </div>
          </div>
        </div>
      );
    })
  );

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        Students will not be able to access assignments before the start date and after the due date.
      </div>
      {renderLevelSetDates()}
      <div className={styles.note}>
        Note: You can always edit the dates for each assignment in class &gt; assignments.
        Any assignments that already have dates set will not change.
      </div>
    </div>
  )
};

export default LevelSetStartAndDueDatePicker;