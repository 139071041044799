import { IConfig } from './types';
import localConfig from './local';
import localMathConfig from './localMath';
import productionConfig from './production';
import productionMathConfig from './productionMath';
import liveConfig from './live';
import liveMathConfig from './liveMath';
import stagingConfig from './staging';
import stagingMathConfig from './stagingMath';
import nonprodMathConfig from './nonprodMath';
import nonprodConfig from './nonprod';
import ciConfig from './ci';

let config: IConfig;

if (window.location.href.startsWith('https://read.')
  || window.location.href.startsWith('https://reader.')
  || window.location.href.startsWith('https://api.activelylearn')) {
  config = productionConfig;
}
else if (window.location.href.startsWith('https://math.')
  || window.location.href.startsWith('https://al-api.')
  || window.location.href.startsWith('https://math-api-prod.')) {
  config = productionMathConfig;
}
else if (window.location.href.startsWith('https://read-live.')
  || window.location.href.startsWith('https://reader-live.')
  || window.location.href.startsWith('https://api-live.activelylearn')) {
  config = liveConfig;
}
else if (window.location.href.startsWith('https://math-live.')
  || window.location.href.startsWith('https://al-api-live.')
  || window.location.href.startsWith('https://math-api-live.')) {
  config = liveMathConfig;
}
else if (window.location.href.startsWith('https://altestal.')
  || window.location.href.startsWith('https://altestal-reader.')
  || window.location.href.startsWith('https://api-test.activelylearn')) {
  config = stagingConfig;
}
else if (window.location.href.startsWith('https://read-nonprod.')
  || window.location.href.startsWith('https://reader-nonprod.')
  || window.location.href.startsWith('https://api-nonprod.')) {
  config = nonprodConfig;
}
else if (window.location.href.startsWith('https://math-staging.')
  || window.location.href.startsWith('https://al-api-staging.')
  || window.location.href.startsWith('https://math-api-staging.')) {
  config = stagingMathConfig;
}
else if (window.location.href.startsWith('https://math-nonprod.')
  || window.location.href.startsWith('https://al-api-nonprod.')
  || window.location.href.startsWith('https://math-api-nonprod.')) {
  config = nonprodMathConfig;
}
else if (window.location.href.includes('pr.activelylearn.com')) {
  config = ciConfig;
}
else if (window.location.href.startsWith('http://localhost:5002')
  || window.location.href.startsWith('http://localhost:5003')) {
  config = localMathConfig;
}
else {
  config = localConfig;
}

export default config;
