import { IConfig } from './types';

const config: IConfig = {
  env: 'staging',
  productName: 'Achieve3000 Math',
  backendUrl: 'https://al-api-staging.achieve3000.com',
  productUrl: 'https://math-staging.achieve3000.com',
  mathIframeApi: 'https://math-api-staging.achieve3000.com',
  mathIframeOrigin: 'https://math-app-staging.achieve3000.com',
  cookieDomain: '.achieve3000.com',
  csrfTokenKey: 'CSRF-TOKEN-STAGING',
  isMath: true,
};

export default config;
