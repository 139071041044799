import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface IProps extends SwitchProps {
  leftOption: string;
  rightOption: string;
  checked: boolean;
  onChange: (event: any) => void;
}

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const activelyLearnTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          backgroundColor: "#016cc6",
        },
        track: {
          backgroundColor: "var(--al-blue) !important",
        },
      },
    },
  },
});

const useStyles = makeStyles({
  switchBase: {
    '&.Mui-focusVisible': {
      '& > span > span': {
        outline: '2px solid var(--al-blue)',
        outlineOffset: '2px'
      }
    }
  }
});

const RadioButtonSwitch = (props: IProps): JSX.Element => {
  const {
    leftOption,
    rightOption,
    checked,
    ...rest
  } = props;
  const styles = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={activelyLearnTheme}>
        <label onClick={props.onChange}>{leftOption}</label>
        <Switch
          checked={checked}
          color="primary"
          disableRipple={true}
          inputProps={{
            'role': 'button',
            'aria-label': checked ? 'Completion Selected' : 'Grades Selected'
          }}
          classes={{
            switchBase: styles.switchBase
          }}
          {...rest} />
        <label onClick={props.onChange}>{rightOption}</label>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default RadioButtonSwitch;
