import React from 'react';
import Button from '../../../common/button/component';
import HintsModal from '../../../reader/modals/hints/container';
import { openModal } from '../../../../actions/eventEmitter';

const ShowHintsButton = (): JSX.Element => {
  return (
    <Button
      orangeType="empty"
      onClick={() => openModal(<HintsModal />)}
    >
      show hints
    </Button>
  );
};

export default ShowHintsButton;
