import React, { useEffect, useRef } from 'react';
import styles from './styles.module.css';
import { FaHome, FaSchool } from 'react-icons/fa';
import { closeModal } from '../../../../actions/eventEmitter';
import { Modal } from 'sharedfrontend';

const locationOptions = [
  { label: 'School', value: 'school', icon: <FaSchool /> },
  { label: 'Home', value: 'home', icon: <FaHome /> },
];

const LevelSetLocationModal = (props: any): JSX.Element => {
  const modalRef: any = useRef();
  const { saveLevelSetLocation, levelSetLocation, problemSetId } = props;
  const [ userLocation, setUserLocation ] = React.useState(levelSetLocation);

  useEffect(() => {
    if (modalRef.current) {
      if (!!userLocation) {
        modalRef.current.enableOkButton();
      }
      else {
        modalRef.current.disableOkButton();
      }
    }
  }, [userLocation]);

  const onSave = () => {
    saveLevelSetLocation({
      levelSetLocation: userLocation,
      problemSetId,
    });
    closeModal();
  };

  return (
    <Modal
      title=""
      okText="Save"
      okCallback={onSave}
      cancelText=""
      onOpenCallback={(modal: any) => {
        modal.disableOkButton();
        modalRef.current = modal;
      }}
    >
      <React.Fragment>
        <p>Where are you attempting this assignment?</p>
        <p className={styles.locationButtonContainer}>
          {locationOptions.map(x => (
            <LocationButton 
              key={x.value}
              label={x.label}
              value={x.value}
              icon={x.icon}
              isActive={x.value === userLocation}
              onClick={setUserLocation}
            />
          ))}
        </p>
      </React.Fragment>
    </Modal>
  );
};

export default LevelSetLocationModal;

const LocationButton = (props: any) => {
  return (
    <button
      role="tab"
      type="button"
      className={`${styles.locationButton} ${props.isActive ? styles.selected : ''}`}
      onClick={() => props.onClick(props.value)}
    >
      <div>{props.icon}</div>
      {props.label}
    </button>
  );
};