import React from 'react';
import { isWithinInterval } from 'date-fns';
import { FaExclamationCircle, FaInfoCircle, FaPrint } from 'react-icons/fa';
import TimeAgo from 'react-timeago';
import { openModal } from '../../../actions/eventEmitter';
import {
  getCurrentSchoolYearDatesAsObj, getFormattedTime, getLevelSetSeries,
  getNWEAAssesmentAdaptiveTestDates, getQuantileScoreFromString,
  getStudentDisplayName, getTimeFromString,
} from '../../../utils/common';
import { BenchmarkRank, BenchmarkSource } from '../../../utils/constants';
import Button from '../../common/button/component';
import classDataStyles from '../styles.module.css';
import { ResumeOrReadministerLevelSetModal } from './resetLevelSetModal.component';
import styles from './styles.module.css';
import { ViewPastAssessmentsModal } from './viewPastAssessmentsModal.component';
import { ChartHeader, Tooltip } from 'sharedfrontend';
import { ReactTable, IHeaderData, IRowData, ICellData } from 'sharedfrontend/src/components/tables/ReactTable';
import tableStyles from '../../../styles/table.module.css';

interface formattedDataLayout {
  name: string;
  nameSort: any;
  currentAssessments: any;
  pastAssessments: any[];
  growth?: any;
}

const { startDate: startDateObj, endDate: endDateObj } = getCurrentSchoolYearDatesAsObj();
const startDate = startDateObj.toISOString();
const endDate = endDateObj.toISOString();

const LevelSetTable = (props: any): JSX.Element => {
  const {
    classId,
    data,
    fetchData,
    students,
    benchmarkSource,
  } = props;
  const isBenchmarkSourceNWEA = benchmarkSource === BenchmarkSource.NWEA;

  if (!data) {
    return <div className="ctaInfo">Loading...</div>
  }

  if (data.length === 0) {
    return <React.Fragment></React.Fragment>;
  }

  const formattedBenchmarkData = [];

  for (const student of students) {
    formattedBenchmarkData.push({
      firstName: student.firstName,
      lastName: student.lastName,
      assessments: data.filter((x: any) => x.studentUserId === student.userId),
    });
  }

  const formattedData: formattedDataLayout[] = formattedBenchmarkData.map((benchmarkData: any) => {
    const studentName = getStudentDisplayName(benchmarkData.firstName, benchmarkData.lastName);
    const currentAssessments = getCurrentAssesments(benchmarkData);

    return {
      name: studentName,
      nameSort: studentName.toLowerCase(),
      growth: undefined,
      currentAssessments,
      pastAssessments: getPastAssesments(benchmarkData, currentAssessments),
    }
  });

  /*
  Current Assesment : [[BOY assesment], [MOY assesment], [EOY assesment]]
  */

  formattedData.forEach(data => {
    let boyAssesment = null;
    let eoyAssesment = null;
    let moyAssesment = null;

    data.currentAssessments.forEach((currentAssesment: any) => {
      const { benchmarkName, lastUpdatedDate } = currentAssesment;
      const assesmentUpdateDate = getTimeFromString(lastUpdatedDate);
      const { BOY, MOY, EOY } = getNWEAAssesmentAdaptiveTestDates();

      if (
        benchmarkName.includes(BenchmarkRank.BeginningOfYear) ||
        (isBenchmarkSourceNWEA && isWithinInterval(
          assesmentUpdateDate, { start: BOY.startDate, end: BOY.endDate }
        ))
      ) {
        boyAssesment = currentAssesment;
      }
      else if (
        benchmarkName.includes(BenchmarkRank.MiddleOfYear) ||
        (isBenchmarkSourceNWEA && isWithinInterval(
          assesmentUpdateDate, { start: MOY.startDate, end: MOY.endDate }
        ))
      ) {
        moyAssesment = currentAssesment;
      }
      else if (
        benchmarkName.includes(BenchmarkRank.EndOfYear) ||
        (isBenchmarkSourceNWEA && isWithinInterval(
          assesmentUpdateDate, { start: EOY.startDate, end: EOY.endDate }
        ))
      ) {
        eoyAssesment = currentAssesment;
      }
    });

    data.currentAssessments = {
      [BenchmarkRank.BeginningOfYear]: boyAssesment,
      [BenchmarkRank.MiddleOfYear]: moyAssesment,
      [BenchmarkRank.EndOfYear]: eoyAssesment
    }
  });

  formattedData.forEach(data => {
    data.growth = getGrowth(data);
  });

  const columns: IHeaderData[] = [{
    Header: 'Student Name',
    accessor: 'name',
    width: 120,
  }];

  Object.keys(BenchmarkRank).forEach(currentRank => {
    columns.push({
      Header: BenchmarkRank[currentRank],
      accessor: BenchmarkRank[currentRank],
      width: 120,
    });
  });

  columns.push({
    Header: 'Growth This School Year',
    accessor: 'growth',
    width: 120,
  });

  const tooltipText = `Click on a student's name to see Quantile information from
    previous school years${isBenchmarkSourceNWEA ? '' : ' or to reset a previous LevelSet'}`;

  const studentsRowData: IRowData[] = formattedData.map((benchmarkData) => {
    const rowContent: { [key: string]: ICellData } = {
      name: {
        cellContent: (
          <div className={styles.nameContainer}>
            {benchmarkData.name}
          </div>
        ),
        sortValue: benchmarkData.nameSort,
        className: styles.studentNameColumn,
        onClick: () => {
          openModal(
            <ViewPastAssessmentsModal
              benchmarkSource={benchmarkSource}
              pastAssessments={benchmarkData.pastAssessments}
            />
          );
        }
      },
      growth: {
        cellContent: (
          <div>
            {benchmarkData.growth || '-'}
          </div>
        ),
        sortValue: getQuantileScoreFromString(benchmarkData.growth || ''),
        className: styles.cellContainer,
      }
    };

    Object.keys(BenchmarkRank).forEach(currentRank => {
      const currentAssessments = benchmarkData.currentAssessments[BenchmarkRank[currentRank]];
      rowContent[BenchmarkRank[currentRank]] = {
        cellContent: currentAssessments
          ? <TableCell { ...currentAssessments } fetchData={fetchData} classId={classId} />
          : <div>-</div>,
        sortValue: currentAssessments?.rawQuantileMeasure || currentAssessments?.percentComplete,
        className: styles.cellContainer,
      }
    });

    return { rowContent };
  });

  return (
    <React.Fragment>
      <div className={styles.tableHeaderContainer}>
        <ChartHeader title={isBenchmarkSourceNWEA ? 'NWEA Student Data' : 'LevelSet Math Student Data'} />
        <Tooltip title={tooltipText}>
          <div><FaInfoCircle /></div>
        </Tooltip>
        <div className={styles.printButton}>
          <Button
            iconSize="small"
            additionalStyles={classDataStyles.hideForPrint}
            onClick={window.print}
          >
            <FaPrint />
          </Button>
        </div>
      </div>
      <ReactTable
        columns={columns}
        data={studentsRowData}
        defaultSortBy={[{ id: 'name', desc: false }]}
        gridLines
        type="secondary"
        ignoreHeightAdjust
        fixedHeader={false}
        className={tableStyles.reactTable}
      />
    </React.Fragment>
  );
};

const TableCell: React.FunctionComponent<any> = (props: any) => {
  const tooltipText = (
    <div>
      {props.benchmarkName}
      <div className={styles.helperText}>
        <span>{props.quantileMeasure ? 'Quantile' : 'Percent Complete'}</span>
        <br />
        (Date)
        <br />
          (Time spent, Attempted at)
      </div>
    </div>
  );

  return (
    <Tooltip title={tooltipText} tooltipColor="blue">
      <div key={props.studentUserId + '-' + props.benchmarkId} className={styles.cellContainer}>
        <div>
          {props.quantileMeasure || props.percentComplete + '% complete'}
          <div className={styles.timeago}>
            <span>
              (
              <TimeLabel
                isCompleted={!!props.quantileMeasure}
                lastUpdatedDate={props.lastUpdatedDate}
              />
              )
            </span>
          </div>
          {props.problemSetTimeInSeconds &&
            <div className={styles.timeTakenLocation}>
              (
              {getFormattedTime(props.problemSetTimeInSeconds, true)}
              {props.levelSetLocation ? ', ' + props.levelSetLocation : ''}
              )
            </div>
          }
        </div>
        {props.isInvalidLevelSetAttempt && !props.quantileMeasure &&
          (
            <Button
              iconSize="small"
              onClick={() => openModal(
                <ResumeOrReadministerLevelSetModal
                  levelSetData={props}
                  onReset={() => props.fetchData({ classId: props.classId, startDate, endDate })}
                />
              )}
            >
              <FaExclamationCircle />
            </Button>
          )
        }
      </div>
    </Tooltip>
  );
};

const getGrowth = (data: any) => {
  const assessments = data.currentAssessments;
  let firstAssesmentQuantileScore: any = null;
  let lastAssesmentQuantileScore: any = null;
  Object.values(BenchmarkRank).forEach((rank: any) => {
    if (assessments[rank] && assessments[rank].rawQuantileMeasure) {
      lastAssesmentQuantileScore = getQuantileScoreFromString(assessments[rank].quantileMeasure);

      if (!firstAssesmentQuantileScore) {
        firstAssesmentQuantileScore = getQuantileScoreFromString(assessments[rank].quantileMeasure);
      }
    }
  });

  if (firstAssesmentQuantileScore &&
    lastAssesmentQuantileScore &&
    firstAssesmentQuantileScore !== lastAssesmentQuantileScore
  ) {
    const growthNum = lastAssesmentQuantileScore - firstAssesmentQuantileScore;

    return `${growthNum}Q`;
  }

  return null;
};

const getCurrentAssesments = (data: any) => {
  if (!data || data.assessments.length === 0) {
    return [];
  }

  let currentLevelSets = data.assessments.filter((a: any) =>
    isWithinInterval(
      getTimeFromString(a.lastUpdatedDate),
      { start: startDateObj, end: endDateObj }
    )
  );

  if (currentLevelSets.length > 0) {
    let latestLevelSet = currentLevelSets[0];
    currentLevelSets.forEach((x: any) => {
      if (getTimeFromString(x.lastUpdatedDate) > getTimeFromString(latestLevelSet.lastUpdatedDate)) {
        latestLevelSet = x;
      }
    });
    currentLevelSets = currentLevelSets.filter(
      (x: any) => getLevelSetSeries(x.benchmarkName) === getLevelSetSeries(latestLevelSet.benchmarkName));
  }

  return currentLevelSets;
}

const getPastAssesments = (data: any, currentAssesmentData: any) => {
  if (!data || data.assessments.length === 0) {
    return [];
  }
  const pastAssesments = data.assessments.filter((x: any) => !(currentAssesmentData.map(
    (y: any) => getUniqueIdentifier(y)).includes(getUniqueIdentifier(x))));

  return pastAssesments;
};

const getUniqueIdentifier = (assesment: any) => {
  return `${assesment.benchmarkId}-${assesment.benchmarkName}-${assesment.lastUpdatedDate}`;
}

export default LevelSetTable;

export const TimeLabel: React.FunctionComponent<any> = (props: any) => {
  const localDate = new Date(props.lastUpdatedDate.replace(' ', 'T') + 'Z');
  return props.isCompleted
    ? (
      <React.Fragment>
        {localDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
      </React.Fragment>
    )
    : <TimeAgo date={localDate} live={false} />;
};