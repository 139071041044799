import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styles from './styles.module.css';

toast.configure({
  draggable: false,
  transition: Slide,
  position: toast.POSITION.TOP_CENTER,
  className: styles.container,
  hideProgressBar: true,
});

const showAlert = ({ message, isError = false, autoCloseTimeInMs }: {
  message: string; isError?: boolean; autoCloseTimeInMs?: number;
}) => {
  if (isError) {
    toast.warn(message, {
      className: styles.alertWarning,
      autoClose: autoCloseTimeInMs || false,
    });
  }
  else {
    toast.info(message, {
      className: styles.alertInfo,
      autoClose: autoCloseTimeInMs || 2000,
    });
  }
};

export default showAlert;