import React from 'react';
import { IoIosArrowRoundBack } from 'react-icons/io';
import styles from './styles.module.css';
import Button from '../../common/button/component';
import classnames from 'classnames';
import { handleExitButtonClick } from '../../../utils/common';

const ExitButton = (props: any): JSX.Element => {
  const {
    isIntervention,
    isOnStep,
    role,
    validRoles,
    classId,
    revealBookTitle,
    pageView,
    className = 'btnHeaderExitReader',
    catalogSearchTerm,
    catalogSelectedGradeLevel,
  } = props;
  const buttonClassNames = classnames(className,
    {
      [styles.button]: true,
      [styles.intervention]: isIntervention,
      [styles.step]: isOnStep,
    }
  );

  return validRoles.includes(role)
    ? (
      <Button
        additionalStyles={buttonClassNames}
        onClick={() => handleExitButtonClick({
          pageView,
          classId,
          revealBookTitle,
          catalogSearchTerm,
          catalogSelectedGradeLevel,
        })}
      >
        <IoIosArrowRoundBack />
      </Button>
    )
    : <React.Fragment></React.Fragment>;
};

export default ExitButton;
