import React from 'react';
import Button from '../../../common/button/component';

const GoToNextStepButton = (props: any): JSX.Element => {
  return props.nextStepId && props.validRoles.includes(props.role)
    ? <Button orangeType="filled" onClick={() => props.goToStep(props.nextStepId)}>go to next step</Button>
    : <React.Fragment></React.Fragment>;
};

export default GoToNextStepButton;
