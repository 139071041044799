import React from 'react';
import { Left, Right } from '../../common/barParts/component';
import AssignmentTitle from '../../common/assignmentTitle/container';
import ExitButton from '../../../common/exitButton/container';
import MasteryProgressBar from './masteryProgressBar/container';
import CompletionProgressBar from './completionProgressBar/container';
import EditQuestionButton from '../../buttons/editQuestion/container';
import Paging from '../../paging/container';
import AssignButton from '../../../common/assignButton/container';
import readerStyles from '../../styles.module.css';
import { ImplementationType, Role } from '../../../../utils/constants';
import ToggleButton from '../../buttons/toggleButton/container';
import UpdateProblemProblemSetButton
  from '../../common/updateProblemProblemSetButton/updateProblemProblemSetButton.container';

const Header = (props: any): JSX.Element => {
  const { isBenchmark, problemNumber, mastery, problemId, revealBookTitle } = props;
  const showTooltip = problemNumber === 1 && mastery > 0;
  const catalogSearchTerm = window.sessionStorage.getItem('searchTerm');
  const catalogSelectedGradeLevel = window.sessionStorage.getItem('selectedGradeLevel');

  return (
    <header className={readerStyles.header} data-ignore="1">
      <Left>
        <ExitButton
          revealBookTitle={revealBookTitle}
          validRoles={[Role.SUPERADMIN, Role.TEACHER, Role.STUDENT]}
          catalogSearchTerm={catalogSearchTerm}
          catalogSelectedGradeLevel={catalogSelectedGradeLevel}
        />
        <AssignmentTitle />
        {!isBenchmark && (
          <AssignButton
            revealBookTitle={revealBookTitle}
            validRoles={[Role.SUPERADMIN, Role.TEACHER]}
          />
        )}
        {<EditQuestionButton type={ImplementationType.PROBLEM} validRoles={[Role.SUPERADMIN]} />}
        {!isBenchmark &&
          <ToggleButton
            id={problemId}
            validRoles={[Role.SUPERADMIN]}
            type={ImplementationType.PROBLEM}
          />
        }
        <UpdateProblemProblemSetButton validRoles={[Role.SUPERADMIN]} />
      </Left>
      <Right>
        <Paging validRoles={[Role.SUPERADMIN, Role.TEACHER]} />
        {!isBenchmark && <MasteryProgressBar validRoles={[Role.STUDENT]} showTooltip={showTooltip} />}
        <CompletionProgressBar validRoles={[Role.STUDENT]} showTooltip={showTooltip} />
      </Right>
    </header>
  );
};

export default Header;
