import { connect } from 'react-redux';
import MasteryTable from './masteryTable.component';

const mapStateToProps = (state: any) => {
  return {
    startDate: state.classReportsDataFilter.startDate,
    endDate: state.classReportsDataFilter.endDate,
  };
};

export default connect(
  mapStateToProps,
)(MasteryTable);