import React from 'react';
import { updateHash } from '../../../../utils/iframe';
import Button from '../../../common/button/component';

const redirect = (problemSetId: number, classId: number) => {
  updateHash({ path: `/mathreader?problemSetId=${problemSetId}&classId=${classId}`, redirect: true });
}

const ExitInterventionButton = (props: any): JSX.Element => {
  const { isIntervention, createdFromProblemSetId, classId } = props;
  return isIntervention
    ? <Button orangeType="empty" onClick={() => redirect(createdFromProblemSetId, classId)}>Exit review</Button>
    : <React.Fragment></React.Fragment>
};

export default ExitInterventionButton;
