import { getCookie, destroyAppInitDataAndRedirect, setCookie } from './commonHelpers';
import { connectionErrors } from '../helpers/constants';
import { getParamsInCamelCase } from './objectHelpers';
import { showAlert } from '../components/alert';
import config from '../config';

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response: any): Promise<any> {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  else {
    const resp = await response.json();
    return getParamsInCamelCase(resp);
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export function checkStatus(response: Response): void {
  if (!(response.status >= 200 && response.status < 300)) {
    const error = new ResponseError(response);
    error.response = response;
    throw error;
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request({
  url,
  options = {},
  isText = false,
  showAlertOnError = true,
  onError,
  onUnauthorizedError,
}: {
  url: string;
  options?: RequestInit;
  isText?: boolean;
  showAlertOnError?: boolean;
  onError?: Function;
  onUnauthorizedError?: Function;
}): Promise<any | { err: ResponseError }> {
  const requestData: RequestInit = {
    credentials: 'include',
    ...options,
  };

  const response = await fetch(url, requestData);

  try {
    checkStatus(response);
    return isText ? response.text() : parseJSON(response);
  }
  catch (err) {
    await handleError({ err, onError, onUnauthorizedError, showAlertOnError, url });
  }
}

export async function postRequest({
  url,
  data,
  options,
  isText = false,
  showAlertOnError = true,
  onError,
  isFormData = false,
  contentType,
  additionalHeaders = {},
  onUnauthorizedError,
}: {
  url: string;
  data: any;
  options?: RequestInit;
  isText?: boolean;
  showAlertOnError?: boolean;
  onError?: Function;
  isFormData?: boolean;
  contentType?: string;
  additionalHeaders?: Record<string, any>;
  onUnauthorizedError?: Function;
}): Promise<any | { err: ResponseError }> {
  const headers: any = {
    Accept: '*',
    ...additionalHeaders,
  };

  headers['Content-Type'] = contentType || 'application/json'

  const requestData: RequestInit = {
    credentials: 'include',
    method: 'post',
    headers,
    body: isFormData ? data : JSON.stringify(data),
    ...options,
  };

  const response = await saveRequestIfUserOffline();

  if (response.message === 'offline') {
    if (onError) {
      onError(new Error(response.message));
    }

    throw response;
  }
  else {
    const response = await fetch(url, requestData);

    try {
      checkStatus(response);
      return isText ? response.text() : parseJSON(response);
    }
    catch (err) {
      await handleError({ err, onError, onUnauthorizedError, showAlertOnError, url });
    }
  }
}

async function handleError({
  err,
  onError,
  onUnauthorizedError,
  showAlertOnError,
  url,
}): Promise<void> {
  if (onError) {
    onError(err);
  }
  if (err.response.status === 403 && onUnauthorizedError) {
    const resp = await err.response.json().then(getParamsInCamelCase);
    onUnauthorizedError(resp);
  }
  else if (showAlertOnError) {
    handleAlertOnError({ url, err });
  }
  else {
    throw err;
  }
}

export async function extractDataFromResponseError(
  err: ResponseError,
): Promise<{
  status: number;
  statusText: string;
  response: any;
}> {
  const { status, statusText } = err.response;

  let response = await err.response.text();

  // https://stackoverflow.com/questions/37121301/how-to-check-if-the-response-of-a-fetch-is-a-json-object-in-javascript
  try {
    response = JSON.parse(response); // try to parse as JSON
  }
  catch (e) {
    // If unable to parse as JSON then assume that the response is of text type
  }

  return { status, statusText, response };
}

async function handleAlertOnError({
  url,
  err,
}: {
  url: string;
  err: ResponseError;
}): Promise<void> {
  const { status, response } = await extractDataFromResponseError(err);
  handleUnauthenticatedAccessOrShowAlert({ response, status, url });
}

async function saveRequestIfUserOffline() {
  if (!navigator.onLine) {
    if (location.href.includes('/grading')) {
      showAlert({ message: connectionErrors.teacherOfflineMessage, type: 'error' });
    }
    else {
      showAlert({ message: connectionErrors.offline, type: 'error' });
    }

    return { message: 'offline' };
  }

  return { message: 'online' };
}

export const getErrorTextFromResponse = (response: any, status: number): string => {
  try {
    const errorText = response && typeof response === 'object' ? response.message : response;

    return errorText || connectionErrors[status === 403 ? 'notAuthorized' : 'nonblocking'];
  }
  catch (e) {
    return connectionErrors[status === 403 ? 'notAuthorized' : 'nonblocking'];
  }
};

export const handleUnauthenticatedAccessOrShowAlert = ({
  response,
  status,
  url,
}: {
  response: any;
  status: number;
  url?: string;
}): void => {
  if (url?.includes('signout')) {
    setCookie('lastActiveAt', null, -1);
    location.href = config.productUrl + '/account.html';
  }
  if (status === 401) {
    destroyAppInitDataAndRedirect({
      forwardURL: location.href,
    });
  }
  else {
    const message = getErrorTextFromResponse(response, status);
    showAlert({ message, type: 'error' });
  }
};