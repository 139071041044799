export const editMode = (isEditing: boolean) => {
  return {
    type: 'EDIT_MODE',
    body: isEditing,
  }
}

export const goToHint = (indexToGoTo: number) => {
  return {
    type: 'GO_TO_HINT',
    body: indexToGoTo,
  }
}