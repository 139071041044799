import React, { useState } from 'react';
import { ReactSelect, Tooltip } from 'sharedfrontend';
import HeaderLayout from '../../common/header/headerLayout.component';
import { ReactSelectStyles, colors } from '../../../utils/style';
import DateRangePicker from './adminDatePicker.container';
import Button from '../../common/button/component';
import showAlert from '../../common/toaster';
import { postDataPlain } from '../../../actions/api';
import { ReportExport } from '../../../utils/constants';
import config from '../../../config';

const gradeOptions = [
  { label: 'All Grade Levels', value: undefined },
  { label: 'Kindergarten', value: 14 },
  { label: 'Grade 1', value: 1 },
  { label: 'Grade 2', value: 2 },
  { label: 'Grade 3', value: 3 },
  { label: 'Grade 4', value: 4 },
  { label: 'Grade 5', value: 5 },
  { label: 'Grade 6', value: 6 },
  { label: 'Grade 7', value: 7 },
  { label: 'Grade 8', value: 8 },
  { label: 'Grade 9', value: 9 },
  { label: 'Grade 10', value: 10 },
  { label: 'Grade 11', value: 11 },
  { label: 'Grade 12', value: 12 },
];

const benchmarkSourceOptions = [
  { label: 'NWEA', value: 'nwea' },
  { label: 'Levelset', value: 'levelset' },
];

const AdminReportsHeader = (props: any) => {
  const {
    selectedGradeLevel,
    selectedSchoolIds,
    setSelectedGradeLevel,
    setSelectedSchools,
    schools,
    startDate,
    endDate,
    hasNweaSchool,
    hasLevelsetSchool,
    benchmarkSource,
    setBenchmarkSource,
  } = props;
  
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const schoolName = schools.length === 1 ? schools[0].Name : null;
  const schoolFilter = schools.length > 1
    ? (
      <SchoolFilter
        schools={schools}
        selectedSchoolIds={selectedSchoolIds}
        onSchoolSelected={setSelectedSchools}
        hasNweaSchool={hasNweaSchool}
      />
    )
    : null;

  const exportData = () => {
    showAlert({ message: 'Your report will be emailed to you shortly.' });
    setIsExportDisabled(true);
    postDataPlain({
      type: 'ADMIN_REPORT_EXPORT',
      params: {
        schoolIds: selectedSchoolIds,
        gradeLevel: selectedGradeLevel,
        startDate,
        endDate,
        benchmarkSource,
        utcOffset: new Date().getTimezoneOffset(),
      },
    });
    setTimeout(() => {
      setIsExportDisabled(false);
    }, (config.reportExportDisableTimout));
  };

  return (
    <HeaderLayout title={schoolName} navFilter={schoolFilter}>
      {hasNweaSchool && hasLevelsetSchool && schools.length > 1 && !selectedSchoolIds &&
        <BenchmarkSourceFilter
          setBenchmarkSource={setBenchmarkSource}
          benchmarkSource={benchmarkSource}
        />
      }
      <GradeFilter
        selectedGradeLevel={selectedGradeLevel}
        onGradeSelected={setSelectedGradeLevel}
      />
      <DateRangePicker position={!schoolFilter && 'right'} />
      <Tooltip
        title={ReportExport.reportExportToolTipText}
        tooltipColor="blue"
        isTooltipOpen={isExportDisabled}
        tooltipPlacement="right"
      >
        <div>
          <Button
            orangeType="empty"
            onClick={exportData}
            disabled={isExportDisabled}
          >
            Export
          </Button>
        </div>
      </Tooltip>
    </HeaderLayout>
  );
};

export default AdminReportsHeader;

const BenchmarkSourceFilter = (props: any) => {
  const { setBenchmarkSource, benchmarkSource } = props;
  const selectedOption = benchmarkSourceOptions.find(x => x.value === benchmarkSource);
  return (
    <ReactSelect
      id="benchmarkSourceSelector"
      value={selectedOption}
      suggestions={benchmarkSourceOptions}
      onChange={(option: any) => setBenchmarkSource(option.value)}
      isSearchable={false}
      styles={{
        container: (base: any) => ({
          ...base,
          boxShadow: `1px 1px 10px ${colors.boxShadowGray}`,
          width: 250,
        }),
        control: (base: any) => ({
          ...base,
          padding: '1px 6px',
          cursor: 'pointer',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
      }}
    />
  )
};

const GradeFilter = (props: any) => {
  const { selectedGradeLevel, onGradeSelected } = props;
  const selectedGradeLevelOption = gradeOptions.find(x => x.value === selectedGradeLevel);

  return (
    <ReactSelect
      id="gradeLevelSelector"
      placeholder="Filter by grade"
      value={selectedGradeLevelOption}
      suggestions={gradeOptions}
      onChange={(selectedGradeOption: any) => onGradeSelected(selectedGradeOption.value)}
      isSearchable={false}
      styles={{
        container: (base: any) => ({
          ...base,
          boxShadow: `1px 1px 10px ${colors.boxShadowGray}`,
          width: 250,
          margin: '0px 10px',
        }),
        control: (base: any) => ({
          ...base,
          padding: '1px 6px',
          cursor: 'pointer',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        placeholder: (base: any) => ({
          ...base,
          fontFamily: 'AvenirNext-Bold',
          textTransform: 'uppercase',
          fontSize: 12,
          color: colors.placeHolderGray,
          letterSpacing: 0.5,
        }),
      }}
    />
  );
};

const SchoolFilter = (props: any) => {
  const { selectedSchoolIds, schools, onSchoolSelected, hasNweaSchool } = props;
  const suggestions = schools.map((s: any) => ({ label: s.Name, value: s.SchoolId }));
  const selectedSchool = selectedSchoolIds
    ? suggestions.find((s: any) => s.value === selectedSchoolIds[0])
    : { label: 'All Schools', value: -1 };

  const onChange = (selectedSchool: any) => {
    if (selectedSchool.value === -1) {
      const benchmarkSourceFilter = hasNweaSchool ? 'nwea' : 'levelset';
      onSchoolSelected(undefined, benchmarkSourceFilter);
    }
    else {
      onSchoolSelected([selectedSchool.value]);
      const school = schools.find((s: any) => s.SchoolId === selectedSchool.value);
      const benchmarkSourceFilter = school.EnabledFeatures?.includes('nweaintegrationmath') ? 'nwea' : 'levelset';
      onSchoolSelected([selectedSchool.value], benchmarkSourceFilter);
    }
  };

  return (
    schools.length === 1
      ? schools[0].Name
      : (
        <ReactSelect
          id="schoolSelector"
          value={selectedSchool}
          suggestions={[{ label: 'All Schools', value: -1 }, ...suggestions]}
          onChange={onChange}
          inputId="select-school"
          customSelectStyles={ReactSelectStyles}
        />
      )
  )
};
