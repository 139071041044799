import React from 'react';
import { makeStyles } from '@mui/styles';
import { Dropdown } from '../inputs';
import classNames from 'classnames';

const useStyles = makeStyles({
  disabledExtraHelp: {
    opacity: 0.5
  },
});

function ExtraHelpDropdown({ student, isFeatureLocked = false, onClick = null, onChange }) {  
  const styles = useStyles();

  function getButtonContent(extraHelpState): string {
    if (extraHelpState === false) {
      return 'Always Off';
    }
    else if (extraHelpState === true) {
      return 'Always On';
    }
    else {
      return 'Automatic';
    }
  }
  
  const dropdownItems = isFeatureLocked
    ? []
    : [{
      content: 'Automatic',
      onClick: () => onChange(null),
      cssClass: student.extraHelpState === null ? 'menuitem selected' : '',
    }, {
      content: 'Always On',
      onClick: () => onChange(true),
      cssClass: student.extraHelpState ? 'menuitem selected' : '',
    }, {
      content: 'Always Off',
      onClick: () => onChange(false),
      cssClass: student.extraHelpState === false ? 'menuitem selected' : '',
    }];
  
  return (
    <Dropdown
      id={`ddlExtraHelpState${student.userId}`}
      showCaretInButton
      buttonContent={getButtonContent(student.extraHelpState)}
      className={classNames({
        'btn-box': true,
        [styles.disabledExtraHelp]: isFeatureLocked
      })}
      onClick={onClick || null}
      dropdownItems={dropdownItems}
    />
  );
}

export default ExtraHelpDropdown;