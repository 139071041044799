import ShowOverviewVideos from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    videoData: state.problemData.videoData,
  };
}

export default connect(
  mapStateToProps,
)(ShowOverviewVideos);