import AdminReportsHeader from './adminHeader.component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    selectedGradeLevel: state.reports.selectedGradeLevel,
    selectedSchoolIds: state.reports.selectedSchoolIds,
    schools: state.reports.schools,
    startDate: state.reports.dateFilter.startDate,
    endDate: state.reports.dateFilter.endDate,
    hasNweaSchool: state.reports.hasNweaSchool,
    hasLevelsetSchool: state.reports.hasLevelsetSchool,
    benchmarkSource: state.reports.benchmarkSourceFilter,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedGradeLevel: (gradeLevel: string) => {
      dispatch({
        type: 'SET_REPORT_GRADE_LEVEL',
        body: gradeLevel
      })
    },
    setSelectedSchools: (schoolIds: number[], benchmarkSourceFilter: string) => {
      dispatch({
        type: 'SET_REPORT_SCHOOLS',
        body: { schoolIds, benchmarkSourceFilter }
      })
    },
    setBenchmarkSource: (source: string) => {
      dispatch({
        type: 'SET_REPORT_BENCHMARK_SOURCE',
        body: source
      })
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsHeader);