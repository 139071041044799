import UpdateProblemSetButton from './updateProblemSetButton.component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    role: state.userData.role,
  }
};

export default connect(
  mapStateToProps,
)(UpdateProblemSetButton);
