import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'sharedfrontend';
import Problem from './problem/container';
import ProblemHeader from './problem/header/container';
import Step from './step/container';
import StepHeader from './step/header/container';
import './common/learnosityQuestion/learnosityOverrides.css';
import {
  handleExitButtonClick,
  parseQueryString,
  checkRightProblemOrderLoadingAndRedirect,
  utcToLocal
} from '../../utils/common';
import { addReadSpeaker } from '../../utils/readspeaker';
import { sendMessage } from '../../utils/iframe';
import showAlert from '../common/toaster';
import LevelSetLocationModal from '../reader/modals/levelSetLocation/container';
import { closeModal, openModal } from '../../actions/eventEmitter';

interface ReaderIProps { 
  isLocked?: boolean;
  classId?: number;
  currentProblemId?: any;
  currentStepId?: any;
  location?: any;
  isBenchmark?: any;
  isStudent?: any;
  levelSetLocation?: any;
  startDate?: any;
  endDate?: any;
  completedProblemIds: any;
  problemIds: any;
  fetchData?: ({ problemSetId, classId, problemIdFromUrl, callback }) => any;
  clearReaderState?: () => any;
  saveLevelSetLocation: (params: any) => any;
  getReaderNewUrlParams?: (assignmentId: number) => any;
  showStepAnswer: (stepId: number, problemSetId: number, classId: number) => any;
}

const Reader = (props: ReaderIProps) => {
  const { 
    currentProblemId, 
    currentStepId, 
    isLocked, 
    showStepAnswer, 
    location, 
    fetchData, 
    clearReaderState,
    isBenchmark,
    isStudent,
    levelSetLocation,
    saveLevelSetLocation,
    getReaderNewUrlParams
  } = props;
  const navigate = useNavigate();
  const { problemId, problemSetId, classId, assignmentId, reveal } = parseQueryString(location.search);
  const isRightProblemOrderLoading = 
  checkRightProblemOrderLoadingAndRedirect({ ...props, problemSetId, classId } as any);

  const checkAndShowLevelSetLocationPopup = () => {
    if (isBenchmark && isStudent && !levelSetLocation) {
      openModal(
        <LevelSetLocationModal saveLevelSetLocation={saveLevelSetLocation} />
      );
    }
  };

  useEffect((): any => {
    (async () => {
    // Redirect old reader url with assignmentId to new
      if (assignmentId) {
        const { problemSetId, classId } = await getReaderNewUrlParams(assignmentId);
        window.location.href = `mathreader?problemSetId=${problemSetId}&classId=${classId}`;
      }
      else {
        fetchData({
          problemSetId,
          classId,
          problemIdFromUrl: problemId,
          callback: fetchDataCallback,
        });

        addReadSpeaker();
      }
    })();
    return clearReaderState;
  }, []);

  useEffect(() => {
    const loadedWithoutProblemIdInUrl = location.search.indexOf('problemId') === -1;
    const newUrl = `/mathreader${location.search}&problemId=${currentProblemId}`;

    if (isLocked) {
      const { startDate, endDate } = props;
      const message =
        startDate && endDate
          ? `This assignment is accessible between ${utcToLocal(
            startDate
          )} - ${utcToLocal(endDate)}`
          : `This assignment is accessible after ${utcToLocal(startDate)}`;

      showAlert({ message: `${message}! Exiting now.`, isError: true });
      window.setTimeout(
        () => handleExitButtonClick({ classId: props.classId }),
        3000
      );
      return;
    }

    if (loadedWithoutProblemIdInUrl) {
      navigate(newUrl, { replace: true });
    }
    else {
      navigate(newUrl);
    }

    sendMessage('UPDATE_HASH', { path: newUrl });
    checkAndShowLevelSetLocationPopup();
  }, [currentProblemId]);

  useEffect(() => {
    if (problemId !== currentProblemId) {
      const newUrl = `/mathreader${location.search.replace(
        'problemId=' + problemId,
        'problemId=' + currentProblemId
      )}`;

      navigate(newUrl);
      sendMessage('UPDATE_HASH', { path: newUrl });
    }
  }, [problemId, currentProblemId]);

  if (assignmentId || !currentProblemId || isLocked || !isRightProblemOrderLoading) {
    return null;
  }
  else {
    return currentStepId ? (
      <>
        <StepHeader />
        <Step showStepAnswer={showStepAnswer} />
      </>
    ) : (
      <>
        <ProblemHeader revealBookTitle={reveal} />
        <Problem />
      </>
    );
  }
}

export default Reader;

const fetchDataCallback = (params: any) => {
  const {
    completedProblemIds,
    problemIds,
    classId,
    isBenchmark,
    problemSetName,
    isInvalidLevelSetAttempt,
  } = params;

  if (!isBenchmark) {
    if (isInvalidLevelSetAttempt) {
      openModal(
        <Modal
          title="Oops!"
          maxWidth="sm"
          okText="Exit"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
          cancelText=""
        >
          <p>
            A problem has occurred, notify your teacher to check for a message
            in their LevelSet report.
          </p>
        </Modal>
      );
    }
    else if (
      completedProblemIds &&
      completedProblemIds.length === problemIds.length
    ) {
      openModal(
        <Modal
          title="Awesome!"
          maxWidth="sm"
          okText="Exit"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
          cancelText=""
        >
          <p>You have completed {problemSetName}!</p>
        </Modal>
      );
    }
  }
};
