import React from 'react';
import styles from './styles.module.css';
import Button from '../../../common/button/component';
import UpdateStepProblemSetModal from '../../modals/updateStepProblemSet/updateStepProblemSet.container';
import { openModal } from '../../../../actions/eventEmitter';
import { RiExchangeFundsLine } from 'react-icons/ri';
import { Tooltip } from 'sharedfrontend';

const UpdateStepProblemSetButton = (props: any): JSX.Element => {
  return props.validRoles.includes(props.role)
    ? (
      <Button
        additionalStyles={styles.button}
        onClick={() => openModal(<UpdateStepProblemSetModal />)}
      >
        <Tooltip title="Change Problem Set">
          <span><RiExchangeFundsLine /></span>
        </Tooltip>
      </Button>
    )
    : <React.Fragment></React.Fragment>;
};

export default UpdateStepProblemSetButton;
