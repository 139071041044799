import { connect } from 'react-redux';
import UpdateProblemSetButton from './updateProblemProblemSetButton.component';

const mapStateToProps = (state: any) => {
  return {
    role: state.userData.role,
  }
};

export default connect(
  mapStateToProps,
)(UpdateProblemSetButton);