import Card from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';
import { CardMedia, CardContent, Typography } from '@mui/material';
import { updateHash } from '../../utils/iframe';

const useStyles = makeStyles({
  root: {
    minWidth: 280,
    margin: 20,
    cursor: 'pointer',
  },
  media: {
    height: '320px'
  },
});

const Tile = (props: { 
  title: string;
  src: string;
  titleSlug: string;
}) => {
  const openRevealBook = (titleSlug: string) => {
    updateHash({ path: `/reveal/${titleSlug}`, redirect: true });
  };

  const classes = useStyles();

  return (
    <Card 
      className={classes.root}
      onClick={() => openRevealBook(props.titleSlug)} 
      onKeyDown={() => openRevealBook(props.titleSlug)}
    >
      <CardMedia
        className={classes.media}
        image={props.src}
      />
      <CardContent>
        <Typography variant="h6" component="h6" align="center">
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  )
};

export default Tile;