import LearnzillionVideos from './component';
import { connect } from 'react-redux';
import { postData } from '../../../../actions/api';

const mapStateToProps = (state: any) => {
  return {
    problemSetId: state.problemSetData.problemSetId,
    classId: state.problemSetData.classId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    track: (params: any) => {
      dispatch(postData({
        type: 'TRACK_VIDEOS',
        params
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearnzillionVideos);
