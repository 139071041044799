export enum ClassSource {
  NATIVE = 'native',
  GOOGLE = 'google',
  LTI = 'lti',
  EDMODO = 'edmodo',
  CLEVER = 'clever',
  CANVAS = 'canvas',
  BRIGHTSPACE = 'brightspace',
  SCHOOLOGY = 'schoology',
  ONEROSTER = 'oneroster',
  ACHIEVE3K = 'achieve3k',
  OPENLEARNING = 'openlearning',
}

export enum ClassSettings {
  AUTO_ASSIGN_ASSIGNMENTS = 'autoAssignAssignments',
}

export enum TeacherGradeLevel {
  Grade2To3 = 1,
  Grade4To5,
  Grade6To8,
  Grade9To12,
  HigherEd,
  GradeKTo1,
}

export enum Role {
  STUDENT = 'student',
  TEACHER = 'teacher',
  SUPERADMIN = 'superadmin',
}

export enum ImplementationType {
  HINT = 'hint',
  PROBLEM = 'problem',
  STEP = 'step',
}

export enum ProblemType {
  ASSOCIATION = 'association',
  BLOCK = 'block',
  BLOCK_KEYBOARD = 'block-keyboard',
  BLOCK_ON_FOCUS_KEYBOARD = 'block-on-focus-keyboard',
  CHOICEMATRIX = 'choicematrix',
  CLASSIFICATION = 'classification',
  CLOZEASSOCIATION = 'clozeassociation',
  CLOZEDROPDOWN = 'clozedropdown',
  CLOZEFORMULA = 'clozeformula',
  CLOZETEXT = 'clozetext',
  FLOATING_KEYBOARD = 'floating-keyboard',
  FORMULAV2 = 'formulaV2',
  GRAPHPLOTTING = 'graphplotting',
  HIGHLIGHT = 'highlight',
  HORIZONTAL = 'horizontal',
  IMAGECLOZEASSOCIATION = 'imageclozeassociation',
  IMAGECLOZEDROPDOWN = 'imageclozedropdown',
  IMAGECLOZEFORMULA = 'imageclozeformula',
  IMAGECLOZETEXT = 'imageclozetext',
  INLINE = 'inline',
  LIST = 'list',
  MCQ = 'mcq',
  NUMBERLINE = 'numberline',
  NUMBERLINEPLOT = 'numberlineplot',
  ORDERLIST = 'orderlist',
  POINT = 'point',
  SEGMENT = 'segment',
  SHORTTEXT = 'shorttext',
  SIMPLECHART = 'simplechart',
  SIMPLESHADING = 'simpleshading',
  SORTLIST = 'sortlist',
  TABLE = 'table',
  TOKENHIGHLIGHT = 'tokenhighlight',
  VERTICAL = 'vertical',
}

export const StateMathSubjectGradeLevelMap: any = {
  'Grade 1': '1',
  'Grade 2': '2',
  'Grade 3': '3',
  'Grade 4': '4',
  'Grade 5': '5',
  'Grade 6': '6',
  'Grade 7': '7',
  'Grade 8': '8',
  'Grade 9': '9',
  'Grade 10': '10',
  'Grade 11': '11',
  'Pre-Algebra': '8',
  'Algebra 1': '9',
  'Algebra I': '9',
  'Mathematics I': '9',
  'Integrated Mathematics I': '9',
  'Core Math I': '9',
  'Secondary Mathematics I': '9',
  'Geometry': '10',
  'Mathematics II': '10',
  'Integrated Mathematics II': '10',
  'Core Math II': '10',
  'Secondary Mathematics II': '10',
  'Algebra 2': '11',
  'Algebra II': '11',
  'Mathematics III': '11',
  'Integrated Mathematics III': '11',
  'Core Math III': '11',
  'Secondary Mathematics III': '11',
  'Kindergarten': '14',
};

export const GradeLevelSetMap: any = {
  'Grade 1': 'B',
  'Grade 2': 'C',
  'Grade 3': 'D',
  'Grade 4': 'E',
  'Grade 5': 'F',
  'Grade 6': 'G',
  'Grade 7': 'H',
  'Grade 8': 'I',
  'Grade 9': 'J',
  'Grade 10': 'K',
  'Grade 11': 'L',
  'Pre-Algebra': 'I',
  'Algebra 1': 'J',
  'Algebra I': 'J',
  'Mathematics I': 'J',
  'Integrated Mathematics I': 'J',
  'Core Math I': 'J',
  'Secondary Mathematics I': 'J',
  'Geometry': 'K',
  'Mathematics II': 'K',
  'Integrated Mathematics II': 'K',
  'Core Math II': 'K',
  'Secondary Mathematics II': 'K',
  'Algebra 2': 'L',
  'Algebra II': 'L',
  'Mathematics III': 'L',
  'Integrated Mathematics III': 'L',
  'Core Math III': 'L',
  'Secondary Mathematics III': 'L',
  'Kindergarten': 'A',
};

export const LevelSetTimeDifference: any = {
  'MOY_EOY_START': 133,
  'MOY_END': 28,
  'EOY_END': 14
};

export enum LevelSet {
  BOY = 'boy',
  MOY = 'moy',
  EOY = 'eoy',
}

export enum AlertDuration {
  SHORT = 3000,
  MEDIUM = 4000,
  LONG = 5000,
}

export const BenchmarkRank: any = {
  BeginningOfYear: 'Beginning of Year',
  MiddleOfYear: 'Middle of Year',
  EndOfYear: 'End of Year',
};

export const ReportExport = {
  reportExportToolTipText: 'We are generating your report at the moment',
};

export const InfoText = {
  noDataText: 'Currently, there is no information. When students begin working, we will update this report.',
  noChartDataText: `Currently, there is no information applicable to your account for this chart.
    When students begin working, we will update this report.`,
};

export const PageView = {
  ASSIGNMENT: 'assignment',
  PREVIEW: 'preview',
  READER: 'reader',
  REVEAL: 'reveal',
};

export enum BenchmarkSource {
  LEVELSET = 'levelset',
  NWEA = 'nwea'
}
