import React from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '../tooltip/index';

const useStyles = makeStyles({
  horizontalBar: {
    display: 'flex',
    height: '40px',
    width: '100%'
  },
  barSegment: {
    overflow: 'hidden',
    padding: '20px 0',
    position: 'relative'
  },
  barSegmentLabel: {
    display: 'inline-block',
    fontWeight: 'bold',
    position: 'absolute',
    top: '50%',
    transform: 'translate(50%, -50%)',
  },
})

interface BarData {
  percentValue: string;
  tooltipLabel?: string;
}

interface HorizontalBarProps {
  data: BarData[];
  barColors: string[];
  labelColors?: string[];
  className?: string;
}

// if percentValue is 4% or less, don't show the label in the bar
const hidePercentLabelCutoff = 4;

const HorizontalBar: React.FunctionComponent<HorizontalBarProps> = (props) => {
  const { data, barColors, labelColors } = props;
  const styles = useStyles();

  const renderBarSections = () => {
    return data.map((bar: BarData, index) => {
      return (
        <Tooltip key={index} tooltipColor="blue" title={bar.tooltipLabel ? bar.tooltipLabel : bar.percentValue}>
          <span
            className={styles.barSegment}
            style={{
              width: bar.percentValue,
              background: barColors[index],
            }}
          >
            <span
              className={styles.barSegmentLabel}
              style={{
                color: labelColors ? labelColors[index] : '#fff',
              }}
            >
              {parseInt(bar.percentValue) <= hidePercentLabelCutoff ? '' : bar.percentValue}
            </span>
          </span>
        </Tooltip>
      )
    })
  }

  return (
    <div className={styles.horizontalBar}>
      {renderBarSections()}
    </div>
  );
};

export default HorizontalBar;
