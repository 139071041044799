import React from 'react';
import { Checkbox } from '../inputs';
import { Modal } from '../modal';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { SanitizedHtml }  from '../sanitizedHtml';
import { Fonts } from '../../helpers/constants';

const useStyles = makeStyles({
  checkbox: {
    margin: '5px 10px',
    display: 'flex',
    padding: '10px 0',
    alignItems: 'flex-start',
    '&:hover': {
      background: 'rgba(0,0,0,0.08)',
    },
    '& .MuiCheckbox-root': {
      padding: '0 9px 0 0',
    },
  },
  modal: {
    maxWidth: '900px !important',
    '& .modal-body': {
      marginTop: '10px !important',
    }
  },
  subHeading: {
    borderBottom: '1px solid rgba(0,0,0,0.3)',
    textTransform: 'uppercase',
    letterSpacing: 0.78,
    fontSize: 14,
    paddingBottom: 3,
    marginBottom: 15,
  },
  depth1: {
    color: '#0077D1',
    fontSize: 22,
    marginTop: 15,
    marginBottom: 10,
  },
  depth2: {
    marginLeft: 10,
  },
  depth3: {
    marginLeft: 20,
  },
  depth4: {
    marginLeft: 30,
  },
  depth5: {
    marginLeft: 40,
  },
  code: {
    width: 120,
    flexShrink: 0,
    marginRight: 15,
    wordWrap: 'break-word',
    fontFamily: Fonts.BOLD,
    color: '#37474F',
  },
  label: {
    display: 'flex',
    '@media (max-width: 480px)': {
      flexDirection: 'column',
    },
  },
});

interface IStandardsSelector {
  standards: any[];
  okCallback: Function;
  cancelCallback?: Function;
  selectedStandardIdsInSet: any[];
  title: string;
  hideModals: Function;
};

export default function StandardsSelector({
  standards,
  okCallback,
  cancelCallback,
  selectedStandardIdsInSet,
  title,
  hideModals,
}: IStandardsSelector) {
  const [selectedStandardIds, setSelectedStandardIds] = React.useState(selectedStandardIdsInSet);
  const styles = useStyles({});

  const handleStandardIdsChange = (standardId: number) => {
    let standardIdsToSet = [...selectedStandardIds];

    if (standardIdsToSet.includes(standardId)) {
      standardIdsToSet = standardIdsToSet.filter((x: number) => x !== standardId);
    }
    else {
      standardIdsToSet.push(standardId);
    }

    setSelectedStandardIds(standardIdsToSet);
  };

  const handleSubmit = () => {
    okCallback(selectedStandardIds);
    hideModals();
  };

  return (
    <Modal id="selectStandardDialog"
      title={title}
      okText="Done"
      cancelText="Cancel"
      okCallback={handleSubmit}
      cssClass={styles.modal}
      cancelCallback={cancelCallback}>
      {
        standards.map((standard: any) => {
          return standard.IsChildMost
            ? <Checkbox data-standardid={standard.StandardId}
              className={styles.checkbox + ' ' + styles['depth' + standard.Depth]}
              label={
                <div className={styles.label}>
                  <span className={styles.code}>{standard.StandardCode}</span>
                  <span>{standard.ShortText}</span>
                </div>
              }
              key={standard.StandardId}
              setChecked={() => handleStandardIdsChange(standard.StandardId)}
              checked={selectedStandardIds.includes(standard.StandardId)} />
            : <div key={standard.StandardId} className={
              classnames({
                [styles.subHeading]: standard.Depth !== 1,
                [styles[`depth${standard.Depth}`]]: true,
              })
            }>
              {standard.StandardCode}{!!standard.StandardCode && ' - '}
              <SanitizedHtml Tag="span">{standard.ShortText}</SanitizedHtml>
            </div>
        })
      }
    </Modal>
  );
}
