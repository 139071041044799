import React from 'react';
import styles from './styles.module.css';

interface LayoutProps {
  navFilter?: any;
  title?: string;
  children: any;
  headerClassName?: string;
}

const HeaderLayout = (props: LayoutProps) => {
  const { title, navFilter, children, headerClassName } = props;

  return (
    <header className={`${styles.header} ${headerClassName}`}>
      <div className={styles.headerWrapper}>
        {!!title && <h1 className={styles.mainTitle}>{title}</h1>}
        {!!navFilter && navFilter}
        <div className={styles.filters}>
          {children}
        </div>
      </div>
    </header>
  );
};

export default HeaderLayout;
