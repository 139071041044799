import { createTheme } from '@mui/material/styles';
import { colors } from './style';
import '../../src/colors.css';

const theme = createTheme({
  typography: {
    fontFamily: 'AvenirNext-Medium, "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      light: colors.blue,
      main: colors.darkBlue,
      dark: colors.grayBlue,
      contrastText: colors.white,
    },
  },
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderBottom: 'solid 1px var(--light-gray1)',
          fontFamily: 'AvenirNext-Medium',
          fontSize: 18,
          color: 'var(--dark-blue)',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: 'var(--dark-blue)',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: 'var(--dark-blue)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'var(--dark-blue)',
        },
      },
    }
  },
});

export default theme;