import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import Label from '../text/Label';

export interface LegendProps {
  className?: string,
  title?: string;
  items: Array<LegendItem>;
  variant?: string;
};

export interface LegendItem {
  color: string;
  label: string | JSX.Element;
}

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
  },
  color: {
    display: 'inline-block',
    height: 16,
    flexShrink: 0,
    marginRight: 10,
    width: 16
  },
  legendValues: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  },
  legendItem: {
    alignItems: 'center',
    display: 'flex',
    margin: '10px 25px 10px 0',

    '&:last-child': {
      marginRight: 0
    }
  },
  legendTitle: {
    fontSize: '13px',
    marginBottom: '10px',
    width: '100%',
  },
  stackedLegend: {
    boxShadow: '0px 2px 5px 0px rgba(130, 130, 130, 0.25)',
    display: 'inline-block',
    padding: '20px 25px',
    width: '100%',

    '& $legendTitle': {
      borderBottom: '1px solid #aaa',
      marginBottom: '20px',
      paddingBottom: '20px',
    },

    '& $legendValues': {
      alignItems: 'initial',
      flexDirection: 'column',
    },

    '& $legendItem': {
      marginBottom: '15px'
    }
  }
})

const Legend: React.FunctionComponent<LegendProps> = (props) => {
  const { className, title, items, variant } = props;
  const styles = useStyles();

  switch (variant) {
    case 'stacked': {
      return (
        <div className={classnames(styles.root, styles.stackedLegend, className)}>
          {title && <Label className={styles.legendTitle}>{title}</Label>}
          <div className={styles.legendValues}>
            {items.map((item, index) => {
              return (
                <div key={index} className={styles.legendItem}>
                  <span className={styles.color} style={{ backgroundColor: item.color }}></span>
                  <Label>{item.label}</Label>
                </div>
              )
            })}
          </div>
        </div>
      );
    }
    default: {
      return (
        <div className={classnames(styles.root, className)}>
          {title && <Label className={styles.legendTitle}>{title}</Label>}
          <div className={styles.legendValues}>
            {items.map((item, index) => {
              return (
                <div key={index} className={styles.legendItem}>
                  <span className={styles.color} style={{ backgroundColor: item.color }}></span>
                  <Label>{item.label}</Label>
                </div>
              )
            })}
          </div>
        </div>
      );
    }
  }
}

export default Legend;
