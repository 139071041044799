import React from 'react';
import * as mH from '../../../utils/data';
import styles from './styles.module.css';
import { Tooltip, ExternalLink } from 'sharedfrontend';
import { plural, truncate } from '../../../utils/common';
import { FaExternalLinkAlt } from 'react-icons/fa';
import config from '../../../config';

const ColoredMasteryPercentage = ({ mastery, numProblemsCompleted }: {
  mastery: number;
  numProblemsCompleted: number;
}): JSX.Element => {
  const percent: number = mH.percent(mastery, 1) || 0;
  // baselining at 3 problems completed to get red color
  const color: any = mH.getMasterySegment(percent, numProblemsCompleted > 3 ? numProblemsCompleted : 3).backgroundColor;

  return <span style={{ color, marginLeft: 2, marginRight: 5 }}>{percent}%</span>
};

const InterventionList = (props: any): JSX.Element => {
  const { interventions, showStudentName } = props;

  return (
    <React.Fragment>
      {interventions.map((data: any) => (
        <div
          key={data.interventionId}
          className={styles.interventionContainer}
        >
          <div className={styles.nameContainer}>
            <Tooltip title={data.standardCode || data.name} tooltipColor="blue">
              <div className={styles.name}>
                {truncate(data.interventionName, 100)}
                <ExternalLink
                  href={`${config.alFrontendUrl}/#/teacher/mathreader?problemSetId=${data.problemSetId}`}
                  className={styles.externalLink}
                >
                  <FaExternalLinkAlt />
                </ExternalLink>
                <div className={styles.secondaryInfo}>
                  {showStudentName &&
                    <div>{data.lastName + ', ' + data.firstName}</div>
                  }
                  <div>
                    Mastery: 
                    <ColoredMasteryPercentage
                      mastery={data.mastery}
                      numProblemsCompleted={data.numProblemsCompleted}
                    />
                    <React.Fragment>
                      ({plural(data.numProblemsCompleted, '%count problem', '%count problems')} completed)
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      ))
      }
    </React.Fragment>
  );
};

export default InterventionList;
