import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { masterySegments } from '../../utils/data';
import { ChartHeader, HorizontalBar, Tooltip } from 'sharedfrontend';
import { ReactTable, IHeaderData, IRowData } from 'sharedfrontend/src/components/tables/ReactTable';
import { InfoText } from '../../utils/constants';
import tableStyles from '../../styles/table.module.css';

const PAGE_SIZE = 8;
const masterySegmentsWithoutInsuffientData = masterySegments.concat();
masterySegmentsWithoutInsuffientData.shift();

interface BarData {
  percentValue: string;
  tooltipLabel: string;
}

const SkillsProgressTable = (props: any): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [sortByColumnName, setSortByColumnName] = useState<string>('name');
  const [sortByDirection, setSortByDirectione] = useState<string>('ASC');
  const {
    data,
    classId,
    selectedGradeLevel,
    startDate,
    endDate,
    selectedSchoolIds,
    benchmarkSource,
    fetchData,
  } = props;
  const pageCount = Math.ceil(data.numTotalProblemSets / PAGE_SIZE);

  useEffect(() => {
    fetchData({
      classId,
      gradeLevel: selectedGradeLevel,
      schoolIds: selectedSchoolIds,
      startDate,
      endDate,
      benchmarkSource,
      limit: PAGE_SIZE,
      offset,
      sortBy: { columnName: sortByColumnName, direction: sortByDirection },
    });

    setLoading(false);
  }, [
    classId,
    fetchData,
    selectedGradeLevel,
    selectedSchoolIds,
    startDate,
    endDate,
    benchmarkSource,
    offset,
    sortByColumnName,
    sortByDirection
  ]);

  const onTableChange = ({ pageIndex, sortBy }: { pageIndex: number; sortBy: any }) => {
    setOffset(pageIndex * PAGE_SIZE);
    setSortByColumnName(sortBy[0].id);
    setSortByDirectione(sortBy[0].desc ? 'DESC' : 'ASC');
  };

  const columns: IHeaderData[] = [
    {
      Header: 'Skills',
      accessor: 'name',
      minWidth: 300,
    },
    {
      Header: 'Percent of Students',
      accessor: 'barParts',
      minWidth: 500,
    },
  ];

  const tooltip = (
    <div className={styles.tooltip}>
      <p>
        The progress of your students can help you identify steps you can take to help them:
      </p>
      <ul>
        <li>
          <strong>Approaching:</strong> Let students continue to work on the problem set until they reach mastery.
        </li>
        <li>
          <strong>Developing:</strong> Look for pre-requisite skills to assign,
            these may help students fill gaps in their understanding. If no recommendations are given,
            check in with the students to identify their difficulties.
        </li>
        <li>
          <strong>Beginning:</strong> Review the student data to identify areas of difficulty and
            intervene one on one to correct misconceptions. Then assign any needed pre-requisite skills.
        </li>
      </ul>
    </div>
  );

  const legend = {
    items: masterySegmentsWithoutInsuffientData.map(x => ({ color: x.backgroundColor, label: x.label })),
  };

  const MasteryCell = ({ value }: { value: BarData[] }) => {
    return (
      <HorizontalBar
        data={value}
        barColors={Object.values(masterySegmentsWithoutInsuffientData.map(x => x.backgroundColor))}
        labelColors={Object.values(masterySegmentsWithoutInsuffientData.map(x => x.textColor))}
      />
    );
  };

  const cleanedData: IRowData[] = data.problemSets.map((problemSet: any) => ({
    rowContent: {
      name: {
        cellContent: (
          <Tooltip title={problemSet.standardCode} tooltipColor="blue">
            <span>{problemSet.name}</span>
          </Tooltip>
        ),
        className: styles.skillColumn + ' progressOverviewProblemSetName'
      },
      barParts: {
        cellContent: (
          <MasteryCell value={
            problemSet.groups.map((group: any) => ({
              percentValue: Math.round((group.numStudentsInGroup / problemSet.numTotalStudents) * 100) + '%',
              tooltipLabel: `${group.numStudentsInGroup} of ${problemSet.numTotalStudents} students`,
            }))
          }
          />
        ),
        className: 'progressOverviewBar'
      },
    },
  }));

  return (
    <React.Fragment>
      <ChartHeader
        title="Progress Overview"
        column={false}
        legend={legend}
        tooltip={tooltip}
      />
      <div className={styles.tableContainer}>
        <ReactTable
          columns={columns}
          data={cleanedData}
          onTableChange={onTableChange}
          loading={loading}
          loaderType="text"
          noDataText={InfoText.noDataText}
          paginationType="server"
          pageCount={pageCount}
          pageIndex={Math.floor(offset / PAGE_SIZE)}
          defaultSortBy={[{ id: sortByColumnName, desc: sortByDirection === 'DESC' }]}
          type="secondary"
          fixedHeader={false}
          ignoreHeightAdjust
          className={tableStyles.reactTable}
        />
      </div>
    </React.Fragment>
  );
};

export default SkillsProgressTable;