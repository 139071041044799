import { useState } from 'react';
import classnames from 'classnames';
import Cluster from './cluster';
import AssignButton from '../common/assignButton/container';
import ProblemSet from './problemSet';
import Button from '../common/button/component'
import { FaAngleRight, FaAngleDown, FaHeart } from 'react-icons/fa';
import { handleEnterKey } from '../../utils/common';
import { Role } from '../../utils/constants';
import styles from './styles.module.css';

const Strands = ({
  name,
  clusters,
  problemSets = [],
  isReveal = false,
  favoriteProblemSetIds = [],
  toggleFavorite = () => null,
}: {
  name: string;
  clusters?: any[];
  problemSets?: any[];
  isReveal?: boolean;
  favoriteProblemSetIds?: number[];
  toggleFavorite?: (data: { problemSetIds: number[]; isFavorited: boolean }) => void;
}): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const numSubStandards = clusters
    ? clusters.reduce((total, cluster) => total + cluster.problemSets.length, 0)
    : problemSets.length;

  let levelSetSeries, problemSetIds = [] as any;
  const hasLevelSet = clusters
    ? clusters.every(cluster => {
      if (cluster.problemSets[0]?.isBenchmark) {
        levelSetSeries = cluster.problemSets.filter(
          problemSet => problemSet.shouldShowInCatalog
        ).map((ps: any) =>({
          problemSetId: ps.problemSetId, name: ps.name, gradeLevel: ps.gradeLevel,
        }));
        problemSetIds = cluster.problemSets.map((ps: any) => ps.problemSetId);

        return true;
      }

      return false;
    })
    : false;

  const [isFavorite, setIsFavorite] = useState(favoriteProblemSetIds.includes(problemSetIds[0]));

  const favoriteClassName = classnames({
    [styles.redFavorite]: isFavorite,
  });

  const headerClassNames = classnames({
    [styles.conceptHeader]: true,
    [styles.isExpanded]: isExpanded,
  });

  const listClassNames = classnames({
    [styles.standardsList]: true,
    [styles.isExpanded]: isExpanded,
  });

  const handleToggleFavorite = async (e: any, problemSetIds: number[], isFavorited: boolean) => {
    e.stopPropagation();

    toggleFavorite({
      problemSetIds,
      isFavorited: !isFavorite,
    });

    setIsFavorite(isFavorited);
  }

  return (
    <div className={styles.conceptContainer}>
      <div
        className={headerClassNames}
        onClick={setIsExpanded.bind(null, !isExpanded)}
        onKeyDown={(e) => handleEnterKey(e, setIsExpanded.bind(null, !isExpanded))}
        role="button"
        tabIndex={0}
      >
        <div className={styles.conceptExpandArrow}>
          {isExpanded ? <FaAngleDown /> : <FaAngleRight />}
        </div>
        <div className={styles.conceptName}>{name}</div>
        <div className={styles.conceptCountBubble}>{numSubStandards}</div>
        {hasLevelSet &&
          <div className={styles.buttonContainerLevelSet}>
            <AssignButton
              problemSetName={name}
              problemSets={levelSetSeries}
              isBenchmark={true}
              validRoles={[Role.SUPERADMIN, Role.TEACHER]}
            />
            <Button 
              onClick={(e: any) => handleToggleFavorite(e, problemSetIds, !isFavorite)}
              additionalStyles={favoriteClassName}
            >
              <FaHeart />
            </Button>
          </div>
        }
      </div>
      <ul className={listClassNames}>
        {clusters && clusters.map((cluster: any) =>
          <Cluster
            key={name + cluster.name}
            {...cluster}
            toggleFavorite={toggleFavorite}
          />
        )}
        <div className={styles.listProblems}>
          {problemSets && problemSets.map((problemSet: any) =>
            <ProblemSet
              key={problemSet.name}
              {...problemSet}
              isReveal={isReveal}
              toggleFavorite={toggleFavorite}
            />
          )}
        </div>
      </ul>
    </div>
  );
};

export default Strands;