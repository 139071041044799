import React, { useState } from 'react';
import styles from './styles.module.css';
import { Modal } from 'sharedfrontend';
import { closeModal } from '../../../../actions/eventEmitter';
import VideoPlayer from '../../../common/videoPlayer/component';
import config from '../../../../config';

let playedVideoInIntervalTime = 0;

const SolutionVideoModal = (props: any): JSX.Element => {
  const { solutionVideoId, problemId, problemSetId } = props;
  const [intervalId, setIntervalId] = useState();
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleCloseModal = () => {
    const totalPlayedTime = playedSeconds + playedVideoInIntervalTime;
    track(totalPlayedTime);
    clearInterval(intervalId);
    closeModal();
  }

  const handleOnPlay = () => {
    const startTime = Date.now();
    const intervalId: any = setInterval(() => {
      playedVideoInIntervalTime = Math.round((Date.now() - startTime) / 1000);
    }, 1000);

    setIntervalId(intervalId);
  }

  const setVideoPlayedTime = () => {
    const totalPlayedTime = playedSeconds + playedVideoInIntervalTime;
    playedVideoInIntervalTime = 0;
    setPlayedSeconds(totalPlayedTime);
    clearInterval(intervalId);
  }

  const track = (timeSpentInSeconds: number) => {
    const percentageViewed = timeSpentInSeconds / duration;

    if (percentageViewed > 0) {
      props.track({
        event: 'percentageViewed',
        value: percentageViewed,
        timeSpentInSeconds,
        problemSetId,
        problemId,
      });
    }
  }
  
  return (
    <Modal
      title=""
      maxWidth={false}
      okText=""
      cancelText="Close"
      cancelCallback={handleCloseModal}
    >
      <div className={styles.videos}>
        <VideoPlayer
          url={`${config.achieve3000HostedVideoLink}/${solutionVideoId}.mp4`}
          controls
          setDuration={setDuration}
          handleOnPlay={handleOnPlay}
          setVideoPlayedTime={setVideoPlayedTime}
        />
      </div>
    </Modal>
  );
};

export default SolutionVideoModal;
