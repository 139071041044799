import React, { useEffect, useState } from 'react';
import ProgressBar from '../../../common/progressBar/component';

const MasteryProgressBar = (props: any): JSX.Element => {
  const [masteryPercentage, setMasteryPercentage] = useState(Math.round(props.mastery * 100));

  useEffect(() => {
    if (props.gradeFeedback && props.gradeFeedback.isCorrect) {
      const percentage = Math.round(props.mastery * 100);
      setMasteryPercentage(percentage);
    }
  }, [props.gradeFeedback, props.mastery]);

  if (props.validRoles.includes(props.role)) {
    return (
      <ProgressBar
        progressTitle="Concept Mastery"
        progressDisplay={`${masteryPercentage}%`}
        progressPercentage={masteryPercentage}
        isIntervention={props.isIntervention}
        showTooltip={props.showTooltip}
      />
    );
  }
  else {
    return <React.Fragment></React.Fragment>
  }
};

export default MasteryProgressBar;
