import React from 'react';
import { styled } from '@mui/system';
import { TabPanel as MuiTabPanel } from '@mui/lab';

interface TabPanelProps {
  value: string;
  children: React.ReactNode;
}

const StyledTabPanel = styled(MuiTabPanel)(({ theme }) => ({
  color: '#a1a1a1',
  padding: '20px 10px',
  [theme.breakpoints.up('sm')]: {
    padding: '24px',
  },
}));

const TabPanel: React.FC<TabPanelProps> = (props) => {
  return <StyledTabPanel {...props} />;
};

export default TabPanel;
