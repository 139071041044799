interface ReturnTypeIProps {
  type: string;
  body?: any;
}

export const assignedProblemSet = (problemSetId: number): ReturnTypeIProps => {
  return {
    type: 'ASSIGNED_PROBLEMSET',
    body: problemSetId,
  };
};

export const unAssignedProblemSet = (problemSetId: number): ReturnTypeIProps => {
  return {
    type: 'UNASSIGNED_PROBLEMSET',
    body: problemSetId,
  };
};

export const setAssignedProblemSets = (problemSetIds: number[]): ReturnTypeIProps => {
  return {
    type: 'SET_ASSIGNED_PROBLEMSETS',
    body: problemSetIds,
  };
};

export const setRevealBookTitleSlug = (bookTitleSlug: string): ReturnTypeIProps => {
  return {
    type: 'SET_REVEAL_BOOKTITLESLUG',
    body: bookTitleSlug,
  }
}