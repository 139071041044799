import React from 'react';
import Button from '../../../common/button/component';

const SubmitResponseButton = (props: any): JSX.Element => {
  const { validRoles, role, submitResponse, disableButton } = props;

  return validRoles.includes(role)
    ? <Button orangeType="filled" onClick={submitResponse} disabled={disableButton}>submit response</Button>
    : <></>;
};

export default SubmitResponseButton;
