import { IConfig } from './types';

const config: IConfig = {
  env: 'local',
  productName: 'Achieve3000 Math',
  backendUrl: 'http://localhost:5003',
  productUrl: 'http://localhost:5002',
  mathIframeApi: 'http://localhost:5000',
  mathIframeOrigin: 'http://localhost:5001',
  cookieDomain: 'localhost',
  csrfTokenKey: 'CSRF-TOKEN-LOCAL',
  isMath: true,
};

export default config;
