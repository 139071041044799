import { openAssignModal } from '../components/catalog/component';
import config from '../config';
import { getData } from '../actions/api';

export const inIframe = () => {
  try {
    return window.self !== window.top;
  }
  catch (e) {
    return true;
  }
}

export const sendMessage = (event: string, data?: any) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/top
  (window.top || window.parent).postMessage({ event, ...(data || {})}, config.alFrontendUrl);
};

export const startListener = (store: any) => {
  window.addEventListener('message', (event) => {
    if (event.origin !== config.alFrontendUrl) {
      return;
    }

    const body = { ...event.data, event: undefined };

    if (event.data.event === 'CHECK_FIRST_CLASS') {
      openAssignModal(body);
    }
    else if (event.data.event === 'FILTER_CATALOG') {
      let search = '';
      let grade = '';
      let hasSearched = false;
      let showingFavorites = false;
      const params: any = {};

      if (event.data.url.includes('search=') || event.data.url.includes('grade=')) {
        search = event.data.query.search || '';
        grade = event.data.query.grade;

        if (search) {
          params.searchTerm = search;
          hasSearched = true;
        }

        if (grade) {
          params.gradeLevel = grade;
        }
      }
      else if (event.data.url.includes('/favorites')) {
        showingFavorites = true;
      }
      else {
        grade = store.getState().catalogData.filters.defaultGrade;
        params.gradeLevel = grade;
      }

      store.dispatch({
        type: 'UPDATE_CATALOG_FILTERS',
        body: {
          search,
          grade,
          hasSearched,
          showingFavorites,
        },
      });

      store.dispatch(getData({
        type: 'CATALOG_DATA',
        params,
      }));
    }
    else {
      store.dispatch({
        type: 'FROM_AL_' + event.data.event,
        body,
      });
    }
  });
};

export const updateHash = ({
  path, redirect = false, addToHistory = false, params = null,
}: {
  path: string;
  redirect?: boolean;
  addToHistory?: boolean;
  params?: any;
}) => {
  if (inIframe()) {
    sendMessage('UPDATE_HASH', {
      path,
      redirect,
      addToHistory,
      params,
    });
  }
  else {
    window.location.assign(path);
  }
};