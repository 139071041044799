import DateRangePicker from './dateRangePicker.component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    startDate: state.classReportsDataFilter.startDate,
    endDate: state.classReportsDataFilter.endDate,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setDateRange: (datesObj: any) => {
      dispatch({
        type: 'DATE_RANGE_SELECTED',
        body: datesObj,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DateRangePicker);
