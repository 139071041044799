import React from 'react';
import Button from '../../common/button/component';
import showAlert from '../../common/toaster';
import tooltipStyles from '../../common/tooltipStyles.module.css';
import styles from './styles.module.css';
import {
  getMasterySegment,
  getStudentPercentInEachMasteryRange,
  masterySegments,
  percent,
} from '../../../utils/data';
import { getStudentDisplayName, handleEnterKey } from '../../../utils/common';
import { getDataPlain, postDataPlain } from '../../../actions/api';
import Loading from '../../common/loading/loading.component';
import AssignmentDropdown from '../../common/assignmentsDropdown/assignmentsDropdown.component';
import { FaInfoCircle, FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { updateHash } from '../../../utils/iframe';
import { colors } from '../../../utils/style';
import classNames from 'classnames';
import { Legend, Tooltip } from 'sharedfrontend';
import PieChart from 'sharedfrontend/src/components/dataViz/PieChart';
import { ReactTable, IHeaderData, IRowData, ICellData } from 'sharedfrontend/src/components/tables/ReactTable';
import { InfoText } from '../../../utils/constants';
import tableStyles from '../../../styles/table.module.css';

const ClassMasteryDataTable = (props: any): JSX.Element => {
  const { assignments, masteryData, problemSetId, isLoading } = props;

  const columns: IHeaderData[] = [{
    Header: 'Student Name',
    accessor: 'name',
    minWidth: 220,
  }];

  for (let i = 0; i < assignments.length; i++) {
    columns.push({
      Header: (assignments[i] as any).name,
      accessor: (assignments[i] as any).name.replace(/ /g, '_'),
      minWidth: 150,
    });
  }

  return (
    <div className={styles.tableContainer}>
      <ReactTable
        columns={columns}
        hiddenColumns={getHiddenColumns(assignments, columns, problemSetId)}
        data={masteryData}
        loading={isLoading}
        noDataText={InfoText.noDataText}
        defaultSortBy={[{ id: 'name', desc: false }]}
        gridLines
        type="secondary"
        ignoreHeightAdjust
        className={tableStyles.reactTable}
      />
    </div>
  );
};

const ClassMasteryData = (props: any): JSX.Element => {
  const { classId } = props;
  const [masteryData, setMasteryData] = React.useState([] as any[]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [assignments, setAssignments] = React.useState([]);
  const [problemSetId, setProblemSetId] = React.useState(null);
  const [showPieChart, setShowPieChart] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getDataPlain({
        type: 'CLASS_MASTERY_DATA',
        params: { classId }
      });

      setIsLoading(false);
      setAssignments(data.assignments);
      setMasteryData(data.masteryData);
    };

    fetchData();
  }, [classId]);

  const defaultAssignment = { problemSetId: null, name: 'All class assignments', standardCode: '' }; 
  const assignmentSortedByName = [...assignments].sort((a1: any, a2: any) => a1.name.localeCompare(a2.name));

  const onExport = () => {
    showAlert({ message: 'Your report will be emailed to you shortly.' });
    postDataPlain({
      type: 'CLASS_DATA_MASTERY_EXPORT',
      params: { classId, problemSetId: problemSetId || undefined  },
    });
  };

  return (
    <React.Fragment>
      {assignments.length > 0 &&
        <div className={styles.filterContainer}>
          <AssignmentDropdown
            assignments={[defaultAssignment, ...assignmentSortedByName]}
            selectedProblemSetId={problemSetId}
            onAssignmentSelection={setProblemSetId}
            className="assignmentSelector"
          />
          <Button
            orangeType="empty"
            onClick={onExport}
          >
            Export
          </Button>
        </div>
      }
      <div className={styles.legendContainer}>
        <Legend items={masterySegments.map(x => ({ color: x.backgroundColor, label: x.label }))} />
        <Tooltip title={legendTooltip} tooltipColor="blue">
          <div className={tooltipStyles.legendTooltip}>
            <FaInfoCircle style={{ color: colors.blue }} />
          </div>
        </Tooltip>
      </div>
      {isLoading
        ? <Loading />
        : (
          <ClassMasteryDataTable
            assignments={assignments}
            masteryData={prepareData({ masteryData, assignments, showPieChart, classId, setShowPieChart })}
            problemSetId={problemSetId}
            classId={classId}
            isLoading={isLoading}
          />
        )
      }
    </React.Fragment>
  );
};

export default ClassMasteryData;

const prepareData = (data: any) => {
  const { masteryData, assignments, showPieChart, classId, setShowPieChart } = data;

  if (masteryData.length === 0) {
    return [];
  }

  const expandIcon = showPieChart
    ? <FaCaretDown className={styles.expandIcon} />
    : <FaCaretRight className={styles.expandIcon} />;

  const classRowData: { [key: string]: ICellData } = {
    name: {
      cellContent: <span>Class {expandIcon}</span>,
      sortValue: null,
    }
  };

  const classRowContent = assignments.reduce((rowData: any, assignment: any) => {
    rowData[assignment.name.replace(/ /g, '_')] = {
      cellContent: ClassCell(getPieChartData(assignment.pieChartData), showPieChart),
      sortValue: null,
    }

    return rowData;
  }, classRowData);

  const studentsRowContent: IRowData[] = masteryData.map((mData: any) => {
    const studentName = getStudentDisplayName(mData.firstName, mData.lastName);
    const rowData: any = {
      name: {
        cellContent: StudentNameCell({...mData, name: studentName }, classId),
        sortValue: studentName.toLowerCase(),
      }
    };

    const rowContent = assignments.reduce((data: any, assignment: any) => {
      const currentAssignmentMastery  = mData.mastery.find(
        (m: any) => m.problemSetId === assignment.problemSetId
      );

      data[assignment.name.replace(/ /g, '_')] = {
        cellContent: MasteryCell(currentAssignmentMastery),
        sortValue: currentAssignmentMastery.numProblemsCompleted > 2
          ? getSortValue(currentAssignmentMastery)
          : currentAssignmentMastery.numProblemsCompleted
      };

      return data;
    }, rowData);

    return { rowContent };
  });

  return [{
    rowContent: classRowContent,
    className: styles.expandingRow,
    onClick: () => setShowPieChart(!showPieChart),
  }, ...studentsRowContent];
};

const getPieChartData = (pieChartData: any) => {
  return {
    data: getStudentPercentInEachMasteryRange(pieChartData),
    labels: masterySegments.map(x => x.label),
    colors: masterySegments.map(x => x.backgroundColor),
    additionalOptions: {
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        datalabels: { display: false },
        labels: {
          fontColor: 'transparent'
        },
      }
    },
    tooltipOptionOverrides: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const label = `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}%`;
          return label;
        }
      }
    }
  };
};

const getSortValue = (data: any) => {
  if (data.isAssigned === false) {
    return -1;
  }
  else if (data.numProblemsCompleted > 2 && data.mastery !== null) {
    return data.mastery;
  }
  else {
    return 0;
  }
};

const getHiddenColumns = (assignments: any, columns: any, problemSetId: string) => {
  const hiddenAssignmentNames = problemSetId
    ? assignments.filter((x: any) => x.problemSetId !== problemSetId).map((assignment: any) => assignment.name)
    : assignments.filter((x: any) => x.isAssignedIndividually).map((assignment: any) => assignment.name);

  return columns
    .filter((column: any) => hiddenAssignmentNames.includes(column.Header))
    .map((column: any) => column.Header.replace(/ /g, '_'));
};

const MasteryCell: React.FunctionComponent<any> = (props: any): JSX.Element => {
  const { mastery, numProblemsCompleted, isAssigned } = props;

  if (!isAssigned) {
    return <div className={styles.masteryCell}>N/A</div>;
  }

  const value: any = percent(mastery, 1);
  const { backgroundColor } = getMasterySegment(value, numProblemsCompleted);
  const hasMastery = numProblemsCompleted > 2 && mastery !== null;

  const toolTipText = `${hasMastery ? 'Mastery % ' : ''}(# problems solved)`;

  return (
    <Tooltip title={toolTipText} tooltipColor="blue">
      <div className={styles.masteryCell} style={{ color: backgroundColor }}>
        {hasMastery && <span>{value}% </span>}
        <span>({numProblemsCompleted})</span>
      </div>
    </Tooltip>
  );
};

const ClassCell: React.FunctionComponent<any> = (chartProps: any, showPieChart: boolean): JSX.Element => {
  const masteryPercentage = chartProps.data[chartProps.data.length - 1];
  const classes = classNames({
    [styles.masteryCell]: true,
    [styles.classCell]: true,
  });

  return (
    <div className={classes}>
      <span>{`${masteryPercentage}% in mastery`}</span>
      {showPieChart &&
        <div className={styles.pieChartContainer}>
          <PieChart config={chartProps} />
        </div>
      }
    </div>
  );
}

const StudentNameCell: React.FunctionComponent<any> = (props: any, classId: number): JSX.Element => {
  const { userId, name, quantileMeasure } = props;
  const path = `/classes/${classId}/studentdata/mastery/?studentuserid=${userId}`; 

  return (
    <Tooltip title={quantileMeasure || ''} tooltipColor="blue">
      <span
        onClick={() => updateHash({ path, redirect: true })}
        onKeyDown={(e) => handleEnterKey(e, updateHash({ path, redirect: true }))}
        className={styles.studentNameLink}
        role="button"
        tabIndex={0}
      >
        {name}
      </span>
    </Tooltip>
  );
};

const legendTooltip = (
  <div className={tooltipStyles.legendTooltipInner}>
    <p>
      The levels of your student can help you identify what steps you can take to help them:
    </p>
    <ul>
      <li>
        <strong>Mastery:</strong> Student no longer needs to practice this skill.
      </li>
      <li>
        <strong>Approaching:</strong> Let student continue to work on the problem set.
      </li>
      <li>
        <strong>Developing:</strong> Look for pre-requisites to assign that can help a student fill gaps.
          If no pre-requisites are recommended, have students continue to work in problem set.
      </li>
      <li>
        <strong>Beginning:</strong> Go to student data and see the type of problems they are getting wrong to
          intervene one on one.
      </li>
      <li>
        <strong>Insufficient Data:</strong> Student has completed fewer than three problems.
      </li>
    </ul>
  </div>
);