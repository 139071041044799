import React from 'react';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { makeStyles } from '@mui/styles';
import DisabledDatePicker from './DisabledDatePicker';

const useStyles = makeStyles((theme) => ({
  fromToWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 5,

    '& > *': {
      margin: '0 5px'
    },

    '@media (min-width: 768px) and (max-width: 1200px)': {
      '&.reportDateFilterPicker': {
        gridTemplateColumns: '1fr',
        '& > div:first-child': {
          marginBottom: '10px'
        }
      }
    }
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  }
}));

interface IFromToDayPickersProps {
  fromLabel: string;
  toLabel: string;
  fromOnChange: Function;
  toOnChange: Function;
  selectedDates: {
    start: Dayjs;
    end: Dayjs;
  };
  className?: string;
}

export const FromToDayPickers: React.FunctionComponent<IFromToDayPickersProps> = (props) => {
  const {
    fromLabel = 'From',
    toLabel = 'To',
    fromOnChange,
    toOnChange,
    selectedDates,
    className,
  } = props;
  const styles = useStyles();

  return (
    <div className={classnames('fromToWrapper', styles.fromToWrapper, className)}>
      <DisabledDatePicker
        label={fromLabel}
        selectedDate={selectedDates.start}
        onChange={fromOnChange}
        minDate={dayjs('07-28-18')}
        maxDate={dayjs(selectedDates.end)}
      />
      <DisabledDatePicker
        label={toLabel}
        selectedDate={selectedDates.end}
        onChange={toOnChange}
        minDate={dayjs(selectedDates.start)}
        maxDate={dayjs()}
      />
    </div> 
  );
};
