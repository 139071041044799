import { Tooltip } from 'sharedfrontend';
import React from 'react';
import { RiExchangeFundsLine } from 'react-icons/ri';
import { openModal } from '../../../../actions/eventEmitter';
import UpdateProblemProblemSetModal from '../../modals/updateProblemProblemSet/updateProblemProblemSet.container';
import styles from './styles.module.css';
import Button from '../../../common/button/component';

const UpdateProblemProblemSetButton = (props: any): JSX.Element => {
  return props.validRoles.includes(props.role)
    ? (
      <Button
        additionalStyles={styles.button}
        onClick={() => openModal(<UpdateProblemProblemSetModal />)}
      >
        <Tooltip title="Change Problem Set">
          <span><RiExchangeFundsLine /></span>
        </Tooltip>
      </Button>
    )
    : <></>;
};

export default UpdateProblemProblemSetButton;