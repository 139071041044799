import React, { useEffect, useRef } from 'react';
import { Button } from '../button';
import { showAlert } from '../alert';
import styles from './styles.module.css';
import ClipboardJS from 'clipboard';
import ClipboardIcon from '../../icons/copyToClipboard.svg';

interface Props {
  callback: Function;
  onClick: Function;
  children?: React.ReactNode;
  clipboardText: string;
  id: string;
  iconSize?: number;
  title?: string;
  [key: string]: any;
}

function CopyToClipboardButton({
  callback,
  onClick,
  iconSize = 18,
  title = 'Copy link to clipboard',
  // @ts-ignore
  children = <ClipboardIcon width={iconSize} height={iconSize} role="presentation" />,
  clipboardText,
  id,
  ...otherProps
}: Props): JSX.Element {
  const inputRef: any = useRef();

  useEffect(() => {
    let clipboardInstance;

    if (inputRef.current) {
      clipboardInstance = new ClipboardJS(`#${id}`, { target: () => inputRef.current });
      clipboardInstance.on('success', callback);

      clipboardInstance.on('error', (e) => {
        showAlert({
          message: 'Please press CTRL+C and try again',
        });
      });
    }

    return () => clipboardInstance?.destroy();
  }, [inputRef]);

  return (
    <>
      <input aria-hidden ref={inputRef} tabIndex={-1} className={styles.clipboardTextInput} value={clipboardText} />
      <Button variant="text" id={id} onClick={onClick} title={title} aria-label={title} {...otherProps}>
        {children}
      </Button>
    </>
  );
}

export default CopyToClipboardButton;
