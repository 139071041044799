import produce from 'immer';
import {
  getCurrentSchoolYearDatesAsObj,
  getCurrentSchoolYearDatesAsString,
  getLevelSetSeries,
  getNWEAAssesmentAdaptiveTestDates,
  getTimeFromString,
  groupObjectArrayByKey,
  uniqArray,
} from '../utils/common';
import { isWithinInterval } from 'date-fns';
import { BenchmarkRank } from '../utils/constants';

const {
  startDate: adminReportStartDate,
  endDate: adminReportEndDate,
} = getCurrentSchoolYearDatesAsString();

const initialState = {
  schools: [],
  selectedGradeLevel: undefined,
  selectedSchoolIds: undefined,
  hasNweaSchool: false,
  hasLevelsetSchool: false,
  adminReportStartDate,
  adminReportEndDate,
  usageData: null,
  benchmarkData: null,
  forecastData: null,
  progressOverviewData: { numTotalProblemSets: 0, problemSets: [] },
  dateFilter: {
    startDate: adminReportStartDate,
    endDate: adminReportEndDate,
  }
};

const reducer: any = produce((draft: any, action: any) => {
  switch (action.type) {
  case 'RESOLVE_ADMIN_REPORT_KEY_USAGE_DATA': {
    Object.assign(draft, { usageData: action.body });
    break;
  }
  case 'RESOLVE_ADMIN_REPORT_QUANTILE_BENCHMARKS_DATA': {
    const {
      benchmarkResults,
      numRosteredStudents,
      bandNames,
      benchmarkSource,
    } = action.body;

    // filter out previous assessments
    const { startDate, endDate } = getCurrentSchoolYearDatesAsObj();
    const filteredBenchmarkResults = benchmarkResults.filter((x: any) =>
      isWithinInterval(new Date(x.lastUpdatedDate), { start: startDate, end: endDate })
    );

    // select latest assesment for a student for a given school year
    const benchmarks: any = [];
    let latestFilteredBenchmarkResults: any[] = [];

    const filteredBenchmarkResultsGroupByStudentId = groupObjectArrayByKey(filteredBenchmarkResults, 'studentUserId');

    Object.values(filteredBenchmarkResultsGroupByStudentId).forEach((assesmentsForStudent: any) => {
      let latestAssesment = assesmentsForStudent.length > 0 && assesmentsForStudent[0];
      assesmentsForStudent.forEach((x: any) => {
        if (getTimeFromString(x.lastUpdatedDate) > getTimeFromString(latestAssesment.lastUpdatedDate)) {
          latestAssesment = x;
        }
      });
      assesmentsForStudent.forEach((x: any) => {
        if (getLevelSetSeries(x.benchmarkName) === getLevelSetSeries(latestAssesment.benchmarkName)) {
          latestFilteredBenchmarkResults.push(x);
        }
      });
    });

    latestFilteredBenchmarkResults = latestFilteredBenchmarkResults.filter((x: any) => x.quantileMeasure);

    const boyData: any = [];
    const moyData: any = [];
    const eoyData: any = [];
    const { BOY, MOY, EOY } = getNWEAAssesmentAdaptiveTestDates();

    latestFilteredBenchmarkResults.forEach((benchmarkData: any) => {
      const { benchmarkName, lastUpdatedDate } = benchmarkData;
      const assesmentUpdateDate = getTimeFromString(lastUpdatedDate);
      const isBenchmarkSourceNWEA = benchmarkSource === 'nwea';

      if (
        benchmarkName.includes(BenchmarkRank.BeginningOfYear) ||
        (isBenchmarkSourceNWEA && isWithinInterval(
          assesmentUpdateDate, { start: BOY.startDate, end: BOY.endDate }
        ))
      ) {
        boyData.push(benchmarkData);
      }
      else if (
        benchmarkName.includes(BenchmarkRank.MiddleOfYear) ||
        (isBenchmarkSourceNWEA && isWithinInterval(
          assesmentUpdateDate, { start: MOY.startDate, end: MOY.endDate }
        ))
      ) {
        moyData.push(benchmarkData);
      }
      else if (
        benchmarkName.includes(BenchmarkRank.EndOfYear) ||
        (isBenchmarkSourceNWEA && isWithinInterval(
          assesmentUpdateDate, { start: EOY.startDate, end: EOY.endDate }
        ))
      ) {
        eoyData.push(benchmarkData);
      }
    });

    const filteredQuantileData = {
      [BenchmarkRank.BeginningOfYear]: boyData,
      [BenchmarkRank.MiddleOfYear]: moyData,
      [BenchmarkRank.EndOfYear]: eoyData
    }

    Object.keys(BenchmarkRank).forEach((currentRank) => {
      const levels = [];

      for (let j = 0; j < bandNames.length; j++) {
        levels.push({
          name: bandNames[j],
          numStudentsInGroup: filteredQuantileData[BenchmarkRank[currentRank]].filter((x: any) => 
            x.performanceStandardBand === (j + 1)).length,
        });
      }

      benchmarks.push({
        name: BenchmarkRank[currentRank],
        numStudentsThatTookThisBenchmark: filteredQuantileData[BenchmarkRank[currentRank]].length,
        levels,
      });
    }
    )

    const benchmarkData = {
      numGradedStudents: uniqArray(latestFilteredBenchmarkResults.map((x: any) => x.studentUserId)).length,
      numRosteredStudents,
      benchmarks,
      benchmarkSource,
    };

    Object.assign(draft, { benchmarkData });
    break;
  }
  case 'RESOLVE_ADMIN_REPORT_STATE_FORECAST_DATA': {
    Object.assign(draft, { forecastData: action.body });
    break;
  }
  case 'RESOLVE_ADMIN_REPORT_PROGRESS_OVERVIEW_DATA': {
    Object.assign(draft, { progressOverviewData: action.body });
    break;
  }
  case 'SET_REPORT_GRADE_LEVEL': {
    Object.assign(draft, {
      selectedGradeLevel: action.body,
      usageData: null,
      benchmarkData: null,
      forecastData: null,
    });
    break;
  }
  case 'SET_REPORT_SCHOOLS': {
    const { schoolIds, benchmarkSourceFilter } = action.body;
    Object.assign(draft, {
      selectedSchoolIds: schoolIds,
      usageData: null,
      benchmarkData: null,
      forecastData: null,
      benchmarkSourceFilter,
    });
    break;
  }
  case 'FROM_AL_GET_SCHOOL_INFO': {
    draft.schools = action.body.schools;
    // If T is part of only one school - set that as the selected schoolId
    if (action.body.schools.length === 1) {
      draft.selectedSchoolIds = [action.body.schools[0].SchoolId];
    }

    draft.hasNweaSchool = action.body.schools.some((s: any) =>
      s.EnabledFeatures && s.EnabledFeatures.includes('nweaintegrationmath'));
    draft.hasLevelsetSchool = action.body.schools.some((s: any) =>
      s.EnabledFeatures && !s.EnabledFeatures.includes('nweaintegrationmath'));
    if (draft.hasNweaSchool) {
      draft.benchmarkSourceFilter = 'nwea';
    }
    else {
      draft.benchmarkSourceFilter = 'levelset';
    }
    break;
  }
  case 'ADMIN_DATE_RANGE_SELECTED': {
    Object.assign(draft, {
      dateFilter: action.body,
      usageData: null,
      benchmarkData: null,
      forecastData: null,
    });
    break;
  }
  case 'SET_REPORT_BENCHMARK_SOURCE': {
    Object.assign(draft, {
      benchmarkSourceFilter: action.body,
      usageData: null,
      benchmarkData: null,
      forecastData: null,
    });
    break;
  }
  }
}, initialState);

export default reducer;
