import React from 'react';
import { Role } from '../../../../utils/constants';
import Button from '../../../common/button/component';

const GoToFirstStepButton = (props: any): JSX.Element => {
  const [isDisabled, setDisabled] = React.useState(props.disableButton);

  const buttonText = props.ageRange === 'younger' && props.forSkipping ? 'ask for help' : 'take it step by step';
  const onClick = props.forSkipping && props.role === Role.STUDENT
    ? props.skipToSteps.bind(null, props.problemId, props.firstStepId, props.problemSetId, props.classId)
    : props.goToStep.bind(null, props.firstStepId);
  
  React.useEffect(() => {
    setDisabled(props.disableButton);
  }, [props.disableButton]);

  return (
    <Button
      orangeType={props.forSkipping ? 'empty' : 'filled'}
      onClick={() => {
        onClick();
        setDisabled(true);
      }}
      disabled={isDisabled}
    >
      {buttonText}
    </Button>
  );
};

export default GoToFirstStepButton;
