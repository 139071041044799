import { connect } from 'react-redux';
import UsageTable from './component';

const mapStateToProps = (state: any) => {
  return {
    startDate: state.classReportsDataFilter.startDate,
    endDate: state.classReportsDataFilter.endDate,
  };
};

export default connect(
  mapStateToProps,
)(UsageTable);
