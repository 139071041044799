import React from 'react';
import styles from './styles.module.css';
import AssignmentDropdown from '../../common/assignmentsDropdown/assignmentsDropdown.component';
import DateRangePicker from '../../common/dateRangePicker/dateRangePicker.container';
import { utcToLocal, getFormattedTime, getStudentDisplayName } from '../../../utils/common';
import { getDataPlain } from '../../../actions/api';
import Loading from '../../common/loading/loading.component';
import Button from '../../common/button/component';
import { FaPrint } from 'react-icons/fa';
import { ReactTable, IHeaderData, IRowData } from 'sharedfrontend/src/components/tables/ReactTable';
import { InfoText } from '../../../utils/constants';
import tableStyles from '../../../styles/table.module.css';

const defaultAssignment = { problemSetId: null, name: 'All assignments', standardCode: '' }; 

const columns: IHeaderData[] = [
  {
    Header: 'Student Name',
    accessor: 'name',
    width: 100,
  },
  {
    Header: 'Last Logged In',
    accessor: 'lastSignInDate',
    width: 80,
  },
  {
    Header: 'Time Spent',
    accessor: 'timeSpent',
    width: 60,
  },
  {
    Header: 'Instructional Videos',
    accessor: 'instructionVideosWatched',
    width: 60,
  },
  {
    Header: 'Hints Viewed',
    accessor: 'hintsViewed',
    width: 60,
  },
  {
    Header: 'Solutions Watched',
    accessor: 'solutionVideosWatched',
    width: 60,
  },
  {
    Header: 'Assigned',
    accessor: 'assigned',
    width: 60,
  },
  {
    Header: 'Mastered',
    accessor: 'mastery',
    width: 60,
  },
  {
    Header: 'Problems Solved',
    accessor: 'problemsCompleted',
    width: 60,
  },
];

const ClassUsageTable = (props: any): JSX.Element => {
  const { classId, startDate, endDate } = props;
  const [problemSetId, setProblemSetId] = React.useState(null);
  const [usageData, setUsageData] = React.useState<IRowData[]>([]);
  const [assignments, setAssignments] = React.useState([defaultAssignment]);
  const [fetchingData, setFetchingData] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async (params: any) => {
      setFetchingData(true);
      const data = await getDataPlain({ type: 'CLASS_USAGE_DATA', params });
  
      const assignments: any = [defaultAssignment, ...data.assignments];
  
      setFetchingData(false);
      setAssignments(assignments);
      setUsageData(prepareData(data.usageData));
    };

    fetchData({ classId, startDate, endDate, problemSetId: problemSetId || undefined });
  }, [classId, startDate, endDate, problemSetId]);

  const onAssignmentSelect = (problemSetId: any) => {
    setProblemSetId(problemSetId);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <Button iconSize="small" additionalStyles={styles.hideForPrint} onClick={window.print}><FaPrint /></Button>
        <div className={styles.filterContainer}>
          <AssignmentDropdown
            assignments={assignments}
            selectedProblemSetId={problemSetId}
            onAssignmentSelection={onAssignmentSelect}
            className="mr-lg assignmentSelector"
          />
          <DateRangePicker />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {!fetchingData &&
          <ReactTable
            columns={columns}
            data={usageData}
            loading={fetchingData}
            noDataText={InfoText.noDataText}
            defaultSortBy={[{ id: 'name', desc: false }]}
            gridLines
            type="secondary"
            ignoreHeightAdjust
            fixedHeader={false}
            className={tableStyles.reactTable}
          />
        }
        {fetchingData && <Loading />}
      </div>
    </React.Fragment>
  );
};

export default ClassUsageTable;

const prepareData = (usageData: any[]): IRowData[] => {
  const preparedData: IRowData[] = [];

  for (const studentData of usageData) {
    const studentDisplayName = getStudentDisplayName(studentData.firstName, studentData.lastName);

    preparedData.push({
      rowContent: {
        name: {
          cellContent: studentDisplayName,
          sortValue: studentDisplayName.toLowerCase(),
        },
        lastSignInDate: {
          cellContent: utcToLocal(studentData.lastSignInDate, false),
          sortValue: +new Date(studentData.lastSignInDate)
        },
        timeSpent: {
          cellContent: getFormattedTime(studentData.timeSpentInSeconds, true),
          sortValue: studentData.timeSpentInSeconds,
        },
        solutionVideosWatched: {
          cellContent: studentData.numSolutionVideoWatched,
          sortValue: studentData.numSolutionVideoWatched,
        },
        instructionVideosWatched: {
          cellContent: studentData.numInstructionVideoWatched,
          sortValue: studentData.numInstructionVideoWatched,
        },
        hintsViewed: {
          cellContent: studentData.numHintsViewed,
          sortValue: studentData.numHintsViewed,
        },
        assigned: {
          cellContent: studentData.numProblemSetsAssigned,
          sortValue: studentData.numProblemSetsAssigned,
        },
        mastery: {
          cellContent: studentData.numMasteredProblemSets,
          sortValue: studentData.numMasteredProblemSets,
        },
        problemsCompleted: {
          cellContent: studentData.numProblemsCompleted,
          sortValue: studentData.numProblemsCompleted,
        },
      },
    });
  }

  return preparedData;
};
