import React from 'react';
import { Tooltip } from 'sharedfrontend';
import WelcomeCard from './welcomeCard.component';
import { plural, secondsToFriendlyFormat } from '../../utils/common';
import styles from './styles.module.css';

interface IProps {
  masteredCounts: any;
  studentFirstName: string;
  assignment: any;
  assignmentsCount: number;
  timeSpentLastWeekInSeconds: number;
}

const WelcomeSection = (props: IProps) => {
  const {
    masteredCounts,
    studentFirstName,
    assignment,
    assignmentsCount,
    timeSpentLastWeekInSeconds,
  } = props;

  const tooltipText = (
    <div>
      <span>{`${masteredCounts.assigned} Assigned Skills`}</span>
      <br/>
      <span>{`${masteredCounts.prereq} Prerequisite Skills`}</span>
    </div>
  );

  return (
    <div className={styles.welcomeSection}>
      <div className={styles.welcomeMessageContainer}>
        <div className={styles.welcomeMessage}>
          {`Welcome, ${studentFirstName}!`}
        </div>
        {assignmentsCount > 0 &&
          <div className={styles.welcomeInfoMessage}>
            You have <Tooltip title={tooltipText} tooltipColor="blue">
              <span className={styles.welcomeInfo}>
                {plural(masteredCounts.assigned + masteredCounts.prereq, '%count skill', '%count skills')}
              </span>
            </Tooltip> mastered!
          </div>
        }
        {timeSpentLastWeekInSeconds >= 0 &&
          <div className={styles.welcomeInfoMessage}>
            You have spent <span className={styles.welcomeInfo}>
              {secondsToFriendlyFormat(timeSpentLastWeekInSeconds, false)}
            </span> in Achieve3000 Math this week!
          </div>
        }
      </div>
      {assignmentsCount > 0 && !!assignment &&
        <WelcomeCard assignment={assignment} />
      }
      {assignmentsCount > 0 && !assignment &&
        <div className={styles.completeStateContainer}>
          <div className={styles.completeStateTextContainer}>
            <div className={styles.completeStateTitle}>Congratulations! You have completed all assigned work.</div>
            <div className={styles.completeStateText}>
              Assigned new skills will appear on this page.
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default WelcomeSection;
