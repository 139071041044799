import React, { useEffect } from 'react';
import { SwitchTabs as TabSwitch } from 'sharedfrontend';
import ClassDataMastery from './mastery/masteryTable.container';
import ClassDataUsage from './usage/container';
import ClassDataBenchmarkTable from './quantile/benchmarkTable.container';
import AutoAssignAssignmentToggle from './autoAssignAssignments.component';
import Interventions from '../interventions/interventions.container';
import QuantileBenchmarksChart from '../admin/quantileBenchmarksChart.container';
import { getDataPlain } from '../../actions/api';
import Button from '../common/button/component';
import SyncMasteryModal from '../common/modals/syncMastery/container';
import { sendMessage, updateHash } from '../../utils/iframe';
import { ClassSource } from '../../utils/constants';
import { openModal } from '../../actions/eventEmitter';
import styles from './styles.module.css';

const tabs = [
  { label: 'Mastery Progress', key: 'mastery' },
  { label: 'Prerequisites', key: 'prerequisites' },
  { label: 'Activity', key: 'activity' },
  { label: 'Quantile®', key: 'quantile' },
];

interface IState {
  selectedTab: string;
  externalSyncedAssignments: Array<ExternalAssignment>;
  classInfo: any;
}

interface ExternalAssignment {
  problemSetId: number;
  name: string;
  source: string;
}

const ClassData = (props: any): JSX.Element => {
  const { classes, ltiAccountSource, students, params } = props;
  const [selectedTab, setSelectedTab] = React.useState(params.tabName || tabs[0].key);
  const [externalSyncedAssignments, setExternalSyncedAssignments] = React.useState([]);
  const classId = parseInt(params.classId);
  const classInfo = classes.find((x: any) => x.classId === classId);

  useEffect(() => {
    if (ltiAccountSource && ltiAccountSource.length > 0) {
      fetchExternalAssignments();
    }

    if (!classInfo) {
      props.getClasses();
    }
  // eslint-disable-next-line
  }, []);

  const fetchExternalAssignments = async () => {
    const externalSyncedAssignments: ExternalAssignment[] = await getDataPlain({
      type: 'CLASS_EXTERNAL_SYNCED_ASSIGNMENTS',
      params: { classId }
    });

    setExternalSyncedAssignments(externalSyncedAssignments);
  }

  const handleTabChange = (newTab: string) => {
    if (selectedTab !== newTab) {
      setSelectedTab(newTab);
      updateHash({
        path: `/classes/${classId}/classdata/${newTab}`,
        params: { subTab: newTab },
      });
    }
  }

  const renderSubTab = () => {
    switch (selectedTab) {
    case tabs[1].key:
      return (
        <Interventions classId={classId} viewBy="student" canFlip={true} />
      );
    case tabs[2].key:
      return <ClassDataUsage classId={classId} />;
    case tabs[3].key:
      return (
        <>
          <div className={styles.hideForPrint}>
            <AutoAssignAssignmentToggle classId={classId} />
            <QuantileBenchmarksChart classId={classId} />
          </div>
          <ClassDataBenchmarkTable classId={classId} />
        </>
      );
    case tabs[0].key:
    default:
      return <ClassDataMastery classId={classId} />;
    }
  };

  const syncMastery = () => {
    if (classInfo && classInfo.source === ClassSource.GOOGLE) {
      sendMessage('SYNC_GRADES_WITH_CLASSROOM', { classId });
    }
    else {
      openModal(<SyncMasteryModal classAssignments={externalSyncedAssignments} classId={classId} />);
    }
  }

  const hasStudents = !!students.find((x: any) => x.classId === classId);
  const shouldShowSyncButton = selectedTab === tabs[0].key &&
    classInfo &&
    hasStudents &&
    (externalSyncedAssignments.length > 0 || classInfo.source === ClassSource.GOOGLE);

  return (
    <div className={styles.classDataWrapper}>
      <div className={[styles.mathClassDataHeader, styles.hideForPrint].join(' ')}>
        <TabSwitch
          tabs={tabs}
          selectedTab={selectedTab}
          onChange={handleTabChange}
        />
        {shouldShowSyncButton &&
          <Button
            onClick={syncMastery}
            orangeType="empty"
          >
            Sync mastery to {classInfo.source === ClassSource.GOOGLE ? 'Google Classroom' : classInfo.source}
          </Button>
        }
      </div>
      {renderSubTab()}
    </div>
  );
};

export default ClassData;
