import DOMPurify from 'dompurify';
const naturalCompare = require('natural-compare-lite');
import bowser from 'bowser';
import config from '../config';
import { sanitizeUrl } from '@braintree/sanitize-url';

export const sanitize = (
  dirty?: string | null,
  allowedTags?: (keyof JSX.IntrinsicElements)[],
  allowedAttributes?: string[],
): any => {
  if (dirty === null) {
    return null;
  }
  else if (typeof dirty === 'undefined') {
    return dirty;
  }

  allowedTags = [...(allowedTags || []), 'b', 'i', 'u', 'em', 'strong', 'a', 'p', 'br', 'sub', 'sup', 'span'];
  allowedAttributes = [...(allowedAttributes || []), 'href', 'target', 'rel', 'class', 'data-userid'];

  return DOMPurify.sanitize(dirty, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: allowedAttributes,
  });
};

export const sanitizeAllHTMLTags = (
  dirty?: string | null
): any => {
  if (dirty === null) {
    return null;
  }
  else if (typeof dirty === 'undefined') {
    return dirty;
  }

  return DOMPurify.sanitize(dirty, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });
};

export const percent = (input: any, decimals?: any, suffix?: any) => {
  suffix = suffix || '%';
  decimals = !isNaN(decimals) ? decimals : 0;
  if (isNaN(input)) {
    return '';
  }
  return Math.round(input * Math.pow(10, decimals + 2)) / Math.pow(10, decimals) + suffix;
};

export const isFunction = (functionToCheck: any) => {
  const getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
};

export const sortComparator = (a: any, b: any, desc: boolean) => {
  const isSortValueAExist = a !== null && a !== undefined;
  const isSortValueBExist = b !== null && b !== undefined;

  if (isSortValueAExist && isSortValueBExist) {
    if (a === 'N/A' && b !== 'N/A') {
      return desc ? -1 : 1;
    }
    else if (a !== 'N/A' && b === 'N/A') {
      return desc ? 1 : -1;
    }
    else if (a !== b) {
      if (typeof a === 'string' && typeof b === 'string') {
        return naturalCompare(a.toLowerCase(), b.toLowerCase());
      }
      else {
        return a < b ? -1 : 1;
      }
    }
  }
};

export const toOrdinal = (i: any) => {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return i + 'st';
  }

  if (j === 2 && k !== 12) {
    return i + 'nd';
  }

  if (j === 3 && k !== 13) {
    return i + 'rd';
  }

  return i + 'th';
};

export const htmlDecode = (input: string) => {
  // https://stackoverflow.com/a/7394787
  const txt = document.createElement('textarea');
  txt.innerHTML = sanitize(input);
  return txt.value;
};

/**
 * Calculates the height available from the top of the table element to the bottom of the page
 *
 * @return {undefined} undefined
 *
 * @example
 *
 *     adjustTableHeight()
 */
export const adjustTableHeight = () => {
  try {
    const tableWrapper: HTMLElement | null = document.querySelector('.tableWrapper');
    const table: HTMLElement | null = document.querySelector('.tableWrapper table');

    if (tableWrapper && table) {
      const height = window.innerHeight - tableWrapper.getBoundingClientRect().top;

      // Don't do anything if the top of the table has been scrolled above the top of the screen
      // or if you've scrolled down the page and resize the screen, table never needs to be
      // bigger than the device screen or browser window
      if (tableWrapper.getBoundingClientRect().top < 0 || height > window.innerHeight) {
        return;
      }

      const tableHeightReal = table.clientHeight + 6; // add 6 for potential horiz scrollbar
      const tableHeightMaxInWindow = height - 20; // subtract 20 so we get some spacing at the bottom
      tableWrapper.style.height = `${Math.min(tableHeightReal, tableHeightMaxInWindow)}px`;
    }
  }
  catch (e) {
    console.error(e);
  }
};

export const isTouchDevice = (): boolean => {
  // iPadOS & macOS Catalina has same userAgent string, so iPadOS is detected as macOS Catalina by bowser
  // This is a workaround for now to Additionally check for maxTouchPoints > 1
  const isiPadOS = bowser.mac && navigator.maxTouchPoints > 1;

  return bowser.mobile || (bowser.tablet && navigator.userAgent.indexOf('Tablet PC 2.0') < 0) || bowser.ios || isiPadOS;
};

export const openNewWindow = (url: string, target?: string): void => {
  if ((window as any).isIOSApp) {
    if (url.indexOf('http') === 0) {
      (window as any).webkit.messageHandlers.openUrlInSafari.postMessage(url);
    }
    else {
      // read.activelylearn.com url, so open directly in app
      window.open(url);
    }
  }
  else {
    window.open(url, target);
  }
};

export const setCookie = (name: any, value: any, exdays?: any): void => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  const exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);

  const cookieValue = `${name}=${encodeURIComponent(value)}; `;
  const expires = exdays ? `expires=${exdate.toUTCString()}; ` : '';
  const domain = location.href.includes('localhost') ? '' : 'domain=.activelylearn.com; ';

  const cookie = `${cookieValue}${expires}${domain}path=/`;
  document.cookie = cookie;
};

export const getCookie = (name: string): string => {
  const value = document.cookie;
  let start = value.indexOf(' ' + name + '=');

  if (start === -1) {
    start = value.indexOf(name + '=');
  }

  if (start === -1) {
    return '';
  }
  else {
    start = value.indexOf('=', start) + 1;
    let end = value.indexOf(';', start);

    if (end === -1) {
      end = value.length;
    }

    return decodeURIComponent(value.substring(start, end));
  }
};

export const destroyAppInitDataAndRedirect = (data: {
  forwardURL?: string;
  forwardURLExtra?: string;
}): void => {
  const { forwardURL, forwardURLExtra } = data;
  setCookie('lastActiveAt', null, -1);

  // When auto-logged out from tour, do not redirect to tour upon sign in
  const href =
    forwardURL && !forwardURL.includes('/reader/tour')
      ? `${config.productUrl}/account.html?furl=${encodeURIComponent(forwardURL)}${forwardURLExtra || ''}`
      : `${config.productUrl}/account.html`;

  window.location.href = sanitizeUrl(href);
};

export const isValidNumber = (value): boolean => (
  value !== '' && value !== null && !isNaN(value)
);

export const isValidPercentage = (value): boolean => (
  value >= 0 && value <= 100
);

export const getGradeLevelNameFromId = (gradeLevelId) => {
  const gradeLevelIdNameMap = {
    1: 'Grade 2-3',
    2: 'Grade 4-5',
    3: 'Grade 6-8',
    4: 'Grade 9-12',
    5: 'Higher Ed',
    6: 'Adult Learners'
  };

  return gradeLevelIdNameMap[gradeLevelId];
}