import React from 'react';
import PremiumBanner from './ALPremiumBanner';
import PremiumLockIcon from '../../icons/lock.svg';
import { Modal } from '../modal';

interface Props {
  title: string;
  headerText: string;
  hideModal: Function;
}

function PremiumBannerModal({ 
  title, hideModal, headerText = 'This feature is only available on our Prime plan. You can upgrade now to access this feature.',
}: Props): JSX.Element {

  return (
    <Modal
      title={
        <>
          {/*// @ts-ignore */}
          {title} <PremiumLockIcon width={16} height={16} />
        </>
      }
      cancelText="Done"
      cancelCallback={hideModal}
      okText=""
      maxWidth="md"
    >
      <p>{headerText}</p>
      <PremiumBanner />
    </Modal>
  );
}

export default PremiumBannerModal;
