import React, { useEffect } from 'react';
import StepFooter from '../step/footer/component';
import LearnosityQuestionWrapper from '../learnosityQuestionWrapper/container';
import styles from './styles.module.css';

const Step = (props: any): JSX.Element => {
  const {
    stepId,
    problemId,
    problemSetId,
    studentUserId,
    classId,
    hasProblemDataLoaded,
    hasEntityLoaded,
    hasEntityRendered,
    responseExists,
    showStepAnswer,
    fetchData,
    showRuler,
    showProtractor,
  } = props;

  useEffect(() => {
    fetchData({
      stepId,
      problemId,
      problemSetId,
      classId,
      studentUserId: studentUserId || undefined,
      hasProblemDataLoaded,
    });
  // eslint-disable-next-line
  }, [fetchData, stepId, hasEntityLoaded, hasProblemDataLoaded, showRuler, showProtractor]);

  return (
    <main>
      <div className={styles.step}>
        {hasEntityLoaded && <LearnosityQuestionWrapper type="step" showStepAnswer={showStepAnswer} />}
      </div>
      {hasEntityRendered && !responseExists && !studentUserId &&
        <StepFooter showStepAnswer={showStepAnswer} />
      }
    </main>
  );
};

export default Step;