import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { getMasterySegment, percent } from '../../utils/data';
import styles from './styles.module.css';

interface IProps {
  masterySegment: any;
  quantileMeasure?: string;
  isIntervention?: boolean;
  isBenchmark?: boolean;
}

const Mastery = (props: IProps) => {
  const {
    masterySegment,
    quantileMeasure = '',
    isIntervention = false,
    isBenchmark = false,
  } = props;

  const { backgroundColor, label } = masterySegment;
  const isMastered = label === 'Mastery';
  const fontFamily = isIntervention || isMastered
    ? 'WorkSans-Regular'
    : 'WorkSans-SemiBold';

  if (isBenchmark && !quantileMeasure) {
    return (<div></div>);
  }

  return (
    <div
      style={{
        color: quantileMeasure ? '#000' : backgroundColor,
        fontFamily,
      }}
      className={styles.mastery}
    >
      {isMastered &&
        <FaCheck style={{ marginRight: 4 }} />
      }
      {quantileMeasure || label}
    </div>
  );
};

export const getMasterySegmentFromAssignment = (assignment: any) => {
  const masteryPercent: any = percent(assignment.mastery, 1);
  return getMasterySegment(masteryPercent, assignment.numProblemsCompleted);
};

export default Mastery;
