import React from 'react';
import { Snackbar, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fonts } from '../../helpers/constants';

export interface ActionBarProps {
  onCancel: Function;
  message: string;
  okText?: string;
  onOkClick?: Function;
  isOkDisabled?: boolean;
}

const useStyles = makeStyles({
  banner: {
    zIndex: 1000,
    top: 'auto !important',
    bottom: 0,
    '@media (max-width: 480px)': {
      left: 0,
      width: '100vw',
    },

    '& > div': {
      height: 50,
      fontSize: 16,
      fontFamily: Fonts.MEDIUM,
      letterSpacing: 'normal',
      flexWrap: 'nowrap',
      '@media (max-width: 480px)': {
        borderRadius: 0,
      },
    },
  },
  btn: {
    color: 'var(--al-blue-themeBlack) !important',
    fontFamily: Fonts.BOLD,
    fontSize: 14,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
});

/**
 *
 * ActionBar
 * @description Snackbar that shows at top with a message & cancel button
 *
 */
function ActionBar({ onCancel, message, onOkClick, okText, isOkDisabled = false }: ActionBarProps): JSX.Element {
  const styles = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open
      ContentProps={{ 'aria-describedby': 'actionBarMessage' }}
      message={<span id="actionBarMessage">{message}</span>}
      action={
        <div className="flex">
          {!!okText && 
            <Button disabled={isOkDisabled} className={styles.btn} variant="text" onClick={() => onOkClick && onOkClick()}>
              {okText}
            </Button>
          }
          <Button className={styles.btn} variant="text" onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      }
      classes={{
        root: styles.banner,
      }}
    />
  );
}

export default ActionBar;
