import React, { useState, useEffect } from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  dateTimePicker: {
    fontWeight: 600,
    maxWidth: 250,
  },
  withoutKeyboard: {
    '& input': {
      cursor: 'pointer',
    },
  },
});

export interface IPropsDateTimePicker extends DatePickerProps<any> {
  id: string;
  label?: string;
  value: Date | null | string;
  onChange,
  format?: string;
  inputVariant?: 'standard' | 'outlined' | 'filled';
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  autoOk?: boolean;
  allowKeyboardControl?: boolean;
  ampm?: boolean;
  clearable?: boolean;
  disableToolbar?: boolean;
  className?: string;
  placeholder?: string;
  minDateMessage?: string;
  [key: string]: any;
};

export default function DateTimePicker({
  id,
  label = '',
  value,
  onChange,
  withKeyBoard = false, // Don't use with keyboard as am/pm typing does not work currently
  format = 'MM/DD/YYYY hh:mm a',
  inputVariant = 'outlined',
  disabled = false,
  disableFuture = false,
  disablePast = true,
  autoOk = !withKeyBoard,
  allowKeyboardControl = true,
  ampm = true,
  clearable = false,
  disableToolbar = false,
  className = '',
  placeholder = withKeyBoard ? 'mm/dd/yyyy hh:mm' : '',
  minDateMessage = 'Date should not be in the past',
  showTodayButton = true,
  ...rest
}: IPropsDateTimePicker) {
  const styles = useStyles();
  // Need local state to set value locally but do not update in parent state when value is invalid
  const [val, setVal] = useState(value);

  useEffect(() => {
    // sync local state with parent state
    setVal(value);
  }, [value]);

  const classes = classnames([styles.dateTimePicker], className, { [styles.withoutKeyboard]: !withKeyBoard });

  function handleKeyboardDateTimeChange(date, value) {
    let valueToSet = date;

    if (date && date.isValid()) {
      valueToSet = date.format('MM/DD/YYYY hh:mm a');
    }

    setVal(valueToSet);

    // Only change state in parent component if value is either valid or null
    if (!valueToSet || (valueToSet && date.isValid())) {
      onChange(date);
    }
  }

  function getDateString(value) {
    const valueToParse = (value.endsWith(' p') || value.endsWith(' a'))
      ? `${value}m`
      : value;

    // if user enters time like 16:00 pm / 16:00 am
    if (isNaN(Date.parse(valueToParse))) {
      return valueToParse.replace(/ am| pm/, '');
    }
    else {
      return valueToParse;
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {withKeyBoard
        ? <DatePicker
            format={format}
            disableFuture={disableFuture}
            disablePast={disablePast}
            label={label}
            value={val ? dayjs(val) : val}
            disabled={disabled}
            onChange={handleKeyboardDateTimeChange}
            {...rest}
            slotProps={{
              textField: {
                id,
                className: classes,
                variant: inputVariant,
                placeholder,
              }
            }} />
        : <DesktopDatePicker
            format={format}
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={(date) => onChange(date)}
            value={val ? dayjs(val) : val}
            label={label}
            disabled={disabled}
            {...rest}
            slotProps={{
              textField: {
                id,
                className: classes,
                variant: inputVariant,
                placeholder,
              }
            }} />
      }
    </LocalizationProvider>
  );
};
