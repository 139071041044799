import ProblemHeader from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    isBenchmark: state.problemSetData.isBenchmark,
    problemNumber: state.problemSetData.problemIds.indexOf(state.readerNavigation.currentProblemId) + 1,
    mastery: state.problemSetData.mastery,
    problemId: state.readerNavigation.currentProblemId,
  };
};

export default connect(
  mapStateToProps,
)(ProblemHeader);
