import { IConfig } from './types';

const config: IConfig = {
  env: 'local',
  productName: 'Actively Learn',
  productUrl: 'http://localhost:22222',
  backendUrl: 'http://localhost:3000',
  readerAppUrl: 'http://localhost:5550',
  cookieDomain: 'localhost',
  csrfTokenKey: 'CSRF-TOKEN-LOCAL',
  stripeKey: 'pk_test_hGcCTxnNucfD8epy7K4uXcNJ',
};

export default config;
