import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import AL_Logo_Blue_BackgroundIcon from '../../icons/AL_Logo_Blue_Background.svg';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: 'var(--al-blue)',
    height: '100vh',
    overflow: 'hidden',
  },
  container: {
    width: '560px',
    margin: '40px auto',
    fontSize: '20px',
    backgroundColor: 'white',
    fontFamily: 'var(--font-med)',
    borderRadius: '5px',
    boxShadow: '0 2px 4px var(--gray_dark)',

    '& svg': {
      stroke: 'none',
      fill: 'none'
    },

    '& > div': {
      padding: '30px',
    },

    '& p': {
      lineHeight: 1.5
    }
  },
  footNote: {
    margin: '50px 0',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btnGoBack: {
    padding: '10px 16px',
    borderRadius: '20px',
  }
}));

/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */
function NotFound({ pageLoadCallback, exitUrl }): JSX.Element {
  const styles  = useStyles();

  useEffect(() => {
    pageLoadCallback();
    document.title = 'Page not found! - Actively Learn';
  }, []);

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <div>
          <h1 className={styles.flexCol}>
            <a href={exitUrl} aria-label="Page not found!">
              {/* @ts-ignore */}
              <AL_Logo_Blue_BackgroundIcon height={54} width="auto" />
            </a>
          </h1>
          <p className="mb-lg">We couldn't find what you're looking for!</p>
          <p className="mt-lg">We sincerely apologize for the inconvenience.</p>
          <p className={styles.footNote}>-The Actively Learn Team</p>
          <a className={`${styles.btnGoBack} btn-box callout large`} href={exitUrl}>
            Go back to Actively Learn
          </a>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
