import classNames from 'classnames';
import { DateTimePicker } from 'sharedfrontend';
import { formatStartAndDueDate as formatDate } from '../../../utils/common';
import styles from './styles.module.css';

const StartAndDueDatePicker = (props: any): JSX.Element => {
  const assignmentStartDate = props.assignDates[0]?.startDate || null;
  const assignmentDueDate = props.assignDates[0]?.dueDate || null;

  const onChange = (fieldName: string, value: any) => {
    const assignmentInfo = props.assignDates[0];

    if (fieldName === 'startDate') {
      assignmentInfo.startDate = value ? formatDate(new Date(value).toString()) : formatDate(new Date().toString());
    }
    else {
      assignmentInfo.dueDate = props.clearable ? value : value
        ? formatDate(new Date(value).toString())
        : assignmentDueDate;
    }

    if (props.isUpdate) {
      const classes = [...props.assignClasses];
      const selectedClass = classes.find((classData: any) => classData.classId === props.classIdToUpdate);
      selectedClass.problemSets = props.assignDates;
      props.setAssignDates(classes);
    }
    else {
      props.setAssignDates([assignmentInfo]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        Students will not be able to access assignments before the start date and after the due date.
      </div>
      <div className={styles.dateContainer}>
        <DateTimePicker
          label="Start Date:"
          id="startDate"
          placeholder="Select a date"
          value={assignmentStartDate}
          onChange={(value) => onChange('startDate', value)}
          withKeyBoard
          minDateMessage=""
          disablePast={true}
        />
      </div>
      <div className={classNames(styles.dateContainer, 'flex')}>
        <DateTimePicker
          label="Due Date:"
          id="dueDate"
          placeholder="Select a date"
          value={assignmentDueDate}
          onChange={(value) => onChange('dueDate', value)}
          withKeyBoard
          minDateMessage=""
          disablePast={true}
          clearable={props.clearable}
          showTodayButton={props.showTodayButton}
        />
        <div className={styles.optional}>(optional)</div>
      </div>
      <div className={styles.note}>
        Note: You can always edit the dates for each assignment in class &gt; assignments.
        Any assignments that already have dates set will not change.
      </div>
    </div>
  );
};

export default StartAndDueDatePicker;