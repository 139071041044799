export const addReadSpeaker = () => {
  try {
    const readSpeakerJs = document.createElement('script');
    readSpeakerJs.setAttribute('type', 'text/javascript');
    readSpeakerJs.setAttribute('src', 'https://cdn-na.readspeaker.com/script/13049/webReader/webReader.js?pids=wr');
    readSpeakerJs.setAttribute('id', 'rs_req_Init');
    document.head.appendChild(readSpeakerJs);
  }
  catch (err) {
    // do not block reader load if readspeaker errors/goes down
  }
};

export const addReadSpeakerClickEvents = () => {
  try {
    (window as any).rs.q(function () {
      (window as any).rs.ui.addClickEvents(); 
    });
  }
  catch (err) {
    // do not block reader load if readspeaker errors/goes down
  }
};

export const destroyReadSpeakerActivePlayer = () => {
  try {
    (window as any).rs.q(function () {
      (window as any).rs.ui.destroyActivePlayer(); 
    });
  }
  catch (err) {
    // do not block reader load if readspeaker errors/goes down
  }
};