import React from 'react';
import styles from './styles.module.css';

interface IProps {
  children: React.ReactNode;
}

const Checklist = (props: IProps): JSX.Element => {
  return (
    <ul className={styles.checklist}>
      {React.Children.map(props.children, child => (
        <li>
          {child}
        </li>
      ))}
    </ul>
  );
};

export default Checklist;
