import config from '../config';
import { setCookie, getCookie } from './common';
import { sendMessage } from './iframe';
import store from '../store';

export const onAction = () => {
  // log user out if they've come back to page after max inactive time and did any action
  const didLogout = checkAndLogOut();

  if (!didLogout) {
    setCookie('lastActiveAt', +new Date());
  }
};

export const onIdle = () => {
  sendMessage('AUTOLOGOUT');
};

export const checkForAutoLogoutOnLoad = () => {
  // log user out if they've closed their browser tab and then came back
  checkAndLogOut();
};

const checkAndLogOut = () => {
  const lastActiveAtTime = getCookie('lastActiveAt');
  const state = store.getState() as any;
  const maxInactiveTime = state.problemSetData && state.problemSetData.isBenchmark
    ? config.maxInactiveTimeLevelSet
    : config.maxInactiveTime;

  if (lastActiveAtTime && +new Date() - parseInt(lastActiveAtTime) > maxInactiveTime) {
    onIdle();
    return true;
  }

  return false;
};
