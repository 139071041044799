import React from 'react';
import { getDataPlain, postDataPlain } from '../../actions/api';
import { RadioButtonSwitch } from 'sharedfrontend';
import { ClassSettings } from '../../utils/constants';
import showAlert from '../common/toaster';
import styles from './styles.module.css';

const AutoAssignAssignmentToggle = ({ classId }: {
  classId: number;
}): JSX.Element | null => {
  const [checked, setChecked] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      const classSetting = await getDataPlain({
        type: 'CLASS_SETTING',
        params: {
          classId,
          type: ClassSettings.AUTO_ASSIGN_ASSIGNMENTS,
        },
      });
      setChecked(classSetting);
    }

    fetchData();
  }, [classId]);

  const toggleSetting = async () => {
    await postDataPlain({
      type: 'CLASS_SETTING',
      params: {
        classIds: [classId],
        type: ClassSettings.AUTO_ASSIGN_ASSIGNMENTS,
        value: checked ? 'false' : 'true',
      },
    });

    setChecked(!checked);
    showAlert({ message: 'Success!' });
  }

  return (
    <div className={styles.autoAssignContainer}>
      <span>
        Do you want us to auto-assign problem sets based on students&#39; results for this class?
      </span>
      <div className={styles.autoAssignSwitch}>
        <RadioButtonSwitch
          leftOption="No"
          rightOption="Yes"
          checked={checked}
          onChange={toggleSetting}
        />
      </div>
    </div>
  );
};

export default AutoAssignAssignmentToggle;