import { Modal } from 'sharedfrontend';
import React from 'react';
import { closeModal } from '../../../../actions/eventEmitter';
import showAlert from '../../../common/toaster';
import styles from './styles.module.css';

const UpdateProblemProblemSetModal = (props: any) => {

  const { problemId, problemSetId, saveProblemProblemSet } = props;
  const [newProblemSetId, setNewProblemSetId] = React.useState(0);

  const onSave = (modal: any) => {
    if (newProblemSetId >= 0) {
      saveProblemProblemSet({
        problemId,
        oldProblemSetId: problemSetId,
        newProblemSetId: newProblemSetId
      });
      closeModal();
    }
    else {
      modal.enableOkButton();
      showAlert({ message: 'Select a valid Problem Set!', isError: true });
    }
  };

  return (
    <Modal
      title="Change Problem Set"
      okText="Save"
      okCallback={onSave}
      cancelText="Cancel"
      maxWidth="md"
      shouldOverflow={true}
    >
      <React.Fragment>
        <div className={styles.form}>
          <span>New problemset id : </span>
          <input
            type="number"
            onChange={(e: any) => setNewProblemSetId(e.target.valueAsNumber)}
            className={styles.formInput}
          />
        </div>
      </React.Fragment>
    </Modal>
  );
}

export default UpdateProblemProblemSetModal;