import ShowStepAnswerButton from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    showButton: state.stepData.numAttempts > 0 && state.hintsData.allHintsViewed,
    stepId: state.readerNavigation.currentStepId,
    problemSetId: state.problemSetData.problemSetId,
    classId: state.problemSetData.classId,
    role: state.userData.role,
    disableButton: state.readerNavigation.submittedResponse,
  }
}

export default connect(
  mapStateToProps,
)(ShowStepAnswerButton);
