import React from 'react';
import { getDataPlain } from '../../../../actions/api';
import Button from '../../../common/button/component';

class LearnosityHintEditor extends React.Component<any, any> {
  authorApp: any;
  learnosityQuestionEditorSignedRequest: any;

  async componentDidMount() {
    this.learnosityQuestionEditorSignedRequest = await getDataPlain({
      type: 'LEARNOSITY_EDITOR_SIGNED_REQUEST',
    });

    this.renderQuestion();
  }

  renderQuestion = () => {
    this.authorApp = (window as any).LearnosityAuthor.init(this.learnosityQuestionEditorSignedRequest, {
      errorListener: (e: Error) => {
        rg4js('send', { error: e, tags: ['LearnosityAuthor.init'] });
      },
      readyListener: () => {
        this.authorApp
          .on('render:item', () => {
            this.authorApp.setWidget(this.props.learnosityJson.questions[0]);
          });
      },
    });
  }

  save = () => {
    this.authorApp.destroy();
    this.props.onSave(this.props.id, this.authorApp.getWidget());
  }

  close = () => {
    this.authorApp.destroy();
    this.props.onClose();
  }

  render() {
    return (
      <React.Fragment>
        <div id="learnosity-author"></div>
        <Button orangeType="empty" onClick={this.close} size="small">Cancel</Button>
        <Button orangeType="filled" onClick={this.save} size="small">Save and close</Button>
      </React.Fragment>
    );
  }
}

export default LearnosityHintEditor;
