import { connect } from 'react-redux';
import LearnosityQuestion from './component';
import { postData } from '../../../../actions/api';
import { ImplementationType } from '../../../../utils/constants';

const mapStateToProps = (state: any, ownProps: any) => {
  const { currentProblemId, currentStepId, submittedResponse } = state.readerNavigation;

  let learnosityJson = '';
  let id = 0;

  if (ownProps.type === ImplementationType.PROBLEM) {
    id = currentProblemId;
    learnosityJson = ownProps.isGraphingTool ? state.problemData.graphJson : state.problemData.learnosityJson;
  }
  else if (ownProps.type === ImplementationType.STEP) {
    id = currentStepId;
    learnosityJson = ownProps.isGraphingTool ? state.stepData.graphJson : state.stepData.learnosityJson;
  }

  return {
    id,
    type: ownProps.type,
    submittedResponse,
    learnosityJson,
  };
};

const gradeResponse = ({
  id, type, response, timeSpentInSeconds, callback,
}: {
  id: number;
  type: string;
  response: any;
  timeSpentInSeconds: number;
  callback: (data: any) => void;
}) => {
  return (dispatch: any, getState: any) => {
    const {
      problemSetName,
      problemSetId,
      problemIds,
      mastery: currentMastery,
      isBenchmark,
      classId,
      createdFromProblemSetId,
      createdFromProblemSetName,
      assignmentSource,
    } = getState().problemSetData;

    const {
      problemSetName: stepProblemSetName,
      stepProblemSetId,
    } = getState().stepData;

    const { currentProblemId } = getState().readerNavigation;
    const currentIndex = problemIds.indexOf(currentProblemId);
    const nextProblemId = currentIndex === problemIds.length ? null : problemIds[currentIndex + 1];

    if (isBenchmark) {
      dispatch(postData({
        type: 'GRADE_BENCHMARK_RESPONSE',
        params: {
          problemId: id,
          classId,
          problemSetId,
          responseJson: JSON.stringify(response),
          timeSpentInSeconds,
        },
        mutators: {
          onResolve: (resolveAction: any) => {
            const body = {
              ...resolveAction.body,
              nextProblemId,
              classId,
              problemSetName,
              isBenchmark,
              problemSetId,
            };

            callback(body);

            return {
              ...resolveAction,
              body,
            };
          },
        },
      }));
    }
    else {
      dispatch(postData({
        type: 'GRADE_RESPONSE',
        params: {
          [`${type}Id`]: id,
          responseJson: JSON.stringify(response),
          timeSpentInSeconds,
          classId,
          problemSetId,
        },
        mutators: {
          onResolve: (resolveAction: any) => {
            const body = {
              ...resolveAction.body,
              nextProblemId,
              id,
              type,
              response,
              isSameProblemSetId: resolveAction.body.problemSetId === problemSetId ||
                type === ImplementationType.PROBLEM,
              problemIds,
              currentMastery,
              classId,
              problemSetName,
              problemSetId,
              stepProblemSetName,
              stepProblemSetId,
              createdFromProblemSetId,
              createdFromProblemSetName,
              assignmentSource,
            };

            callback(body);

            return {
              ...resolveAction,
              body,
            };
          },
        },
      }));
    }
  };
};

const problemRendered = () => {
  return (dispatch: any) => {
    dispatch({ type: 'PROBLEM_RENDERED' });
  };
};

export default connect(
  mapStateToProps,
  { gradeResponse, problemRendered },
)(LearnosityQuestion);