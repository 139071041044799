import ExitButton from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    classId: state.problemSetData.classId,
    role: state.userData.role,
    isIntervention: state.problemSetData.isIntervention,
    isOnStep: !!state.readerNavigation.currentStepId,
  };
};

export default connect(
  mapStateToProps,
)(ExitButton);
