import React from 'react';
import Button from '../../../common/button/component';

const ShowStepAnswerButton = (props: any): JSX.Element => {
  const { stepId, problemSetId, showButton, showStepAnswer, classId, disableButton } = props;

  return showButton && props.validRoles.includes(props.role)
    ? (
      <Button
        orangeType="empty"
        onClick={() => showStepAnswer(stepId, problemSetId, classId)}
        disabled={disableButton}
      >
        show answer
      </Button>
    )
    : <React.Fragment></React.Fragment>
};

export default ShowStepAnswerButton;
