import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const activelyLearnTheme = createTheme({
  typography: {
    fontFamily: '\"WorkSans-Medium\", \"Helvetica\", \"Arial\", sans-serif',
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#0077d1",
  
          "&$focused": {
            color: "#0077d1",
          },
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
        underline: {
          "&:after": {
            borderBottom: "2px solid #0077d1",
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: "#0077d1",
        },
      }
    },
  }
});

interface IDisabledDatePickerPickerProps {
  label: string;
  onChange: Function;
  selectedDate: Dayjs;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const DisabledDatePicker: React.FunctionComponent<IDisabledDatePickerPickerProps> = (props) => {
  const { label, selectedDate, onChange, minDate, maxDate } = props;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={activelyLearnTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            format="MMM DD, YYYY"
            minDate={minDate}
            maxDate={maxDate}
            label={label}
            value={selectedDate ? dayjs(selectedDate) : selectedDate}
            onChange={(date) => onChange(date)}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DisabledDatePicker;