import React from 'react';
import cn from 'classnames';
import Label, { getLabelFromAssignment } from '../label/label.component';
import DueDate from '../dueDate/dueDate.component';
import Mastery, { getMasterySegmentFromAssignment } from '../mastery.component';
import { redirectToProblemSet } from '../common';
import styles from './styles.module.css';
import { FaArrowUp, FaArrowDown, FaArrowRight } from 'react-icons/fa';

interface IProps {
  assignments: any;
  onSort: () => void;
  title: string;
  disabled?: boolean;
}

const Table = (props: IProps) => {
  const {
    assignments = [],
    disabled = false,
    onSort,
    title,
  } = props;

  const [showingAll, setShoingwAll] = React.useState(false);
  const [sortAsc, setSortAsc] = React.useState(true);
  const assignmentsToShow = showingAll ? assignments : assignments.slice(0, 6);
  const isLevelset = title === 'LEVELSETS';
  const isMastered = title === 'MASTERED';
  const count = isMastered
    ? getMasteredCount(assignments)
    : assignments.length;

  const handleClickSort = () => {
    setSortAsc(!sortAsc);
    onSort();
  }

  if (assignments.length === 0) {
    return null;
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTitleContainer}>
        <div className={styles.tableTitle}>
          {`${title} (${count})`}
        </div>
        <div
          className={styles.tableTitleSort}
          onClick={handleClickSort}
          onKeyDown={handleClickSort}
          role="button"
          tabIndex={0}
        >
          {sortAsc &&
            <FaArrowUp style={{ marginRight: 4 }} />
          }
          {!sortAsc &&
            <FaArrowDown style={{ marginRight: 4 }} />
          }
          Sort
        </div>
      </div>
      <div className={styles.tableContentContainer}>
        <div className={styles.tableHeader}>
          <div>Type</div>
          <div>Title</div>
          <div>Start / End</div>
          <div>Completed</div>
          <div>{isLevelset ? 'Quantile®' : 'Mastery'}</div>
        </div>
        {assignmentsToShow.map((a: any) =>
          <TableRow key={`assignment_${a.problemSetId}`} assignment={a} disabled={disabled} />
        )}
        {assignments.length > 6 && !showingAll &&
          <div className={styles.tableViewAllContainer}>
            <span
              className={styles.tableViewAllButton}
              onClick={() => setShoingwAll(true)}
              onKeyDown={() => setShoingwAll(true)}
              role="button"
              tabIndex={0}
            >
              {`View all skills in "${title}"`} <FaArrowRight style={{ marginLeft: 4 }} />
            </span>
          </div>
        }
      </div>
    </div>
  );
};

export const TableRow = ({
  assignment, isWelcomeCard = false, className = '', disabled = false
}: { assignment: any; isWelcomeCard?: boolean; className?: string; disabled?: boolean }) => {

  const containerClass = cn({
    [styles.tableRowContainer]: true,
    [className]: true,
  });

  return (
    <div className={containerClass}>
      <TableRowContents
        assignment={assignment}
        isWelcomeCard={isWelcomeCard}
        disabled={disabled}
      />
      {assignment.interventions && assignment.interventions.map((pr: any) =>
        <TableRowContents
          key={`preReq_${pr.problemSetId}`}
          assignment={pr}
          isWelcomeCard={isWelcomeCard}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export const TableRowContents = ({
  assignment, isWelcomeCard = false, className='', disabled = false
}: { assignment: any; isWelcomeCard?: boolean; className?: string; disabled?: boolean }) => {

  const isDisabled = disabled
    || (new Date() < new Date(assignment.startDate.replace(' ', 'T') + 'Z'))
    || assignment.isInvalidLevelSetAttempt;
  const tableRowClasses = cn({
    [styles.tableRow]: true,
    [styles.tableRowNoMastery]: isWelcomeCard,
    [styles.tableRowDisabled]: isDisabled,
    [className]: true,
  });

  const onClickHandler = (a: any, disabled: boolean) => disabled
    ? () => {}
    : () => redirectToProblemSet(a);

  return (
    <div
      className={tableRowClasses}
      role="button"
      tabIndex={0}
      onClick={onClickHandler(assignment, isDisabled)}
      onKeyDown={onClickHandler(assignment, isDisabled)}
    >
      <Label label={getLabelFromAssignment(assignment)} />
      <div className={styles.assignmentNameTile}>{assignment && assignment.name}</div>
      <DueDate
        startDate={new Date(assignment.startDate.replace(' ', 'T') + 'Z')}
        endDate={assignment.endDate && new Date(assignment.endDate.replace(' ', 'T') + 'Z')}
      />
      <div className={styles.completedCount}>{`${assignment.numProblemsCompleted} Complete`}</div>
      {!isWelcomeCard &&
        <Mastery
          masterySegment={getMasterySegmentFromAssignment(assignment)}
          quantileMeasure={assignment.quantileMeasure}
          isIntervention={!!assignment.createdFromId}
          isBenchmark={!!assignment.isBenchmark}
        />
      }
    </div>
  );
};

const getMasteredCount = (masteredAssignments: any) => {
  const numAssignments = masteredAssignments.length;
  const numMasteredInterventions = masteredAssignments.reduce((acc: number, a: any) => {
    const masteredInterventions = a.interventions.filter((i: any) => !!i.masteredDate);
    return acc + masteredInterventions.length;
  }, 0);

  return numAssignments + numMasteredInterventions;
};

export default Table;
