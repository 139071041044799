import React from 'react';
import Button from '../../../common/button/component';
import { FaPencilAlt } from 'react-icons/fa';
import { Tooltip } from 'sharedfrontend';

export const EditButton = (props: any): JSX.Element => {
  return (
    <Button
      iconSize="small"
      onClick={props.onClick}
    >
      <Tooltip title="Edit hint">
        <span><FaPencilAlt /></span>
      </Tooltip>
    </Button>
  );
};
