import React from 'react';
import Button from '../../../common/button/component';
import LearnzillionVideosModal from '../../../common/modals/learnzillionVideos/container';
import { openModal } from '../../../../actions/eventEmitter';

const ShowOverviewVideosButton = (props: any): JSX.Element => {
  return props.videoData.length > 0
    ? (
      <Button
        orangeType="empty"
        onClick={() => openModal(<LearnzillionVideosModal videos={props.videoData} />)}
      >
        watch overview video
      </Button>
    )
    : <React.Fragment></React.Fragment>
};

export default ShowOverviewVideosButton;