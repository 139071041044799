import Button from '../button/component';
import { sendMessage } from '../../../utils/iframe';

const AssignButton = (props: any): JSX.Element => {
  if (props.validRoles.includes(props.role) && !props.classId) {
    const { 
      assignedProblemSetIds, 
      problemSetId, 
      isBenchmark, 
      problemSets, 
      revealBookTitle, 
      isProblemSetAssigned 
    } = props;
    const isAssigned = isBenchmark
      ? problemSets.some((ps: any) => assignedProblemSetIds.includes(ps.problemSetId))
      : assignedProblemSetIds.includes(problemSetId) || isProblemSetAssigned;
    const eventData = { 
      problemSets: isBenchmark ? problemSets : [{ problemSetId }],
      isBenchmark,
      revealBookTitle,
    };

    return (
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          sendMessage('CHECK_FIRST_CLASS', eventData);
        }}
        orangeType={isAssigned ? 'filled' : 'empty'}
      >
        {isAssigned ? 'Assigned' : 'Assign'}
      </Button>
    );
  }
  else {
    return <></>;
  }
};

export default AssignButton;
