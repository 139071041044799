import React from 'react';
import GoToProblemButton from '../buttons/goToProblem/container';
import styles from './styles.module.css';
import showAlert from '../../common/toaster';
import { Role } from '../../../utils/constants';

const Paging = (props: any): JSX.Element => {
  const canEdit = props.role === Role.SUPERADMIN;

  return props.validRoles.includes(props.role)
    ? (
      <div className={styles.paging}>
        <GoToProblemButton type="previous" validRoles={props.validRoles} />
        <div className={styles.numbers}>
          {canEdit ? <EditProblemNumber {...props} /> : props.problemNumber} / {props.numProblems}
        </div>
        <GoToProblemButton type="next" validRoles={props.validRoles} />
      </div>
    )
    : <React.Fragment></React.Fragment>;
};

export default Paging;

const EditProblemNumber = (props: any) => {
  const [problemNumber, setProblemNumber] = React.useState(props.problemNumber);
  const problemNumberRef = React.useRef(props.problemNumber);

  React.useEffect(() => {
    if (props.problemNumber !== problemNumberRef.current) {
      setProblemNumber(props.problemNumber);
      problemNumberRef.current = props.problemNumber;
    }
  }, [props.problemNumber, problemNumberRef])

  const moveProblem = (ev: any) => {
    if (ev.target.value) {
      const newProblemNumber = parseInt(ev.target.value);
      setProblemNumber(newProblemNumber);
      problemNumberRef.current = newProblemNumber;

      if (props.problemNumber !== newProblemNumber) {
        if (newProblemNumber > props.numProblems || newProblemNumber < 1) {
          showAlert({
            message: `Problem number should be greater than 0 and less than ${props.numProblems}`
          });
        }
        else {
          props.moveProblem(newProblemNumber);
        }
      }
    }
    else {
      setProblemNumber(props.problemNumber);
      problemNumberRef.current = props.problemNumber;
    }
  };

  return (
    <input
      className={styles.input}
      type="number"
      min={1}
      max={props.numProblems}
      value={problemNumber}
      onChange={(ev: any) => setProblemNumber(ev.target.value)}
      onBlur={moveProblem}
    />
  );
};