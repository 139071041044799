import React, { forwardRef } from 'react';
import classNames from 'classnames';
import globalStyles from "../styles.module.css";

interface Props {
  href: string;
  children: React.ReactNode;
  className?: string;
  noStyles?: boolean;
  [key: string]: any;
}

function ExternalLink({ href, children, className = '', noStyles, ...restProps }: Props, ref): JSX.Element {
  return (
    <a
      ref={ref}
      rel="noopener noreferrer"
      href={href}
      target="_blank"
      className={classNames(className, { link: !noStyles })}
      {...restProps}
    >
      {children}
      <span className={globalStyles.visuallyHidden}>Opens in new tab</span>
    </a>
  );
}

export default forwardRef(ExternalLink) as any;
