import { connect } from 'react-redux';
import Component from './component';

const mapStateToProps = (state: any) => {
  return {
    firstName: state.userData.firstName,
  };
};

export default connect(
  mapStateToProps,
)(Component);
