import React from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import InfoCircleIcon from '../../icons/info_circle.svg';
import Legend, { LegendProps } from './Legend';
import Label from '../text/Label';
import Title from '../text/Title';
import { Tooltip } from '../tooltip/index';

interface IProps {
  className?: string;
  column?: boolean;
  title: string;
  subtitle?: JSX.Element;
  legend?: LegendProps;
  legendTooltip?: JSX.Element;
  tooltip?: JSX.Element;
  iconSize?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    width: '100%',
    flex: 1
  },
  titleSection: {
    boxSizing: 'border-box',
    flex: 1,
    padding: '0 10px 0 0',
    width: '100%',

    '& > div': {
      alignItems: 'center',
      display: 'flex',
    }
  },
  title: {
    marginBottom: '5px',
    marginTop: 0
  },
  tooltip: {
    marginLeft: '15px'
  },
  legendSection: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
  },
  column: {
    flex: '1 1 330px',
    flexDirection: 'column',

    [theme?.breakpoints?.up('sm')]: {
      flex: '0 1 330px',
    },

    '& $titleSection': {
      marginBottom: '40px'
    }
  },
  row: {}
}));

const ChartHeader: React.FunctionComponent<IProps> = (props) => {
  const { title, subtitle, tooltip, legend, legendTooltip, column = true, iconSize = 20 } = props;
  const styles = useStyles();

  return (
    <div className={classnames(styles.root, column ? styles.column : styles.row)}>
      <div className={styles.titleSection}>
        <div>
          <Title className={styles.title}>{title}</Title>
          {tooltip &&
            <Tooltip title={tooltip}>
              <span className={styles.tooltip}>
                {/*// @ts-ignore */}
                <InfoCircleIcon width={iconSize} height={iconSize} />
              </span>
            </Tooltip>
          }
        </div>
        {subtitle && <Label>{subtitle}</Label>}
      </div>
      <div className={styles.legendSection}>
        {legend && <Legend title={legend.title} items={legend.items} variant={legend.variant} />}
        {legendTooltip}
      </div>
    </div>
  );
}

export default ChartHeader;
