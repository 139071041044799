import React from 'react';
import ReactSpeedometer, { CustomSegmentLabelPosition } from 'react-d3-speedometer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { colors } from '../../utils/style';
const {
  beginningRed,
  developingRed,
  approachingGreen,
  masteryGreen,
} = colors;

interface IProps {
  mastery: any;
  numProblemsCompleted: number;
}

const MasteryGauge = (props: IProps) => {
  const { mastery, numProblemsCompleted } = props;
  const matchesTablet = useMediaQuery('(min-width:481px) and (max-width: 768px)');
  const matchesMobile = useMediaQuery('(max-width: 480px)');
  let height = 180;
  let width = 350;
  let ringWidth = 80;
  if (matchesTablet) {
    height = 150;
    width = 280;
    ringWidth = 65;
  }
  else if (matchesMobile) {
    height = 125;
    width = 250;
    ringWidth = 50;
  }

  let scaledMastery = getMasteryValue(mastery);
  if (numProblemsCompleted < 3) {
    scaledMastery = 0;
  }
  else if (scaledMastery >= 0.75 && numProblemsCompleted < 5) {
    scaledMastery = 0.75;
  }

  return (
    <ReactSpeedometer
      forceRender={true}
      height={height}
      width={width}
      ringWidth={ringWidth}
      needleHeightRatio={0.8}
      needleColor="#383838"
      paddingHorizontal={10}
      paddingVertical={10}
      value={scaledMastery}
      currentValueText=" "
      minValue={0}
      maxValue={1}
      segments={4}
      segmentColors={[beginningRed, developingRed, approachingGreen, masteryGreen]}
      customSegmentLabels={[
        {
          text: 'Beginning',
          position: 'OUTSIDE' as CustomSegmentLabelPosition,
        },
        {
          text: 'Developing',
          position: 'OUTSIDE' as CustomSegmentLabelPosition,
        },
        {
          text: 'Approaching',
          position: 'OUTSIDE' as CustomSegmentLabelPosition,
        },
        {
          text: 'Mastery',
          position: 'OUTSIDE' as CustomSegmentLabelPosition,
        },
      ]}
    />
  );
};

const getMasteryValue = (mastery: number | null) => {
  if (!mastery) {
    return 0;
  }
  else if (mastery < 0.6) {
    return (mastery / 0.6) * 0.25;
  }
  else if (mastery < 0.8) {
    return (((mastery - 0.6) / 0.2) * 0.25) + 0.25;
  }
  else if (mastery < 0.9) {
    return (((mastery - 0.8) / 0.1) * 0.25) + 0.5;
  }
  else {
    return (((mastery - 0.9) / 0.1) * 0.25) + 0.75;
  }
};

export default MasteryGauge;
