import { connect } from 'react-redux';
import { getData } from '../../actions/api';
import StudentKeyUsageChart from './studentKeyUsage.component';

const mapStateToProps = (state: any) => {
  return {
    selectedGradeLevel: state.reports.selectedGradeLevel,
    selectedSchoolIds: state.reports.selectedSchoolIds,
    startDate: state.reports.dateFilter.startDate,
    endDate: state.reports.dateFilter.endDate,
    data: state.reports.usageData,
    benchmarkSource: state.reports.benchmarkSourceFilter,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: ({
      classId,
      endDate,
      gradeLevel,
      schoolIds,
      startDate,
      benchmarkSource,
    }: {
      classId: number;
      gradeLevel: string;
      schoolIds: number[];
      startDate: string;
      endDate: string;
      benchmarkSource: string;
    }) => {
      dispatch(getData({
        type: 'ADMIN_REPORT_KEY_USAGE_DATA',
        params: {
          classId,
          gradeLevel,
          schoolIds,
          startDate,
          endDate,
          benchmarkSource,
        },
      }));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentKeyUsageChart);
