import React from 'react';
import { ReactSelect, ReactSelectComponents } from 'sharedfrontend';
import styles from './styles.module.css';
import { getDataPlain } from '../../../../actions/api';
import GradeFeedbackBar from '../../../reader/gradeFeedbackBar/component';
import { plural, secondsToFriendlyFormat } from '../../../../utils/common';
import { colors } from '../../../../utils/style';
import { FaRegClock, FaRegLightbulb, FaRegEye } from 'react-icons/fa';

const stepDropdownStyles = {
  container: (base: any) => ({
    ...base,
    width: 250,
    marginRight: 20,
  }),
  control: (base: any) => ({
    ...base,
    padding: '1px 6px',
    cursor: 'pointer',
  }),
  indicatorSeparator: (base: any) => ({
    display: 'none',
  }),
  option: (base: any) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
  }),
  placeholder: (base: any) => ({
    ...base,
    fontFamily: 'AvenirNext-Medium',
    textTransform: 'uppercase',
    fontSize: 12,
    color: colors.darkBlue,
    letterSpacing: 0.5,
  }),
  menu: (base: any) => ({
    ...base,
    width: 250,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999
  }),
}

let activity: any[] = [];

class ProblemResponse extends React.Component<any, any> {
  state = {
    suggestions: [],
    selectedIndex: 0,
    hasEntityLoaded: true,
  }

  async componentDidMount() {
    activity = await getDataPlain({
      type: 'STUDENT_DATA_PROBLEM_ACTIVITY',
      params: {
        problemId: this.props.problemId,
        resetId: this.props.resetId,
        studentUserId: this.props.studentUserId,
        classId: this.props.classId,
      }
    });

    const suggestions = activity.map((x: any, i: number) => ({
      value: i,
      label: x.name,
      stepName: x.stepName,
    }));

    this.setState({ suggestions });

    this.loadQuestion();
  }

  loadQuestion = () => {
    (window as any).LearnosityApp.init(activity[this.state.selectedIndex].learnosityJson, {
      errorListener: (e: Error) => {
        console.log('error', e);
      },
    });
  }

  handleOnChange = (selectedSuggestion: { value: number; label: string }) => {
    const selectedIndex = selectedSuggestion.value;

    this.setState({ selectedIndex, hasEntityLoaded: false }, () => {
      this.setState({ hasEntityLoaded: true }, () => window.setTimeout(this.loadQuestion, 100));
    });
  }

  render() {
    const { suggestions, selectedIndex, hasEntityLoaded } = this.state;

    if (activity.length === 0) {
      return <div>Loading...</div>
    }

    const selectedSuggestion = activity[selectedIndex];
    const {
      timeSpentInSeconds,
      feedbackMessage,
      isCorrect,
      numHintViews,
      wasShownAnswer,
      timeSpentInSecondsInSolutionVideos,
    } = activity[selectedIndex];

    const totalTimeSpent = timeSpentInSeconds + timeSpentInSecondsInSolutionVideos;

    return (
      <React.Fragment>
        <div className={styles.container}>
          <div className={styles.summary}>
            {suggestions.length > 1 &&
              <ReactSelect
                value={suggestions[selectedIndex]}
                suggestions={suggestions}
                components={{ Option }}
                isSearchable={false}
                onChange={this.handleOnChange}
                styles={stepDropdownStyles}
              />
            }
            {!!totalTimeSpent &&
              <div className={styles.iconContainer}>
                <FaRegClock style={{ marginRight: 5 }}/>
                {secondsToFriendlyFormat(totalTimeSpent)}
              </div>
            }
            {numHintViews !== null &&
              <div className={styles.iconContainer}>
                <FaRegLightbulb style={{ marginRight: 5 }}/>
                {`${plural(numHintViews, '%count hint')} viewed`}
              </div>
            }
            {!!wasShownAnswer &&
              <div className={styles.iconContainer}>
                <FaRegEye style={{ marginRight: 5 }}/>
                {'answer viewed'}
              </div>
            }
          </div>
          {hasEntityLoaded &&
            <React.Fragment>
              <div id={`container-${selectedSuggestion.id}`}>
                <span className={`learnosity-response question-${selectedSuggestion.id}`}></span>
              </div>
              <GradeFeedbackBar gradeFeedbackData={{ feedbackMessage, isCorrect }} hideToolbar={true} />
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

const Option = (props: any) => {
  const { attempt, stepName } = activity[props.value];

  return (
    <ReactSelectComponents.Option {...props}>
      <label>
        {props.label}
        {attempt &&
          <span className={styles.attemptLabel}>Attempt {attempt}</span>
        }
        {stepName &&
          <span className={styles.stepName}>{stepName}</span>
        }
      </label>
    </ReactSelectComponents.Option>
  );
};

export default ProblemResponse;
