interface ReturnTypeIProps {
  type: string;
  body?: any;
}

export const goToProblem = (problemId: number): ReturnTypeIProps => ({
  type: 'GO_TO_PROBLEM',
  body: { problemId },
});

export const goToStep = (stepId: number): ReturnTypeIProps => ({
  type: 'GO_TO_STEP',
  body: { stepId },
});

export const submittedResponse = (): ReturnTypeIProps => ({
  type: 'SUBMITTED_RESPONSE',
});

export const markAllHintsViewed = (): ReturnTypeIProps => ({
  type: 'ALL_HINTS_VIEWED',
});

export const closeFeedbackBar = (): ReturnTypeIProps => ({
  type: 'CLOSE_FEEDBACK_BAR',
});

export const exitReader = (): ReturnTypeIProps => ({
  type: 'EXIT_READER',
});
