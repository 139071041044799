import ProblemResponse from './component';
import { connect } from 'react-redux';
import { goToStep } from '../../../../actions/readerNavigation';

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToStep: (stepId: number) => {
      dispatch(goToStep(stepId));
    },
  }
};

export default connect(
  undefined,
  mapDispatchToProps
)(ProblemResponse);
